import { Component, OnInit } from '@angular/core';
import { CartService } from 'app/services/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CartItem } from 'app/models/cartitem';
import { Subscription } from 'rxjs';
import { CartState } from 'app/models/cart-state';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  public items: CartItem[] = [];
  public totalPrice = 0;  
  public numberOfItems: number;
  private subscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private cartService: CartService,
              private userService: UserService,
              private router: Router) { }

  ngOnInit() {
    this.subscription = this
      .cartService
      .cartState
      .subscribe((state: CartState) => {
        this.items = state.items;
        this.totalPrice = state.totalPrice;
        this.numberOfItems = state.numberOfItems;
      });

    this.cartService.loadCart();
  }

  remove(id: string): void {
    const cart: any = JSON.parse(localStorage.getItem('cart'));

    let item: CartItem;
    for (let i = 0; i < cart.length; i++) {
      item = JSON.parse(cart[i]);
      if (item.product.id === id) {
        cart.splice(i, 1);
        break;
      }
    }
    localStorage.setItem('cart', JSON.stringify(cart));
    this.cartService.loadCart();
    this.totalPrice = this.cartService.totalPrice();
  }

  goToCheckout() {
    if (this.userService.currentFirebaseUser) {
      this.router.navigate(['shipping']);
    } else {
      this.router.navigate(['checkout']);
    }
  }

}
