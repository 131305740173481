import { Component, OnInit, Input } from '@angular/core';
import { CartItem } from '../../models/cartitem';
import { CartService } from '../../services/cart.service';
import { ProductService } from 'app/services/product.service';
import { Subject, Observable } from 'rxjs';
import { Cart } from 'app/models/cart';
import { CustomerService } from 'app/services/customer.service';
import { CartState } from 'app/models/cart-state';

@Component({
  selector: 'productitem',
  templateUrl: './productitem.component.html',
  styleUrls: ['./productitem.component.scss']
})
export class ProductitemComponent implements OnInit {
  @Input() item: CartItem;
  @Input() edit: Boolean;
  @Input() cart: Cart;

  private itemQuantitySubject: Subject<string> = new Subject<string>();

  constructor(private cartService: CartService,
    private customerService: CustomerService,
    private productService: ProductService) { }

  ngOnInit() {
    this.customerService.cartState.subscribe((state: CartState) => {
      this.cart.cartItems = state.items;
      let itemIndex = this.cart.cartItems.findIndex(p => p.product.id == this.item.product.id);
      if (itemIndex != -1) {
        this.item = this.cart.cartItems[itemIndex];
      }
    });
  }

  updatedQuantity(quantity: string) {
    this.itemQuantitySubject.next(quantity);
  }

  getUpdatedQuantity(): Observable<string> {
    return this.itemQuantitySubject.asObservable();
  }

  updateItem(item: CartItem, quantity: string) {
    this.productService.getProductById(item.product.id).then(product => {
        this.item.quantity = Number(quantity);
        this.cart.addItem(this.item);
        this.customerService.updateCart(this.cart);
    });
  }

  removeItem(item: CartItem): void {
    if (confirm("Weet je zeker dat je " + item.product.title + " wilt verwijderen?")) {
      this.cart.removeItem(this.item);
      this.customerService.updateCart(this.cart);
    }
  }

}
