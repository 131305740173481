import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ProductService } from 'app/services/product.service';
import { Product } from 'app/models/product';
import { UserService } from 'app/services/user.service';
import { Photo } from 'app/models/photo';
import { CartService } from 'app/services/cart.service';
import { PrivateGroupService } from 'app/services/private-group.service';
import { User } from 'app/models/user';
import { ShopProductCategory } from 'app/models/shop-product-category';
import { ShopProductCategoryService } from 'app/services/shop-product-category.service';
import { Pagination } from 'app/models/pagination.model';
import { PaginationService } from 'app/services/pagination.service';

declare const $: any;

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, AfterViewChecked {
  private popoverApplied: boolean = false;

  constructor(
    private productService: ProductService,
    private cartService: CartService,
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService,
    private privateGroupService: PrivateGroupService,
    private shopProductCategoryService: ShopProductCategoryService,
    public paginationService: PaginationService
  ) {
    this.pagination = new Pagination(12);
    this.route.params.subscribe(params => this.loadData(params.user_id));
  }
  ngAfterViewChecked(): void {
    this.loadPopoverData();
  }

  public products: Product[] = [];
  public productPhotos: Photo[];
  public userSlug: string;
  public showList: boolean = false;
  public privateShopOwners: User[] = new Array<User>();
  public users: User[] = new Array<User>();
  public user: User = null;
  public shopProductCategories: ShopProductCategory[];
  public categoryProducts: Product[] = new Array<Product>();
  public categoriesWithProducts: ShopProductCategory[];
  public categoriesWithProductsCount: number[] = new Array<number>();
  public pagination: Pagination;

  toggleList(boolVal: boolean) {
    this.showList = boolVal;
  }

  async ngOnInit() {
    if (this.userService.userHasProducts) {
      this.toggleList(false);
    }
  }

  async loadPopoverData() {

    if($("[data-toggle=popover]").length > 1 && !this.popoverApplied) {
      $("[data-toggle=popover]").popover({
        html : true,
        content: function() {
            var content = $(this).attr("data-popover-content");
            return $(content).children(".popover-body").html();
        }
      });      
      this.popoverApplied = true;
    }
  }

  async loadUsers(slug = '') {
    if (slug !== '') {
        this.user = await this.userService.findUserBySlug(slug);
        this.users.push(this.user);
        this.shopProductCategories = await this.shopProductCategoryService.getCategories(this.user.id);
    } else {
      this.user = null;
      this.users = await this.userService.getAllUsers();
    }
  }

  async loadData(slug = '') {
    await this.loadUsers(slug);
    await this.loadProducts(slug);
    
    this.products?.forEach(product => {
      if (product.user === undefined || product.user == null || !product.user?.hasOwnProperty('id')) {
  
        const user = this.users.find(u => u.id == product.user_id);
        product.user = User.construct(user);
      }
      else {
        product.user = User.construct(product.user);
      }
    })
    // setTimeout(this.loadPopoverData, 500);
  }

  async loadProducts(slug = '') {
    this.userSlug = slug;

    if (slug !== '') {

      if(this.user == null) {
        this.user = await this.userService.findUserBySlug(slug);
      }

      if (this.shopProductCategories.length > 0) {
        this.products = new Array<Product>();
        this.categoriesWithProducts = new Array<ShopProductCategory>();
        this.shopProductCategories.forEach(async shopProductCategory => {
          const shopCategoryProducts = await this.productService.getProductsByShopProductCategoryId(this.user, shopProductCategory.id, 9999, null, true);
                    
          if (shopCategoryProducts.products.length > 0) {
            this.categoriesWithProducts.push(shopProductCategory);
            this.categoryProducts[shopProductCategory.id] = shopCategoryProducts.products;
            this.categoriesWithProductsCount[shopProductCategory.id] = await this.productService.getProductCategoryCount(this.user.id, shopProductCategory.id);
          }
        })
      }
      else {
        const products = await this.productService.getProducts(this.user, this.pagination.itemsPerPage, this.pagination.lastVisible);
        
        this.pagination.updateLastVisible(products.lastVisible);
        if (products.products.length > 0) {
          this.products.push(...products.products);
          this.pagination.setHasMore(products.products.length === this.pagination.itemsPerPage);
        }
        else {
          this.pagination.setHasMore(false);
        }
      }

    } else {
      const products = await this.productService.getAllProducts(this.pagination.itemsPerPage, this.pagination.lastVisible);
      if (products.products.length > 0) {
        this.pagination.updateLastVisible(products.lastVisible);
        this.products.push(...products.products);

        this.pagination.setHasMore(products.products.length === this.pagination.itemsPerPage);
      }
      else {
        this.pagination.setHasMore(false);
      }
    }
  }

  gotoProduct(product: Product) {
    this.router.navigate(['/products', product.slug]);
  }

  addToCart(product: Product) {
    this.cartService.addToCart(product);
  }

  editProduct(product: Product) {
    this.router.navigate(['/products', product.slug, 'edit']);
  }

  deleteProduct(product: Product) {
    if (confirm("Weet je zeker dat je " + product.title + " wilt verwijderen?")) {
      this.productService.deleteProduct(product.id).then(d => {
        this.deleteFromProducts(product.id);
      })
    }
  }

  deleteFromProducts(id: string) {
    const productIndex = this.products.findIndex(p => p.id == id);

    if (productIndex != -1) {
      this.products.splice(productIndex, 1);
    }
  }

  canOrderProduct(product: Product): boolean {
    return this.userService.canOrderProduct(product, this.cartService);
  }

  hasStock(product: Product): boolean {
    return this.userService.productHasStock(product, this.cartService);
  }

  productRouterLink(product: Product) {
    if (this.userSlug === undefined || this.userSlug === '') {
      return `/products/${product.slug}`;
    }
    else {
      return ['/', this.userSlug, 'products', product.slug];
    }
  }

  productCategoryHasProducts(categoryId: string): boolean {
    if (!this.shopProductCategories || this.shopProductCategories.length == 0) {
      return false;
    }

    if(!this.categoryProducts || this.categoryProducts.length == 0)
      return false;
    
    return this.categoryProducts[categoryId] && this.categoryProducts[categoryId].length > 0;
  }

  onNextPage() {
    this.pagination.changePage(this.pagination.currentPage + 1);
    
    this.loadProducts(this.userSlug);
  }

}
