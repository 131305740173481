<div class="content">
  <div class="product-data">
    <div class="image-wrapper">
      <div class="image-container">
        <img class="img-responsive" src="{{item.product_photo}}" alt="{{item.product_title}}"
          title="{{item.product_title}}" style="max-width: 90px; max-height: 120px;" />
      </div>
    </div>
    <div class="info-wrapper">
      <div>
        <div class="brand-name">
          {{item.product_title}} x {{item.quantity}}
        </div>
        <div class="price">&euro; {{item.total_price}}</div>
      </div>
    </div>
  </div>
</div>