import { OrderLine } from "../orderline";
import { Serializeable } from "../serializeable";

export class MailOrderParamsProductSubscription extends Serializeable {
    static instanceType = MailOrderParamsProductSubscription;

    public name: string;
    public price: string;
    public quantity: number;
    public total: string;
    public product_photo: string;
    public delivery: string;

    constructor(orderline: OrderLine, deliveryDate: string) {
        super();
        this.name = orderline.product_title;
        this.price = orderline.price.toString();
        this.quantity = orderline.quantity;
        this.total = orderline.total_price.toString();
        this.product_photo = orderline.product_photo;
        this.delivery = deliveryDate;       
    }

}