import { Component, OnInit, Input } from '@angular/core';
import { ProductBundle } from 'app/models/product_bundle';
import { ProductService } from 'app/services/product.service';
import { UserService } from 'app/services/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Product } from 'app/models/product';

@Component({
  selector: 'product-bundle-form',
  templateUrl: './product-bundle-form.component.html',
  styleUrls: ['./product-bundle-form.component.scss']
})
export class ProductBundleFormComponent implements OnInit {
  public title: string;
  public buttonText: string;
  public edit: boolean = false;
  public add: boolean = false;
  public show: boolean = false;
  public products: Product[] = [];
  public userSlug: string;

  @Input() mode: string;
  @Input() model: ProductBundle;

  constructor(private productService: ProductService,
    private userService: UserService,
    private router: Router,
    public route: ActivatedRoute) {
    this.route.params.subscribe(params => this.loadProductBundle(params));
  }

  ngOnInit() {
    if (this.mode == "edit") {
      this.title = "Productfamilie bewerken";
      this.buttonText = "Bewerken";

      this.edit = true;
    }

    if (this.mode == "add") {
      this.title = "Productfamilie toevoegen";
      this.buttonText = "Toevoegen";
      this.add = true;
    }

    if (this.mode == "show") {
      this.show = true;
    }

  }

  editProductBundle() {
    this.model.slug = this.model.productBundleSlug;
    this.productService.addProductBundle(this.model)
      .then(() => {
        this.router.navigate([this.userService.sxUser.slug, 'bundles']);
      });
  }

  deleteProductFromBundle(product: Product) {
    if (confirm("Weet je zeker dat je " + product.title + " wilt verwijderen uit de productfamilie " + this.model.name + "?")) {

      const productIndex = this.products.findIndex(p => p.id == product.id);
      const pIndex = this.model.products.findIndex(p => p == product.id);

      if (productIndex != -1) {
        let product = this.products[productIndex];
        product.bundle_id = null;
        this.productService.updateProduct(product).then(() => {
          this.products.splice(productIndex, 1);
        });
      }

      if (pIndex != -1) {
        this.model.products.splice(pIndex, 1);
        this.productService.updateProductBundle(this.model);
      }
    }

  }

  private loadProductBundle(params: Params) {
    this.userSlug = params.userslug;

    this.productService.getProductBundle(params.slug).then(productbundle => {
      this.model = productbundle;

      if (this.show) {
        this.title = this.model.name;
      }

      this.productService.getProductsForProductBundle(productbundle).then(products => {
        this.products = products;
      })
    });
  }

}
