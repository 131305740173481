<h3>Stap 2. Product(en) selecteren</h3>

<div class="container">
  <div class="row pb-0">
    <div class="col-md-6">
      <h5 class="card-title">Kies de layout voor de producten</h5>
        <div class="col-md-12">
          <input type="radio" id="productlayout" [(ngModel)]="productLayout" [value]="productUnderneath"
            (click)="setProductLayout(productUnderneath)">
          <label for="productlayout">Producten onder elkaar (4 maximaal)</label>
        </div>
        <div class="col-md-12">
          <input type="radio" id="productlayout" [(ngModel)]="productLayout" [value]="productNextToEachOther"
            (click)="setProductLayout(productNextToEachOther)">
          <label for="productlayout">Twee producten naast elkaar (6 producten maximaal)</label>
        </div>
    </div>
    <div class="col-md-6">
      <div *ngIf="productLayoutUnderneathEachOther">
        <h5>Product layout</h5>
        <div class="container">
          <div class="row pb-0">
            <div class="col">Productplaatje 1</div>
          </div>
          <div class="row pb-0">
            <div class="col">Productplaatje 2</div>
          </div>
          <div class="row pb-0">
            <div class="col">Productplaatje 3</div>
          </div>
          <div class="row pb-0">
            <div class="col">Productplaatje 4</div>
          </div>
        </div>
      </div>
      <div *ngIf="productLayoutNextToEachOther">
        <h5>Product layout</h5>
        <div class="container">
          <div class="row pb-0">
            <div class="col">Productplaatje 1</div>
            <div class="col">Productplaatje 2</div>
          </div>
          <div class="row pb-0">
            <div class="col">Productplaatje 3</div>
            <div class="col">Productplaatje 4</div>
          </div>
          <div class="row pb-0">
            <div class="col">Productplaatje 5</div>
            <div class="col">Productplaatje 6</div>
          </div>
        </div>
      </div>
    </div>  
  </div>
  <div class="row pt-0">
    <div class="col-12">
      <div id="newCustomer">
        <h5 class="card-title">Voeg producten toe</h5>
        <form #inviteForm="ngForm" [formGroup]="productForm" (ngSubmit)="addProduct(productForm)">
          <div class="row">
            <div class="col-md-12">
              <label for="product">Product:</label>
              <select name="product" formControlName="product" *ngIf="products != null">
                <option value="">Selecteer een product</option>
                <option value="{{product.id}}" *ngFor="let product of products">{{product.title}}</option>
              </select>
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-success">Toevoegen</button>
            </div>
          </div>
        </form>
        <div class="row" *ngIf="hasSelectedProducts">
          <div class="col-md-12">
            <div *ngFor="let product of selectedProducts">

            </div>
            <ul class="cart-items">
              <li class="basket-item" *ngFor="let product of selectedProducts">
                <div class="content {{product.stock == 0 ? 'border-red' : ''}}">
                  <div class="product-data">
                    <div class="image-wrapper">
                      <div class="image-container">
                        <img class="img-responsive" src="{{product.photo_url}}" alt="{{product.title}}"
                          title="{{product.title}}" style="max-width: 90px; max-height: 120px;" />
                      </div>
                    </div>
                    <div class="info-wrapper">
                      <div>
                        <div class="brand-name">
                          {{product.title}}
                        </div>
                        <div class="stock">
                          Voorraad: <span class="{{product.stock > 0 ? 'green': 'red'}}">{{product.stock || 0}}</span>&nbsp;<a routerLink="/{{userSlug}}/products"
                            target="_blank">Aanpassen</a>
                        </div>
                        <ul class="link-wrapper">
                          <a href="javascript:void(0)" (click)="removeProduct(product.id)">
                            <li class="delete-link">
                              Verwijder product
                            </li>
                          </a>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="price-data">
                    <div class="price">&euro; {{product.price}}</div>
                  </div>
                  <div>
                    Upload een (promotie) foto voor het product:
                    <uploader [uid]="product.id" [displayMode]="this.displayMode" [imageSizes]="this.imageSizes">
                    </uploader>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <button type="button" class="btn btn-success" (click)="nextStep()" *ngIf="canGoToTheNextStep">Volgende
          stap</button>
      </div>
    </div>
  </div>
</div>