import { Injectable } from '@angular/core';
import { collection, CollectionReference, doc, Firestore,  getDocs, addDoc, setDoc, getDoc, query, where, limit } from '@angular/fire/firestore';
import { PrivateGroupInvitation } from 'app/models/private_group_invitation';
import { PrivateGroupMember } from 'app/models/private_group_member';
import { User } from 'app/models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrdermailerService } from './ordermailer.service';
import { ServiceBase } from './service-base';
import { converter } from 'app/models/converter';
import { readDocs } from 'app/utils/data-utils';

@Injectable()
export class PrivateGroupService extends ServiceBase {
  private privateGroupInvitationSubject: BehaviorSubject<PrivateGroupInvitation>;
  public invitationState: Observable<PrivateGroupInvitation>;
  private invitations: CollectionReference<PrivateGroupInvitation>
  private members: CollectionReference<PrivateGroupMember>

  get currentInvitation(): PrivateGroupInvitation {
    return this.privateGroupInvitationSubject?.value || null;
  }

  constructor(private db: Firestore,
    private orderMailerService: OrdermailerService
  ) { 
    super();

    this.members = collection(db, 'private_group_members').withConverter(converter<PrivateGroupMember>())
    this.invitations = collection(db, 'private_group_invites').withConverter(converter<PrivateGroupInvitation>())
  }

  async createPrivateGroupMember(privateGroupMember: PrivateGroupMember): Promise<string> {
    return addDoc(this.members, privateGroupMember).then(ref => ref.id)
  }

  async updatePrivateGroupMember(privateGroupMember: PrivateGroupMember): Promise<void> {
    const ref = doc(this.members, privateGroupMember.id)

    return setDoc(ref, privateGroupMember)
  }

  async createInvitationForUser(shopUser: User, user: User): Promise<PrivateGroupInvitation> {
    return new Promise<PrivateGroupInvitation>((resolve) => {
      this.getPrivateGroupsForUser(user.id).then(privateGroups => {
        let index = - 1;
        if (privateGroups != null) {
          index = privateGroups.findIndex(pg => pg.shop_user_id == shopUser.id);
        }

        if (index == -1) {
          const invitation = new PrivateGroupInvitation();
          invitation.shop_user_id = shopUser.id;
          invitation.shop_user_slug = shopUser.slug;
          invitation.send_date = new Date();
          invitation.accepted = false;
          invitation.sx_user_id = user.id;
          invitation.firstname = user.firstname;
          invitation.lastname = user.lastname;
          invitation.email = user.email;

          this.createInvitation(invitation).then(inviteId => {
            invitation.id = inviteId;
            resolve(invitation);
          });
        }
      });
    });
  }

  sendPrivateGroupInvitationToUser(invitation: PrivateGroupInvitation, shopUser: User, user: User) {
    this.orderMailerService.sendPrivateGroupLinkInvitationLinkToUser(shopUser, user, invitation.id);
    this.sendPrivateGroupInviteNotification(invitation);
  }

  createInvitation(invitation: PrivateGroupInvitation): Promise<string> {

    return addDoc(this.invitations, invitation).then(ref => {
      invitation.id = ref.id

      this.updateInvitation(invitation)
      return ref.id
    })
  }

  async updateInvitation(invitation: PrivateGroupInvitation): Promise<void> {
    const ref = doc(this.invitations, invitation.id)

    return setDoc(ref, invitation)
  }

  async getInvitationById(id: string): Promise<PrivateGroupInvitation> {
    const ref = doc(this.invitations, id)
    return getDoc(ref).then(ref => PrivateGroupInvitation.constructWithId(ref.id, ref.data()))
  }

  getInvitationByIdAndSlug(id: string, userslug: string): Promise<PrivateGroupInvitation> {
    const q = query(this.invitations,
                    where('id', '==', id),
                    where('shop_user_slug', '==', userslug),
                    limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty) return Promise.reject('No PrivateGroupInvitation found')
        else {
          const pgi = PrivateGroupInvitation.constructWithId(snap.docs[0].id, snap.docs[0].data())
          return pgi
        }
    })                                    
  }

  sendPrivateGroupInviteNotification(invitation: PrivateGroupInvitation) {
    this.privateGroupInvitationSubject = new BehaviorSubject<PrivateGroupInvitation>(invitation);
    this.invitationState = this.privateGroupInvitationSubject.asObservable();
  }

  async getPrivateGroupsForUser(userId: string): Promise<PrivateGroupMember[]> {
    const q = query(this.members,
      where('user_id', '==', userId),
      limit(20))

    return getDocs(q).then(readDocs)
  }

  async userIsMemberOfPrivateGroup(shopUserId: string, userId: string): Promise<boolean> {
    if (userId === undefined) {
      return false;
    }
    
    const q = query(this.members,
      where('shop_user_id', '==', shopUserId),
      where('user_id', '==', userId),
      limit(1))

      return getDocs(q).then(snap => {
        if (snap.empty) return false
        return true
      })      
  }

  async userHasRequestedMembership(shopUserId: string, userId: string): Promise<boolean> {
    if (userId === undefined) {
      return false;
    }
    
    const q = query(this.invitations,
      where('shop_user_id', '==', shopUserId),
      where('sx_user_id', '==', userId),
      where('accepted', '==', false),
      limit(1))

      return getDocs(q).then(snap => {
        if (snap.empty) return false
        return true
      })      
  }

  
}
