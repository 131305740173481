import { Timestamp } from "@angular/fire/firestore";
import { Serializeable } from "./serializeable";

export class ProductSubscription extends Serializeable {
    static instanceType = ProductSubscription;

    id: string;
    productId: string;
    userId: string;
    shopUserId: string;
    subscriptionDate: Timestamp;
    subscriptionPrice: string;
    unsubscribeDate: Timestamp;
    frequency: number;
    sendDates: Timestamp[];
    numberOfTimesSent: number;
}