import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() currentPage: number = 1;
  @Input() hasMore: boolean = false;  // Track if there are more items to load

  @Output() loadMore = new EventEmitter<void>();  // Emit when "Load More" is clicked
  

  onLoadMore() {
    this.loadMore.emit();
  }

  get hasData(): boolean {
    return this.hasMore;
  }
}
