import { Injectable } from '@angular/core';
import { FileResized } from 'app/models/file_resized';

@Injectable()
export class ImageResizerService {

  constructor() {

  }

  resizeImage(file: File, imageType: string, width: number, height: number): Promise<FileResized> {
    return new Promise((resolve) => {
      let img: any = new Image();
      let reader: any = new FileReader();
      reader.onload = function (e: any) { img.src = e.target.result; };
      reader.readAsDataURL(file);
      let proxy = this;
      let fileWidth: number = 0;
      let fileHeight: number = 0;
      img.onload = function (): void {
        fileWidth = this.width;
        fileHeight = this.height;
        resolve(new FileResized(proxy.createNewImage(fileHeight, fileWidth, img, file, imageType, height, width), height, width));
      };
    });
  }

  createNewImage(height: any, width: any, img: any, file: any, imageType: string, maxFileHeight: number, maxFileWidth: number): File {
    let canvas: HTMLCanvasElement = document.createElement("canvas");
    let ctx: any = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    let MAX_WIDTH: number = maxFileWidth;
    let MAX_HEIGHT: number = maxFileHeight;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }

    canvas.width = width;
    canvas.height = height;
    let ctx1 = canvas.getContext("2d");
    ctx1.drawImage(img, 0, 0, width, height);
    let newImage: any = canvas.toDataURL(imageType);

    let blobBin = atob(newImage.split(',')[1]);
    let array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }

    let newBlob = new Blob([new Uint8Array(array)], { type: imageType });
    let newFile = new File([newBlob as any], file.name, { lastModified: new Date().getTime(), type: newBlob.type });
    return newFile;
  }

}
