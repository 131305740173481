<div class="container">
    <form *ngIf="categoryForm" [formGroup]="categoryForm">

        <h3>Hoofdcategorie aanmaken</h3>
        <div class="form-group form-check">
            <label for="title">Categorienaam</label>
            <input class="form-control" type="text" name="title" id="title" name="title" formControlName="title" />
        </div>
        <div class="form-group form-check">
            <label for="sequence">Volgorde (1 = bovenaan)</label>
            <input class="form-control" type="number" name="sequence" id="sequence" name="sequence"
                formControlName="sequence" value="1" min="1" />
        </div>
        <button type="submit" class="btn btn-success" (click)="addCategory(categoryForm.value)">{{buttonText}}</button>

    </form>
</div>