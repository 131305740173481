import { Component } from '@angular/core';

@Component({
  selector: 'app-shop-product-category',
  templateUrl: './shop-product-category.component.html',
  styleUrls: ['./shop-product-category.component.scss']
})
export class ShopProductCategoryComponent {

}
