import { Serializeable } from '../models/serializeable';
import { OrderLine } from './orderline';
import { Address } from './address';

export class Order extends Serializeable {
    static instanceType = Order;

    id: string;
    user_id: string;
    shop_user_id: string;
    email: string;
    billing_address_id: string;
    shipping_address_id: string;
    pickup_address_id: string;
    order_date: any;
    status: string;
    currency: string;
    total_price: number;
    created_at: Date;
    updated_at: Date;
    orderlines: Object[];
    addresses: Object[];

    constructor(id: string|null = null, user_id: string|null = null) {
        super();

        if (id !== null) {
          this.id = id;
        }
        if (user_id !== null) {
          this.user_id = user_id;
        }

        this.orderlines = [];
        this.addresses = [];
    }
}
