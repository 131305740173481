<div class="container">
  <div class="row flex">
    <section id="cart" class="fullwidth">
      <h3>Bestelling is succesvol geplaatst</h3>
      <div class="container">
        <div class="row border">
          <div class="col-sm-12">
            Ordernummer: {{order.id}}
            <ul class="cart-items">
              <li class="basket-item" *ngFor="let orderline of order.orderlines">
                <orderline [item]="orderline"></orderline>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
