<product-form [model]="this.product" [mode]="this.mode" activeStep="1"></product-form>
<div class="container">
    <div class="row">
        <div class="col" *ngFor="let photo of productPhotos">
            <img src="{{photo.url}}" alt="{{product.title}}" title="{{product.title}}" class="thumbnail" />
            <br />
            <a class="btn btn-danger" href="javascript:void(0)" (click)="deletePhoto(photo)">Delete photo</a>
        </div>
    </div>

</div>