<div id="product-detail" class="container-fluid" *ngIf="product">
    <div class="row back-button">
        <div class="fas fa-arrow-left arrow-back"></div>
        <a [routerLink]="['/', userSlug, 'products']" *ngIf="userSlug !== undefined">Terug</a>
        <a [routerLink]="['/', 'products']" *ngIf="userSlug === undefined">Terug</a>

    </div>

    <div class="item">
        <div class="row">
            <div class="card mb-12">
                <div class="row no-gutters">
                    <div class="col-md-6">
                        <div *ngFor="let photo of productPhotos">
                                <img src="{{photo.url}}" class="card-img" alt="{{product.title}}" title="{{product.title}}">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card-body">
                            <h5 class="card-title">{{product.title}}</h5>
                            <div class="row pt-0 pb-0">
                                <div class="col-auto">
                                    <div class="card-text" *ngIf="canOrderProduct(product) && !product.private || product.private && !product.user?.privateShop && userService.sxUser != null || product.private && product.user?.privateShop && userService.sxUser != null && product.user?.isPrivateGroupMember(userService.sxUser.id)"><strong>€ {{product.price}}</strong></div>
                                    <div *ngIf="canOrderProduct(product) && userService.sxUser != null && product.priceFrom != null && product.priceFrom != '' && product.priceFrom != product.price && (!product.private || product.private && !product.user?.privateShop && userService.sxUser != null || product.private && product.user?.privateShop && userService.sxUser != null && product.user?.isPrivateGroupMember(userService.sxUser.id))"><del>€ {{product.priceFrom}}</del></div>    
                                </div>
                                <div class="col input-group align-top" *ngIf="canOrderProduct(product) && product.subscription && product.subscriptionPrice && !product.private && userService.sxUser != null || product.subscription && product.private && !product.user?.privateShop && userService.sxUser != null || product.subscription && product.private && product.user?.privateShop && userService.sxUser != null && product.user?.isPrivateGroupMember(userService.sxUser.id)">
                                    <input class="mb-1" type="checkbox" name="subscription" id="subscription" (change)="toggleSubscription($event.target)" />
                                    <label for="subscription" class="ml-1">Met abonnement € {{product.subscriptionPrice}}</label>                   
                                </div>
                                <div class="col pl-0" *ngIf="product.subscription && product.user?.privateShop && !canOrderProduct(product) && !product.user?.isPrivateGroupMember(userService.sxUser?.id)">
                                    Met Abonneer &amp; Bespaar extra korting. Word lid.        
                                </div>
                            </div>
                            <div class="row pt-1 pb-0" *ngIf="productSubscription && productSubscription.subscription">
                                <div class="col">
                                    <select class="form-select w-100" (change)="selectFrequency($event.target.value)">
                                        <option value="" selected>Kies frequentie</option>
                                        <option value="30">30 Dagen</option>
                                        <option value="60">60 Dagen</option>
                                        <option value="90">90 Dagen</option>
                                        <option value="120">120 Dagen</option>
                                    </select>
                                    <h6 class="pt-1 pb-0 mb-0">
                                        <small>U kunt op elk moment de frequentie wijzigen of annuleren. 
                                        Wij zullen u voor elke verzending per email herinneren.</small>
                                    </h6>
                                </div>
                            </div>
                            <div class="product-buy" *ngIf="canOrderProduct(product)">
                                <button type="button" class="btn btn-success" (click)="addToCart(product)" [disabled]="productSubscription && productSubscription.subscription && !productSubscription.frequencySelected">Toevoegen
                                    aan winkelmandje</button>
                            </div>
                            <div class="product-buy" *ngIf="isPrivateShopAndNoMember()">
                                <button type="button" class="btn btn-success" (click)="redirectToGroupPage(product)">Word lid van de Privégroep van {{product.user.firstname}}</button>
                                <div class="container transparent-primary pt-2 mt-2">
                                    Waarom lid worden? Dit zijn de voordelen:
                                    <div class="row pb-0">
                                        <ul>
                                            <li>Extra korting</li>
                                            <li>Altijd op tijd je producten thuis</li>
                                            <li>Exclusieve voordelen</li>
                                        </ul>    
                                </div>
                                </div>
                            </div>
                            <p class="card-text pt-2">{{product.description}}</p>
                            <br>
                            <p class="card-text">Ontmoet je verkoper</p>
                            <div class="col-md-9" *ngIf="product.hasUser">
                                <div class="row">
                                    <img src="{{product.user.photo}}" class="responsive profile-photo" />
                                    <div class="col">
                                        <h5 class="card-title">{{product.user.firstname}}</h5>
                                    </div>
                                    <div class="col">
                                        <p class="card-text">Eigenaar van {{product.user.shopTitle}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4" *ngIf="userService.isProductCreator(product)">
                                        <button type="button" class="btn btn-danger"
                                            (click)="deleteProduct(product)">Verwijder</button>
                                    </div>
                                    <div class="col-md-4" *ngIf="userService.isProductCreator(product)">
                                        <button type="button" class="btn btn-info"
                                            (click)="editProduct(product)">Bewerk</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>