import { Product } from 'app/models/product';
import { Serializeable } from 'app/models/serializeable';
import { User } from 'app/models/user';

export class NewsletterParamsProduct extends Serializeable {
    static instanceType = NewsletterParamsProduct;
   
    public name: string;
    public price: string;
    public product_photo: string;
    public product_link: string;

    constructor(shopUser: User, product: Product, photo: string) {
        super();
        this.name = product.title;
        this.price = product.price.toString();
        this.product_photo = photo;
        this.product_link = "https://www.stylexplore.com/" + shopUser.slug + "/products/" + product.slug; 
    }
}