import { Serializeable } from 'app/models/serializeable';
import { SlugifyPipe } from 'app/pipes/slugify.pipe';

export class ProductBundle extends Serializeable {
    static instanceType = ProductBundle;

    id: string;
    user_id: string;
    name: string;
    slug: string;
    products: string[];

    get productBundleSlug(): string {
        if (this.slug == null || this.slug === undefined) {
            this.slug = '';
        } else {
            this.slug = new SlugifyPipe().transform(this.user_id + this.name);
        }
        return this.slug;
    }

    get generateSlug(): string {
        return new SlugifyPipe().transform(this.user_id + this.name);
    }

    get hasProducts(): boolean {
        return this.products !== undefined && this.products.length > 0;
    }

}
