import { Component, Input, OnInit } from '@angular/core';
import { User } from 'app/models/user';
import { AddressService } from 'app/services/address.service';
import { CustomerService } from 'app/services/customer.service';
import { Product } from 'app/models/product';
import { ProductService } from 'app/services/product.service';
import { UserService } from 'app/services/user.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Order } from 'app/models/order';
import { CartItem } from 'app/models/cartitem';
import { Cart } from 'app/models/cart';
import { CartState } from 'app/models/cart-state';

@Component({
  selector: 'customer-order-creator-order-data',
  templateUrl: './customer-order-creator-order-data.component.html',
  styleUrls: ['./customer-order-creator-order-data.component.scss']
})
export class CustomerOrderCreatorOrderDataComponent implements OnInit {
  @Input() customer: User;
  public products: Product[] = [];
  public productForm: UntypedFormGroup;
  public order: Order;
  public cart: Cart;
  public cartItems: Array<CartItem>;
  private orderLineSequence: number;

  constructor(private customerService: CustomerService, 
              private addressService: AddressService, 
              private productService: ProductService, 
              private userService: UserService, 
              private formBuilder: UntypedFormBuilder) {
    this.productForm = this.formBuilder.group({
      product: '',
      quantity: 1
    });

    this.order = new Order();
    this.cart = this.customerService.orderCreatorCart;
    this.orderLineSequence = 1;
  }

  ngOnInit() {    
    this.productService.getProducts(this.userService.sxUser, 9999, null).then(products => {
      this.products = products.products;
    });
    
    this
    .customerService
    .cartState
    .subscribe((state: CartState) => {
      this.cart.cartItems = state.items;
    });
  }

  addNewProduct(productFormData: UntypedFormGroup) {
    if (productFormData.valid) {
      let productIndex: number = this.products.findIndex(p => p.id == productFormData.value.product);
      if (productIndex != -1) {
        let product: Product = this.products[productIndex];
        let cartItem: CartItem = new CartItem(product, productFormData.value.quantity);

        this.cart.addItem(cartItem);
        this.customerService.updateCart(this.cart);        
      }
    }
  }

  public gotoAddressScreen() {
    this.customerService.saveOrderCreatorCart(this.cart);
    this.customerService.setOrderCreatorStep('confirm');
  }

  get selectedProduct(): any {
    return this.productForm.get('product').value;
  }

  get hasProductsInCart(): boolean { 
    return this.cart.count > 0;
  }
}
