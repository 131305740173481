<h3>Stap 4. Nieuwsbrief is verstuurd</h3>

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="card border-success mb-9">
        <div class="card-body text-success">
          <h5 class="card-title">De nieuwsbrief is succesvol verstuurd naar al je klanten.</h5>
          <p class="card-text">Heeft je klant na enkele minuten geen mail ontvangen? Controleer dan de SPAM box.</p>
        </div>
      </div>      
    </div>
  </div>
</div>