<div class="container">
  <div class="row flex">
    <progressbar [items]="this.progressBarItems"></progressbar>
  </div>
  <div class="row flex">
    <section id="cart">
      <h3>Controleer en bestel</h3>
      <discount-code></discount-code>
      <div class="container">
        <div class="row border">
            <div class="col-sm-12" *ngIf="hasPickupAddress">
              <address [displayFullWidth]="true" [edit]="false" addressType="pickup" [addressId]="pickupAddressId" addressType="pickup" edit="false" title="Het afhaaladres" formButtonText="Opslaan"></address>
            </div>
            <div class="col-sm" *ngIf="!hasPickupAddress">
              <address addressType="shipping" edit="false" title="Je bezorgadres" formButtonText="Opslaan"></address>
            </div>
            <div class="col-sm" *ngIf="!hasPickupAddress">
              <address addressType="{{billingAddressType}}" edit="false" title="Je factuuradres" formButtonText="Opslaan"></address>
            </div>  
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="fullwidth">
            <cart-items [items]="this.items" [edit]="false"></cart-items>
            <discount-code></discount-code>
            <cart-total></cart-total>      
            <button class="btn btn-success" (click)="placeOrder()">Bestelling plaatsen</button>
          </div>
        </div>
      </div>      
    </section>
  </div>
</div>