import { Inject } from '@angular/core';

import { ServiceBase } from "app/services/service-base";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { EmailInfo } from './email_info';
import { SendinblueMailBase } from './sendinblue_mail_base';
import { takeUntil} from 'rxjs/operators';

export class MailServiceBase extends ServiceBase {
    protected httpHeaders: HttpHeaders;
    protected shopEmailInfo: EmailInfo;

    constructor(@Inject(APP_CONFIG) protected config: AppConfig, 
                protected http: HttpClient) {
        super();

        this.httpHeaders = new HttpHeaders()
        .set("accept", "application/json")
        .set("content-type", "application/json")
        .set("api-key", config.sendinblueApiKey);

        this.shopEmailInfo = new EmailInfo(config.emailFromName, config.emailFrom);
    }

    sendEmailViaSendInBlue(mailOrder: SendinblueMailBase) {
        mailOrder.setFromEmail(this.shopEmailInfo);
        var mailOrderJson: string = JSON.stringify(mailOrder);
    
        const headers: HttpHeaders = this.httpHeaders;
        this.http.post(this.config.sendinblueSendMailUrl, mailOrderJson, {headers})
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
            (val) => {
                console.log("POST call successful value returned in body", 
                            val);
            },
            response => {
                console.log("POST call in error", response);
            },
            () => {
                console.log("The POST observable is now completed.");
            });  
      }
}