<div class="container">
    <div class="row" *ngIf="users">
        <div class="item col-sm" *ngFor="let user of users">
            <a [routerLink]="['/', user.slug, 'products']"><img src="{{user.photo}}" class="responsive" alt="{{user.displayName}}"
            title="{{user.displayName}}" style="max-width: 180px; max-height: 240px;" /></a>
            <div class="caption card-body">
                <h4 class="group card-title inner list-group-item-heading">{{user.displayName}}</h4>
                <p class="group inner list-group-item-text">{{user.shopTitle}}</p>
                <a [routerLink]="['/', user.slug, 'products']" class="btn btn-primary">Bezoek de privégroep</a>
            </div>
        </div>
    </div>
</div>
