import { Component } from '@angular/core';
import { User } from '@angular/fire/auth'
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { UserService } from 'app/services/user.service';
import { Router } from '@angular/router';
import { CartService } from 'app/services/cart.service';
import { CartState } from 'app/models/cart-state';

declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  items: Observable<any[]>;
  photos: Observable<any[]>;
  loggedIn: false;
  totalCartItems: number;
  private subscription: Subscription;
  private popoverApplied: boolean = false;
  public currentFirebaseUser: User;

  constructor(public authService: AuthService,
    public userService: UserService,
    private router: Router,
    private cartService: CartService) {
    this.userService.currentFirebaseUser.subscribe(x => this.currentFirebaseUser = x);
  }

  ngOnInit() {
    this.subscription = this
      .cartService
      .cartState
      .subscribe((state: CartState) => {
        this.totalCartItems = state.numberOfItems;
      });

    this.cartService.loadCart();
  }

  fbLogin() {
    this.authService.facebookLogin();
  }

  ngAfterViewInit() {
    if (!this.popoverApplied) {

      // $('[data-toggle="popover"]').popover();
      $("[data-toggle=popover]").popover({
        html : true,
        content: function() {
            var content = $(this).attr("data-popover-content");
            return $(content).children(".popover-body").html();
        },
        // title: function() {
        //     var title = $(this).attr("data-popover-content");
        //     return $(title).children(".popover-heading").html();
        // }
    });
      
      this.popoverApplied = true;
      
      }
  }
}
