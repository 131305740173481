import { Component, OnInit } from '@angular/core';
import { ProgressBarItem } from '../../models/progressbaritem';
import { ProgressbarService } from '../../services/progressbar.service';
import { CartService } from '../../services/cart.service';
import { CartItem } from '../../models/cartitem';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { Router } from '@angular/router';
import { SlugifyPipe } from '../../pipes/slugify.pipe';
import { Cart } from 'app/models/cart';
import { FormValidationService } from 'app/services/form-validation.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  public progressBarItems: Array<ProgressBarItem>;
  public items: Array<CartItem>;
  public cart: Cart;
  public checkoutForm;
  public loginForm;
  public displayNewCustomer: boolean = true;
  private formSubmitRegistrationAttempt: boolean;

  constructor(private progressBarService: ProgressbarService,
    private cartService: CartService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService, 
    private userService: UserService,
    private router: Router) { 
    this.progressBarItems = this.progressBarService.getCheckoutProgressBar(2);
    this.cart = this.cartService.loadCart();
    this.items = this.cart.cartItems;

    this.checkoutForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, FormValidationService.emailValidator]],
      password: ['', [Validators.required, FormValidationService.passwordValidator]],
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, FormValidationService.emailValidator]],
      password: ''
    });
  }

  onSubmit(customerData: UntypedFormGroup) {
    // Process checkout data here
    if (customerData.valid) {
      this.authService.userExists(customerData.value.email).then(res => {
        if (!res) {
          this.authService.createAccount(customerData.value).then(val => {
            this.userService.createUniqueUserSlug(customerData.value.firstname, customerData.value.lastname).then(userSlug => {
              this.userService.createUser(customerData.value, userSlug, val).then(_user => {
                this.router.navigate(['/shipping']);
              });  
            })
          });
        }
        else {
          this.router.navigate(['/shipping']);
        }
      });
    }
    else {
      Object.keys(customerData.controls).forEach(field => { // {1}
        const control = customerData.get(field);            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });
    }
  }

  onSubmitLogin(customerData) {
    this.authService.login(customerData).then(_user => {
      this.router.navigate(['/shipping']);
    })
  }

  showRegistration() {
    this.displayNewCustomer = true;
  }

  showLogin() {
    this.displayNewCustomer = false;
  }

  ngOnInit() {
  }
}
