import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewsletterCreator } from 'app/models/newsletter-creator';
import { User } from 'app/models/user';
import { CustomerService } from 'app/services/customer.service';
import { NewsletterService } from 'app/services/newsletter.service';
import { OrdermailerService } from 'app/services/ordermailer.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'newsletter-creator-newsletter-preview',
  templateUrl: './newsletter-creator-newsletter-preview.component.html',
  styleUrls: ['./newsletter-creator-newsletter-preview.component.scss']
})
export class NewsletterCreatorNewsletterPreviewComponent implements OnInit {
  public newsletterCreator: NewsletterCreator;
  public email: string;
  public sentPreview: boolean = false;
  public customers: User[] = new Array<User>();
  
  constructor(private newsletterService: NewsletterService,
              private orderMailerService: OrdermailerService,
              private userService: UserService,
              private customerService: CustomerService,
              private router: Router) { }

  async ngOnInit() {
    let model: NewsletterCreator = this.newsletterService.newsletterCreatorModel;
    this.newsletterCreator = model;
    this.email = this.userService.sxUser.email;

    if (!this.newsletterCreator.canSelectProducts) {
      this.router.navigate(['/newsletter-creator']);
    }

    const customers = await this.customerService.getCustomersForShopUser(this.userService.sxUser.id);
    const privateGroupMembers = await this.userService.getPrivateGroupMembers(this.userService.sxUser.id);

    customers.forEach(customer => {
      if (this.customers.find(x => x.id === customer.id) == null) {
        this.customers.push(customer);
      }
    });      

    privateGroupMembers.forEach(async privateGroupMember => {
      const user = await this.userService.getUserById(privateGroupMember.user_id);
      if (this.customers.find(x => x.id === user.id) == null) {
        this.customers.push(user);
      }
    });

  }

  sendPreview() {
    // send to shop owner
    this.orderMailerService.sendNewsletterToCustomer(this.userService.sxUser, this.userService.sxUser, this.newsletterCreator);
    this.sentPreview = true;
  }

  send() {
    this.customers.forEach(customer => {
      this.orderMailerService.sendNewsletterToCustomer(this.userService.sxUser, customer, this.newsletterCreator);    
    });

    this.newsletterService.removeNewsletterCreatorModelFromLocalStorage();
    this.newsletterService.setNewsletterCreatorStep('sent');
  }

}
