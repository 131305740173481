import { Injectable } from '@angular/core';
import { Firestore, collection, doc, setDoc, query, where, CollectionReference, getDocs, limit, getDoc, onSnapshot, collectionData } from '@angular/fire/firestore';
import { Auth, onAuthStateChanged, updateProfile, UserCredential, User as AuthUser } from '@angular/fire/auth';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';

import { map } from 'rxjs/operators';
import { User } from 'app/models/user';
import { ServiceBase } from './service-base';
import { SlugifyPipe } from 'app/pipes/slugify.pipe';
import { Product } from 'app/models/product';
import { ProductBundle } from 'app/models/product_bundle';
import { PrivateGroupMember } from 'app/models/private_group_member';
import { CartService } from './cart.service';
import { converter } from 'app/models/converter';
import { readDocs } from 'app/utils/data-utils';

@Injectable()
export class UserService extends ServiceBase {

  private currentFirebaseUserSubject: BehaviorSubject<AuthUser>;
  public currentFirebaseUser: Observable<AuthUser>;
  private currentSxUserSubject: BehaviorSubject<User>;
  public currentSxUser: Observable<User>;
  private currentShopUserSubject: BehaviorSubject<User>;
  private users: CollectionReference<User>

  get user(): AuthUser { return this.currentFirebaseUserSubject.value };
  get sxUser(): User { return this.currentSxUserSubject.value };
  get shopUser(): User { return this.currentShopUserSubject.value; }

  get userHasProducts(): boolean { return this.user && this.sxUser && this.sxUser.user_type == 2; }
  get showProducts(): boolean { return (this.user && this.sxUser && this.sxUser.user_type != 2) || this.user == null; }
  get userhasPrivateGroupMembership(): boolean { return this.sxUser != null && this.sxUser.hasPrivateGroupMembership; }
  public isProductCreator(product: Product) { return this.userHasProducts && product.user_id == this.user.uid; }
  public isProductBundleCreator(productBundle: ProductBundle) { return productBundle.user_id == this.user.uid; }
  public redirectUrl: string;

  constructor(
    public db: Firestore,
    public afAuth: Auth,
    private slugifyPipe: SlugifyPipe
  ) {

    super();

    this.users = collection(db, 'users').withConverter(converter<User>())

    var currentFirebaseUserLs = localStorage.getItem('currentFirebaseUser');
    if (currentFirebaseUserLs != null && currentFirebaseUserLs !== "undefined") {
      this.currentFirebaseUserSubject = new BehaviorSubject<AuthUser>(JSON.parse(currentFirebaseUserLs));
      this.currentFirebaseUser = this.currentFirebaseUserSubject.asObservable();
    }
    else {
      this.currentFirebaseUserSubject = new BehaviorSubject<AuthUser>(null);
      this.currentFirebaseUser = this.currentFirebaseUserSubject.asObservable();
    }

    var currentSxUserLs = localStorage.getItem('currentSxUser');

    if (currentSxUserLs != null && currentSxUserLs !== "undefined") {
      let theSxUser: User = new User();
      Object.assign(theSxUser, JSON.parse(currentSxUserLs));
      this.currentSxUserSubject = new BehaviorSubject<User>(theSxUser);
      this.currentSxUser = this.currentSxUserSubject.asObservable();
    }
    else {
      this.currentSxUserSubject = new BehaviorSubject<User>(null);
      this.currentSxUser = this.currentSxUserSubject.asObservable();
    }

    onAuthStateChanged(afAuth, (user) => {
      if (user) {
        localStorage.setItem('currentFirebaseUser', JSON.stringify(user));
        this.currentFirebaseUserSubject.next(user as AuthUser);

        if (this.sxUser == null) {
          this.getUserById(user.uid).then(usersx => {
            localStorage.setItem('currentSxUser', JSON.stringify(usersx));
            this.currentSxUserSubject.next(usersx);
          })
        }
      }
    })
  }

  ngOnInit() {
  }

  logout() {
    localStorage.removeItem('currentFirebaseUser');
    localStorage.removeItem('currentSxUser');

    this.currentFirebaseUserSubject.next(null);
    this.currentSxUserSubject.next(null);
  }

  async createUniqueUserSlug(firstname: string, lastname: string): Promise<string> {
    return new Promise<string>((resolve) => {
      const originalSlug: string = this.slugifyPipe.transform(firstname.toLocaleLowerCase() + ' ' + lastname.toLocaleLowerCase());

      const users1$ = collectionData(query(this.users, where('firstname', '==', firstname), where('lastname', '==', lastname)))
      const users2$ = collectionData(query(this.users, where('slug', '==', originalSlug)))

      return combineLatest([users1$, users2$]).pipe(
        map(([s1, s2]) => [...s1, ...s2])
      ).subscribe((users) => {
        if (users.length === 0) {
          resolve(this.slugifyPipe.transform(firstname.toLocaleLowerCase() + ' ' + lastname.toLocaleLowerCase()));
        }
        else {
          const slugNumbers: Array<number> = new Array<number>();

          users.forEach(user => {
            let slugIndex: number = user.slug.toString().lastIndexOf('-');
            let slugNumber: number;

            if (slugIndex != -1) {
              slugNumber = parseInt(user.slug.toString().substring(slugIndex + 1));

              if (!isNaN(slugNumber)) {
                slugNumbers.push(slugNumber);
              }
            }
          });

          if (slugNumbers.length === 0) {
            resolve(this.slugifyPipe.transform(firstname.toLocaleLowerCase() + ' ' + lastname.toLocaleLowerCase()));
          }
          else {
            const highestSlugNumber = Math.max(...slugNumbers);
            let newSlugNumber: number = highestSlugNumber + 1;
            resolve(this.slugifyPipe.transform(firstname.toLocaleLowerCase() + ' ' + lastname.toLocaleLowerCase() + ' ' + newSlugNumber.toString()));
          }
        }
      })
    })
  }

  async createUser(value: any, slug: string, userCredential: UserCredential): Promise<User> {
    var user: User = new User();
    if (!userCredential.user) return Promise.reject(null)

    user.id = userCredential.user.uid;
    user.firstname = value.firstname;
    user.lastname = value.lastname;
    user.displayName = value.firstname + ' ' + value.lastname;
    user.email = value.email;
    user.slug = slug;
    user.user_type = 1;
    user.customer = value.customer !== undefined ? value.customer : false;
    user.shop_user_id = value.shop_user_id !== undefined ? value.shop_user_id : null;

    return setDoc(doc(this.users, user.id), Object.assign({}, user)).then(() => user)
  }

  async findUsersBy(field: string, value: any, count = 20): Promise<Array<User>> {
    const q = query(this.users, where(field, '==', value), limit(count)).withConverter(converter<User>())

    return getDocs(q).then(readDocs)
  }

  async findUserBy(field: string, value: any): Promise<User> {
    const results = await this.findUsersBy(field, value, 1)

    return new Promise<User>((resolve, reject) => {
      const user = results.shift()
      if (user) resolve(User.constructWithId(user.id, user))
      else resolve(null)
    })
  }

  async getPrivateShopOwners(): Promise<Array<User>> {
    return this.findUsersBy('privateShop', true)
  }

  async getPrivateGroupMembers(id: string): Promise<Array<PrivateGroupMember>> {
    const members = collection(this.db, 'private_group_members').withConverter(converter<PrivateGroupMember>())
    const q = query(members, where('shop_user_id', '==', id))

    return getDocs(q).then(readDocs)
  }

  async getCustomers(id: string): Promise<Array<User>> {
    return this.findUsersBy('shop_user_id', id)
  }

  async getUserById(id: string): Promise<User> {
    return new Promise<User>(async (resolve, reject) => {
      const reference = doc(this.users, id)
      const snap = await getDoc(reference)

      if (snap.exists()) resolve(snap.data())
      else reject(`no User found by id: ${id}`)
    })
  }

  async getCurrentUser(): Promise<User> {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      })
    })
  }

  async updateCurrentUser(value): Promise<void> {
    return updateProfile(this.afAuth.currentUser, {
      displayName: value.name
    })
  }

  async updateSxUser(user: User): Promise<void> {
    return this.updateUser(user).then(() => {
      localStorage.setItem('currentSxUser', JSON.stringify(user));
      this.currentSxUserSubject.next(user);
    })
  }

  async updateUser(user: User): Promise<User> {
    const reference = doc(this.users, user.id)
    await setDoc(reference, user)

    return this.findUserBySlug(user.slug);
  }

  async findUserBySlug(slug: string): Promise<User> {
    return this.findUserBy('slug', slug)
  }

  async getAllUsers(): Promise<User[]> {
    const q = query(this.users).withConverter(converter<User>())

    return getDocs(q).then(snap => snap.docs.map(doc => doc.data()))
  }

  canOrderProduct(product: Product, cartService: CartService): boolean {
    let sxUser = this.sxUser;

    if (product.user && sxUser && product.user.id == sxUser.id) {
      return true;
    }

    if (product.private && sxUser == null) {
      return false;
    }

    if (!product.private && sxUser == null && product.user?.hasPrivateShop) {
     return false;
    }

    if (product.user?.hasPrivateShop && sxUser != null) {
      if (product.user === undefined || product.user == null || !product.user?.hasOwnProperty('id')) {
        return false;
      }
      
      const productUser: User = User.construct(product.user);

      if (!productUser.isPrivateGroupMember(sxUser.id)) {
         return false;
      }
    }

    return this.productHasStock(product, cartService);
  }

  productHasStock(product: Product, cartService: CartService): boolean {
    return cartService.canOrderProduct(product, 1);
  }

  async getSponsorUser(): Promise<User> {
    if (this.sxUser.customer && this.sxUser.hasShopUser) {
      return this.getUserById(this.sxUser.shop_user_id)
    } else {
      if (this.sxUser.isShopOwner ||
        this.sxUser.hasPrivateGroupMembership ||
        this.sxUser.hasShopUser ||
        this.sxUser.privateShop) {
        return Promise.resolve(this.sxUser);
      } else {
        return Promise.reject()
      }
    }
  }

}
