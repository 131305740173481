import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user';
import StringUtils from 'app/utils/stringutils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm;
  public emailBadlyFormatted: boolean = false;
  public noUserFound: boolean = false;
  public passwordInvalid: boolean = false;

  constructor(private authService: AuthService,
              private router: Router,
              private formBuilder: UntypedFormBuilder,
              private userService: UserService) {
    this.loginForm = this.formBuilder.group({
      email: '',
      password: ''
    });
  }

  ngOnInit() {
  }

  fbLogin() {
    this.authService.facebookLogin()
    .then(fbUser => {
      this.authService.userExists(fbUser.user.email).then(res => {
        if (!res)
        {
            var newUser: User = new User();
            var names = StringUtils.transformDisplayname(fbUser.user.displayName);
            newUser.firstname = names[0];
            newUser.lastname = names[1];
            newUser.email = fbUser.user.email;

            this.userService.createUniqueUserSlug(newUser.firstname, newUser.lastname).then(userSlug => {
              this.userService.createUser(newUser, userSlug, fbUser).then(() => {
                this.router.navigate(['/']);
              });            
            });
        }
      });
      this.router.navigate(['/']);
    })
  }

  onSubmitLogin(customerData) {
    this.authService.login(customerData).then(() => {
      this.authService.userExists(customerData.email).then(res => {
        if (!res)
        {
          this.authService.createAccount(customerData).then(val => {
            this.userService.createUniqueUserSlug(customerData.value.firstname, customerData.value.lastname).then(userSlug => {
              this.userService.createUser(customerData, userSlug, val).then(() => {
                this.router.navigate(['/']);
              });
            });
          });
        }
      });

      this.router.navigate(['/']);
    }).catch(err => {
        this.passwordInvalid = err.message.match(/invalid/);
        this.emailBadlyFormatted = err.message.match(/badly/);
        this.noUserFound = err.message.match(/deleted/);
    })
  }

}
