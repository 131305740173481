import { Serializeable } from "./serializeable";

export class ShopProductCategory extends Serializeable {
    static instanceType = ShopProductCategory;

    id: string;
    userId: string;
    title: string;
    slug: string;
    sequence: number;

    constructor(id: string | null = null, userId: string | null = null) {
        super();

        if (id !== null) {
            this.id = id;
        }
        if (userId !== null) {
            this.userId = userId;
        }
    }
}