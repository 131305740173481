import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { UserService } from './user.service';

@Injectable()
export class RedirectService  {

  constructor(public userService: UserService,
    private router: Router) { }

  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let redirectUrl = this.userService.redirectUrl;

      if (redirectUrl === undefined || redirectUrl == null) {
        return resolve(true);
      }

      this.router.navigate([redirectUrl]);
      return resolve(true);
    });
  }
}
