import { Serializeable } from 'app/models/serializeable';

export class RepeatService extends Serializeable {
    static instanceType = RepeatService;

    id: string;
    user_id: string;
    shop_user_id: string;
    shop_user_slug: string;
    user_fullname: string;
    shop_user_fullname: string;
    join_date: Date;
    send_date: Date;
    active: boolean;

    constructor(id = null) {
        super();

        if (this.id !== null) {
            this.id = id;
        }
    }
}