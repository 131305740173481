<div class="row">
  <form id="userForm" [formGroup]="userForm" (ngSubmit)="onSubmitUserForm(userForm)">
    <div class="container" *ngIf="userService.userHasProducts">
      <div class="header">
        Deze instellingen verschijnen in de nieuwsbrief email.
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for="advantage1">Voordeel 1</label><br />
          <textarea class="form-control" id="advantage1" rows="5" formControlName="advantage1"></textarea>
        </div>
        <div class="col-md-6">
          <label for="advantage2">Voordeel 2</label><br />
          <textarea class="form-control" id="advantage2" rows="5" formControlName="advantage2"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for="advantage3">Voordeel 3</label><br />
          <textarea class="form-control" id="advantage3" rows="5" formControlName="advantage3"></textarea>
        </div>
        <div class="col-md-6">
          <label for="advantage4">Voordeel 4</label><br />
          <textarea class="form-control" id="advantage4" rows="5" formControlName="advantage4"></textarea>
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">
      Opslaan
    </button>
  </form>
</div>
