import { User } from 'app/models/user';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { Serializeable } from 'app/models/serializeable';

export class MailPromotorDiscountCode extends Serializeable {
    static instanceType = MailPromotorDiscountCode;
   
    public promotor_discount: string;
    public promotor_firstname: string;
    public promotor_lastname: string;
    public promotor_email: string;
    public promotor_phoneNumber: string;
    public promotor_fullname: string;
    public prospect_fullname: string;
    public shopowner_linkshop: string;

    public discount_code: string;
    public minimum_order_price: string;
    public shopowner_fullname: string;
    public shopowner_email: string;
    public shopowner_phoneNumber: string;

    constructor(promotorUser: User, shopUser: User, invitation: ReferralInvitation) {
        super();
        
        this.promotor_discount  = invitation.promotor_discount.toFixed(2);

        this.promotor_firstname = promotorUser.firstname;
        this.promotor_lastname = promotorUser.lastname;
        this.promotor_email = promotorUser.email;
        this.promotor_phoneNumber = promotorUser.phoneNumber;
        this.promotor_fullname  = promotorUser.displayName;

        this.prospect_fullname = invitation.prospectFullName;

        this.shopowner_fullname = shopUser.displayName;
        this.shopowner_email = shopUser.email;
        this.shopowner_phoneNumber = shopUser.phoneNumber;
        this.shopowner_linkshop = 'https://www.stylexplore.com/' + shopUser.slug + '/products';

        this.discount_code = invitation.promotor_discount_code;
        this.minimum_order_price = invitation.minimum_order_price.toFixed(2);
    }
}