import { Component, OnInit } from '@angular/core';
import { Product } from 'app/models/product';

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {

  constructor() { }

  public product: Product;
  public mode: string = "add";

  ngOnInit() {
    this.product = new Product();
  }

}
