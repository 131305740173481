<div class="container">
    <div class="row">
        <form #inviteForm="ngForm">
            <h3>Verstuur uitnodiging privégroep</h3>
            <div class="form-group">
                <label for="firstname" class="required">Voornaam</label>
                <input type="text" class="form-control" id="firstname" required [(ngModel)]="model.firstname"
                    name="firstname" placeholder="Voornaam">
            </div>
            <div class="form-group">
                <label for="lastname" class="required">Achternaam</label>
                <input type="text" class="form-control" id="lastname" required [(ngModel)]="model.lastname"
                    name="lastname" placeholder="Achternaam">
            </div>
            <div class="form-group">
                <label for="email" class="required">Email</label>
                <input type="text" class="form-control" id="email" required [(ngModel)]="model.email" name="email"
                    placeholder="Email">
            </div>
            <button type="submit" class="btn btn-success" (click)="sendInvite()">Verstuur</button>
        </form>
    </div>
    <div class="thumbnail card bg-danger" *ngIf="hasNoticeMessage">
        <div class="caption card-body text-white">
            {{noticeMessage}}
        </div>
    </div>
    <div class="row" *ngIf="createdInviteLink">
        <div class="col-md-12">
            De uitnodiging is succesvol verstuurd naar {{sentModel.email}}!
        </div>
        <div class="col-md-12">
            Kopiëer de uitnodiging link hier:<br />
            <a [routerLink]="['/', userSlug, 'join' ,inviteId]">Uitnodiging voor {{sentModel.firstname}}
                {{sentModel.lastname}}</a>
        </div>
    </div>
</div>