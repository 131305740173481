import { Component, OnInit } from '@angular/core';
import { OrderService } from 'app/services/order.service';
import { Order } from 'app/models/order';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  public orders: Order[];

  constructor(private orderService: OrderService,
              private userService: UserService) {
    
    if (this.userService.sxUser.isShopOwner || this.userService.sxUser.hasPrivateShop) {
      this.orderService.getOrdersForShopOwner(this.userService.sxUser.id).then(orders => {
        this.orders = orders;
      })
    }
    else {
      this.orderService.getOrdersForUser(this.userService.user.uid).then(orders => {
        this.orders = orders;
      });  
    }
  }

  ngOnInit() {
  }

}
