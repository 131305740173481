<div id="order-detail" class="container-fluid" *ngIf="order">
  <div class="container">
    <div class="row flex">
      <a [routerLink]="['/orders']">< Alle bestellingen</a>
    </div>
    <div class="row flex">
      <section id="cart" class="fullwidth">
        <h1>Bestelling {{order.id}}</h1>
        <div class="container">
          <div class="row border">
            <div class="col-sm-12">
              <ul class="cart-items">
                <li class="basket-item" *ngFor="let orderline of order.orderlines">
                  <orderline-detail [item]="orderline"></orderline-detail>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <h2>Details bestelling</h2>
            <div class="col-sm">
              <div><strong>Besteld op</strong></div>
              <div>
                {{order.order_date.toDate()| date:'medium'}}
              </div>
            </div>
            <div class="col-sm">
              <div *ngIf="shippingAddress">
                <div><strong>Bezorgadres</strong></div>
                <div>
                  <div>
                    {{shippingAddress.firstname}} {{shippingAddress.lastname}}
                  </div>
                  <div>
                    {{shippingAddress.street}} {{shippingAddress.housenumber}} {{shippingAddress.housenumber_addition}}
                  </div>
                  <div>
                    {{shippingAddress.zipcode}} {{shippingAddress.city}}
                  </div>
                  <div>
                    {{shippingAddress.country}}
                  </div>
                </div>  
              </div>
              <div class="billing" *ngIf="!pickupAddress">
                <div><strong>Factuuradres</strong></div>
                <div *ngIf="billingAddress">
                  <div>
                    {{billingAddress.firstname}} {{billingAddress.lastname}}
                  </div>
                  <div>
                    {{billingAddress.street}} {{billingAddress.housenumber}} {{billingAddress.housenumber_addition}}
                  </div>
                  <div>
                    {{billingAddress.zipcode}} {{billingAddress.city}}
                  </div>
                  <div>
                    {{billingAddress.country}}
                  </div>
                </div>
                <div *ngIf="sameAddress">
                  Gelijk aan bezorgadres
                </div>
              </div>
              <div class="pickup" *ngIf="pickupAddress">
                <div><strong>Afhaaladres</strong></div>
                <div>
                  <div>
                    {{pickupAddress.firstname}} {{pickupAddress.lastname}}
                  </div>
                  <div>
                    {{pickupAddress.street}} {{pickupAddress.housenumber}} {{pickupAddress.housenumber_addition}}
                  </div>
                  <div>
                    {{pickupAddress.zipcode}} {{pickupAddress.city}}
                  </div>
                  <div>
                    {{pickupAddress.country}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <div><strong>Kostenoverzicht</strong></div>
              <div class="row">
                <div class="col-sm">
                  Totaal artikelen ({{order.orderlines.length}})
                </div>
                <div class="col-sm" style="align-items: flex-end;">
                  &euro; {{order.total_price | number}}
                </div>  
              </div>              
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

</div>