import { Component, OnInit } from '@angular/core';
import { PrivateGroupMember } from 'app/models/private_group_member';
import { RepeatServiceManual } from 'app/models/repeat_service_manual';
import { User } from 'app/models/user';
import { NewsletterService } from 'app/services/newsletter.service';
import { OrdermailerService } from 'app/services/ordermailer.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  public model: RepeatServiceManual;
  private shopOwner: User;
  private customer: User;

  constructor(private userService: UserService,
              private newsletterService: NewsletterService,
              private orderMailerService: OrdermailerService) { 
    this.model = new RepeatServiceManual();
    this.userService.getPrivateShopOwners().then(users => {
      this.model.shopOwners = users;
    });
  }

  ngOnInit() {
  }

  loadCustomers(user_id: string) {
    this.model.customers = [];

    for(var i=0; i < this.model.shopOwners.length; i++) {
      let so: User = this.model.shopOwners[i];
      if (so.id == user_id) {
        this.shopOwner = so;
      }
    }

    this.userService.getCustomers(user_id).then(users => {
      for(var i = 0; i <users.length; i++) {
          this.model.customers.push(users[i]);
      }
    });

    this.userService.getPrivateGroupMembers(user_id).then(privateGroupMembers => {
      for(var i = 0; i <privateGroupMembers.length; i++) {
        let privateGroupMember: PrivateGroupMember = privateGroupMembers[i];
        
        if (privateGroupMember.user_id === undefined || privateGroupMember.user_id == null) {
          continue;
        }

        this.userService.getUserById(privateGroupMember.user_id).then(user => {
          if (user != null) {
            if (this.model.customers.indexOf(user) == -1) {
              this.model.customers.push(user);
            }  
          }
        });
      }
    });
  }

  selectCustomer(user_id: string) {
    for(var i=0; i < this.model.customers.length; i++) {
      let c: User = this.model.customers[i];
      if (c.id == user_id) {
        this.model.customer = user_id;
        this.customer = c;
        break;
      }
    }
  }

  sendEmail() {
    this.orderMailerService.sendNewsletterToCustomer(this.shopOwner, this.customer);
    this.newsletterService.create(this.shopOwner, this.customer);
  }

}
