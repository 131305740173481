export class ProgressBarItem {
    label: string;
    current: boolean;
    complete: boolean;
    url: string;

    constructor(url: string, label: string, current: boolean = false, complete: boolean = false) {
        this.url = url;
        this.label = label;
        this.current = current;
        this.complete = complete;
    }

    get cssClass(): string {
        var cssClassName: string = "";

        if (this.current) {
            cssClassName = "is-current";
        }

        if (this.complete) {
            cssClassName += " is-complete";
        }

        return cssClassName;
    }
}