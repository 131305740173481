import { Serializeable } from '../../serializeable';
import { User } from 'app/models/user';
import { NewsletterCreator } from 'app/models/newsletter-creator';
import { NewsletterParamsProduct } from './newsletter_params_product';
import { NewsletterNestedProduct } from './newsletter_nested_product';
import { UploadedFile } from 'app/models/uploaded_file';

export class MailNewsletterSetsOfTwo extends Serializeable {
    static instanceType = MailNewsletterSetsOfTwo;
   
    public shop_title: string;
    public shop_description: string;
    public order_contact: string;
    public shopowner_fullname: string;
    public shopowner_photo: string;
    public shopowner_mobilephone: string;
    public shopowner_email: string;
    public shopowner_linkshop: string;
    public profileLink: string;
    public firstname: string;
    public fullname: string;
    public benefit_one: string;
    public benefit_two: string;
    public benefit_three: string;
    public benefit_four: string;
    public beautydeals_text_title: string;
    public beautydeals_preview_text: string;
    public beautydeals_text_subject: string;
    public beautydeals_text_introduction: string;
    public beautydeals_image_centerpiece: string;
    public product_name: string;
    public product_link: string;
    public customer_fullname: string;
    public product_sets: Array<NewsletterNestedProduct>;

    constructor(shopUser: User, user: User, newsletterCreator: NewsletterCreator = null) {
        super();
        this.product_sets = new Array<NewsletterNestedProduct>();

        let product_set = new NewsletterNestedProduct();
        product_set.products = new Array<NewsletterParamsProduct>();

        let fileSize: string = newsletterCreator.productLayout === 'naastelkaar' ? 'small' : 'medium';

        for(var i = 0; i < newsletterCreator.products.length; i++) {
            let product = newsletterCreator.products[i];
            let productPhotos: UploadedFile[] = newsletterCreator.promotionImages[product.id];
            let productPhoto = productPhotos != null ? productPhotos.filter(x => x.size === fileSize)[0] : null;

            if (productPhoto == null || productPhoto === undefined) {
                let photoIndex: number = -1;
                
                photoIndex = product.photos.findIndex(p => p.fileSize === fileSize);

                if (photoIndex != -1) {
                    product_set.products.push(new NewsletterParamsProduct(shopUser, product, product.photos[photoIndex].url));   
                }
                else {
                    product_set.products.push(new NewsletterParamsProduct(shopUser, product, product.photo_url));   
                }
            }
            else {
                product_set.products.push(new NewsletterParamsProduct(shopUser, product, productPhoto.downloadURL));
            }

            if (i % 2 == 1) {
                this.product_sets.push(product_set);
                product_set = new NewsletterNestedProduct();
                product_set.products = new Array<NewsletterParamsProduct>();
            }
        }

        this.shop_title = shopUser.shopTitle;
        this.shop_description = shopUser.shopDescription;
        this.order_contact = shopUser.orderContactDetails;
        this.shopowner_fullname = shopUser.displayName;
        this.shopowner_photo = shopUser.photo;
        this.shopowner_mobilephone = shopUser.phoneNumber;
        this.shopowner_email = shopUser.email;
        this.shopowner_linkshop = 'https://www.stylexplore.com/' + shopUser.slug + '/products';
        this.firstname = user.firstname;
        this.fullname = user.firstname + ' ' + user.lastname;
        this.customer_fullname = user.displayName;
        this.benefit_one = shopUser.advantage1;
        this.benefit_two = shopUser.advantage2;
        this.benefit_three = shopUser.advantage3;
        this.benefit_four = shopUser.advantage4;

        if (newsletterCreator != null) {
            this.beautydeals_text_title = newsletterCreator.title;
            this.beautydeals_preview_text = newsletterCreator.preview;
            this.beautydeals_text_subject = newsletterCreator.subject;
            this.beautydeals_text_introduction = newsletterCreator.text;
        }
    }
}