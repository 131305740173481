import { Component, OnInit, Input } from '@angular/core';
import { ProgressBarItem } from '../../models/progressbaritem';

@Component({
  selector: 'progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {
  @Input() items: Array<ProgressBarItem>;

  constructor() { }

  ngOnInit() {
  }

}
