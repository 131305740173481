<div class="row">
  <form id="addressForm" [formGroup]="referralForm" (ngSubmit)="onSubmitReferralForm(referralForm)">
    <div class="container">
      <div class="header">
        Doe je mee met het referral programma? Vul dan hieronder je tekst in.
      </div>
      <div class="row">
        <div class="col-sm-12">
          <label for="referralemailtext" class="required">Tekst voor email uitnodiging</label>
          <textarea type="text" class="form-control" id="referralemailtext" placeholder=""
            formControlName="referralemailtext" required rows="20"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <button type="submit" class="btn btn-success">
            Opslaan
          </button>
        </div>
      </div>
    </div>
  </form>
</div>