<h3>Stap 1. Nieuwsbrief opstellen</h3>

<div class="container">
  <div class="row">
    <div class="col-12">      
      <div id="newCustomer">
        <h5 class="card-title">Voer het onderwerp, de titel en de tekst van de nieuwsbrief in</h5>
        <form #inviteForm="ngForm" [formGroup]="newsletterForm" (ngSubmit)="createNewsletter(newsletterForm)" autocomplete="off" >
          <div class="form-group">
            <label for="subject">Onderwerpregel</label>
            <input type="text" class="form-control" id="subject" required formControlName="subject" name="subject"
              placeholder="De onderwerpregel verschijnt in de e-mail inbox van de ontvanger">
              <div *ngIf="submitted && subject.invalid" class="alert alert-danger">
                <div *ngIf="subject.errors?.['required']">De onderwerpregel is verplicht om in te vullen. Vul een tekst in.</div>
              </div>              
          </div>
          <div class="form-group">
            <label for="preview">Voorbeeldtekst</label>
            <input type="text" class="form-control" id="preview" required formControlName="preview" name="preview"
              placeholder="Achter de onderwerpregel verschijnt de voorbeeldtekst">
              <div *ngIf="preview.invalid && submitted" class="alert alert-danger">
                <div *ngIf="preview.errors?.['required']">De voorbeeldtekst is verplicht om in te vullen. Vul een tekst in.</div>
              </div>              
          </div>
          <div class="form-group">
            <label for="title">Titel</label>
            <input type="text" class="form-control" id="name" required formControlName="title" name="title"
              placeholder="Dit is de titel van de tekst die in de nieuwsbrief verschijnt">
              <div *ngIf="title.invalid && submitted" class="alert alert-danger">
                <div *ngIf="title.errors?.['required']">De titel is verplicht om in te vullen. Vul een tekst in.</div>
              </div>                            
          </div>
          <div class="form-group">
            <label for="text">Tekst</label>
            <textarea  class="form-control" id="text" required formControlName="text" name="text"
              placeholder="Dit is de tekst die onder de titel in de nieuwsbrief verschijnt" rows="15"></textarea>
              <div *ngIf="text.invalid && submitted" class="alert alert-danger">
                <div *ngIf="text.errors?.['required']">De tekst is verplicht om in te vullen. Vul een tekst in.</div>
              </div>                            
          </div>
          <button type="submit" class="btn btn-success">Volgende stap</button>
        </form>
      </div>
    </div>
  </div>
</div>