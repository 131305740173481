import { Serializeable } from './serializeable';

export class PrivateGroupInvitation extends Serializeable {
    static instanceType = PrivateGroupInvitation;

    id: string;
    firstname: string;
    lastname: string;
    shop_user_id: string;
    shop_user_slug: string;
    sx_user_id: string;
    email: string;
    accepted: boolean;    
    join_date: Date;
    send_date: Date;
}