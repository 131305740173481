<div class="container">
  <div class="row">

    <form #productBundleForm="ngForm">
      <h3>Herhaalservice klant email (template #43)</h3>
      <div class="form-group" *ngIf="model.hasShopOwners">
        <label for="shopowner">Shop owner</label>
        <select id="shopowner" required [(ngModel)]="model.shopowner" name="shopowner" (change)="loadCustomers($event.target.value)">
          <option value="{{item.id}}" *ngFor="let item of model.shopOwners">{{item.displayName}}</option>
        </select>
      </div>
      <div class="form-group" *ngIf="model.hasCustomers">
        <label for="customer">Customer</label>
        <select id="customer" required [(ngModel)]="model.customer" name="customer" (change)="selectCustomer($event.target.value)">
          <option value="{{item.id}}" *ngFor="let item of model.customers">{{item.displayName}}</option>
        </select>
      </div>
      <!-- <div class="form-group" *ngIf="model.hasProducts">
        <label for="product">Product</label>
        <select id="product" required [(ngModel)]="model.product" name="product">
          <option value="{{item.id}}" *ngFor="let item of products" (click)="selectProduct(item.id)">{{item.title}}</option>
        </select>
      </div> -->
      <button type="submit" class="btn btn-success" (click)="sendEmail()" *ngIf="model.hasCustomer">Verstuur email</button>
    </form>
  </div>
</div>