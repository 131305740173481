<form id="addressForm" [formGroup]="addressForm" (ngSubmit)="onSubmitAddressForm(addressForm)">
  <h3>{{title}}</h3>

  <div class="form compact" *ngIf="!edit">
    <div class="container">
      <div class="row">
        <div [class]="addressClass">
          <div>{{addressForm.value.firstname}} {{addressForm.value.lastname}}</div>
          <div>{{addressForm.value.street}} {{addressForm.value.housenumber}} {{addressForm.value.housenumber_addition}}
          </div>
          <div>{{addressForm.value.zipcode}} {{addressForm.value.city}}</div>
          <div>{{addressForm.value.country}}</div>
          <div></div>
        </div>
        <div class="col-sm-6" *ngIf="editButton">
          <div>
            <button type="submit" class="btn btn-success" (click)="editAddress(true)">Bewerken</button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="sameShipping">
        <div class="col-sm-12" *ngIf="!initWithSameShipping">
          <input type="checkbox" formControlName="sameShippingAndBillingAddress"
              (change)="onChangeShipping($event.target.checked)" />
            <label for="same-billing">Factuuradres is hetzelfde als het bezorgadres</label>
        </div>
        <div class="col-sm-12" *ngIf="initWithSameShipping">
          <input type="checkbox" formControlName="sameShippingAndBillingAddress"
              (change)="onChangeShipping($event.target.checked)" checked="checked" />
            <label for="same-billing">Factuuradres is hetzelfde als het bezorgadres</label>
        </div>
      </div>
    </div>
  </div>
  <div class="form form-row container" *ngIf="edit">
    <div class="container">
      <br>
      <div class="row">
        <div class="col-sm">
          <input type="text" class="form-control" id="firstname" placeholder="Voornaam" formControlName="firstname"
            required>
        </div>
        <div class="col-sm">
          <input type="text" class="form-control" id="lastname" placeholder="Achternaam" formControlName="lastname"
            required>
        </div>
      </div>

    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <input type="text" class="form-control" id="zipcode" placeholder="Postcode" formControlName="zipcode"
            required>
        </div>
        <div class="col-md-4">
          <input type="number" class="form-control" id="housenumber" placeholder="Huisnummer"
            formControlName="housenumber" required>

        </div>
        <div class="col-md-4">
          <input type="text" class="form-control" id="housenumber_addition" placeholder="Toevoeging"
            formControlName="housenumber_addition">
        </div>
      </div>

    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <input type="text" class="form-control" id="street" placeholder="Straat" formControlName="street" required />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <input type="text" class="form-control" id="city" placeholder="Stad" formControlName="city" required>

        </div>
        <div class="col-md-6">
          <select id="country" class="form-control" formControlName="country">
            <option value="NL">Nederland</option>
            <option value="BE">Belgie</option>
            <option value="DE">Duitsland</option>
          </select>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn-success">
            {{formButtonText}}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>