import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CartState } from 'app/models/cart-state';
import { CartItem } from 'app/models/cartitem';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { CartService } from 'app/services/cart.service';
import { DiscountCodeService } from 'app/services/discount-code.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'discount-code',
  templateUrl: './discount-code.component.html',
  styleUrls: ['./discount-code.component.scss']
})
export class DiscountCodeComponent implements OnInit {
  public hasDiscountCode: boolean;
  public discountCodeIsValid: boolean;
  public checkedDiscountCode: boolean;
  public showDiscountCodeForm: boolean;
  public discountCode: string;
  public discountCodeStatusText: string;
  public discountCodeForm: UntypedFormGroup;
  public invitation: ReferralInvitation;
  public totalCartPrice: number;
  public cartItems: CartItem[];

  constructor(private discountCodeService: DiscountCodeService,
              private formBuilder: UntypedFormBuilder,
              private userService: UserService, 
              private cartService: CartService) { 
    this.hasDiscountCode = discountCodeService.hasDiscountCode();
    this.initializeForm();
  }

  ngOnInit() {
    this
    .cartService
    .cartState
    .subscribe((state : CartState) => {
      this.totalCartPrice = state.totalPrice;
      this.cartItems = state.items;

      if (!(state.discountCode === undefined || state.discountCode == null)) {
        this.hasDiscountCode = true;
      }
      else {
        this.hasDiscountCode = false;
      }  
    });
  }

  toggleDiscountCode() {
    this.showDiscountCodeForm = !this.showDiscountCodeForm;
  }

  async checkDiscountCode(form: UntypedFormGroup) {
    if (form.valid) {
      let loggedInUser = this.userService.sxUser;

      let prospectDiscountCode = await this.discountCodeService.getInvitationForDiscountCode(form.value.discountCode);
      let promotorDiscountCode = await this.discountCodeService.getInvitationForPromotorDiscountCode(form.value.discountCode);

      let isProspect: boolean = prospectDiscountCode != null;
      let isPromotor: boolean = promotorDiscountCode != null;

      let referralInvitation: ReferralInvitation = isProspect ? prospectDiscountCode : promotorDiscountCode;
      let discountDate = isPromotor ? referralInvitation.promotor_discount_date : referralInvitation.discount_date;
  
      var hasInvitation = !(referralInvitation == null ||
                          referralInvitation === undefined);

      if (hasInvitation) {
        this.discountCodeIsValid = this.discountCodeService.isValid(referralInvitation, this.totalCartPrice, this.cartItems, discountDate, isProspect, isPromotor);
        this.discountCodeStatusText = await this.discountCodeService.getDiscountStatusText(referralInvitation, this.totalCartPrice, this.cartItems, referralInvitation.shopowner_user_id, isProspect, isPromotor);

        if (this.discountCodeIsValid) {
          var cart = this.cartService.loadCart();
          
          if (isPromotor) {
            this.cartService.applyDiscount(cart, referralInvitation.promotor_discount_code, referralInvitation.promotor_discount, referralInvitation.promotor_discount_date.toDate(), referralInvitation.shopowner_user_id, referralInvitation.minimum_order_price, isPromotor, isProspect);
          }

          if (isProspect) {
            this.cartService.applyDiscount(cart, referralInvitation.discount_code, referralInvitation.prospect_discount, referralInvitation.discount_date.toDate(), referralInvitation.shopowner_user_id, referralInvitation.minimum_order_price, isPromotor, isProspect);
          }

          this.cartService.loadCart(); // update cart with discount
        }

        this.checkedDiscountCode = true;
      } 
      else {
        this.discountCodeIsValid = false;
        this.discountCodeStatusText = "Deze kortingscode bestaat niet";
      }

      this.checkedDiscountCode = true;
    }
    else {
      this.checkedDiscountCode = false;
    }
  }

  private initializeForm() {
    this.discountCodeForm = this.formBuilder.group({
      discountCode: this.hasDiscountCode ? this.discountCode : ''
    });
  }
}
