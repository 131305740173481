<div class="container" *ngIf="hasInvitation">
    <div class="row">
        <div class="container col-md-9">
            <h3>Ontvang {{referralInvitation.prospect_discount}} % korting bij je eerste bestelling vanaf
                {{referralInvitation.minimum_order_price}} euro</h3>    
            <div>
                Gefeliciteerd {{referralInvitation.prospect_first_name}}!<br /><br />
                Omdat {{referralInvitation.promotor_name}} jou genoemd heeft,
                ontvang jij van ons een introductie aanbod.
            </div>
            <div *ngIf="loggedOut">
                Om jouw unieke kortingscode te ontvangen via email, hebben wij je e-mail adres nodig.<br />
            </div>
            <div>
                We beloven geen spam te sturen.<br /><br />
            </div>
            <div>
                <div *ngIf="!loggedOut">
                    Klik op de knop om gebruik te maken van de aanbieding.
                </div>    
                <form id="referrerForm" [formGroup]="referrerForm" (ngSubmit)="sendDiscountCode(referrerForm)">
                    <div class="form-group" *ngIf="loggedOut">
                        <label for="email">E-mailadres</label>
                        <input type="email" class="form-control" id="email" placeholder="E-mailadres" formControlName="email">
                      </div>
                      <button type="submit" class="btn btn-primary btn-success">
                        Ontvang je cadeau
                      </button>
                      <div>&nbsp;</div>
                      <div class="card border-success mb-9" *ngIf="discountCodeSent">
                        <div class="card-body text-success">
                          <h5 class="card-title">Je unieke kortingscode is verstuurd per email</h5>
                          <p class="card-text">Heb je na enkele minuten geen mail ontvangen? Check dan je SPAM box.</p>
                        </div>
                      </div>
                </form> 
            </div>
        </div>
    </div>
</div>