import { Component, OnInit, Input } from '@angular/core';
import { OrderLine } from 'app/models/orderline';

@Component({
  selector: 'orderline-detail',
  templateUrl: './orderline-detail.component.html',
  styleUrls: ['./orderline-detail.component.scss']
})
export class OrderlineDetailComponent implements OnInit {
  @Input() item: OrderLine;
  constructor() { }

  ngOnInit() {
  }

}
