import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'app/models/category';
import { CategoryTreeNode } from 'app/models/category-tree-node';
import { Product } from 'app/models/product';
import { CategoryService } from 'app/services/category.service';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() product: Product;
  public categoryTree: CategoryTreeNode[] = [];
  public mainCategory: CategoryTreeNode;
  public secondCategory: Category;
  public thirdCategory: Category;

  constructor(private categoryService: CategoryService) { 
    
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  get hasCategories(): boolean {
    let p: Product = this.product;
    let hasNoProduct = p == null || p == undefined;
    
    if (hasNoProduct) {
      return false;
    }

    return false;

    // let hasNoCategories = p.categories == null || p.categories == undefined;
    // return !hasNoCategories && p.categories.length > 0;  
  }
}