import { Component, OnInit } from '@angular/core';
import { ProgressbarService } from 'app/services/progressbar.service';
import { CartService } from 'app/services/cart.service';
import { ProgressBarItem } from 'app/models/progressbaritem';
import { CartItem } from 'app/models/cartitem';
import { Router } from '@angular/router';
import { Cart } from 'app/models/cart';
import { Address } from 'app/models/address';
import { AddressService } from 'app/services/address.service';
import { AddressState } from 'app/models/address_state';
import { UserService } from 'app/services/user.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {
  public progressBarItems: Array<ProgressBarItem>;
  public items: Array<CartItem>;
  public cart: Cart;
  public showBillingAddress: boolean;

  public sameShippingAndBillingAddress: boolean = true;
  public editBillingForm: boolean = !this.sameShippingAndBillingAddress;
  public hasPickupAddress: boolean;
  public shipOrder: boolean = false;
  public pickupOrder: boolean = false;
  public pickupAddressId: string = "";
  private shippingAddress: Address;
  private billingAddress: Address;

  constructor(private progressBarService: ProgressbarService,
              private cartService: CartService,
              private router: Router,
              private addressService: AddressService, 
              private userService: UserService) {
    this.progressBarItems = this.progressBarService.getCheckoutProgressBar(3);
    this.cart = this.cartService.loadCart();
    this.items = this.cart.cartItems;

    this.sameShippingAndBillingAddress = this.cart.sameShippingAndBillingAddress;
    this.showBillingAddress = !this.sameShippingAndBillingAddress;

    if (this.cart.pickupAddressId !== null && this.cart.pickupAddressId !== undefined) {
      this.shipOrder = false;
      this.pickupOrder = true;
    }
    else {
      if (this.shipOrder === false && this.pickupOrder === false) {
        this.shipOrder = true;
        this.pickupOrder = false;
      }  
    }

  }

  ngOnInit() {

    if (this.items.length > 0) {
      let index = this.items.findIndex(a => a.product.user !== null && a.product.user !== undefined && a.product.user.pickup_address_id !== null && a.product.user.pickup_address_id !== undefined);
      this.hasPickupAddress = index != -1;
      if (this.hasPickupAddress) {
        let cartItem: CartItem = this.items[index];
        this.pickupAddressId = cartItem.product.user.pickup_address_id;
      }
      else {
        this.pickupAddressId = null;
        this.pickupOrder = false;
        this.shipOrder = true;
      }

      if (this.pickupOrder == false && this.shipOrder == false) {
        this.pickupOrder = false;
        this.shipOrder = true;
      }
    }

    this.addressService.addressState.subscribe((state: AddressState) => {
      this.showBillingAddress = !state.sameShippingAndBillingAddress;

      if (state.sameShippingAndBillingAddress) {
        this.shippingAddress = state.address;
      }
      else {
        this.shippingAddress = null;
      }

      this.sameShippingAndBillingAddress = state.sameShippingAndBillingAddress;
    });
  }

  changeOrderMethod(shipOrder: boolean) {
    this.shipOrder = shipOrder;
    this.pickupOrder = !shipOrder;

    if (this.pickupOrder) {
      this.cart.pickupAddressId = this.pickupAddressId;
    }
    else {
      this.cart.pickupAddressId = null;
    }

    this.cartService.updateCart(this.cart);
  }

  gotoNextScreen() {
    if(this.pickupOrder) {
      this.router.navigate(['/confirm']);
    }
    else {
      forkJoin([
        this.addressService.getAddress('shipping'),
        this.addressService.getAddress('billing')]
      ).subscribe(async addresses => {
        this.shippingAddress = addresses[0];
        this.billingAddress = addresses[1];

        let sa: Address = this.shippingAddress;    
      
        if (this.sameShippingAndBillingAddress) {
            
          if (this.billingAddress != null) {
              this.billingAddress.firstname = sa.firstname;
              this.billingAddress.lastname = sa.lastname;
              this.billingAddress.street = sa.street;
              this.billingAddress.housenumber = sa.housenumber;
              this.billingAddress.housenumber_addition = sa.housenumber_addition;
              this.billingAddress.zipcode = sa.zipcode;
              this.billingAddress.city = sa.city;
              this.billingAddress.country = sa.country;
              this.addressService.updateAddress(this.billingAddress);
            }
            else {
              this.billingAddress = sa;
            }
            
            this.userService.sxUser.shipping_address_id = sa.id;
            this.userService.sxUser.billing_address_id = sa.id;
  
            this.addressService.updateAddressWithType(this.shippingAddress, 'shipping');
            this.addressService.updateAddressWithType(this.shippingAddress, 'billing')
        }
        else {
          if (this.shippingAddress !== null && this.shippingAddress !== undefined) {
            this.userService.sxUser.shipping_address_id = this.shippingAddress.id;
            this.addressService.updateAddressWithType(this.shippingAddress, 'shipping');
          }

          if (this.billingAddress !== null && this.billingAddress !== undefined) {
            this.userService.sxUser.billing_address_id = this.billingAddress.id;
            this.addressService.updateAddressWithType(this.billingAddress, 'billing')
          }
        }
                
        this.router.navigate(['/confirm']);
      });      
    }



  }  
}
