import { Serializeable } from './serializeable';

export class Address extends Serializeable {
    static instanceType = Address;
    id: string;
    user_id: string;
    firstname: string;
    lastname: string;
    address_type: string;
    street: string;
    housenumber: string;
    housenumber_addition: string;
    zipcode: string;
    city: string;
    country: string;

    get hasValues(): boolean {
        return this.id !== '';
    }

    get hasHouseNumberAddition(): boolean {
        let hasNoAddition: boolean = this.housenumber_addition == null || this.housenumber_addition === undefined;
        return !hasNoAddition;
    }
}