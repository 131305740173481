import { Serializeable } from 'app/models/serializeable';
import { MailOrderParamsProduct } from './mail_order_params_product';
import { Address } from 'app/models/address';
import { User } from '../user';
import { OrderLine } from '../orderline';

export class MailOrderParams extends Serializeable {
    static instanceType = MailOrderParams;

    public products: MailOrderParamsProduct[];
    public shipping_fullname: string;
    public shipping_street_and_housenumber: string;
    public shipping_zipcode: string;
    public shipping_city: string;
    public shipping_country: string;
    public billing_fullname: string;
    public billing_street_and_housenumber: string;
    public billing_zipcode: string;
    public billing_city: string;
    public billing_country: string;

    public billing_email: string;
    public billing_phone: string;

    public pickup_fullname: string;
    public pickup_address_fullname: string;
    public pickup_street_and_housenumber: string;
    public pickup_zipcode: string;
    public pickup_city: string;
    public pickup_country: string;

    public shop_title: string;
    public shop_description: string;
    public shipping_thanks: string;
    public payment_details: string;
    public shipping_details: string;
    public order_contact: string;
    public footer_logo: string;
    public shopowner_fullname: string;
    public shopowner_photo: string;
    public shopowner_mobilephone: string;
    public shopowner_email: string; 
    public order_total_price: string;

    public ordernumber: string;

    public new_user_fullname: string; 
    public new_user_email: string; 
    public new_user_password: string;

    public customer_fullname: string;
    public customer_email: string;
    public customer_phone: string;
    public customer_firstname: string;
    public customer_lastname: string;
    public discount: string;

    constructor(orderLines: OrderLine[], shippingAddress: Address, billingAddress: Address, shopUser: User, pickupAddress: Address, customerUser: User, discount: number) {
        super();

        this.products = new Array<MailOrderParamsProduct>();

        let totalPrice: number = 0;
        let skipOrderLines: boolean = orderLines == null || orderLines === undefined;
        let skipShippingAddress: boolean = shippingAddress == null || shippingAddress === undefined;
        let skipBillingAddress: boolean = billingAddress == null || billingAddress === undefined;
        let skipPickupAddress: boolean = pickupAddress == null || pickupAddress === undefined;
        let skipShopUser: boolean = shopUser == null || shopUser === undefined;

        this.customer_fullname = customerUser.displayName;
        this.customer_email = customerUser.email;
        this.customer_phone = customerUser.phoneNumber; 
        this.customer_firstname = customerUser.firstname;
        this.customer_lastname = customerUser.lastname;
        
        this.discount = '€ ' + discount.toFixed(2);

        if (!skipOrderLines) {
            orderLines.forEach(orderline => {
                totalPrice += orderline.total_price;
                if (this.ordernumber === undefined) {
                    this.ordernumber = orderline.order_id;
                }

                this.products.push(new MailOrderParamsProduct(orderline));            
            });    
        }

        if (!skipShippingAddress)
        {
            const housenumberAddition: string = shippingAddress.hasHouseNumberAddition ? (shippingAddress.housenumber_addition.length > 0 ? " " + shippingAddress.housenumber_addition : "") : "";
            this.shipping_street_and_housenumber = shippingAddress.street + " " + shippingAddress.housenumber + housenumberAddition;
            this.shipping_fullname = shippingAddress.firstname + " " + shippingAddress.lastname;
            this.shipping_zipcode = shippingAddress.zipcode;
            this.shipping_city = shippingAddress.city;
            this.shipping_country = shippingAddress.country;
        }

        if (!skipBillingAddress)
        {
            const billingHousenumberAddition: string = billingAddress.hasHouseNumberAddition ? (billingAddress.housenumber_addition.length > 0 ? " " + billingAddress.housenumber_addition : "") : "";
            this.billing_fullname = billingAddress.firstname + " " + billingAddress.lastname;
            this.billing_street_and_housenumber = billingAddress.street + " " + billingAddress.housenumber + billingHousenumberAddition;
            this.billing_zipcode = billingAddress.zipcode;
            this.billing_city = billingAddress.city;
            this.billing_country = billingAddress.country;
        }

        if (!skipPickupAddress)
        {
            const billingHousenumberAddition: string = pickupAddress.hasHouseNumberAddition ? (pickupAddress.housenumber_addition.length > 0 ? " " + pickupAddress.housenumber_addition : "") : "";
            this.pickup_fullname = customerUser.displayName;
            this.pickup_address_fullname = pickupAddress.firstname + " " + pickupAddress.lastname;
            this.pickup_street_and_housenumber = pickupAddress.street + " " + pickupAddress.housenumber + billingHousenumberAddition;
            this.pickup_zipcode = pickupAddress.zipcode;
            this.pickup_city = pickupAddress.city;
            this.pickup_country = pickupAddress.country;    
        }

        if (totalPrice != null || totalPrice !== undefined) {
            let theTotalOrderPrice: number = totalPrice - discount;
            this.order_total_price = '€ ' + theTotalOrderPrice.toFixed(2);
        }

        if (!skipShopUser)
        {
            this.shop_title = shopUser.shopTitle;
            this.shop_description = shopUser.shopDescription;
            this.shipping_thanks = shopUser.shippingMessage;
            this.payment_details = shopUser.paymentDetailsMessage;
            this.shipping_details = shopUser.shippingDetailsMessage;
            this.order_contact = shopUser.orderContactDetails;
            this.footer_logo = "";
            this.shopowner_fullname = shopUser.displayName;
            this.shopowner_photo = shopUser.photo;
            this.shopowner_mobilephone = shopUser.phoneNumber;
            this.shopowner_email = shopUser.email;    
        }

        if (!skipPickupAddress) {
            this.payment_details = shopUser.pickupOrderPaymentDetailsMessage;
            this.shipping_details = shopUser.pickupOrderShippingDetailsMessage;    
        }

    }
}
