import { Component, OnInit } from '@angular/core';
import { CartService } from 'app/services/cart.service';
import { ProgressbarService } from 'app/services/progressbar.service';
import { CartItem } from 'app/models/cartitem';
import { ProgressBarItem } from 'app/models/progressbaritem';
import { Cart } from 'app/models/cart';
import { Address } from 'app/models/address';
import { OrderService } from 'app/services/order.service';
import { Router } from '@angular/router';
import { Order } from 'app/models/order';
import { AddressService } from 'app/services/address.service';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  public progressBarItems: Array<ProgressBarItem>;
  public items: Array<CartItem>;
  public shippingAddress: Address;
  public pickupAddress: Address;
  public pickupAddressId: string;
  public cart: Cart;
  public billingAddressType: string;
  public hasPickupAddress: boolean = false;

  constructor(private progressBarService: ProgressbarService,
              private cartService: CartService,
              private orderService: OrderService,
              private addressService: AddressService,
              private userService: UserService,
              private router: Router) {
                this.progressBarItems = this.progressBarService.getCheckoutProgressBar(4);
                this.cart = this.cartService.loadCart();
                this.pickupAddressId = this.cart.pickupAddressId;
                
                if (this.cart.hasPickupAddress) {
                  this.hasPickupAddress = true;
                  this.addressService.getAddressById(this.cart.pickupAddressId).then(pickupAddress => {
                    this.pickupAddress = pickupAddress;
                  });
                }

                this.items = this.cart.cartItems;
                this.billingAddressType = this.cart.sameShippingAndBillingAddress ? "shipping" : "billing";
                this.shippingAddress = this.addressService.shippingAddress;
              }

  ngOnInit() {
    this.orderService.orderState.subscribe(() => {
      this.router.navigate(['/confirmation']);
    });
  }

  async placeOrder() {
    let cart: Cart = this.cartService.loadCart()
    let user: User = this.userService.sxUser;
    let shippingAddress: Address = this.addressService.shippingAddress; 
    let billingAddress: Address = this.addressService.billingAddress;
        
    await this.orderService.createOrder(cart, user, shippingAddress, billingAddress, null);
  }

}
