<div class="row">
  <form id="addressForm" [formGroup]="addressForm" (ngSubmit)="onSubmitAddressForm(addressForm)">
    <div class="container" *ngIf="userService.userHasProducts">
      <div class="header">
        Komen klanten bij jou de producten afhalen?
      </div>
      <div class="form-check">
        <input formControlName="hasPickupAddress" [value]="true" class="form-check-input" type="radio" id="has-pickup-address"
          (click)="displayAddressForm()"><label for="has-pickup-address">Ja</label>
      </div>
      <div class="form-check">
        <input formControlName="hasPickupAddress" [value]="false" class="form-check-input" type="radio" id="has-no-pickup-address"
          (click)="hideAddressForm()"><label for="has-no-pickup-address">Nee</label>
      </div>
      <div *ngIf="showForm">
        <h3>Afhaaladres</h3>
        <div class="row">
          <div class="col-sm">
            <label for="firstname" class="required">Voornaam</label>
            <input type="text" class="form-control" id="firstname" placeholder="Voornaam" formControlName="firstname"
              required>
          </div>
          <div class="col-sm">
            <label for="lastname" class="required">Achternaam</label>
            <input type="text" class="form-control" id="lastname" placeholder="Achternaam" formControlName="lastname"
              required>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <label for="street" class="required">Postcode</label>
            <input type="text" class="form-control" id="zipcode" placeholder="Postcode" formControlName="zipcode"
              required>
          </div>
          <div class="col-md-4">
            <label for="housenumber" class="required">Huisnummer</label>
            <input type="text" class="form-control" id="housenumber" placeholder="Huisnummer"
              formControlName="housenumber" required>

          </div>
          <div class="col-md-4">
            <label for="housenumber_addition">toevoeging</label>
            <input type="text" class="form-control" id="housenumber_addition" placeholder="bijv. 2A"
              formControlName="housenumber_addition">
          </div>
        </div>

        <div class="row">
          <div class="col-sm">
            <label for="street" class="required">Straat</label>
            <input type="text" class="form-control" id="street" placeholder="Straat" formControlName="street"
              required />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="city" class="required">Woonplaats</label>
            <input type="text" class="form-control" id="city" placeholder="city" formControlName="city" required>
          </div>
          <div class="col-md-6">
            <label for="country" class="required">Land</label>
            <select id="country" class="form-control" formControlName="country">
              <option value="NL">Nederland</option>
              <option value="BE">Belgie</option>
              <option value="DE">Duitsland</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn-success">
            Opslaan
          </button>
        </div>
      </div>
    </div>
  </form>
</div>