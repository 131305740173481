import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ProductService } from 'app/services/product.service';
import { Product } from 'app/models/product';
import { UserService } from 'app/services/user.service';
import { Photo } from 'app/models/photo';
import { CartService } from 'app/services/cart.service';
import { Pagination } from 'app/models/pagination.model';
import { PaginationService } from 'app/services/pagination.service';

@Component({
  selector: 'my-products',
  templateUrl: './my-products.component.html',
  styleUrls: ['./my-products.component.scss']
})
export class MyProductsComponent implements OnInit {

  public products: Product[] = [];
  public productPhotos: Photo[];
  public userSlug: string;
  public showList: boolean = false;
  public pagination: Pagination;

  constructor(private productService: ProductService,
    private cartService: CartService,
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService,
    public paginationService: PaginationService) {
      this.pagination = new Pagination(12);
    this.route.params.subscribe(params => this.loadProducts(params.user_id));
  }
  toggleList(boolVal: boolean) {
    this.showList = boolVal;
  }

  async ngOnInit() {
    if (this.userService.userHasProducts) {
      this.toggleList(true);
    }
  }

  async loadProducts(slug = '') {
    this.userSlug = slug;
    if (slug !== '') {
      this.userService.findUserBySlug(slug).then(async u => {
        const products = (await this.productService.getProducts(u, this.pagination.itemsPerPage, this.pagination.lastVisible));
        if (products.products.length > 0) {
          this.products.push(...products.products);
          this.pagination.updateLastVisible(products.lastVisible); 
          this.pagination.setHasMore(products.products.length === this.pagination.itemsPerPage); 
        }
        else {
          this.pagination.setHasMore(false);
        }
      });
    } else {
      const products = await this.productService.getAllProducts(this.pagination.itemsPerPage, this.pagination.lastVisible);
      if (products.products.length > 0) {
        this.products.push(...products.products);
        this.pagination.updateLastVisible(products.lastVisible);  
        this.pagination.setHasMore(products.products.length === this.pagination.itemsPerPage); 
      }
      else {
        this.pagination.setHasMore(false);
      }
    }
  }

  gotoProduct(product: Product) {
    this.router.navigate(['products', product.slug]);
  }

  addToCart(product: Product) {
    this.cartService.addToCart(product);
  }

  editProduct(product: Product) {
    this.router.navigate(['products', product.slug, 'edit']);
  }

  deleteProduct(product: Product) {
    if (confirm("Weet je zeker dat je " + product.title + " wilt verwijderen?")) {
      this.productService.deleteProduct(product.id).then(d => {
        this.deleteFromProducts(product.id);
      })
    }
  }

  deleteFromProducts(id: string) {
    const productIndex = this.products.findIndex(p => p.id == id);

    if (productIndex != -1) {
      this.products.splice(productIndex, 1);
    }
  }

  updateStock(product: Product, stock: string) {
    product.stock = parseInt(stock);
    this.productService.updateProduct(product);
  }

  canOrderProduct(product: Product): boolean {
    return this.userService.canOrderProduct(product, this.cartService);
  }

  hasStock(product: Product): boolean {
    return this.userService.productHasStock(product, this.cartService);
  }

  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  onNextPage() {
    this.pagination.changePage(this.pagination.currentPage + 1);
    this.loadProducts(this.userSlug);
  }
}