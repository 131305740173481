<div class="container">
  <div class="row flex">
    <section id="cart">
      <h5 class="header_font"><strong>Jouw winkelmandje</strong></h5>
      <cart-items [items]="items" [edit]="true"></cart-items>
    </section>
    <div class="sidebar">
      <div class="sidebar-container">
        <div class="box-container">
          <div class="shadow">
            <cart-total></cart-total>      
            <div>
              <button type="button" class="btn btn-success btn-block" (click)="goToCheckout()">
                Verder naar bestellen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>