import { Serializeable } from '.././serializeable';
import { Timestamp } from '@angular/fire/firestore';

export class ReferralInvitation extends Serializeable {
  static instanceType = ReferralInvitation;

  id: string;
  promotor_user_id: string;
  promotor_name: string;
  promotor_email: string;
  prospect_email: string;
  prospect_user_id: string;
  prospect_first_name: string;
  prospect_last_name: string;
  shopowner_user_id: string;
  discount_code: string;
  discount_date: Timestamp;
  activation_date: Timestamp;
  clicked_link_counter: number;
  minimum_order_price: number;
  promotor_discount: number;
  prospect_discount: number;
  used: boolean;
  usedByProspect: boolean;
  usedByPromotor: boolean;
  activated: boolean;
  promotor_used_discount: boolean;
  promotor_activation_date: Timestamp;
  promotor_discount_date: Timestamp;
  promotor_discount_expiration_date: Timestamp;
  promotor_discount_code: string;
  prospectLinkClicks: number;

  constructor(id: string | null = null, promotor_user_id: string | null = null) {
    super();

    if (id) this.id = id;
    if (promotor_user_id) this.promotor_user_id = promotor_user_id;
  }

  public get prospectFullName(): string {
    return this.prospect_first_name + ' ' + this.prospect_last_name;
  }
}