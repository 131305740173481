import { Component, Input } from '@angular/core';
import { ImageSize } from 'app/models/image_size';

@Component({
  selector: 'uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {
  isHovering: boolean;
  public multiple: boolean; 
  public single: boolean; 

  files: File[] = [];
  @Input() uid: string;
  @Input() displayMode: string;
  @Input() resizeImage: boolean;
  @Input() resizedImageHeight: number;
  @Input() resizedImageWidth: number;
  @Input() imageSizes: ImageSize[];

  onNgInit() {
  }

  ngOnChanges() {
    this.multiple = this.displayMode == "multi";
    this.single = this.displayMode == "single";
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));
    }
  }
}
