import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgressBarItem } from 'app/models/progressbaritem';
import { NewsletterService } from 'app/services/newsletter.service';
import { ProgressbarService } from 'app/services/progressbar.service';

@Component({
  selector: 'app-newsletter-creator',
  templateUrl: './newsletter-creator.component.html',
  styleUrls: ['./newsletter-creator.component.scss']
})
export class NewsletterCreatorComponent implements OnInit {
  public progressBarItems: Array<ProgressBarItem>;
  public step: string;
  public createNewsletter: boolean;
  public selectProduct: boolean;
  public previewNewsletter: boolean;
  public confirmedStep: boolean;

  constructor(private progressBarService: ProgressbarService, 
              private route: ActivatedRoute,
              private newsletterService: NewsletterService) { 
                this.setStep('create-newsletter');
    this.route.params.subscribe(params => this.setStep(params.step));
  }

  ngOnInit() {
    this.newsletterService.newsletterCreatorStepState.subscribe((step: string) => {
      this.setStep(step);
    });
  }

  private setStep(step: string) {
    this.step = step;

    this.createNewsletter = step == 'create-newsletter' || step === undefined || step == null;
    this.selectProduct = step == 'select-product';
    this.previewNewsletter = step == 'preview';
    this.confirmedStep = step == 'sent';

    let activeStep:number = 1;

    if (this.selectProduct) {
      activeStep = 2;
    }

    if (this.previewNewsletter) {
      activeStep = 3;
    }

    if (this.confirmedStep) {
      activeStep = 4;
    }

    this.progressBarItems = this.progressBarService.getNewsletterCreatorProgressBar(activeStep);
  }

}
