<div id="userGroup" class="mx-auto" *ngIf="user">
  <section class="card p-2 private-group">
    <img src="{{user.photo}}" class="responsive profile-photo" />
    <h3 align="center">Privégroep van {{user.displayName}}</h3>
    <div class="text-center" *ngIf="user.hasShopTitle">
      Eigenaar van {{user.shopTitle}}
    </div>
  </section>
  <div class="accepted">
    <div class="card">
      <h5 class="card-header" *ngIf="invitation">{{invitation.firstname}}, welkom in de privégroep van
        {{user.displayName}}!</h5>
      <div class="card-body">
        <h5 class="card-title">Je account is succesvol aangemaakt</h5>
        <div class="card-text">&nbsp;</div>
        <a [routerLink]="['/', user.slug, 'products']" class="btn btn-primary">Bezoek de privégroep</a>
      </div>
    </div>
  </div>
</div>