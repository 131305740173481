import { Photo } from './photo';
import { SlugifyPipe } from '../pipes/slugify.pipe'
import { Serializeable } from './serializeable';
import { User } from './user';

export class Product extends Serializeable {
    static instanceType = Product;

    id: string;
    title: string;
    description: string;
    price: string;
    priceFrom: string;
    slug: string;
    user_id: string;
    created_at: Date;
    updated_at: Date;
    photos: Photo[];
    photo_url: string;
    user: User;
    stock: number;
    bundle_id: string;
    private: boolean;
    subscription: boolean;
    subscriptionPrice: string;
    shopProductCategoryId: string;

    constructor(id: string | null = null, user_id: string | null = null) {
        super();

        if (id !== null) {
            this.id = id;
        }
        if (user_id !== null) {
            this.user_id = user_id;
        }
    }

    get productSlug(): string {
        if (!this.slug || this.slug === '') {
            if (this.title) this.slug = new SlugifyPipe().transform(this.title)
            else this.slug = ''
        }

        return this.slug
    }

    get shortDescription(): string {
        let shortDescription = '';

        if (this.description == null) {
            return '';
        }

        if (this.description.length > 10) {
            shortDescription = this.description.substring(0, 10) + '...';
        } else {
            shortDescription = this.description;
        }

        return shortDescription;
    }

    get photo(): string {
        let photo = '';

        if (this.photos.length > 0) {
            photo = this.photos[0].url;
        }
        return photo;
    }

    get hasUser(): boolean {
        return this.user != null || this.user != undefined;
    }

    get hasPickupAddress(): boolean {
        return this.hasUser ? this.user.hasPickupAddress : false;
    }
}
