import { Component, OnInit, Input } from '@angular/core';
import { Address } from 'app/models/address';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CartService } from 'app/services/cart.service';
import { Cart } from 'app/models/cart';
import { AddressService } from 'app/services/address.service';
import { AddressState } from 'app/models/address_state';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user';

@Component({
  selector: 'address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  private _address: Address = null;
  private _cart: Cart;
  private _sxUser: User;
  private _hasSxUser: boolean;

  public addressForm: UntypedFormGroup;
  public sameShippingAndBillingAddress: boolean;
  public addressClass: string = "col-sm-6";

  @Input() sameShipping: boolean;
  @Input() edit: Boolean;
  @Input() title: string;
  @Input() formButtonText: string;
  @Input() addressType: string;
  @Input() editButton: Boolean;
  @Input() user: User;
  @Input() initWithSameShipping: boolean = false;
  @Input() addressId: string = null;
  @Input() displayFullWidth: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private cartService: CartService,
    private addressService: AddressService,
    private userService: UserService) {
    this._cart = this.cartService.loadCart();
    this._sxUser = this.userService.sxUser;
    this._hasSxUser = this._sxUser != null;


  }

  ngOnInit() {
    if (this.displayFullWidth) {
      this.addressClass = "col-sm-12";
    }

    if (this.addressForm == null) {
      this.fillFormGroup();
    }

    this.addressService.addressState.subscribe((state: AddressState) => {
      if (this.addressId !== null) {
        if (state.addressType != this.addressType && !state.sameShippingAndBillingAddress) {
          this.edit = true;
        }  
      }
    });

    this.userService.currentSxUser.subscribe(async (currentSxUserState: User) => {
      this._sxUser = currentSxUserState;
      this._hasSxUser = this._sxUser !== null && this._sxUser !== undefined;

      if (this._address == null && this.user != null && this.addressId === null) {
        this._address = await this.addressService.getAddressForUser(this.user.id, this.addressType);
          if (this._address  != null) {
            this._address = this._address ;
            this.fillAddressForm();
          }

          this.edit = this._address == null && this.addressType == 'shipping';

          if (this.edit) {
            this.sameShippingAndBillingAddress = true;
            this.onChangeShipping(true);
          }          
      }

      if (this._address == null && this.user == null && this.addressId == null) {
        this._address = await this.addressService.getAddress(this.addressType);
          if (this._address != null) {
            this._address = this._address ;
            this.fillAddressForm();
          }

          this.edit = this._address == null && this.addressType == 'shipping';

          if (this.edit) {
            this.sameShippingAndBillingAddress = true;
            this.onChangeShipping(true);
          }
      }

      if (this._address == null && this._hasSxUser) {
        if (this.addressId !== null) {
          this._address = await this.addressService.getAddressById(this.addressId);
          this.fillAddressForm();
        }
        else {
          this._address = await this.addressService.getAddress(this.addressType);
          this.fillAddressForm();
        }                
      }
  
       this.addressService.sameBilling.subscribe((addressState: AddressState) => {
        if (this.sameShippingAndBillingAddress != addressState.sameShippingAndBillingAddress) {
          this.onChangeShipping(addressState.sameShippingAndBillingAddress);
        }
       })

    })



  }

  onChangeShipping(boolVal: boolean) {
    this.sameShippingAndBillingAddress = boolVal;
    this._cart.sameShippingAndBillingAddress = boolVal;
    this.cartService.updateCart(this._cart);

    this.addressService.changeSameOrDifferentBillingAddress({ sameShippingAndBillingAddress: boolVal, address: this._address } as AddressState);

    if (this.addressType == "shipping") {
      this.addressService.updateSameShippingAndBillingAddress(this.sameShippingAndBillingAddress, this._address, this.addressType);
    }

  }

  editAddress(boolVal: boolean) {
    this.edit = boolVal;
  }

  onSubmitAddressForm(addressForm: UntypedFormGroup) {
    if (addressForm.valid) {
      let theNewAddress: Address = Address.construct(addressForm.value);

      addressForm.value.address_type = this.addressType;
      this.addressForm.value.firstname = addressForm.value.firstname;
      this.addressForm.value.lastname = addressForm.value.lastname;
      this.addressForm.value.street = addressForm.value.street;
      this.addressForm.value.housenumber = addressForm.value.housenumber;
      this.addressForm.value.housenumber_addition = addressForm.value.housenumber_addition;
      this.addressForm.value.zipcode = addressForm.value.zipcode;
      this.addressForm.value.city = addressForm.value.city;
      this.addressForm.value.country = addressForm.value.country;

      if (this.user != null) {
        this.addressService.getAddressForUser(this.user.id, this.addressType).then(address => {
          if (address == null) {
            this.addressService.addAddressForUser(addressForm, this.user.id).then(() => {
              this.edit = false;
            });
          }
          else {
            theNewAddress.id = address.id;
            this.addressService.updateAddress(theNewAddress);
            this.edit = false;
          }
        });
      }
      else {
        this.addressService.addOrderAddress(addressForm).then(address => {
          this.edit = false;
        });
      }
    }
  }

  private async fillAddressForm() {
    let address: Address = Address.construct(this._address);

    if (address == null) {
      this.edit = true;
      return;
    }

    if (address.hasValues) {
      this.edit = false;
    }

    this.addressForm = this.formBuilder.group({
      firstname: address.firstname,
      lastname: address.lastname,
      address_type: this.addressType,
      street: address.street,
      housenumber: address.housenumber,
      housenumber_addition: address.housenumber_addition,
      zipcode: address.zipcode,
      city: address.city,
      country: address.country,
      sameShippingAndBillingAddress: this.initWithSameShipping ? true : this._cart.sameShippingAndBillingAddress
    });
    if (!this.initWithSameShipping) {
      this.onChangeShipping(this._cart.sameShippingAndBillingAddress);
    }
  }

  private fillFormGroup(): void {
    let formUser: User = this.user != null ? this.user : this._sxUser;
    let hasFormUser: boolean = formUser != null;
    let sameAddress: boolean = hasFormUser && this.sameShipping ? true : this.sameShippingAndBillingAddress;

    this.addressForm = this.formBuilder.group({
      firstname: hasFormUser ? formUser.firstname : '',
      lastname: hasFormUser ? formUser.lastname : '',
      address_type: this.addressType,
      street: '',
      housenumber: '',
      housenumber_addition: '',
      zipcode: '',
      city: '',
      country: 'NL',
      sameShippingAndBillingAddress: sameAddress
    });
  }


}
