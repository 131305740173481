import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { EmailInfo } from 'app/models/sendinblue/email_info';
import { MailServiceBase } from 'app/models/sendinblue/mail_service_base';
import { MailDiscountCode } from 'app/models/sendinblue/referral/mail_discount_code';
import { MailPromotorDiscountCode } from 'app/models/sendinblue/referral/mail_promotor_discount_code';
import { MailReferralInvitationToProspect } from 'app/models/sendinblue/referral/mail_referral_invitation_to_prospect';
import { SendinblueMailBase } from 'app/models/sendinblue/sendinblue_mail_base';
import { User } from 'app/models/user';

@Injectable({
  providedIn: 'root'
})
export class ReferralMailerService extends MailServiceBase {

  constructor(@Inject(APP_CONFIG) config: AppConfig, 
    httpClient: HttpClient) { 
    super(config, httpClient);
  }

  public sendReferralInvitationEmailToProspect(shopUser: User, promotorUser: User, invitation: ReferralInvitation) { 
    let mailInvitationToUser: MailReferralInvitationToProspect = new MailReferralInvitationToProspect(shopUser, promotorUser, invitation);

    let email:SendinblueMailBase = new SendinblueMailBase();
    email.params = mailInvitationToUser;
    email.to.length = 0;
    email.to.push(new EmailInfo(invitation.prospect_first_name + ' ' + invitation.prospect_last_name, invitation.prospect_email));
    email.templateId = this.config.sendinblueReferralInvitationToProspectTemplateId;
    email.promotorUser = promotorUser;

    this.sendEmailViaSendInBlue(email);
  }

  public sendDiscountCodeEmail(shopUser: User, promotorUser: User, invitation: ReferralInvitation) {
    let mailDiscountCode: MailDiscountCode = new MailDiscountCode(shopUser, promotorUser, invitation);

    let email:SendinblueMailBase = new SendinblueMailBase();
    email.params = mailDiscountCode;
    email.to.length = 0;
    email.to.push(new EmailInfo(invitation.prospectFullName, invitation.prospect_email));
    email.templateId = this.config.sendinblueReferralDiscountCodeToProspectTemplateId;
    email.promotorUser = promotorUser;

    this.sendEmailViaSendInBlue(email);
  }

  public sendDiscountCodeToPromotorEmail(promotorUser: User, shopUser: User, invitation: ReferralInvitation) {
    
    let mailDiscountCode: MailPromotorDiscountCode = new MailPromotorDiscountCode(promotorUser, shopUser, invitation);

    let email:SendinblueMailBase = new SendinblueMailBase();
    email.params = mailDiscountCode;
    email.to.length = 0;
    email.to.push(new EmailInfo(invitation.promotor_name, invitation.promotor_email));
    email.templateId = this.config.sendinblueReferralDiscountCodeToPromotorTemplateId;
    email.promotorUser = promotorUser;
    
    this.sendEmailViaSendInBlue(email);
  }
}
