import { Injectable } from '@angular/core';
import { CollectionReference, Firestore, Timestamp, addDoc, collection, doc, getDocs, limit, query, setDoc, where } from '@angular/fire/firestore';
import { CartItem } from 'app/models/cartitem';
import { converter } from 'app/models/converter';
import { Product } from 'app/models/product';
import { ProductSubscription } from 'app/models/product_subscription';
import { User } from 'app/models/user';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductSubscriptionService {
  private productSubscriptions: CollectionReference<ProductSubscription>;
  private subject = new Subject<CartItem>();
  public productSubscription$: Observable<CartItem> = this.subject.asObservable();

  constructor(private db: Firestore) { 
    this.productSubscriptions = collection(db, 'product_subscriptions').withConverter(converter<ProductSubscription>())
  }

  changeSubscriptionFrequency(cartItem: CartItem) {
    this.subject.next(cartItem);
  }

  async getProductSubscription(product: Product, sxUser: User): Promise<ProductSubscription> {
    const q = query(this.productSubscriptions,
      where('productId', '==', product.id),
      where('userId', '==', sxUser.id),
      where('shopUserId', '==', product.user_id),
      limit(1));

    return getDocs(q).then(snap => {
      if (snap.empty) return undefined;
      const subscription = ProductSubscription.constructWithId(snap.docs[0].id, snap.docs[0].data());
      return subscription;    
    });
  }
  
  async addNewProductSubscription(product: Product, sxUser: User, cartItem: CartItem): Promise<ProductSubscription> {
    const data = {
      productId: product.id,
      userId: sxUser.id,
      shopUserId: product.user_id,
      subscriptionDate: Timestamp.fromDate(new Date()),
      subscriptionPrice: product.subscriptionPrice,
      sendDates: new Array<Timestamp>(),
      frequency: parseInt(cartItem.productSubscription.frequency),
      numberOfTimesSent: 1  
    };

    data.sendDates.push(data.subscriptionDate);

    const productSubscription = await addDoc(this.productSubscriptions, data as ProductSubscription).then(ref => {
      return ProductSubscription.constructWithId(ref.id, data)
    });

    return productSubscription;
  }

  async updateProductSubscription(productSubscription: ProductSubscription): Promise<void> {
    const ref = doc(this.productSubscriptions, productSubscription.id)    

    return setDoc(ref, productSubscription)
  }

}
