<div class="container">
    <div class="row back-button pb-0">
        <div class="fas fa-arrow-left arrow-back"></div>
        <a [routerLink]="['/', userSlug, 'products']" *ngIf="userSlug !== undefined">Terug naar productoverzicht</a>
      </div>    
    <div class="pt-2 w-100">
      <div *ngIf="category">
        <h2>{{category.title}}</h2>
        <hr />
      </div>
    </div>
    <div class="pb-2 user-profile" *ngIf="user">
      <p class="card-text">Ontmoet je verkoper</p>
      <div class="container-fluid">
        <div class="col-md-9 w-auto">
          <div class="row w-auto">
            <img src="{{user.photo}}" class="responsive profile-photo" />
            <div class="col">
              <a class="nav-link d-inline-block shop-owner-information-link" data-html="true" data-placement="right" data-popover-content="#shopOwnerInformation"
                data-toggle="popover" data-trigger="focus" tabindex="0">
                <h5 class="card-title w-auto" title="Klik hier voor meer informatie">{{user.firstname}}</h5>
              </a>
              <div class="hidden d-none popover-container" id="shopOwnerInformation">
                <div class="popover-body">
                  <ul class="nav navbar-nav">
                    <li>{{user.shopTitle}}</li>
                    <li>{{user.shopDescription}}</li>
                    <li>{{user.phoneNumber}}</li>
                    <li><a href="mailto:{{user.email}}">{{user.email}}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
      <div class="col pl-0">
        <p class="card-text">Eigenaar van {{user.shopTitle}}</p>
      </div>
    </div>
    <div class="row" *ngIf="products">
      <div class="col-md-3" *ngFor="let product of products">
        <a [routerLink]="productRouterLink(product)">
          <div class="card">
            <img class="card-img-top" src="{{product.photo_url}}" alt="{{product.title}}" title="{{product.title}}">
            <div class="card-body">
              <h5 class="card-title">{{product.title}}</h5>
              <div class="row" *ngIf="!product.private">
                <div class="col-6">
                  <p class="lead"
                    *ngIf="canOrderProduct(product) && !product.private || product.private && !product.user?.privateShop && userService.sxUser != null || product.private && product.user?.privateShop && userService.sxUser != null && product.user?.isPrivateGroupMember(userService.sxUser.id)">
                    <strong>€ {{product.price}}</strong></p>
                  <p class="lead"
                    *ngIf="canOrderProduct(product) && userService.sxUser != null && product.priceFrom != null && product.priceFrom != '' && product.priceFrom != product.price && (!product.private || product.private && !product.user?.privateShop && userService.sxUser != null || product.private && product.user?.privateShop && userService.sxUser != null && product.user?.isPrivateGroupMember(userService.sxUser.id))">
                    <del>€ {{product.priceFrom}}</del></p>
                </div>
                <div class="product-buy"
                  *ngIf="!userService.userHasProducts && canOrderProduct(product) && hasStock(product)">
                  <button type="button" class="btn btn-success" (click)="addToCart(product)"><span
                      class="fas fa-cart-plus white-text"></span></button>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
            <pagination 
            [currentPage]="paginationService.paginationSubject.value.currentPage"
            [hasMore]="paginationService.paginationSubject.value.hasMore"></pagination>      
    </div>
  </div>