import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { MailOrder } from 'app/models/sendinblue/mail_order';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmailInfo } from 'app/models/sendinblue/email_info';
import { User } from 'app/models/user';
import { MailInvitationToUser } from 'app/models/sendinblue/private_group/mail_invitation_to_user';
import { SendinblueMailBase } from 'app/models/sendinblue/sendinblue_mail_base';
import { MailNewsletter } from 'app/models/sendinblue/newsletter/mail_newsletter';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { MailReferralInvitationToProspect } from 'app/models/sendinblue/referral/mail_referral_invitation_to_prospect';
import { MailServiceBase } from 'app/models/sendinblue/mail_service_base';
import { NewsletterCreator } from 'app/models/newsletter-creator';
import { MailNewsletterSetsOfTwo } from 'app/models/sendinblue/newsletter/mail_newsletter_sets_of_two';

@Injectable({
  providedIn: 'root'
})
export class OrdermailerService extends MailServiceBase {
  constructor(@Inject(APP_CONFIG) config: AppConfig, 
    httpClient: HttpClient) { 
    super(config, httpClient);
  }

  public sendOrderPickupConfirmationToUser(mailOrder: MailOrder, user: User) {
    mailOrder.to.length = 0;

    mailOrder.templateId = this.config.sendinblueOrderPickupConfirmationTemplateId;
    mailOrder.to.push(new EmailInfo(user.displayName, user.email));        

    this.sendEmailViaSendInBlue(mailOrder);
  }

  public sendOrderConfirmationToUser(mailOrder: MailOrder, user: User) {
    mailOrder.to.length = 0;

    mailOrder.templateId = this.config.sendinblueOrderConfirmationTemplateId;
    mailOrder.to.push(new EmailInfo(user.displayName, user.email));        

    this.sendEmailViaSendInBlue(mailOrder);
  }

  public sendNewCustomerPasswordEmailToUser(mailOrder: MailOrder, user: User) {
    mailOrder.to.length = 0;

    mailOrder.templateId = this.config.sendinblueNewUserPasswordTemplateId;
    mailOrder.to.push(new EmailInfo(user.displayName, user.email));        

    this.sendEmailViaSendInBlue(mailOrder);
  }

  public sendShopOwnerOrderPickupConfirmation(mailOrder: MailOrder, shopUser: User)
  {
    mailOrder.to.length = 0;    
    mailOrder.to.push(new EmailInfo(shopUser.displayName, shopUser.email));        
    mailOrder.templateId = this.config.sendinblueShopOwnerOrderPickupConfirmationTemplateId;

    this.sendEmailViaSendInBlue(mailOrder);
  }

  public sendShopOwnerOrderConfirmation(mailOrder: MailOrder, shopUser: User)
  {
    mailOrder.to.length = 0;    
    mailOrder.to.push(new EmailInfo(shopUser.displayName, shopUser.email));        
    mailOrder.templateId = this.config.sendinblueShopOwnerOrderConfirmationTemplateId;

    this.sendEmailViaSendInBlue(mailOrder);
  }

  public sendPrivateGroupLinkInvitationLinkToUser(shopUser: User, user: User, inviteId: string) {
    let invitationLink = '/' + shopUser.slug + '/join/' + inviteId;
    let privateGroupLink = '/' + shopUser.slug + '/products';

    let mailInvitationToUser: MailInvitationToUser = new MailInvitationToUser(shopUser, user, invitationLink, privateGroupLink);

    let email:SendinblueMailBase = new SendinblueMailBase();
    email.shopUser = shopUser;
    email.params = mailInvitationToUser;
    email.to.length = 0;
    email.to.push(new EmailInfo(user.displayName, user.email));
    email.templateId = this.config.sendinbluePrivateGroupLinkInvitationLinkTemplateId;


    this.sendEmailViaSendInBlue(email);
  }

  public sendPrivateGroupInvitationAcceptedToShopUser(shopUser: User, user: User) {
    let privateGroupLink = '/' + shopUser.slug + '/products';

    let mailInvitationToUser: MailInvitationToUser = new MailInvitationToUser(shopUser, user, '', privateGroupLink);

    let email:SendinblueMailBase = new SendinblueMailBase();
    email.shopUser = shopUser;
    email.params = mailInvitationToUser;
    email.to.length = 0;
    email.to.push(new EmailInfo(shopUser.displayName, shopUser.email));
    email.templateId = this.config.sendinbluePrivateGroupLinkInvitationAcceptedTemplateId;

    this.sendEmailViaSendInBlue(email);
  }

  public sendNewsletterToCustomer(shopUser: User, user: User, newsletterCreator: NewsletterCreator = null) {
    let email:SendinblueMailBase = new SendinblueMailBase();
    email.shopUser = shopUser;
    email.to.length = 0;
    email.to.push(new EmailInfo(user.displayName, user.email));
    email.templateId = this.config.sendinblueNewsLetterTemplateId;

    if (newsletterCreator != null) {
      if (newsletterCreator.productLayout === 'naastelkaar') {
        email.templateId = this.config.sendinblueNewsLetterCreatorNaastElkaarTemplateId;
        email.params = new MailNewsletterSetsOfTwo(shopUser, user, newsletterCreator);
      }

      if (newsletterCreator.productLayout === 'onderelkaar') {
        email.templateId = this.config.sendinblueNewsLetterCreatorTemplateId;
        email.params = new MailNewsletter(shopUser, user, newsletterCreator);
      }
    }

    this.sendEmailViaSendInBlue(email);
  }

  public sendRepeatServiceInvitationToCustomer(shopUser: User, user: User) {
    let link = '/repeat-service-confirmation/' + shopUser.id + '/' + user.id;    
    let mailInvitationToUser: MailInvitationToUser = new MailInvitationToUser(shopUser, user, link, '');

    let email:SendinblueMailBase = new SendinblueMailBase();
    email.shopUser = shopUser;
    email.params = mailInvitationToUser;
    email.to.length = 0;
    email.to.push(new EmailInfo(user.displayName, user.email));
    email.templateId = this.config.sendinblueRepeatServiceInvitationCustomerTemplateId;

    this.sendEmailViaSendInBlue(email);
  }

  public sendRepeatServiceInvitationConfirmationToCustomer(shopUser: User, user: User) {
    let mailInvitationToUser: MailInvitationToUser = new MailInvitationToUser(shopUser, user, '', '');

    let email:SendinblueMailBase = new SendinblueMailBase();
    email.params = mailInvitationToUser;
    email.to.length = 0;
    email.to.push(new EmailInfo(user.displayName, user.email));
    email.templateId = this.config.sendinblueRepeatServiceInvitationCustomerConfirmationTemplateId;

    this.sendEmailViaSendInBlue(email);
  }

  public sendReferralInvitationEmailToProspect(shopUser: User, promotorUser: User, invitation: ReferralInvitation) { 
    let mailInvitationToUser: MailReferralInvitationToProspect = new MailReferralInvitationToProspect(shopUser, promotorUser, invitation);

    let email:SendinblueMailBase = new SendinblueMailBase();
    email.params = mailInvitationToUser;
    email.to.length = 0;
    email.to.push(new EmailInfo(invitation.prospect_first_name + ' ' + invitation.prospect_last_name, invitation.prospect_email));
    email.templateId = this.config.sendinblueReferralInvitationToProspectTemplateId;
    email.promotorUser = promotorUser;

    this.sendEmailViaSendInBlue(email);
  }

  public sendSubscribeAndSaveEmailToSubscriber(mailOrder: SendinblueMailBase, user: User) {
    mailOrder.to.length = 0;

    mailOrder.templateId = this.config.brevoSubscribeAndSaveOrderTemplateId;
    mailOrder.to.push(new EmailInfo(user.displayName, user.email));        

    this.sendEmailViaSendInBlue(mailOrder);
  }

  public sendSubscribeAndSaveEmailToShopOwner(mailOrder: SendinblueMailBase, shopUser: User) {
    mailOrder.to.length = 0;

    mailOrder.templateId = this.config.brevoSubscribeAndSaveOrderShopOwnerTemplateId;
    mailOrder.to.push(new EmailInfo(shopUser.displayName, shopUser.email));        

    this.sendEmailViaSendInBlue(mailOrder);
  }

}
