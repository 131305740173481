import { Serializeable } from './serializeable';

export class ImageSize extends Serializeable {
    static instanceType = ImageSize;
    height: number;
    width: number;
    sizeName: string;

    constructor(height: number, width: number, sizeName: string) {
        super();

        this.height = height;
        this.width = width;
        this.sizeName = sizeName;
    }
}