import { User } from 'app/models/user';
import { MailOrderParams } from './mail_order_params';
import { Address } from 'app/models/address';
import { OrderLine } from '../orderline';
import { SendinblueMailBase } from './sendinblue_mail_base';

export class MailOrder extends SendinblueMailBase {
    static instanceType = MailOrder;
   
    constructor(orderLines: OrderLine[], shopUser: User, shippingAddress: Address, billingAddress: Address, pickupAddress: Address, customerUser: User, discount: number) {
        super();
        this.shopUser = shopUser;
        this.params = new MailOrderParams(orderLines, shippingAddress, billingAddress, shopUser, pickupAddress, customerUser, discount);
    }
}
