<div class="card" *ngIf="hasProduct">
  <div class="card-body">
    <div class="row zero-bottom-padding">
      <div class="item col-sm-5">
        <div class="row zero-top-padding align-items-start">
          <div class="col" style="width: 100px; max-width: 100px;">
            <a [routerLink]="['/', userSlug, 'products', product.slug]">
              <img class="group list-group-image img-fluid" style="max-width: 50px; max-height: 50px;"
                src="{{product.photo_url}}" alt="{{product.title}}" title="{{product.title}}" />
            </a>
          </div>
          <div class="col">
            <a [routerLink]="['/', userSlug, 'products', product.slug]">{{product.title}}</a>
          </div>
        </div>
      </div>
      <div class="item col-sm-3 price-column">
        EUR {{product.price}}
      </div>
      <div class="item col-sm-1 quantity-column">
        <input type="number" min="0" class="quantity" value="{{product.stock || 0}}"
          (change)="productService.updateStock(product, $event.target.value)"
          *ngIf="userService.userHasProducts && userService.isProductCreator(product)" />
      </div>
    </div>
  </div>
</div>