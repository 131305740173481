import { Component, OnInit, Input } from '@angular/core';
import { Order } from 'app/models/order';
import { OrderLine } from 'app/models/orderline';

@Component({
  selector: 'order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {
  @Input() order: Order;
  
  constructor() { }

  ngOnInit() {
  }

  get orderline(): OrderLine {
    return this.order.orderlines[0] as OrderLine;
  }

  get orderDate(): Date {
    return this.order.order_date.toDate();
  }

}
