import { Injectable } from '@angular/core';
import { Firestore, collection, getDocs, query, where, limit } from '@angular/fire/firestore';
import { Address } from 'app/models/address';
import { Cart } from 'app/models/cart';
import { CartState } from 'app/models/cart-state';
import { User } from 'app/models/user';
import { readDocs } from 'app/utils/data-utils';
import { Observable, Subject } from 'rxjs';
import { ServiceBase } from './service-base';
import { converter } from 'app/models/converter';

@Injectable()
export class CustomerService extends ServiceBase {
  private customerSubject = new Subject<User>();
  public customerState: Observable<User> = this.customerSubject.asObservable();  

  private cartSubject = new Subject<CartState>();
  public cartState: Observable<CartState> = this.cartSubject.asObservable();

  private orderCreatorStepSubject = new Subject<string>();
  public orderCreatorStepState: Observable<string> = this.orderCreatorStepSubject.asObservable();

  constructor(public db: Firestore) { 
    super();
  }

  clearOrderCreatorData() {
    localStorage.removeItem('orderCreatorCart');
    localStorage.removeItem('orderCreatorAddress_billing');
    localStorage.removeItem('orderCreatorAddress_shipping');
    localStorage.removeItem('orderCreatorCustomer');
    localStorage.removeItem('orderCreatorStep');
  }

  saveOrderCreatorCart(updatedCart: Cart) {
    const cart: Cart = new Cart();
    cart.setCart(updatedCart);
    localStorage.setItem('orderCreatorCart', JSON.stringify(cart));
  }

  get orderCreatorCart(): Cart {
    let jsonCart = localStorage.getItem('orderCreatorCart');
    if (jsonCart == null || jsonCart === undefined) {
      this.saveOrderCreatorCart(new Cart());
      jsonCart = localStorage.getItem('orderCreatorCart');
    }

    const cartObject = JSON.parse(jsonCart);
    const cart: Cart = new Cart();
    cart.setCart(cartObject);

    // let cart: Cart = new Cart();
    // let parsedCart = JSON.parse(localStorage.getItem('orderCreatorCart')) as Cart || new Cart();

    // if (cart.cartItems.length > 0) {
    //   for(var i=0; i < cart.cartItems.length; i++) {
    //     cart.cartItems.push(cart.cartItems[i]);
    //   }
    // }

    cart.sameShippingAndBillingAddress = cartObject.sameShippingAndBillingAddress;
    cart.pickupAddressId = cartObject.pickupAddressId;

    return cart;
  }

  saveOrderCreatorAddress(address: Address, type: string) {
    localStorage.setItem('orderCreatorAddress_' + type, JSON.stringify(address));
  }

  getOrderCreatorAddress(type: string): Address {
    let parsedAddress = JSON.parse(localStorage.getItem('orderCreatorAddress_' + type)) as Address || new Address();
    return parsedAddress;
  }

  setOrderCreatorStep(step: string) {
    this.orderCreatorStepSubject.next(step);
    localStorage.setItem('orderCreatorStep', step);
  }

  setOrderCreatorCustomer(user: User) {
    localStorage.setItem('orderCreatorCustomer', JSON.stringify(user));
  }

  get orderCreatorStep(): string {
    return localStorage.getItem('orderCreatorStep');
  }

  get orderCreatorCustomer(): User {
    return JSON.parse(localStorage.getItem('orderCreatorCustomer'));
  }

  setCustomer(customer: User) {
    this.customerSubject.next(customer);
  }

  updateCart(cart: Cart) {
    this.saveOrderCreatorCart(cart);
    let updatedCart: Cart = this.orderCreatorCart;

    this.updateCartState({
      loaded: true,
      items: updatedCart.cartItems,
      numberOfItems: updatedCart.count,
      totalPrice: updatedCart.totalPrice,
      discount: updatedCart.discount,
      discountCode: updatedCart.discountCode,
      discountDate: updatedCart.discountDate,
      discountMinimumOrderPrice: updatedCart.discountMinimumOrderPrice,
      discountUserId: updatedCart.discountUserId,
      isPromotorDiscount: updatedCart.isPromotorDiscount,
      isProspectDiscount: updatedCart.isProspectDiscount
    } as CartState);
  }

  updateCartState(state: CartState) {
    this.cartSubject.next(state);  
  }

  async getCustomersForShopUser(shop_user_id: string): Promise<User[]> {
    const users = collection(this.db, 'users').withConverter(converter<User>())
    const q = query(users, where('shop_user_id', '==', shop_user_id))

    return getDocs(q).then(readDocs)
  }

}
