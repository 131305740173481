import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'app/models/user';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'profile-shop-settings',
  templateUrl: './profile-shop-settings.component.html',
  styleUrls: ['./profile-shop-settings.component.scss']
})
export class ProfileShopSettingsComponent implements OnInit {
  public userForm: UntypedFormGroup;
  private _sxUser: User;  

  constructor(public userService: UserService, 
              private formBuilder: UntypedFormBuilder) { 
    this._sxUser = this.userService.sxUser;    
    this.fillUserDataForm();
  }

  ngOnInit() {
  }

  public onSubmitUserForm(userForm: UntypedFormGroup) {
 
    if (userForm.valid) {   
      this._sxUser.shopTitle = userForm.value.shopTitle;
      this._sxUser.shopDescription = userForm.value.shopDescription;
      this._sxUser.shippingMessage = userForm.value.shippingMessage;
      this._sxUser.paymentDetailsMessage = userForm.value.paymentDetailsMessage;
      this._sxUser.shippingDetailsMessage = userForm.value.shippingDetailsMessage;
      this._sxUser.orderContactDetails = userForm.value.orderContactDetails;
      this._sxUser.pickupOrderPaymentDetailsMessage = userForm.value.pickupOrderPaymentDetailsMessage;
      this._sxUser.pickupOrderShippingDetailsMessage = userForm.value.pickupOrderShippingDetailsMessage;

      this.userService.updateSxUser(this._sxUser);
    }
  }

  private fillUserDataForm(): void {
    this.userForm = this.formBuilder.group({
      shopTitle: this._sxUser.shopTitle,
      shopDescription: this._sxUser.shopDescription,
      shippingMessage: this._sxUser.shippingMessage,
      paymentDetailsMessage: this._sxUser.paymentDetailsMessage,
      shippingDetailsMessage: this._sxUser.shippingDetailsMessage,
      orderContactDetails: this._sxUser.orderContactDetails,
      pickupOrderPaymentDetailsMessage: this._sxUser.pickupOrderPaymentDetailsMessage,
      pickupOrderShippingDetailsMessage: this._sxUser.pickupOrderShippingDetailsMessage
    });
  }

}
