<div class="container">
    <div class="row back-button pb-0">
      <div class="fas fa-arrow-left arrow-back"></div>
      <a [routerLink]="['/', 'customer-order-creator']" *ngIf="userSlug !== undefined">Terug naar Klantorder</a>
    </div>
  
    <div class="row">
      <div class="col pl-0">
        <p class="h2">Beheer klanten</p>
      </div>      
    </div>
    <div id="customers" class="container" *ngIf="customers">
      <div class="list">
        <div class="row">
          <div class="item col">
            <h4>Klant</h4>
          </div>
          <div class="item col-6">
            <h4>Email</h4>
          </div>
          <div class="item col">
  
          </div>
        </div>
        <div class="item col" *ngFor="let customer of customers">
          <div class="row">
            <div class="item col pl-0">
                {{customer.displayName}}
            </div>
            <div class="item col-6">
              {{customer.email}}
            </div>
            <div class="item col">
              <div class="">
                <a (click)="deleteCustomer(customer)" href="javascript:void(0)">Verwijder</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="privateGroupMembers" class="container" *ngIf="privateGroupMembers">
        <div class="list">
          <div class="row">
            <div class="item col">
              <h4>Privégroep lid</h4>
            </div>
            <div class="item col-6">
              <h4>Email</h4>
            </div>
            <div class="item col">
    
            </div>
          </div>
          <div class="item col" *ngFor="let privateGroupMember of privateGroupMembers">
            <div class="row">
              <div class="item col pl-0">
                  {{privateGroupMember.displayName}}
              </div>
              <div class="item col-6">
                {{privateGroupMember.email}}
              </div>
              <div class="item col">
                <div class="">
                  <a (click)="deletePrivateGroupMember(customer)" href="javascript:void(0)">Verwijder</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  </div>