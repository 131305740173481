import { Inject, Injectable } from '@angular/core';
import { ServiceBase } from './service-base';
import { CollectionReference, Firestore, collection, doc, addDoc, setDoc, query, where, orderBy, getDocs, limit, getDoc } from '@angular/fire/firestore';
import { Timestamp } from 'firebase/firestore'
import { UserService } from './user.service';
import { Cart } from 'app/models/cart';
import { CartService } from './cart.service';
import { Order } from 'app/models/order';
import { OrderLine } from 'app/models/orderline';
import { Subject, Observable, forkJoin } from 'rxjs';
import { MailOrder } from 'app/models/sendinblue/mail_order';
import { User } from 'app/models/user';
import { Address } from 'app/models/address';
import { OrdermailerService } from './ordermailer.service';
import { AddressService } from './address.service';
import { ProductService } from './product.service';
import { ReferralService } from './referral.service';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { AppConfig, APP_CONFIG } from '../app-config.module';
import { DiscountCodeService } from './discount-code.service';
import { ReferralMailerService } from './referral-mailer.service';
import { converter } from 'app/models/converter';
import { readDocs } from 'app/utils/data-utils';
import { ProductSubscriptionService } from './product-subscription.service';
import { ProductSubscription } from 'app/models/product_subscription';
import { MailOrderSubscription } from 'app/models/sendinblue/mail_order_subscription';
import { DatePipe } from '@angular/common';
import { promise } from 'protractor';

@Injectable()
export class OrderService extends ServiceBase {
  private orderSubject = new Subject<Order>();
  public orderState: Observable<Order> = this.orderSubject.asObservable();
  private orders: CollectionReference<Order>

  constructor(private db: Firestore,
    private userService: UserService,
    private cartService: CartService,
    private addressService: AddressService,
    private ordermailerService: OrdermailerService,
    private referralService: ReferralService,
    private productService: ProductService,
    private discountCodeService: DiscountCodeService,
    private referralMailerService: ReferralMailerService,
    private productSubscriptionService: ProductSubscriptionService,
    private datePipe: DatePipe,
    @Inject(APP_CONFIG) private config: AppConfig) {
    super();

    this.orders = collection(this.db, 'orders').withConverter(converter<Order>())
  }

  async createOrder(cart: Cart, user: User, shippingAddress: Address, billingAddress: Address, shopUserId: string) {
    if (cart.hasDiscountCode) {
      await this.handleDiscount(cart.discountCode, user);
    }

    const productSubscriptions = await this.checkOrCreateProductSubscriptions(cart, user);

    if (cart.hasPickupAddress) {
      this.addressService.getAddressById(cart.pickupAddressId).then(async pickupAddress => {
        this.pickupOrder(cart, user, pickupAddress, shopUserId, productSubscriptions);
      })
    }
    else {
      this.sendOrder(cart, user, shippingAddress, billingAddress, shopUserId, productSubscriptions);
    }    
    
  }

async checkOrCreateProductSubscriptions(cart: Cart, user: User): Promise<ProductSubscription[]> {
      const promises = new Array<Promise<ProductSubscription>>();
      const subscriptions = new Array<ProductSubscription>();

      let productSubscriptions = await this.getProductSubscriptions(cart, user);

      cart.cartItems.forEach(cartItem => {
        if (cartItem.productSubscription) {
          let subscription = productSubscriptions ? productSubscriptions.find(x => x.productId == cartItem.product.id && x.userId == user.id) : undefined;

          if (subscription === undefined) {
            promises.push(this.productSubscriptionService.addNewProductSubscription(cartItem.product, user, cartItem));            
          }
          else {
            subscriptions.push(subscription);
          }
        }
      })

      return Promise.all(promises).then(promised => {
        promised.forEach(productSubscription => {
          if (productSubscription !== undefined) {
            subscriptions.push(productSubscription);
          }
        })
        return subscriptions;
      })
  }

  async getProductSubscriptions(cart: Cart, user: User): Promise<ProductSubscription[]> {
    const promises = new Array<Promise<ProductSubscription>>();
    const subscriptions = new Array<ProductSubscription>();

    cart.cartItems.forEach(cartItem => {
      if (cartItem.productSubscription) {
        let subscription = this.productSubscriptionService.getProductSubscription(cartItem.product, user);
        promises.push(subscription);
      }
    })

    return Promise.all(promises).then(promised => {
      promised.forEach(subscription => {
        if (subscription !== undefined) {
          subscriptions.push(subscription);
        }
      })
      return subscriptions;
    })
  }

  pickupOrder(cart: Cart, user: User, pickupAddress: Address, shopUserId: string, productSubscriptions: ProductSubscription[]) {
    const data = {
      user_id: user.id,
      shop_user_id: shopUserId,
      email: user.email,
      order_date: new Date(),
      status: 'sent',
      total_price: cart.totalPrice - cart.totalDiscount,
      currency: 'EUR'
    }

    const ref = doc(this.orders);
    const order: Order = Order.constructWithId(ref.id, data);

    let sequence = 1;

    let orderLinesPerShopOwner = [];
    let productSubscriptionsPerShopOwner = [];
    let updatedProductSubscriptions = [];

    cart.cartItems.forEach(cartItem => {
      let shopOwner: string = cartItem.product.user_id;
      let productStock: number = cartItem.product.stock || 0;

      if (productStock - cartItem.quantity < 0) {
        productStock = 0;
      }
      else {
        productStock = productStock - cartItem.quantity;
      }

      cartItem.product.stock = productStock;
      this.productService.updateProduct(cartItem.product); // update stock

      if (orderLinesPerShopOwner[shopOwner] === undefined) {
        orderLinesPerShopOwner[shopOwner] = new Array();
      }

      //check product subscription
      let productSubscription: ProductSubscription = productSubscriptions.find(x => x.productId == cartItem.product.id && x.userId == user.id);

      const productPrice = cartItem.productSubscription ? cartItem.product.subscriptionPrice : cartItem.product.price;

      let orderLine = {
        order_id: order.id,
        product_id: cartItem.product.id,
        product_title: cartItem.product.title,
        product_photo: cartItem.product.photo_url,
        price: Number.parseFloat(productPrice),
        quantity: cartItem.quantity,
        total_price: cartItem.quantity * Number.parseFloat(productPrice),
        sequence: sequence,
        productSubscriptionId: ''
      };

      if (!(productSubscription === undefined)) {
        orderLine.productSubscriptionId = productSubscription.id;
      }

      if (cartItem.productSubscription && productSubscription && parseInt(cartItem.productSubscription.frequency) != productSubscription.frequency) {
        productSubscription.frequency = parseInt(cartItem.productSubscription.frequency);
        updatedProductSubscriptions.push(productSubscription);
      }

      const theOrderLine: Object = new Object();
      Object.assign(theOrderLine, orderLine);
      order.orderlines.push(theOrderLine);

      orderLinesPerShopOwner[shopOwner].push(orderLine as OrderLine);

      const hasProductSubscription: boolean = !(cartItem.productSubscription === undefined) && !(productSubscription === undefined);

      if (hasProductSubscription) {
        if (productSubscriptionsPerShopOwner[shopOwner] === undefined) {
          productSubscriptionsPerShopOwner[shopOwner] = new Array();
        }

        productSubscriptionsPerShopOwner[shopOwner].push(productSubscription);
      }

      sequence++;
    });

    let thePickupAddress: Object = new Object();
    Object.assign(thePickupAddress, pickupAddress);

    order.addresses.push(thePickupAddress);
    order.pickup_address_id = pickupAddress.id;

    const reference = doc(this.orders, order.id).withConverter(converter<Order>());
    setDoc(reference, order);

    for (var shopOwnerUserId in orderLinesPerShopOwner) {
      this.userService.getUserById(shopOwnerUserId).then(shopUser => {
        // Send order confirmation email via Brevo

        let mailOrder: MailOrder = new MailOrder(orderLinesPerShopOwner[shopUser.id], shopUser, null, null, pickupAddress, user, cart.totalDiscount);
        mailOrder.params.user_email = user.email;

        this.ordermailerService.sendOrderPickupConfirmationToUser(mailOrder, user);

        let mailOrderShopOwner: MailOrder = new MailOrder(orderLinesPerShopOwner[shopUser.id], shopUser, null, null, pickupAddress, user, cart.totalDiscount);

        this.ordermailerService.sendShopOwnerOrderPickupConfirmation(mailOrderShopOwner, shopUser);

        // send product subscription email via Brevo
        if (shopUser.id in productSubscriptionsPerShopOwner) {
          let mailOrderSubscription: MailOrderSubscription = new MailOrderSubscription(orderLinesPerShopOwner[shopUser.id], shopUser, null, null, pickupAddress, user, cart.totalDiscount, productSubscriptionsPerShopOwner[shopUser.id], this.datePipe);
          mailOrderSubscription.params.user_email = user.email; 

          this.ordermailerService.sendSubscribeAndSaveEmailToSubscriber(mailOrderSubscription, user);
          this.ordermailerService.sendSubscribeAndSaveEmailToShopOwner(mailOrderSubscription, shopUser);         
        }
        
      });
    }

    updatedProductSubscriptions.forEach(productSubscription => {
       this.productSubscriptionService.updateProductSubscription(productSubscription);
    });

    localStorage.removeItem('order');
    localStorage.removeItem('cart');

    localStorage.setItem('order', JSON.stringify(order));
    this.cartService.loadCart();
    this.orderSubject.next(order);
  }

  sendOrder(cart: Cart, user: User, shippingAddress: Address, billingAddress: Address, shopUserId: string, productSubscriptions: ProductSubscription[]) {
    const data = {
      user_id: user.id,
      shop_user_id: shopUserId,
      email: user.email,
      order_date: new Date(),
      status: 'sent',
      total_price: cart.totalPrice - cart.totalDiscount,
      currency: 'EUR'
    }

    const ref = doc(this.orders);
    const order: Order = Order.constructWithId(ref.id, data);

    let sequence = 1;

    let orderLinesPerShopOwner = [];
    let productSubscriptionsPerShopOwner = [];
    let updatedProductSubscriptions = [];

    cart.cartItems.forEach(cartItem => {
      let shopOwner: string = cartItem.product.user_id;
      let productStock: number = cartItem.product.stock || 0;

      if (productStock - cartItem.quantity < 0) {
        productStock = 0;
      }
      else {
        productStock = productStock - cartItem.quantity;
      }

      cartItem.product.stock = productStock;
      this.productService.updateProduct(cartItem.product); // update stock

      if (orderLinesPerShopOwner[shopOwner] === undefined) {
        orderLinesPerShopOwner[shopOwner] = new Array();
      }

      //check product subscription
      let productSubscription: ProductSubscription = productSubscriptions.find(x => x.productId == cartItem.product.id && x.userId == user.id);

      const productPrice = cartItem.productSubscription ? cartItem.product.subscriptionPrice : cartItem.product.price;

      let orderLine = {
        order_id: order.id,
        product_id: cartItem.product.id,
        product_title: cartItem.product.title,
        product_photo: cartItem.product.photo_url,
        price: Number.parseFloat(productPrice),
        quantity: cartItem.quantity,
        total_price: cartItem.quantity * Number.parseFloat(productPrice),
        sequence: sequence,
        productSubscriptionId: ''
      };

      if (!(productSubscription === undefined)) {
        orderLine.productSubscriptionId = productSubscription.id;
      }

      if (cartItem.productSubscription && productSubscription && parseInt(cartItem.productSubscription.frequency) != productSubscription.frequency) {
        productSubscription.frequency = parseInt(cartItem.productSubscription.frequency);
        updatedProductSubscriptions.push(productSubscription);
      }

      const theOrderLine: Object = new Object();
      const convertedOrderLine: OrderLine = orderLine as OrderLine;
      Object.assign(theOrderLine, convertedOrderLine);
      order.orderlines.push(theOrderLine);

      orderLinesPerShopOwner[shopOwner].push(convertedOrderLine);

      const hasProductSubscription: boolean = !(cartItem.productSubscription === undefined) && !(productSubscription === undefined);

      if (hasProductSubscription) {
        if (productSubscriptionsPerShopOwner[shopOwner] === undefined) {
          productSubscriptionsPerShopOwner[shopOwner] = new Array();
        }

        productSubscriptionsPerShopOwner[shopOwner].push(productSubscription);
      }

      sequence++;
    });

    let theShippingAddress: Object = new Object();
    Object.assign(theShippingAddress, shippingAddress);

    let theBillingAddress: Object = new Object();

    order.addresses.push(theShippingAddress);

    order.shipping_address_id = shippingAddress.id;
    order.billing_address_id = billingAddress?.id;

    if (order.billing_address_id == null || order.billing_address_id === undefined) {
      order.billing_address_id = order.shipping_address_id;
    }

    if (order.billing_address_id != order.shipping_address_id) {
      Object.assign(theBillingAddress, billingAddress);
      order.addresses.push(theBillingAddress);
    }
    else {
      billingAddress = shippingAddress;
      Object.assign(theBillingAddress, billingAddress);
    }

    const reference = doc(this.orders, order.id).withConverter(converter<Order>());
    setDoc(reference, order);

    for (var shopOwnerUserId in orderLinesPerShopOwner) {
      this.userService.getUserById(shopOwnerUserId).then(shopUser => {
        // Send order confirmation email via Brevo

        let mailOrder: MailOrder = new MailOrder(orderLinesPerShopOwner[shopUser.id], shopUser, shippingAddress, billingAddress, null, user, cart.totalDiscount);
        mailOrder.params.user_email = user.email;
        mailOrder.params.discountCode = cart.hasDiscountCode ? cart.discountCode : '';

        this.ordermailerService.sendOrderConfirmationToUser(mailOrder, user);

        let mailOrderShopOwner: MailOrder = new MailOrder(orderLinesPerShopOwner[shopUser.id], shopUser, shippingAddress, billingAddress, null, user, cart.totalDiscount);

        this.ordermailerService.sendShopOwnerOrderConfirmation(mailOrderShopOwner, shopUser);

        // send product subscription email via Brevo
        if (shopUser.id in productSubscriptionsPerShopOwner) {
          let mailOrderSubscription: MailOrderSubscription = new MailOrderSubscription(orderLinesPerShopOwner[shopUser.id], shopUser, shippingAddress, billingAddress, null, user, cart.totalDiscount, productSubscriptionsPerShopOwner[shopUser.id], this.datePipe);
          mailOrderSubscription.params.user_email = user.email; 

          this.ordermailerService.sendSubscribeAndSaveEmailToSubscriber(mailOrderSubscription, user); 
          this.ordermailerService.sendSubscribeAndSaveEmailToShopOwner(mailOrderSubscription, shopUser);                 
        }        
      });
    }

    updatedProductSubscriptions.forEach(productSubscription => {
      this.productSubscriptionService.updateProductSubscription(productSubscription);
    });

    localStorage.removeItem('order');
    localStorage.removeItem('cart');

    localStorage.setItem('order', JSON.stringify(order));
    this.cartService.loadCart();
    this.orderSubject.next(order);
  }

  async handleDiscount(discountCode: string, user: User) {
    let invitation: ReferralInvitation | null = null;
    let discountDate = new Date();

    let prospectDiscountCode = await this.discountCodeService.getInvitationForDiscountCode(discountCode);
    let promotorDiscountCode = await this.discountCodeService.getInvitationForPromotorDiscountCode(discountCode);

    let isProspect: boolean = prospectDiscountCode != null;
    let isPromotor: boolean = promotorDiscountCode != null;

    if (isProspect) {
      invitation = prospectDiscountCode;
    }

    if (isPromotor) {
      invitation = promotorDiscountCode;
    }

    if (invitation == null || (invitation.usedByProspect && invitation.usedByPromotor)) {
      return;
    }

    let promotorUser: User = await this.userService.getUserById(invitation.promotor_user_id);
    let shopUser: User = await this.userService.getUserById(invitation.shopowner_user_id);

    let expirationDate: Date = new Date();
    expirationDate.setDate(discountDate.getDate() + this.config.referral.discountValidInDays);

    if (isProspect && !invitation.usedByProspect) {
      invitation.activation_date = timestamp(discountDate);
      invitation.usedByProspect = true;
      invitation.prospect_user_id = user.id;
    }

    if (isProspect && !invitation.usedByPromotor) {
      invitation.promotor_used_discount = isPromotor;
      invitation.promotor_discount_date = timestamp(discountDate);
      invitation.promotor_discount_expiration_date = timestamp(expirationDate);
      invitation.promotor_discount_code = this.discountCodeService.generateNewDiscountCode();
    }

    if (isPromotor) {
      invitation.usedByPromotor = true;
      invitation.promotor_activation_date = timestamp(discountDate)
    }

    this.referralService.updateReferralInvitation(invitation);

    if (isProspect) {
      this.referralMailerService.sendDiscountCodeToPromotorEmail(promotorUser, shopUser, invitation);
    }
  }

  sendNewCustomerPasswordEmail(password: string, user: User, shopUser: User) {
    let mailOrder: MailOrder = new MailOrder([], shopUser, null, null, null, user, 0);
    mailOrder.params.new_user_fullname = user.displayName;
    mailOrder.params.new_user_email = user.email;
    mailOrder.params.new_user_password = password;

    this.ordermailerService.sendNewCustomerPasswordEmailToUser(mailOrder, user);
  }

  getCurrentOrder(): Order {
    let order = localStorage.getItem('order');
    if (order) return JSON.parse(order)
    else return null
  }

  async getOrdersForUser(userId: string): Promise<Order[]> {
    const q = query(this.orders,
      where('user_id', '==', userId),
      orderBy('order_date', 'desc'),
      limit(20))

    return getDocs(q).then(readDocs)
  }

  async getOrdersForShopOwner(userId: string): Promise<Order[]> {
    const q = query(this.orders,
      where('shop_user_id', '==', userId),
      orderBy('order_date', 'desc'),
      limit(20))

    return getDocs(q).then(readDocs)
  }

  async getOrderById(id: string): Promise<Order> {
    const ref = doc(this.orders, id)
    return getDoc(ref).then(snap => snap.data())
  }
}

function timestamp(date = new Date) {
  return Timestamp.fromDate(date)
}
