<div class="modal fade" id="subscriptionChangeModal" tabindex="-1" role="dialog" aria-labelledby="subscriptionChangeModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="subscriptionChangeModalLabel">Wijzig Abonneer &amp; Bespaar frequentie</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="item">
          <div class="row pt-1 pb-0">
            <div class="col">
                <select class="form-select w-100" (change)="selectFrequency($event.target.value)">
                    <option value="30" [selected]="item.productSubscription.frequency == '30'">30 Dagen</option>
                    <option value="60" [selected]="item.productSubscription.frequency == '60'">60 Dagen</option>
                    <option value="90" [selected]="item.productSubscription.frequency == '90'">90 Dagen</option>
                    <option value="120" [selected]="item.productSubscription.frequency == '120'">120 Dagen</option>
                </select>
                <h6 class="pt-1 pb-0 mb-0">
                    <small>U kunt op elk moment de frequentie wijzigen of annuleren. 
                    Wij zullen u voor elke verzending per email herinneren.</small>
                </h6>
            </div>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuleren</button>
          <button id="CloseModal" [hidden]="true" type="button" class="btn btn-primary">Wijzigen</button>
          <button type="button" class="btn btn-primary" (click)="changeSubscription()" data-dismiss="modal">Wijzigen</button>
        </div>
      </div>
    </div>
  </div>