<div class="container">
  <div class="row">

    <form #productBundleForm="ngForm">
      <h3>Productfamilie toevoegen</h3>
      <div class="form-group">
        <label for="title" class="required">Naam</label>
        <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name"
          placeholder="Naam">
      </div>

      <button type="submit" class="btn btn-primary" (click)="addProductBundle()">Toevoegen</button>
    </form>
  </div>
</div>