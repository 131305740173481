<div class="container">
  <div class="row">
    <div id="logreg-forms" class="card">
      <div class="card-body">

        <div class="card-text" *ngIf="displayNewCustomer">
          <!-- <button (click)="showRegistration()" class="btn btn-primary btn-block" type="button" id="btn-signup"><i class="fas fa-user-plus"></i> Lid
            worden</button> -->
            <h5 class="card-title">Lid worden</h5>
            <form id="newCustomerForm" [formGroup]="checkoutForm"
            (ngSubmit)="onSubmit(checkoutForm)">
            <div class="authentication unknown-customer">
              <div class="form-group">
                <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <input type="text" class="form-control" id="firstname" placeholder="Voornaam"
                  formControlName="firstname" required>
              </div>
              <div class="form-group col-md-6">
                <input type="text" class="form-control" id="lastname" placeholder="Achternaam"
                  formControlName="lastname" required>
              </div>
            </div>
            <div class="form-group">
              <input type="password" class="form-control" id="password" placeholder="Wachtwoord"
                formControlName="password" required minlength="8">
            </div>

            <button type="submit" class="btn btn-success btn-block">
              <i class="fas fa-user-plus"></i> Lid worden
            </button>
          </form>

        </div>
        <h5 class="card-title" *ngIf="displayLogin">Inloggen</h5>
        <div class="card-text" *ngIf="displayLogin">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin(loginForm.value)">
            <button type="buton" class="btn btn-block" (click)="fbLogin()">
              <i class="fa fa-facebook" aria-hidden="true"></i>
              Inloggen met Facebook
            </button>  
            <input type="email" formControlName="email" class="form-control" placeholder="Email adres" required=""
              autofocus="">
            <input formControlName="password" type="password" id="inputPassword" class="form-control" placeholder="Wachtwoord" required="">

            <button class="btn btn-success btn-block" type="submit"><i class="fas fa-sign-in-alt"></i> Inloggen</button>
            <a href="#" id="forgot_pswd">Wachtwoord vergeten?</a>
            <hr>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>