import { Component, OnInit } from '@angular/core';
import { ShopProductCategory } from 'app/models/shop-product-category';
import { ShopProductCategoryService } from 'app/services/shop-product-category.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-my-shop-product-categories',
  templateUrl: './my-shop-product-categories.component.html',
  styleUrls: ['./my-shop-product-categories.component.scss']
})
export class MyShopProductCategoriesComponent implements OnInit {

  categories: ShopProductCategory[] = [];
  userSlug: string = '';

  constructor(private shopProductCategoryService: ShopProductCategoryService,
    private userService: UserService) {    
  }

  ngOnInit() {
    this.userSlug = this.userService.sxUser.slug;
    this.getCategories();
  }

  getCategories() {
    this.shopProductCategoryService.getCategories(this.userService.sxUser.id).then(categories => {
      this.categories = categories;
    })
  }

  deleteCategory(category: ShopProductCategory) {
    if (confirm("Weet je zeker dat je " + category.title + " wilt verwijderen?")) {
      this.shopProductCategoryService.deleteCategory(category.id).then(() => {
        this.getCategories();
      })
    }
  }
}
