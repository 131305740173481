import { Component, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PrivateGroupInvitation } from 'app/models/private_group_invitation';
import { User } from 'app/models/user';
import { AuthService } from 'app/services/auth.service';
import { CustomerService } from 'app/services/customer.service';
import { OrderService } from 'app/services/order.service';
import { UserService } from 'app/services/user.service';
import * as password from 'secure-random-password';
import { existingSxUserValidator } from './existing-sx-user.validator';

@Component({
  selector: 'customer-order-creator-customer-data',
  templateUrl: './customer-order-creator-customer-data.component.html',
  styleUrls: ['./customer-order-creator-customer-data.component.scss']
})

export class CustomerOrderCreatorCustomerDataComponent implements OnInit {
  existingCustomer: boolean = false;
  newCustomer: boolean = true;
  public customer: User;
  public customers: User[] = new Array<User>();
  public model: PrivateGroupInvitation;
  public customerForm: UntypedFormGroup;
  public existingCustomerForm: UntypedFormGroup;
  public currentStep: number;

  constructor(private formBuilder: UntypedFormBuilder, 
              private authService: AuthService,
              private userService: UserService, 
              private customerService: CustomerService,
              private orderService: OrderService,
              private router: Router) { 
    this.model = new PrivateGroupInvitation();
    this.currentStep = 1;

    this.customerForm = this.formBuilder.group({
      firstname: '',
      lastname: '',
      email: ['', [Validators.required, e => this.existingCustomerValidator(e) ] ],
      password: '',
      customer: true,
      shop_user_id: this.userService.sxUser.id
    });

    this.customerForm.get('email').addAsyncValidators(existingSxUserValidator(this.userService));

    this.existingCustomerForm = this.formBuilder.group({
      customer: ''
    });

  }

  async ngOnInit() {
    const customers = await this.customerService.getCustomersForShopUser(this.userService.sxUser.id);
    const privateGroupMembers = await this.userService.getPrivateGroupMembers(this.userService.sxUser.id);

    customers.forEach(customer => {
      if (this.customers.find(x => x.id === customer.id) == null) {
        this.customers.push(customer);
      }
    });      

    privateGroupMembers.forEach(async privateGroupMember => {
      const user = await this.userService.getUserById(privateGroupMember.user_id);
      if (this.customers.find(x => x.id === user.id) == null) {
        this.customers.push(user);
      }
    });


  }

  switchCustomerType(customerType: string) {
    if (customerType == 'newCustomer') {
      this.existingCustomer = false;
      this.newCustomer = true;
    }
    else {
      this.existingCustomer = true;
      this.newCustomer = false;
    }
  }

  createOrder(existingCustomerFormData: UntypedFormGroup) {
    this.userService.getUserById(existingCustomerFormData.value.customer).then(customerUser => {
      this.customerService.setCustomer(customerUser);
    });
  }

  get email(): any {
    return this.customerForm.get('email')
  }
  
  public createNewCustomer(customerForm: UntypedFormGroup) {
    if (this.customerForm.valid) {

      let loggedinUser: User = this.userService.sxUser;
      this.customerForm.value.password = password.randomPassword();
      this.customerForm.value.customer = true;
      this.customerForm.value.shop_user_id = loggedinUser.id;

      this.authService.userExists(this.customerForm.value.email).then(res => {
        if (!res)
        {
          this.authService.createAccount(this.customerForm.value).then(val => {
            this.userService.createUniqueUserSlug(this.customerForm.value.firstname, this.customerForm.value.lastname).then(userSlug => {
              this.userService.createUser(this.customerForm.value, userSlug, val).then(_user => {
                this.customerService.setCustomer(_user);
                this.orderService.sendNewCustomerPasswordEmail(this.customerForm.value.password, _user, loggedinUser);
              });  
            });
          });
        }
        else {
          this.userService.findUserBy('email', this.customerForm.value.email).then(user => {
            this.customerService.setCustomer(user);
          });
        }
      });
    }
  }

  existingCustomerValidator(control: AbstractControl): ValidationErrors | null  {
      const existingCustomer: User = this.customers.find(x => x.email == control.value);
      const exists = existingCustomer != null || existingCustomer !== undefined;
      if (!exists) {
        this.userService.findUserBy('email', control.value).then(user => {
          const returnVal = (user == null || user === undefined) ? null : { userExists: { value: control.value } };
          return returnVal;  
        }, () =>  { return null })
      }
      else {
        return { emailExists: { value: control.value } };
      }
      // return exists ? { emailExists: { value: control.value } } : null;
    
    // return (control: AbstractControl) => {
    //   userService.findUserBy('email', control.value).then(user => {
    //   const returnVal = (user == null || user === undefined) ? null : { userExists: { value: control.value } };
    //   console.log('userExists', returnVal);
    //   return returnVal; 
    // })
    
    //   const returnVal = (user == null || user === undefined) ? null : { userExists: { value: control.value } };
    //   console.log('userExists', returnVal);
    //   return returnVal; 
    // });
    // console.log('Komde gij hier?!?');
    // return null;
  }
  
}