import { Injectable } from '@angular/core';
import { Product } from 'app/models/product';
import { ProductService } from 'app/services/product.service';
import { UserService } from 'app/services/user.service';
import { CartItem } from 'app/models/cartitem';
import { Subject, Observable } from 'rxjs';
import { CartState } from 'app/models/cart-state';
import { Firestore } from '@angular/fire/firestore';
import { Cart } from 'app/models/cart';
import { ProductSubscriptionCartInfo } from 'app/models/product_subscription_cart_info';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private products: Product[] = [];
  public items: CartItem[] = [];
  public totalItems: number;
  private cartSubject = new Subject<CartState>();
  public cartState: Observable<CartState> = this.cartSubject.asObservable();

  constructor(
    private userService: UserService,
    private productService: ProductService,
    private db: Firestore) {
  }

  findAll(): Product[] {
    return this.products;
  }

  async find(id: string): Promise<Product> {
    return await this.productService.getProductById(id);
  }

  private getSelectedIndex(id: string): number {
    return this.items.findIndex(item => item.product.id === id);
  }

  applyDiscount(cart: Cart, discountCode: string, discount: number, discountDate: Date, discountUserId: string, minimumOrderPrice: number, isPromotorDiscount: boolean, isProspectDiscount: boolean) {
    cart.discountCode = discountCode;
    cart.discount = discount;
    cart.discountDate = discountDate;
    cart.discountUserId = discountUserId;
    cart.discountMinimumOrderPrice = minimumOrderPrice;
    cart.isPromotorDiscount = isPromotorDiscount;
    cart.isProspectDiscount = isProspectDiscount;

    localStorage.setItem('discountCode', discountCode);
    this.updateCart(cart);
  }

  removeDiscount() {

    const cartObject = JSON.parse(localStorage.getItem('cart'));
    const cart: Cart = new Cart();
    cart.setCart(cartObject);

    if (cart) {
      cart.cartItems.forEach((item) => this.items.push(item));
    }

    cart.discount = null;
    cart.discountCode = null;
    cart.discountDate = null;
    cart.discountUserId = null;
    cart.discountMinimumOrderPrice = null;

    localStorage.removeItem('discountCode');
    this.updateCart(cart);
  }

  addToCart(data: Product, subscription: ProductSubscriptionCartInfo = undefined): void {
    let shoppingCartArray: CartItem[];
    let cartItem = new CartItem(data, 1, subscription);
    const cartObject = JSON.parse(localStorage.getItem('cart'));

    const cart: Cart = new Cart();
    cart.setCart(cartObject);
    this.items = cart.cartItems;

    shoppingCartArray = cart.cartItems;

    const index = this.getSelectedIndex(data.id);

    if (index >= 0) {
      cartItem = shoppingCartArray[index];
      cartItem.quantity += 1;
      this.updateCartItem(cartItem);
    } else {
      shoppingCartArray.push(cartItem);
    }

    if (cart.count == 0) {
      localStorage.removeItem('shopping_cart');
    }

    cart.setItems(shoppingCartArray);
    localStorage.setItem('cart', JSON.stringify(cart));

    this.loadCart();
  }

  getCartItem(productId: string): CartItem {
    let shoppingCartArray: CartItem[];
    let cart: Cart;
    cart = JSON.parse(localStorage.getItem('cart')) || new Cart();
    shoppingCartArray = cart.cartItems;
    const index = this.getSelectedIndex(productId);
    let cartItem: CartItem = null;

    if (index !== -1) {
       cartItem = shoppingCartArray[index];
    }

    return cartItem;
  }

  updateCartItem(data: CartItem): void {
    let cart: Cart;
    cart = JSON.parse(localStorage.getItem('cart')) || new Cart();
    let cartItem: CartItem = this.getCartItem(data.product.id);
    if (cartItem != null) {
      cartItem.quantity = data.quantity;
      cartItem.productSubscription = data.productSubscription;
      const index = this.getSelectedIndex(data.product.id);
      cart.cartItems[index] = cartItem;
      this.items[index] = cartItem;
      localStorage.setItem('cart', JSON.stringify(cart));
      this.loadCart();
    }
  }

  removeFromCart(data: CartItem): void {
    let shoppingCartArray: CartItem[];
    let cart: Cart;
    cart = JSON.parse(localStorage.getItem('cart')) || new Cart();
    shoppingCartArray = cart.cartItems;

    const index = this.getSelectedIndex(data.product.id);
    if (index !== -1) {
      shoppingCartArray.splice(index, 1);

      localStorage.setItem('cart', JSON.stringify(cart));
      this.loadCart();
    }
  }

  updateCart(updatedCart: Cart): void {
    const cart: Cart = new Cart();
    cart.setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  canOrderProduct(product: Product, quantity: number): boolean {
    let stock: number = product.stock || 0;
    let cartItem: CartItem = this.getCartItem(product.id);
    let canOrder: boolean = false;

    if (cartItem != null) {
      canOrder = stock - cartItem.quantity - quantity >= 0;
    }
    else {
      canOrder = stock - quantity >= 0;
    }

    return canOrder;
  }

  canOrderCartItem(stock: number, quantity: number) {
    let canOrder: boolean = false;
    canOrder = stock - quantity >= 0;
    return canOrder;
  }

  totalPrice(): number {
    return this.items.reduce((acc, item) => acc + item.totalPrice, 0);
  }

  loadCart(): Cart {
    this.items = [];
    const cartObject = JSON.parse(localStorage.getItem('cart'));
    const cart: Cart = new Cart();
    cart.setCart(cartObject);

    if (cart) {
      cart.cartItems.forEach((item) => this.items.push(item));
    }

    this.totalItems = this.items.length;
    let totalprice = cart.totalPrice;
    let discount = cart.discount;
    let discountCode = cart.discountCode;
    let discountDate = cart.discountDate;

    this.cartSubject.next({
      loaded: true,
      items: this.items,
      numberOfItems: this.totalItems,
      totalPrice: totalprice,
      discount: discount,
      discountCode: discountCode,
      discountDate: discountDate,
      discountMinimumOrderPrice: cart.discountMinimumOrderPrice,
      discountUserId: cart.discountUserId,
      isPromotorDiscount: cart.isPromotorDiscount,
      isProspectDiscount: cart.isProspectDiscount
    } as CartState);

    return cart;
  }

  deleteCart() {
    localStorage.removeItem('cart');
  }
  
  fireNewCartState() {
    this.items = [];
    const cartObject = JSON.parse(localStorage.getItem('cart'));
    const cart: Cart = new Cart();
    cart.setCart(cartObject);

    if (cart) {
      cart.cartItems.forEach((item) => this.items.push(item));
    }

    this.totalItems = this.items.length;
    let totalprice = cart.totalPrice;
    let discount = cart.discount;
    let discountCode = cart.discountCode;
    let discountDate = cart.discountDate;

    this.cartSubject.next({
      loaded: true,
      items: this.items,
      numberOfItems: this.totalItems,
      totalPrice: totalprice,
      discount: discount,
      discountCode: discountCode,
      discountDate: discountDate,
      discountMinimumOrderPrice: cart.discountMinimumOrderPrice,
      discountUserId: cart.discountUserId,
      isPromotorDiscount: cart.isPromotorDiscount,
      isProspectDiscount: cart.isProspectDiscount
    } as CartState);
  }
}
