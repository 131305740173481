import { Injectable } from '@angular/core';
import { ServiceBase } from './service-base';
import { Firestore } from '@angular/fire/firestore';
import { UserService } from './user.service';
import { Category } from 'app/models/category';

@Injectable()
export class CategoryService extends ServiceBase {

  constructor(private db: Firestore,
              private userService: UserService) {
    super();
  }

  getMainCategories(): Promise<Category[]> {
    return new Promise<Category[]>((resolve) => {
      var categories: Category[] = [];
      categories.push(new Category(97, null, "Clothing & Accessories"));
      categories.push(new Category(99, null, "Health & Beauty"));

      resolve(categories.map(c => {
        return c;
      }));

      // this.db.collection<Category>('categories')
      //   .snapshotChanges()
      //   .pipe(takeUntil(this.ngUnsubscribe))
      //   .subscribe(categories => {
      //     resolve(categories.map(c => {
      //       let category: Category = Category.constructWithId(c.payload.doc.id, c.payload.doc.data());
      //       return category;
      //     }));
      //   });
    });
  }

  getSubCategories(id: number): Promise<Category[]> {
    var categories: Category[] = [];
    if (id == 97) {
      categories.push(new Category(135, 97, "Lingerie"));
      categories.push(new Category(159, 135, "Combination Sets"));
      categories.push(new Category(161, 135, "Socks & Hosiery"));
      categories.push(new Category(163, 135, "Bodies"));
      categories.push(new Category(167, 135, "Suspenders & Garters"));
      categories.push(new Category(168, 135, "Bras"));
      categories.push(new Category(169, 135, "Nightwear"))
      categories.push(new Category(170, 135, "Women's Underwear"));
    }

    if (id == 99) {
      categories.push(new Category(100, 99, "Bodycare & Fitness"));
      categories.push(new Category(101, 100, "Bodycare Appliances"));
      categories.push(new Category(102, 100, "Shaving"));
      categories.push(new Category(110, 99, "Cosmetics & Skincare"));
      categories.push(new Category(111, 110, "Cosmetics"));
      categories.push(new Category(113, 110, "Fragrance"));
      categories.push(new Category(114, 110, "Skincare"));
      categories.push(new Category(115, 99, "Haircare"));
      categories.push(new Category(121, 99, "Health"));
    }

    return new Promise<Category[]>((resolve) => {
      resolve(categories.map(c => {
        return c;
      }));
    });
  }

}
