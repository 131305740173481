import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, WithFieldValue, SnapshotOptions } from '@angular/fire/firestore';
 
export function converter<T> () : FirestoreDataConverter<T> {
  return { toFirestore, fromFirestore }
}

function toFirestore<T> (object: WithFieldValue<T>): DocumentData {
  return Object.assign({}, object) as DocumentData
}

function fromFirestore<T> (snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions): T {
  const data = snapshot.data(options)

  return Object.assign({ id: snapshot.id }, data) as unknown as T
}