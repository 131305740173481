import { Component } from '@angular/core';
import { User } from 'app/models/user';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-my-product-links',
  templateUrl: './my-product-links.component.html',
  styleUrls: ['./my-product-links.component.scss']
})
export class MyProductLinksComponent {
  sxUser: User;
  constructor(private userService: UserService) {
    this.sxUser = this.userService.sxUser;
  }
}
