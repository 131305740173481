<link href="https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.6.1/css/bootstrap4-toggle.min.css"
  rel="stylesheet">
<script src="https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.6.1/js/bootstrap4-toggle.min.js"></script>

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <section>
        <!-- Tab panes -->
        <div class="tab-content">
          <div class="tab-pane container fade in show active" id="profile">
            <h5 class="profile-header tab-header">Profiel</h5>
            <form id="userForm" [formGroup]="userForm" (ngSubmit)="onSubmitUserForm(userForm)">
              <div id="yoursettings">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div *ngIf="this.hasProfilePhoto">
                        <img src="{{this.profilePhoto}}" class="responsive profile-photo" />
                      </div>
                      <label>Upload een foto van jezelf</label><br />
                      <uploader [uid]="this.userId" [displayMode]="this.uploaderDisplayMode"></uploader>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm">
                      <label for="firstname" class="required">Voornaam</label>
                      <input type="text" class="form-control" id="firstname" placeholder="Voornaam"
                        formControlName="firstname" required>
                    </div>
                    <div class="col-sm">
                      <label for="lastname" class="required">Achternaam</label>
                      <input type="text" class="form-control" id="lastname" placeholder="Achternaam"
                        formControlName="lastname" required>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label for="email" class="required">Email</label><br />
                      <input type="text" class="form-control" id="email" placeholder="Email" formControlName="email"
                        required>
                    </div>
                    <div class="col-md-6">
                      <label for="phoneNumber">(Mobiel) Telefoonnummer</label><br />
                      <input type="text" class="form-control" id="phoneNumber" placeholder="06..."
                        formControlName="phoneNumber">
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn btn-success">
                Opslaan
              </button>
            </form>
          </div>
          <div class="tab-pane container fade" id="newsletter" *ngIf="userService.userHasProducts">
            <h5 class="profile-header tab-header">Nieuwsbrief</h5>
            <profile-newsletter-settings></profile-newsletter-settings>
          </div>
          <div class="tab-pane container fade" id="referral">
            <h5 class="profile-header tab-header">Referral</h5>
            <profile-referral-settings></profile-referral-settings>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>