
import { Storage, UploadTask, uploadBytesResumable, getDownloadURL, ref } from '@angular/fire/storage';
import { Observable, Subscription, of } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UploadService } from 'app/services/upload.service';
import { ImageResizerService } from 'app/services/image-resizer.service';
import { ImageSize } from 'app/models/image_size';
import { UploadedFile } from 'app/models/uploaded_file';

@Component({
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss']
})
export class UploadTaskComponent implements OnInit, OnDestroy {

  @Input() file: File;
  @Input() uid: string;
  @Input() resizeImage: boolean;
  @Input() resizedImageHeight: number;
  @Input() resizedImageWidth: number;
  @Input() showPercentage: boolean;
  @Input() imageSizes: ImageSize[];

  task: UploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;
  uploadSubscription: Subscription;
  processedFile: boolean = false;

  constructor(
    private storage: Storage,
    private uploadService: UploadService,
    private imageResizerService: ImageResizerService
  ) { }

  async ngOnInit() {
    const me = this;
    this.uploadSubscription = this.uploadService.uploadedFile$.subscribe((uploadedFile: UploadedFile) => {
      if (uploadedFile.filename == this.file.name && !this.processedFile) {
        if (!(uploadedFile == null || uploadedFile === undefined) && uploadedFile.size === 'large') {
          if (!(me.imageSizes == null || me.imageSizes === undefined)) {
            me.imageSizes.forEach(imageSize => {
              me.imageResizerService.resizeImage(me.file, me.file.type, imageSize.width, imageSize.height).then(resizedFile => {
                let imageSize: ImageSize = me.imageSizes.find(x => x.height == resizedFile.height && x.width == resizedFile.width);
                me.uploadFileToFirebase(resizedFile.file, imageSize);
              });
              this.processedFile = true;
            });
          }
        }
      }

    });

    this.startUpload();
  }

  async ngOnDestroy() {
    if (this.uploadSubscription)
      this.uploadSubscription.unsubscribe();
  }

  async startUpload() {
    // resize image first if the parameter has been set
    if (this.resizeImage) {
      this.imageResizerService.resizeImage(this.file, this.file.type, this.resizedImageWidth, this.resizedImageHeight).then(resizedFile => {
        this.uploadFileToFirebase(resizedFile.file);
      });
    }
    else {
      this.uploadFileToFirebase(this.file);
    }
  }

  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  private async uploadFileToFirebase(file: File, imageSize: ImageSize = null) {
    // The storage path
    const filename = file.name
    const path = `photos/${Date.now()}_${filename}`

    // Reference to storage bucket
    const bucket = ref(this.storage, path)

    // The main task
    this.task = uploadBytesResumable(bucket, this.file)

    this.task.on('state_changed',
      (snapshot) => {
        this.percentage = of((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      },
      (error) => { console.log(error) },
      () => {
        getDownloadURL(this.task.snapshot.ref).then(downloadURL => {
          this.uploadService.fileUploaded(fileDetails(this.uid, file, downloadURL, imageSize))
        })
      }
    )
  }
}

function fileDetails(uid: string, file: File, downloadURL: string, sizing: ImageSize): UploadedFile {
  const metadata = {
    uid,
    downloadURL,
    filename: file.name,
    filenameWithoutExtension: getFilenameWithoutExtension(file.name),
    size: sizing?.sizeName || 'large',
    height: sizing?.height || undefined,
    width: sizing?.width || undefined
  }

  return metadata as UploadedFile
}

function getFilenameWithoutExtension(filename: string): string {
  let extensionDotIndex = filename.lastIndexOf('.');
  let filenameWithoutExtension = filename.substring(0, extensionDotIndex);

  return filenameWithoutExtension;
}