import { Injectable } from '@angular/core';
import { AddressState } from '../models/address_state';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

import { Address } from '../models/address';
import { CollectionReference, collection, doc, getDoc, getDocs, Firestore,
         where, query, setDoc, deleteDoc, addDoc, limit } from '@angular/fire/firestore';

import { FormGroup } from '@angular/forms';
import { UserService } from './user.service';
import { ServiceBase } from './service-base';
import { converter } from 'app/models/converter';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends ServiceBase {
  private addressSubject: Subject<AddressState>
  public addressState: Observable<AddressState>

  private addressCollection: CollectionReference<Address>
  private readonly _addresses = new BehaviorSubject<Address[]>([])

  private sameBillingSubject = new Subject<AddressState>();
  public sameBilling: Observable<AddressState> = this.sameBillingSubject.asObservable();

  private get addresses(): Address[] {
    return this._addresses.getValue()
  }

  private set addresses(val: Address[]) {
    this._addresses.next(val);
  }

  constructor(private db: Firestore, private userService: UserService) {
    super()

    this.addressSubject = new Subject<AddressState>()
    this.addressState = this.addressSubject.asObservable()

    this.addressCollection = collection(db, 'address').withConverter(converter<Address>())
  }

  async cleanupAddresses(): Promise<void> {
    return getDocs(this.addressCollection).then(snap => {
      Promise.all(snap.docs.filter(doc => doc.data())
        .map(doc => this.deleteAddressById(doc.id)))
    })
  }

  updateAddressWithType(address: Address, type: string) {
    let addresses = this.addresses;
    let index = this.addresses.findIndex(a => a.address_type == type);
    if (index == -1) {
      addresses.push(address);
    }
    else {
      addresses[index] = address;
    }

    this.addresses = addresses;
  }

  getAddressFromStore(addressType: string): Address|null {
    let index = this.addresses.findIndex(a => a.address_type == addressType);
    let address = index != -1 ? this.addresses[index] : null;

    return address;
  }

  get shippingAddress(): Address|null { return this.getAddressFromStore('shipping'); }
  get billingAddress(): Address|null { return this.getAddressFromStore('billing'); }
  
  updateSameShippingAndBillingAddress(sameShippingAndBillingAddress: boolean, address: Address, addressType: string) {
    this.addressSubject.next({
      sameShippingAndBillingAddress: sameShippingAndBillingAddress,
      address: address,
      addressType: addressType
    } as AddressState);
  }

  async addOrderAddress(shippingForm: FormGroup): Promise<Address> {
    var shippingAddress = {
      address_type: shippingForm.value.address_type,
      city: shippingForm.value.city,
      country: shippingForm.value.country,
      firstname: shippingForm.value.firstname,
      housenumber: shippingForm.value.housenumber,
      housenumber_addition: shippingForm.value.housenumber_addition,
      lastname: shippingForm.value.lastname,
      street: shippingForm.value.street,
      zipcode: shippingForm.value.zipcode,
      user_id: this.userService.user.uid
    };

    const address_type = shippingAddress.address_type;
    let existingAddressId = localStorage.getItem(address_type + '_address_id');
    let addressId = "";

    return new Promise<Address>((resolve, reject) => {


      if (existingAddressId != null) {
        addressId = existingAddressId;

        if (address_type == 'shipping') {
          this.userService.sxUser.shipping_address_id = existingAddressId;
        }
        else {
          this.userService.sxUser.billing_address_id = existingAddressId;
        }

        const reference = doc(this.addressCollection, addressId)
        setDoc(reference, shippingAddress)

        this.getAddress(address_type).then(address => {
          if (address) resolve(address);
          else reject()
        });
      }
      else {
        addDoc(this.addressCollection, shippingAddress).then(res => {
          localStorage.setItem(address_type + '_address_id', res.id);
          addressId = res.id;

          if (address_type == 'shipping') {
            this.userService.sxUser.shipping_address_id = res.id;
          }
          else {
            this.userService.sxUser.billing_address_id = res.id;
          }

          this.userService.updateSxUser(this.userService.sxUser)

          resolve(Address.constructWithId(res.id, shippingAddress))
        });
      }

    });
  }

  async updateAddress(address: Address): Promise<void> {
    const reference = doc(this.addressCollection, address.id)

    setDoc(reference, address)
  }

  deleteAddress(address: Address) {
    if (address == null) {
      return;
    }

    deleteDoc(doc(this.addressCollection, address.id))
  }

  deleteAddressById(addressId: string) {
    deleteDoc(doc(this.addressCollection, addressId))
  }

  async getAddress(address_type: string): Promise<Address> {
    var addressId = localStorage.getItem(address_type + '_address_id');

    if (addressId == '' || addressId == null) {
      return null;
    }

    const reference = doc(this.addressCollection, addressId)
    return getDoc(reference)
            .then(snap => Address.constructWithId(snap.id, snap.data()))
  }

  async addAddressForUser(addressForm: FormGroup, user_id: string): Promise<string> {
    var address = {
      address_type: addressForm.value.address_type,
      city: addressForm.value.city,
      country: addressForm.value.country,
      firstname: addressForm.value.firstname,
      housenumber: addressForm.value.housenumber,
      housenumber_addition: addressForm.value.housenumber_addition,
      lastname: addressForm.value.lastname,
      street: addressForm.value.street,
      zipcode: addressForm.value.zipcode,
      user_id: user_id
    };

    return addDoc(this.addressCollection, address).then((reference) =>{
      return reference.id
    })
  }

  async getAddressForUser(user_id: string, address_type: string): Promise<Address> {
    const q = query(this.addressCollection,
                    where('address_type', '==', address_type),
                    where('user_id', '==', user_id),
                    limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty)  {
        return null
      }
        else {
          const address = Address.constructWithId(snap.docs[0].id, snap.docs[0].data())
          return address
        }
    })
  }

  async getAddressById(id: string): Promise<Address> {
    const q = query(this.addressCollection,
                    where('id', '==', id),
                    limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty) {
        return null
      } 
        else {
          const address = Address.constructWithId(snap.docs[0].id, snap.docs[0].data())
          return address
        }
    })                
  }

  changeSameOrDifferentBillingAddress(addressState: AddressState) {
    this.sameBillingSubject.next(addressState);
  }
}
