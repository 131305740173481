import { Serializeable } from '../serializeable';
import { OrderLine } from 'app/models/orderline';

export class MailOrderParamsProduct extends Serializeable {
    static instanceType = MailOrderParamsProduct;
   
    public name: string;
    public price: string;
    public quantity: number;
    public total: string;
    public product_photo: string;

    constructor(orderline: OrderLine) {
        super();
        this.name = orderline.product_title;
        this.price = orderline.price.toString();
        this.quantity = orderline.quantity;
        this.total = orderline.total_price.toString();
        this.product_photo = orderline.product_photo;
    }
}