import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'app/models/user';
import { PrivateGroupService } from 'app/services/private-group.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-user-private-groups',
  templateUrl: './user-private-groups.component.html',
  styleUrls: ['./user-private-groups.component.scss']
})
export class UserPrivateGroupsComponent implements OnInit {

  public users: Array<User> = new Array<User>();
  constructor(private privateGroupService: PrivateGroupService, 
              private userService: UserService,
              private route: ActivatedRoute ) { 
                this.getPrivateGroups();
  }

  ngOnInit() {
  }

  private getPrivateGroups() {
    this.privateGroupService.getPrivateGroupsForUser(this.userService.sxUser.id).then(privateGroups => {
      privateGroups.forEach(privateGroupMember => {
        this.userService.getUserById(privateGroupMember.shop_user_id).then(user => {
          this.users.push(user);
        });
      });
    });
  }

}
