import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';

import { initializeApp, getApp } from '@angular/fire/app'
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getAuth, provideAuth } from '@angular/fire/auth';


import { DashboardComponent } from './components/dashboard/dashboard.component';

import { AppRoutingModule } from './app-routing/app-routing.module';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ShoppingAuthGuardService } from './services/shopping-auth-guard.service';
import { AdminGuardService } from './services/admin-guard.service';

import { UserService } from './services/user.service';
import { ProductsComponent } from './components/products/products.component';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ProductAddComponent } from './components/product-add/product-add.component';
import { ProductEditComponent } from './components/product-edit/product-edit.component';
import { ProductService } from './services/product.service';
import { CategoryService } from './services/category.service';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { DropzoneDirective } from './dropzone.directive';
import { UploaderComponent } from './components/uploader/uploader.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { ProductBaseComponent } from './components/product-base/product-base.component';
import { ListComponentComponent } from './components/list-component/list-component.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { CartitemComponent } from './components/cartitem/cartitem.component';
import { CartItemsComponent } from './components/cart-items/cart-items.component';
import { ShippingComponent } from './components/shipping/shipping.component';
import { CartTotalComponent } from './components/cart-total/cart-total.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { AddressComponent } from './components/address/address.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { OrderService } from './services/order.service';
import { OrderlineComponent } from './components/orderline/orderline.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderItemComponent } from './components/order-item/order-item.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';

import { AppConfigModule } from './app-config.module';
import { OrderlineDetailComponent } from './components/orderline-detail/orderline-detail.component';
import { ProductEditPhotoComponent } from './components/product-edit-photo/product-edit-photo.component';
import { ProductBundleComponent } from './components/product-bundle/product-bundle.component';
import { ProductBundleAddComponent } from './components/product-bundle-add/product-bundle-add.component';
import { ProductBundlesComponent } from './components/product-bundles/product-bundles.component';
import { ProductBundleEditComponent } from './components/product-bundle-edit/product-bundle-edit.component';
import { ProductBundleFormComponent } from './components/product-bundle-form/product-bundle-form.component';
import { ProductBundleDetailComponent } from './components/product-bundle-detail/product-bundle-detail.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProductGuardService } from './services/product-guard.service';
import { RegisterComponent } from './components/register/register.component';
import { UserGroupComponent } from './components/user-group/user-group.component';
import { UserGroupInviteComponent } from './components/user-group-invite/user-group-invite.component';
import { UserGroupAddInviteComponent } from './components/user-group-add-invite/user-group-add-invite.component';
import { PrivateGroupService } from './services/private-group.service';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PrivateGroupAcceptedComponent } from './components/private-group-accepted/private-group-accepted.component';
import { UserPrivateGroupsComponent } from './components/user-private-groups/user-private-groups.component';
import { RepeatServiceComponent } from './components/repeat-service/repeat-service.component';
import { RepeatServiceConfirmationEmailComponent } from './components/repeat-service-confirmation-email/repeat-service-confirmation-email.component';
import { RepeatEmailService } from './services/repeat.email.service';
import { ProductRowComponent } from './components/product-row/product-row.component';
import { ProductRowHomepageComponent } from './components/product-row-homepage/product-row-homepage.component';
import { CustomerOrderCreatorComponent } from './components/customer-order-creator/customer-order-creator.component';
import { ShopOwnerGuardService } from './services/shop-owner-guard.service';
import { CustomerOrderCreatorCustomerDataComponent } from './components/customer-order-creator-customer-data/customer-order-creator-customer-data.component';
import { CustomerService } from './services/customer.service';
import { CustomerOrderCreatorOrderDataComponent } from './components/customer-order-creator-order-data/customer-order-creator-order-data.component';
import { ProductitemComponent } from './components/productitem/productitem.component';
import { ProductItemsComponent } from './components/product-items/product-items.component';
import { CustomerOrderCreatorAddressDataComponent } from './components/customer-order-creator-address-data/customer-order-creator-address-data.component';
import { CustomerOrderCreatorSendComponent } from './components/customer-order-creator-send/customer-order-creator-send.component';
import { CustomerOrderCreatorSentComponent } from './components/customer-order-creator-sent/customer-order-creator-sent.component';
import { FormValidatorComponent } from './components/form-validator/form-validator.component';
import { FormValidationService } from './services/form-validation.service';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { NewsletterService } from './services/newsletter.service';
import { CreateNewGroupInvitationComponent } from './create-new-group-invitation/create-new-group-invitation.component';
import { RedirectService } from './services/redirect.service';
import { ProfileAddressSettingsComponent } from './components/profile-address-settings/profile-address-settings.component';
import { ProfileShopSettingsComponent } from './components/profile-shop-settings/profile-shop-settings.component';
import { ProfileNewsletterSettingsComponent } from './components/profile-newsletter-settings/profile-newsletter-settings.component';
import { ReferAFriendComponent } from './components/refer-a-friend/refer-a-friend.component';
import { ReferralService } from './services/referral.service';
import { ReferralAuthGuardService } from './services/referral-auth-guard.service';
import { ProfileReferralSettingsComponent } from './components/profile-referral-settings/profile-referral-settings.component';
import { ReferrerComponent } from './components/referrer/referrer.component';
import { DiscountCodeComponent } from './components/discount-code/discount-code.component';
import { DiscountCodeService } from './services/discount-code.service';
import { MyProductsComponent } from './components/my-products/my-products.component';
import { NewsletterCreatorComponent } from './components/newsletter-creator/newsletter-creator.component';
import { NewsletterCreatorCreateNewsletterComponent } from './components/newsletter-creator-create-newsletter/newsletter-creator-create-newsletter.component';
import { NewsletterCreatorSelectProductComponent } from './components/newsletter-creator-select-product/newsletter-creator-select-product.component';
import { NewsletterCreatorNewsletterPreviewComponent } from './components/newsletter-creator-newsletter-preview/newsletter-creator-newsletter-preview.component';
import { NewsletterCreatorNewsletterSentComponent } from './components/newsletter-creator-newsletter-sent/newsletter-creator-newsletter-sent.component';
import { ImageResizerService } from './services/image-resizer.service';
import { provideFirebaseApp } from '@angular/fire/app';
import { ProductSubscriptionModalComponent } from './components/product-subscription-modal/product-subscription-modal.component'
import { DatePipe } from '@angular/common';
import { MyPreferencesComponent } from './components/my-preferences/my-preferences.component';
import { MyInvitationComponent } from './components/my-invitation/my-invitation.component';
import { MyProductLinksComponent } from './components/my-product-links/my-product-links.component';
import { MyOrdersComponent } from './components/my-orders/my-orders.component';
import { ManageCustomersComponent } from './components/manage-customers/manage-customers.component';
import { ShopProductCategoryComponent } from './components/shop-product-category/shop-product-category.component';
import { ShopProductCategoryService } from './services/shop-product-category.service';
import { MyShopProductCategoriesComponent } from './components/my-shop-product-categories/my-shop-product-categories.component';
import { ShopProductCategoryFormComponent } from './components/shop-product-category-form/shop-product-category-form.component';
import { CollectionComponent } from './components/collection/collection.component';
import { PaginationComponent } from './components/pagination/pagination.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    ProductsComponent,
    ProductDetailComponent,
    ProductAddComponent,
    ProductEditComponent,
    ProductFormComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    ProductBaseComponent,
    ListComponentComponent,
    CartComponent,
    CheckoutComponent,
    ProgressbarComponent,
    CartitemComponent,
    CartItemsComponent,
    ShippingComponent,
    CartTotalComponent,
    SlugifyPipe,
    ConfirmComponent,
    AddressComponent,
    ConfirmationComponent,
    OrderlineComponent,
    MyProfileComponent,
    OrdersComponent,
    OrderItemComponent,
    OrderDetailComponent,
    OrderlineDetailComponent,
    ProductEditPhotoComponent,
    ProductBundleComponent,
    ProductBundleAddComponent,
    ProductBundlesComponent,
    ProductBundleEditComponent,
    ProductBundleFormComponent,
    ProductBundleDetailComponent,
    BreadcrumbComponent,
    RegisterComponent,
    UserGroupComponent,
    UserGroupInviteComponent,
    UserGroupAddInviteComponent,
    NotFoundComponent,
    PrivateGroupAcceptedComponent,
    UserPrivateGroupsComponent,
    RepeatServiceComponent,
    RepeatServiceConfirmationEmailComponent,
    ProductRowComponent,
    ProductRowHomepageComponent,
    CustomerOrderCreatorComponent,
    CustomerOrderCreatorCustomerDataComponent,
    CustomerOrderCreatorOrderDataComponent,
    ProductitemComponent,
    ProductItemsComponent,
    CustomerOrderCreatorAddressDataComponent,
    CustomerOrderCreatorSendComponent,
    CustomerOrderCreatorSentComponent,
    FormValidatorComponent,
    NewsletterComponent,
    CreateNewGroupInvitationComponent,
    ProfileAddressSettingsComponent,
    ProfileShopSettingsComponent,
    ProfileNewsletterSettingsComponent,
    ReferAFriendComponent,
    ProfileReferralSettingsComponent,
    ReferrerComponent,
    DiscountCodeComponent,
    MyProductsComponent,
    NewsletterCreatorComponent,
    NewsletterCreatorCreateNewsletterComponent,
    NewsletterCreatorSelectProductComponent,
    NewsletterCreatorNewsletterPreviewComponent,
    NewsletterCreatorNewsletterSentComponent,
    ProductSubscriptionModalComponent,
    MyPreferencesComponent,
    MyInvitationComponent,
    MyProductLinksComponent,
    MyOrdersComponent,
    ManageCustomersComponent,
    ShopProductCategoryComponent,
    MyShopProductCategoriesComponent,
    ShopProductCategoryFormComponent,
    CollectionComponent,
    PaginationComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      return initializeFirestore(getApp(), {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        })
      })
    }),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    AppConfigModule
  ],
  providers: [
    AuthGuardService,
    AdminGuardService,
    ProductGuardService,
    ShoppingAuthGuardService,
    ShopOwnerGuardService,
    AuthService,
    ProductService,
    RepeatEmailService,
    UserService,
    OrderService,
    CategoryService,
    PrivateGroupService,
    CustomerService,
    FormValidationService,
    NewsletterService,
    RedirectService,
    ReferralService,
    ReferralAuthGuardService,
    DiscountCodeService,
    ImageResizerService,
    SlugifyPipe,
    DatePipe,
    ShopProductCategoryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
