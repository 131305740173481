import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'app/models/user';
import { UploadService } from 'app/services/upload.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UploadedFile } from 'app/models/uploaded_file';

@Component({
  selector: 'my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  public userForm: UntypedFormGroup;
  public userId: string;
  public uploaderDisplayMode: string = "single";
  public profilePhoto: string;
  private _sxUser: User;
  private fileUploadSubscription: Subscription;
  private selectedItem: string = "list-profile-list";
  private urlFragment: string = '';

  public get hasProfilePhoto() {
    return this.profilePhoto != undefined || this.profilePhoto != "";
  }

  constructor(public userService: UserService,
    private uploadService: UploadService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute) {
    this.route.fragment.subscribe(f => {
      this.urlFragment = f;
      this.navigateToUrlFragment();
    });

    this._sxUser = this.userService.sxUser;
    this.userId = this._sxUser.id;
    this.profilePhoto = this._sxUser.photo;
    this.selectedItem = "list-profile-list";
    this.fillUserDataForm();


    this.fileUploadSubscription = this.uploadService.uploadedFile$.subscribe((file: UploadedFile) => {
      this.userService.sxUser.photo = file.downloadURL;
      this.profilePhoto = file.downloadURL;
      this.userService.updateSxUser(this.userService.sxUser);
    });
  }

  ngAfterViewInit(): void {
    this.navigateToUrlFragment();
  }

  navigateToUrlFragment() {
    if (this.urlFragment != '') {
      const element = document.getElementById(this.urlFragment + '-tab');
      if (element) {
        element.click();
      }
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.fileUploadSubscription.unsubscribe();
  }

  public uploadProfilePhoto(): void {

  }

  public onSubmitUserForm(userForm: UntypedFormGroup) {

    if (userForm.valid) {
      this._sxUser.firstname = userForm.value.firstname;
      this._sxUser.lastname = userForm.value.lastname;
      this._sxUser.email = userForm.value.email;
      this._sxUser.displayName = this._sxUser.firstname + ' ' + this._sxUser.lastname;
      this._sxUser.phoneNumber = userForm.value.phoneNumber;

      this.userService.updateSxUser(this._sxUser);
    }
  }


  private fillUserDataForm(): void {
    this.userForm = this.formBuilder.group({
      firstname: this._sxUser.firstname,
      lastname: this._sxUser.lastname,
      email: this._sxUser.email,
      phoneNumber: this._sxUser.phoneNumber
    });
  }

  public changeSelectedItem(): void {

  }
}

class DropdownMenu {
  dropdownMenu: HTMLSelectElement;
  options: HTMLOptionsCollection;

  constructor(dropdown: HTMLSelectElement) {
    this.dropdownMenu = dropdown;
    this.options = dropdown.options;
  }

  OnChange() {
    document.location.href = this.options[this.options.selectedIndex].value;
  }

}


window.onload = () => {
  // let select1: HTMLSelectElement = <HTMLSelectElement>document.getElementById("Select1");
  // let ddm1: DropdownMenu = new DropdownMenu(select1);
  // select1.onchange = () => {
  //     ddm1.OnChange();
  // }
}