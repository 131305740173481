import { Product } from './product';
import { Photo } from './photo';
import { ProductSubscriptionCartInfo } from './product_subscription_cart_info';

export class CartItem {

  product: Product;
  photos: Photo[];
  quantity: number;
  productSubscription: ProductSubscriptionCartInfo;

  constructor(product: Product, quantity: number, subscription: ProductSubscriptionCartInfo = undefined) {
    this.product = product;
    this.photos = product.photos;
    this.quantity = quantity;
    this.productSubscription = subscription;
  }

  get photo(): string {
    let photo = '';

    if (this.photos.length > 0) {
      let photoObj = this.photos.filter(x => x.fileSize === 'small')[0];
      if (photoObj == null) {
        photoObj = this.photos.filter(x => x.fileSize == null || x.fileSize === undefined)[0];
      }
      photo = photoObj.url;
    }
    return photo;
  }

  get totalPrice(): number {
    const productPrice = this.productSubscription ? this.product.subscriptionPrice : this.product.price;
    var totalPrice = this.quantity * Number.parseFloat(productPrice);
    return totalPrice;
  }

  get hasPickupAddress(): boolean {
    return this.product.user.hasPickupAddress;
  }
}
