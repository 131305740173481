import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'app/models/product';
import { User } from 'app/models/user';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'product-row-homepage',
  templateUrl: './product-row-homepage.component.html',
  styleUrls: ['./product-row-homepage.component.scss']
})
export class ProductRowHomepageComponent implements OnInit {
  @Input() product: Product;
  public userSlug: string;

  constructor(public userService: UserService) { }

  ngOnInit() {
    if (this.hasProduct) {
      let user: User = this.userService.sxUser;
      this.userSlug = user && user.slug || '';
    }
  }

  public get hasProduct(): boolean {
    return this.product != null;
  }
}
