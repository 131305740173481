import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'app/models/product';
import { ProductService } from 'app/services/product.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'app/services/user.service';
import { ProgressBarItem } from 'app/models/progressbaritem';
import { ProgressbarService } from 'app/services/progressbar.service';
import { UploadService } from 'app/services/upload.service';
import { ProductBundle } from 'app/models/product_bundle';
import { CategoryTreeNode } from 'app/models/category-tree-node';
import { CategoryService } from 'app/services/category.service';
import { Category } from 'app/models/category';
import { stringify } from 'querystring';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UploadedFile } from 'app/models/uploaded_file';
import { ImageSize } from 'app/models/image_size';
import { ShopProductCategoryService } from 'app/services/shop-product-category.service';
import { ShopProductCategory } from 'app/models/shop-product-category';
import { SlugifyPipe } from 'app/pipes/slugify.pipe';

@Component({
  selector: 'product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  public progressBarItems: Array<ProgressBarItem>;
  public hasBundles: boolean;
  public bundles: ProductBundle[];
  public imageSizes: ImageSize[];
  public showCreateMainProductCategoryForm: boolean = false;

  @Input() model: Product;
  @Input() mode: string;
  @Input() displayMode: string;
  public product: Product;
  @Input() activeStep: number;
  modeText: string;
  public productForm: UntypedFormGroup;
  public showOnWebsite: boolean = false;
  public shopProductCategories: ShopProductCategory[] = [];
  private fileUploadSubscription: Subscription;

  constructor(
    public productService: ProductService,
    public userService: UserService,
    private progressBarService: ProgressbarService,
    private router: Router,
    private uploadService: UploadService,
    private categoryService: CategoryService,
    private formBuilder: UntypedFormBuilder,
    private shopProductCategoryService: ShopProductCategoryService
  ) {
    
    this.imageSizes = new Array<ImageSize>();
    this.imageSizes.push(new ImageSize(120, 90, 'thumbnail'));
    this.imageSizes.push(new ImageSize(265, 265, 'small'));
    this.imageSizes.push(new ImageSize(570, 570, 'medium'));

    let myself = this;

    this.fileUploadSubscription = this.uploadService.uploadedFile$.subscribe((file: UploadedFile) => {
      if (!(file == null || file === undefined)) {
        myself.productService.addPhotoToProduct(file.uid, myself.userService.sxUser.id, file.downloadURL, file.filename, file.height, file.width, file.size, file.filenameWithoutExtension);
      }
    });

    this.productService.getProductBundles(this.userService.sxUser.id).then(productBundles => {
      this.bundles = productBundles;
      this.hasBundles = this.bundles.length > 0;
    });
  }


  ngOnInit() {
    this.modeText = this.mode == 'add' ? 'Product toevoegen' : 'Product bewerken';

    this.shopProductCategoryService.getCategories(this.userService.sxUser.id).then(shopCategories => {
      this.shopProductCategories = shopCategories;
    })

    this.shopProductCategoryService.shopProductCategoryState.subscribe((shopProductCategory: ShopProductCategory) => {
      this.shopProductCategoryService.getCategories(this.userService.sxUser.id).then(categories => {
        this.shopProductCategories = categories;
        this.productForm.get('shopProductCategoryId').setValue(shopProductCategory.id);
        this.toggleCreateMainProductCategory();
      })
    });

  }

  ngOnChanges() {
    if (this.model != null) {
      let hasPrivateShop: boolean = this.userService.sxUser.hasPrivateShop;
      this.showOnWebsite = this.model.private !== undefined ? !this.model.private : !hasPrivateShop;
      this.productForm = this.formBuilder.group({
        title: this.model.title,
        description: this.model.description,
        priceFrom: this.model.priceFrom,
        price: this.model.price,
        stock: this.model.stock,
        bundle_id: this.model.bundle_id != null ? this.model.bundle_id : '',
        shopProductCategoryId: this.model.shopProductCategoryId != null ? this.model.shopProductCategoryId : '',
        shopProductCategoryTitle: '',
        shopProductCategorySequence: 1,
        showOnWebsite: this.showOnWebsite,
        subscription: this.model.subscription,
        subscriptionPrice: this.model.subscriptionPrice
      });

      const productUrl: string = this.userService.sxUser.slug + '/products/' + this.model.slug + '/edit';
      const productPhotosUrl: string = this.userService.sxUser.slug + '/products/' + this.model.slug + '/edit-photos';
      this.progressBarItems = this.progressBarService.getAddProductProgressBar(productUrl, productPhotosUrl, this.activeStep);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.fileUploadSubscription.unsubscribe();
  }

  submitted = false;
  onSubmit() { this.submitted = true; }

  loadSubcategories(id: number) {

  }

  toggleSubscription(event: any) {
    const subscriptionValue: boolean = event.checked;
    this.model.subscription = subscriptionValue;
    this.productForm.get('subscription').setValue(subscriptionValue);
  }

  selectShopProductCategory() {
    let shopProductCategoryId: string = this.productForm.get('shopProductCategoryId').value;
    this.model.shopProductCategoryId = shopProductCategoryId;
  }
 
  addProduct(pForm) {
    if (this.activeStep == 1) {
      const originalBundle: string = this.model.bundle_id;

      this.model.title = pForm.title;
      this.model.description = pForm.description;
      this.model.price = pForm.price;
      this.model.priceFrom = pForm.priceFrom;
      this.model.stock = pForm.stock;
      this.model.bundle_id = pForm.bundle_id;

      this.model.subscription = pForm.subscription;
      this.model.subscriptionPrice = pForm.subscriptionPrice;

      if (!this.model.subscription)
        this.model.subscriptionPrice = null;

      this.model.slug = this.model.productSlug;
      this.model.user_id = this.userService.user.uid;
      this.model.private = !pForm.showOnWebsite;

      this.model.shopProductCategoryId = pForm.shopProductCategoryId;

      const editProduct = this.mode != 'add';

      if (editProduct) {
        this.productService.updateProduct(this.model).then(() => {  
          if (originalBundle && originalBundle.length) {
            this.productService.addProductToBundle(this.model.id, this.model.bundle_id).then(result => {
              if (!result) {
                 this.model.bundle_id = null;
                 this.productService.updateProduct(this.model).then(() => {
                  this.router.navigate([this.userService.sxUser.slug, 'products', this.model.slug, 'edit-photos']);
                  this.activeStep = 2;
                 });
              }
              else {
                this.router.navigate([this.userService.sxUser.slug, 'products', this.model.slug, 'edit-photos']);
                this.activeStep = 2;
              }
            })
          }
          else {
            if (originalBundle && originalBundle.length) {
              this.productService.removeProductFromBundle(this.model.id, originalBundle).then(() => {
                this.router.navigate([this.userService.sxUser.slug, 'products', this.model.slug, 'edit-photos']);
                this.activeStep = 2;
              })  
            }
            else {
              this.router.navigate([this.userService.sxUser.slug, 'products', this.model.slug, 'edit-photos']);
              this.activeStep = 2;
            }
          }
        })
      }
      else {
        this.productService.addProduct(this.model)
        .then(productId => {

          if (originalBundle && originalBundle.length) {
            this.productService.addProductToBundle(productId, this.model.bundle_id).then(result => {
              this.model.id = productId;
              if (!result) {
                 this.model.bundle_id = null;
                 this.productService.updateProduct(this.model).then(() => {
                  this.router.navigate([this.userService.sxUser.slug, 'products', this.model.slug, 'edit-photos']);
                  this.activeStep = 2;
                });
              }
              else {
                this.router.navigate([this.userService.sxUser.slug, 'products', this.model.slug, 'edit-photos']);
                this.activeStep = 2;
              }
            })
          }
          else {
            this.router.navigate([this.userService.sxUser.slug, 'products', this.model.slug, 'edit-photos']);
            this.activeStep = 2;
          }

        });
      }      
    }
    else {
      this.router.navigate([this.userService.sxUser.slug, 'products']);
    }
  }

  createMainProductCategory() {
    this.showCreateMainProductCategoryForm = true;
  }

  toggleCreateMainProductCategory() {
    this.showCreateMainProductCategoryForm = !this.showCreateMainProductCategoryForm;
  }
}
