import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrivateGroupInvitation } from 'app/models/private_group_invitation';
import { PrivateGroupMember } from 'app/models/private_group_member';
import { User } from 'app/models/user';
import { OrdermailerService } from 'app/services/ordermailer.service';
import { PrivateGroupService } from 'app/services/private-group.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-user-group-invite',
  templateUrl: './user-group-invite.component.html',
  styleUrls: ['./user-group-invite.component.scss']
})
export class UserGroupInviteComponent implements OnInit {
  public showLogin: boolean = false;
  public showRegistration: boolean = false;
  public createNewInvite: boolean = false;
  public acceptInvite: boolean = false;
  public model: PrivateGroupInvitation;
  public sxUser: User;
  public isMemberAlready: boolean = false;
  private shopUserSlug: string = '';

  constructor(public route: ActivatedRoute,
              private privateGroupService: PrivateGroupService,
              private userService: UserService, 
              private router: Router, 
              private orderMailerService: OrdermailerService) { 
    this.route.params.subscribe(async params => await this.loadInvite(params.userslug, params.id));
  }

  ngOnInit() {
  }

  async loadInvite(userslug: string = '', id: string = '') {
    if (id != '') {
      this.acceptInvite = true;
      this.showLogin = false;
      this.showRegistration = true;
      this.sxUser = this.userService.sxUser;
      // load invitation here and fill the form fields
      const invitation = await this.privateGroupService.getInvitationByIdAndSlug(id, userslug);
      this.model = invitation;
      this.privateGroupService.sendPrivateGroupInviteNotification(invitation);

      this.shopUserSlug = invitation.shop_user_slug;
      if (this.sxUser != null && invitation.sx_user_id == this.sxUser.id) {
        this.isMemberAlready = await this.privateGroupService.userIsMemberOfPrivateGroup(invitation.shop_user_id, this.sxUser.id);
      }

      if (this.sxUser != null && invitation.sx_user_id == this.sxUser.id && !invitation.accepted && !this.isMemberAlready) {
        // accept invite for existing SX user
        invitation.accepted = true;
        invitation.join_date = new Date();
        await this.privateGroupService.updateInvitation(invitation);

        let privateGroupMember: PrivateGroupMember = new PrivateGroupMember();
        privateGroupMember.invitation_date = invitation.send_date;
        privateGroupMember.join_date = invitation.join_date;
        privateGroupMember.shop_user_id = invitation.shop_user_id;
        privateGroupMember.shop_user_slug = invitation.shop_user_slug;

        const shopOwnerUser = await this.userService.getUserById(invitation.shop_user_id);

        if (shopOwnerUser.privateGroupMembers == null || shopOwnerUser.privateGroupMembers === undefined) {
          shopOwnerUser.privateGroupMembers = [];
        }

        const pgmId = await this.privateGroupService.createPrivateGroupMember(privateGroupMember);
        privateGroupMember.id = pgmId;
        privateGroupMember.shop_user_fullname = shopOwnerUser.displayName;
        privateGroupMember.user_fullname = this.sxUser.displayName;
        privateGroupMember.user_id = this.sxUser.id;
        this.privateGroupService.updatePrivateGroupMember(privateGroupMember);

          if (this.sxUser.privateGroupMemberships === undefined || this.sxUser.privateGroupMemberships == null) {
          this.sxUser.privateGroupMemberships = [];
        }

        if (shopOwnerUser.privateGroupMembers.indexOf(this.sxUser.id) == -1) {
          shopOwnerUser.privateGroupMembers.push(this.sxUser.id);
        }

        if (this.sxUser.privateGroupMemberships.indexOf(shopOwnerUser.id) == -1) {
          this.sxUser.privateGroupMemberships.push(shopOwnerUser.id);
        }
        
        await this.userService.updateUser(shopOwnerUser);
        const user2 = await this.userService.updateUser(this.sxUser);
        await this.userService.updateSxUser(user2); 

        this.userService.updateSxUser(user2); 
        this.orderMailerService.sendPrivateGroupInvitationAcceptedToShopUser(shopOwnerUser, user2);                 
        this.router.navigate(['/', invitation.shop_user_slug, 'private-group-accepted', id]);
      }
    }
    else {
      this.acceptInvite = false;
      this.createNewInvite = true;
    }
  }

}
