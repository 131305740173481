import { Component } from '@angular/core';
import { User } from 'app/models/user';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-my-invitation',
  templateUrl: './my-invitation.component.html',
  styleUrls: ['./my-invitation.component.scss']
})
export class MyInvitationComponent {
  sxUser: User;
  constructor(public userService: UserService) {
    this.sxUser = this.userService.sxUser;
  }

}
