<!--
<ul class="cart-items">
  <li class="basket-item" *ngFor="let bundle of bundles">
    <product-bundle [model]="bundle"></product-bundle>
  </li>
</ul> -->

<div id="bundles" class="container" *ngIf="bundles">
  <div class="row">
    <div class="item col-sm-3">
      <h3>Productfamilies</h3>
    </div>
    <div class="item col-sm-9">
      <a [routerLink]="['/', userSlug, 'bundles-add']" class="btn btn-primary">Productfamilie toevoegen</a>
    </div>
  </div>
  <div class="item col-sm-12">
    <div class="row">
      <div class="item col-sm-9">
        Productfamilie
      </div>
      <div class="item col-sm-3">

      </div>
    </div>
  </div>
  <div class="item col-sm-12" *ngFor="let bundle of bundles">
    <div class="row">
      <div class="item col-sm-9">
        {{bundle.name}}
      </div>
      <div class="item col-sm-3">
        <div class="col-xs-12 col-md-6" *ngIf="userService.isProductBundleCreator(bundle)">
          <a [routerLink]="['/', userSlug, 'bundles', bundle.slug]">Bekijk productfamilie</a><br />
          <a [routerLink]="['/', userSlug, 'bundles', bundle.slug, 'edit']">Bewerken</a><br />
          <a (click)="deleteProductBundle(bundle)" href="javascript:void(0)">Verwijder</a>
        </div>
      </div>
    </div>
  </div>
</div>