import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'app/services/product.service';
import { ProductBaseComponent } from 'app/components/product-base/product-base.component';
import { CartService } from 'app/services/cart.service';
import { UserService } from 'app/services/user.service';
import { Product } from 'app/models/product';
import { ProductSubscriptionCartInfo } from 'app/models/product_subscription_cart_info';


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent extends ProductBaseComponent {
  productSubscription: ProductSubscriptionCartInfo = null;

  constructor(public route: ActivatedRoute,
    public productService: ProductService,
    public userService: UserService,
    public router: Router,
    private cartService: CartService) {
    super(route, productService, userService);
  }

  ngOnInit() {
  }

  addToCart() {
    const cartProduct = this.product;
    cartProduct.photos = this.getPhotoInSpecificSize('thumbnail');
    this.cartService.addToCart(cartProduct, this.productSubscription);
    this.router.navigate(['/cart']);
  }

  isPrivateShopAndNoMember(): boolean {
    const canProceed = this.product.user?.privateShop ? !this.canOrderProduct() && !this.product.user?.isPrivateGroupMember(this.userService.sxUser?.id) : false;
    return canProceed;
  }

  isPrivateShopOwnerProduct(): boolean {
    return this.product.private;
  }

  canOrderProduct(): boolean {
    return this.userService.canOrderProduct(this.product, this.cartService);
  }

  hasStock(): boolean {
    return this.userService.productHasStock(this.product, this.cartService);
  }

  orderAtPrivateShop(): void {
    if (this.isPrivateShopOwnerProduct()) {
      this.router.navigate([this.product.user.slug, 'products', this.product.slug, 'login']);
    }
  }

  editProduct(product: Product) {
    this.router.navigate(['/products/' + product.slug + '/edit']);
  }

  deleteProduct(product: Product) {
    if (confirm("Weet je zeker dat je " + product.title + " wilt verwijderen?")) {
      this.productService.deleteProduct(product.id);
    }
  }

  redirectToGroupPage(product: Product) {
    this.router.navigate(['/', product.user.slug, 'group']);
  }

  toggleSubscription(event: any) {
    if (event.checked && this.productSubscription == null) {
      this.productSubscription = new ProductSubscriptionCartInfo();
    }
    
    this.productSubscription.subscription = event.checked;
  }

  selectFrequency(frequency: any) {
    this.productSubscription.frequency = frequency;
    this.productSubscription.frequencySelected = frequency != '';
  }
}
