import { Component, Input, OnInit } from '@angular/core';
import { Cart } from 'app/models/cart';
import { CartState } from 'app/models/cart-state';
import { Order } from 'app/models/order';
import { CustomerService } from 'app/services/customer.service';

@Component({
  selector: 'product-items',
  templateUrl: './product-items.component.html',
  styleUrls: ['./product-items.component.scss']
})
export class ProductItemsComponent implements OnInit {
  @Input() cart: Cart;
  @Input() order: Order;

  @Input() edit: Boolean;

  constructor(private customerService: CustomerService) { }

  ngOnInit() {
    this.customerService.cartState.subscribe((state: CartState) => {
      this.cart.cartItems = state.items;
    });

  }

}
