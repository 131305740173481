<h3>Stap 2. Order gegevens</h3>

<div class="container">
  <div class="row">
    <div class="col-12" *ngIf="customer">
      <div>
        Klant: {{customer.displayName}} ({{customer.email}})
      </div>
      <h5>Voeg mijn producten toe</h5>
      <form [formGroup]="productForm" (ngSubmit)="addNewProduct(productForm)">
        <div class="row">
          <div class="col-12">
            <label for="product">Product:</label>
            <select name="product" formControlName="product" *ngIf="products != null">
              <option value="">Selecteer een product</option>
              <option value="{{product.id}}" *ngFor="let product of products">{{product.title}}</option>
            </select>
          </div>
          <div class="col-12">
            <label for="quantity">Aantal:</label>
            <input type="number" formControlName="quantity" min="1" name="quantity" value="1" />
          </div>
          <div class="col-12">
            <button type="submit" class="btn btn-success" [disabled]="!selectedProduct">Toevoegen</button>
          </div>
        </div>
      </form>
      <product-items [cart]="cart" edit="true"></product-items>
      <cart-total [cart]="cart"></cart-total>
    </div>
    <div class="col-12">
      <button type="submit" class="btn btn-success" (click)="gotoAddressScreen()" [disabled]="!hasProductsInCart">Volgende stap</button>
    </div>
  </div>
</div>