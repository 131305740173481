<link href="https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.6.1/css/bootstrap4-toggle.min.css"
  rel="stylesheet">
<script src="https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.6.1/js/bootstrap4-toggle.min.js"></script>

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <section>
        <!-- Tab panes -->
        <div class="tab-content">
          <section class="tab-pane container fade in show active" id="orders">
            <h5 class="profile-header tab-header">Bestellingen</h5>
            <orders></orders>
          </section>
        </div>
      </section>
    </div>
  </div>
</div>