import { Injectable } from '@angular/core';
import { Storage, deleteObject, ref } from '@angular/fire/storage';
import { collection, CollectionReference, doc, Firestore, collectionGroup, deleteDoc, getDocs, getDoc, query, where, limit, orderBy, addDoc, setDoc, getCountFromServer, startAfter, Query, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { converter } from 'app/models/converter';
import { Product } from 'app/models/product';
import { UserService } from './user.service';
import { Photo } from 'app/models/photo';
import { ServiceBase } from './service-base';
import { User } from 'app/models/user';
import { ProductBundle } from 'app/models/product_bundle';
import { readDocs } from 'app/utils/data-utils';

// Define the return type for getProducts
export interface GetProductsResponse {
  products: Product[];
  lastVisible: QueryDocumentSnapshot<Product> | null;
}

@Injectable()
export class ProductService extends ServiceBase {
  item: Array<Product> = [];

  private products: CollectionReference<Product>
  private bundles: CollectionReference<ProductBundle>

  constructor(
    private db: Firestore,
    private userService: UserService,
    private storage: Storage
  ) {
    super();

    this.products = collection(db, 'products').withConverter(converter<Product>())
    this.bundles = collection(db, 'product_bundles').withConverter(converter<ProductBundle>())
  }

  async createNewProduct(): Promise<Product> {
    const ref = doc(this.products)
    const product = new Product(ref.id, this.userService.user.uid);

    await setDoc(ref, product)
    return product
  }

  async cleanupWonkyProducts(): Promise<void> {
    return getDocs(this.products).then(snap => {
      Promise.all(snap.docs.filter(doc => doc.data().title)
        .map(doc => this.deleteProduct(doc.id)))
    })
  }

  async addProduct(product: Product): Promise<string> {
    product.slug = product.productSlug;
    product.price = product.price.replace(',', '.');

    return addDoc(this.products, product).then(ref => {
      product.id = ref.id
      return ref.id
    })
  }

  async updateProduct(product: Product): Promise<void> {
    const ref = doc(this.products, product.id)
    product.user = Object.assign({}, product.user)

    return setDoc(ref, product)
  }

  async addPhotoToProduct(
    productId: string,
    userId: string,
    downloadURL: string,
    filename: string,
    height: number = null,
    width: number = null,
    fileSize: string = '',
    filenameWithoutExtension: string = ''
  ): Promise<void> {
    const product = await this.getProductById(productId)

    if (product.photos === undefined) {
      product.photos = new Array();
    }

    product.photos.push({
      url: downloadURL,
      product_id: productId,
      user_id: userId,
      filename: filename,
      filenameWithoutExtension: filenameWithoutExtension,
      height: height,
      width: width,
      fileSize: fileSize
    } as Photo);

    if (product.photo_url === undefined || product.photo_url === '') {
      product.photo_url = downloadURL;
    }

    return this.updateProduct(product)
  }

  async getProducts(user: User, itemsPerPage: number, lastVisibleDoc: any): Promise<GetProductsResponse> {
    let q: Query<unknown>;

    if (lastVisibleDoc) {
      q = query(this.products,
        where('user_id', '==', user.id),
        orderBy('title'),
        limit(itemsPerPage),          
        startAfter(lastVisibleDoc))
    }
    else {
     q = query(this.products,
        where('user_id', '==', user.id),
        orderBy('title'),
        limit(itemsPerPage))  
    }

      // Fetch the documents
      const productSnapshot = await getDocs(q);
  
      // Map the products and store the last document
      const products: Product[] = productSnapshot.docs.map(doc => doc.data() as Product);
      const lastVisible = productSnapshot.docs.length > 0 ? productSnapshot.docs[productSnapshot.docs.length - 1] as QueryDocumentSnapshot<Product> : null;
  
      return { products, lastVisible };  // Return both products and the last document reference
  }

  async getProductsByShopProductCategoryId(user: User, shopProductCategoryId: string, itemsPerPage: number, lastVisibleDoc: any, showShelf: boolean = false): Promise<GetProductsResponse> {
      let q: Query<unknown>;
    
      // If lastVisibleDoc is provided, use it for pagination
      if (lastVisibleDoc) {
        q = query(this.products,
          where('user_id', '==', user.id),
          where('shopProductCategoryId', '==', shopProductCategoryId),
          orderBy('title'),
          limit(itemsPerPage),          
          startAfter(lastVisibleDoc));
      } else {
        q = query(this.products,
          where('user_id', '==', user.id),
          where('shopProductCategoryId', '==', shopProductCategoryId),
          orderBy('title'),
          limit(showShelf ? 4 : itemsPerPage));
      }
  
      // Fetch the documents
      const productSnapshot = await getDocs(q);
  
      // Map the products and store the last document
      const products: Product[] = productSnapshot.docs.map(doc => doc.data() as Product);
      const lastVisible = productSnapshot.docs.length > 0 ? productSnapshot.docs[productSnapshot.docs.length - 1] as QueryDocumentSnapshot<Product> : null;
  
      return { products, lastVisible };  // Return both products and the last document reference
  }

  async getProductCategoryCount(userId: string, shopProductCategoryId: string): Promise<number> {
    const q = query(this.products,
      where('user_id', '==', userId),
      where('shopProductCategoryId', '==', shopProductCategoryId))

      const snapshot = await getCountFromServer(q);
      return snapshot.data().count;
  }

  async getAllProducts(itemsPerPage: number, lastVisibleDoc: any): Promise<GetProductsResponse> {
    let q;
    if (lastVisibleDoc) {
      q = query(this.products,
          where('private', '==', false),
          orderBy('title'),
          limit(itemsPerPage),
          startAfter(lastVisibleDoc)).withConverter(converter<Product>())  
    }
    else {
      q = query(this.products,
          where('private', '==', false),
          orderBy('title'),
          limit(itemsPerPage)).withConverter(converter<Product>())  
    }

      // Fetch the documents
      const productSnapshot = await getDocs(q);
  
      // Map the products and store the last document
      const products: Product[] = productSnapshot.docs.map(doc => doc.data() as Product);
      const lastVisible = productSnapshot.docs.length > 0 ? productSnapshot.docs[productSnapshot.docs.length - 1] as QueryDocumentSnapshot<Product> : null;
  
      return { products, lastVisible };  // Return both products and the last document reference
  }

  async getPhotosForUser(user_id: string): Promise<Photo[]> {
    const q = query(collectionGroup(this.db, 'photos'),
      where('user_id', '==', user_id)).withConverter(converter<Photo>())

    return getDocs(q).then(readDocs)
  }

  async getProductBySlug(slug: string): Promise<Product> {
    const q = query(this.products,
      where('slug', '==', slug),
      limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty) return Promise.reject('No product found')
      else {
        const product = Product.constructWithId(snap.docs[0].id, snap.docs[0].data())
        return this.userService.getUserById(product.user_id).then(productUser => {
          product.user = User.constructWithId(productUser.id, productUser);
          return product
        })
      }
    })
  }

  async getProductByUserIdAndSlug(user_id: string, slug: string): Promise<Product> {
    const q = query(this.products,
      where('slug', '==', slug),
      where('user_id', '==', user_id),
      limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty) Promise.reject('getProductBySlug: No product found - ' + user_id + ' - ' + slug)
      else {
        const product = Product.constructWithId(snap.docs[0].id, snap.docs[0].data())
        return this.userService.getUserById(product.user_id).then(productUser => {
          product.user = User.constructWithId(productUser.id, productUser);
          return product
        })
      }
    })
  }

  async getProductById(id: string): Promise<Product> {
    const ref = doc(this.products, id)

    return getDoc(ref).then(ref => ref.data())
  }

  async getPhotos(user_id: string): Promise<Photo[]> {
    return await this.getPhotosForUser(user_id);
  }

  async deletePhotoFromProduct(product_id: string, photo_filename: string): Promise<Product> {
    if (confirm('Are you sure you want to delete this photo?')) {
      const product = await this.getProductById(product_id)
      const photoIndex: number = product.photos.findIndex(p => p.id == photo_filename);

      if (photoIndex != -1) {
        product.photos.splice(photoIndex, 1);

        let allPhotoSizes: Photo[] = product.photos.filter(x => x.filename !== undefined && x.filename == photo_filename);

        if (allPhotoSizes.length > 0) {
          allPhotoSizes.forEach(async photoSize => {
            let photoSizeIndex = product.photos.findIndex(x => x.fileSize == photoSize.fileSize && x.filename == photo_filename);
            if (photoSizeIndex != -1) {
              product.photos.splice(photoSizeIndex, 1);
            }
            photoSizeIndex = -1;
            await deleteObject(ref(this.storage, photoSize.url));
          })
        }

        if (product.photos.length == 0) {
          product.photo_url = '';
        }
        return this.updateProduct(product).then(_ref => product)
      } else return Promise.resolve(product)
    }
    else return Promise.reject()
  }

  async deleteProduct(product_id: string): Promise<void> {
    const ref = doc(this.products, product_id)

    return deleteDoc(ref)
  }

  async addProductBundle(productBundle: ProductBundle): Promise<string> {
    // return addDoc(this.bundles, productBundle).then(ref => ref.id)
    return addDoc(this.bundles, productBundle).then(ref => {
      return ref.id
    })
  }

  async updateProductBundle(productBundle: ProductBundle): Promise<void> {
    const ref = doc(this.bundles, productBundle.id);
    return setDoc(ref, productBundle);
  }

  async getProductBundles(user_id: string): Promise<ProductBundle[]> {
    const q = query(this.bundles, where('user_id', '==', user_id))

    return getDocs(q).then(snap => snap.docs.map(doc => doc.data()))
  }

  async getProductBundle(slug: string): Promise<ProductBundle> {
    const q = query(this.bundles,
      where('slug', '==', slug),
      limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty) Promise.reject('No product bundle found');
      else return ProductBundle.constructWithId(snap.docs[0].id, snap.docs[0].data())
    })
  }

  async getProductBundleById(id: string): Promise<ProductBundle> {
    const ref = doc(this.bundles, id);

    return getDoc(ref).then(ref => ref.data());
    // return getDoc(ref).then(ref => ProductBundle.constructWithId(ref.id, ref.data()))
  }

  addProductToBundle(product_id: string, bundle_id: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {

      this.getProductBundleById(bundle_id).then(productBundle => {
        if (productBundle === undefined) {
          resolve(false);
          return;
        }
        
        if (productBundle.products === undefined) {
          productBundle.products = new Array();
        }

        let bundleIndex = productBundle.products.findIndex(p => p == product_id);
        let addedToBundle: boolean = false;

        if (bundleIndex == -1 && productBundle.products.length < 3) {
          productBundle.products.push(product_id);
          addedToBundle = true;
        }
        else {
          addedToBundle = bundleIndex != -1 && productBundle.products.length <= 3;
        }

        this.updateProductBundle(productBundle).then(() => {
          resolve(addedToBundle);
        });
      });
    });
  }

  async removeProductFromBundle(product_id: string, bundle_id: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {

      if (bundle_id == null ||  bundle_id === undefined || bundle_id === '') {
        resolve(false);
        return;
      }
      
      this.getProductBundleById(bundle_id).then(productBundle => {
        if (productBundle === undefined) {
          resolve(false);
          return;
        }

        if (productBundle.products === undefined) {
          productBundle.products = new Array();
        }

        let bundleIndex = productBundle.products.findIndex(p => p == product_id);
        let removedFromBundle: boolean = false;

        if (bundleIndex != -1) {
          productBundle.products.splice(bundleIndex);
          removedFromBundle = true;
        }

        this.updateProductBundle(productBundle).then(() => {
          resolve(removedFromBundle);
        });
      });
    });
    
  }

  async getProductsForProductBundle(bundle: ProductBundle): Promise<Product[]> {
    let products = new Array();
    return new Promise<Product[]>((resolve) => {
      if (bundle.products === undefined || bundle.products.length == 0) {
        resolve(products);
      }

      let total = bundle.products.length;
      let counter = 0;
      bundle.products.forEach(product_id => {
        this.getProductById(product_id).then(product => {
          if (product !== undefined) {
            products.push(product);
          }

          counter++;

          if (counter == total) {
            resolve(products);
          }
        });
      });
    })
  }

  deleteProductBundle(id: string): Promise<void> {
    const ref = doc(collection(this.db, 'product_bundles'), id)

    return deleteDoc(ref)
  }

  updateStock(product: Product, stock: string) {
    product.stock = parseInt(stock);
    this.updateProduct(product);
  }

  async getProductsByShopCategory(shopProductCategoryId: string): Promise<Product[]> {
    const q = query(this.products,
      where('shopProductCategoryId', '==', shopProductCategoryId))

      return getDocs(q).then(snap => snap.docs.map(doc => {
        const product = doc.data()
        return product
      }))
  }
}
