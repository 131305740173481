import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NewsletterCreator } from 'app/models/newsletter-creator';
import { NewsletterService } from 'app/services/newsletter.service';

@Component({
  selector: 'newsletter-creator-create-newsletter',
  templateUrl: './newsletter-creator-create-newsletter.component.html',
  styleUrls: ['./newsletter-creator-create-newsletter.component.scss']
})
export class NewsletterCreatorCreateNewsletterComponent implements OnInit {
  public newsletterForm: FormGroup;
  public submitted: boolean = false;
  private newsletterCreator: NewsletterCreator;

  constructor(private formBuilder: FormBuilder,
              private newsletterService: NewsletterService) { }

  ngOnInit() {
    this.newsletterService.newsletterCreatorModelState.subscribe();
    this.newsletterCreator = this.newsletterService.newsletterCreatorModel;

    this.newsletterForm = this.formBuilder.group({
      subject: new FormControl(this.newsletterCreator.subject, [ Validators.required ]),
      preview: new FormControl(this.newsletterCreator.preview, [ Validators.required ]),
      title: new FormControl(this.newsletterCreator.title, [ Validators.required ]),
      text: new FormControl(this.newsletterCreator.text, [ Validators.required ])
    }, { updateOn: 'submit' });
  }

  get subject(){ return this.newsletterForm.get('subject') }
  get preview(){ return this.newsletterForm.get('preview') }
  get title(){ return this.newsletterForm.get('title') }
  get text(){ return this.newsletterForm.get('text') }

  createNewsletter(newsletterForm: FormGroup) {
    this.submitted = true;

    if (newsletterForm.valid) {
      
      if (this.newsletterCreator == null) {
        this.newsletterCreator = new NewsletterCreator();
      }

      this.newsletterCreator.subject = newsletterForm.value.subject;
      this.newsletterCreator.preview = newsletterForm.value.preview;
      this.newsletterCreator.title = newsletterForm.value.title;
      this.newsletterCreator.text = newsletterForm.value.text;

      this.newsletterService.setNewsletterCreatorModel(this.newsletterCreator);
      this.newsletterService.setNewsletterCreatorStep('select-product');
    }
  }

}
