import { Serializeable } from 'app/models/serializeable';

export class Category extends Serializeable {
  static instanceType = Category;

  id: number;
  subid: number;
  name: string;

  constructor(id: number = null, subid: number = null, name: string = null) {
    super();

    if (this.id !== null) {
        this.id = id;
    }

    if (this.subid !== null) {
        this.subid = subid;
    }

    if (this.name !== null) {
        this.name = name;
    }
}

}