import { Serializeable } from './serializeable';

export class PrivateGroupMember extends Serializeable {
    static instanceType = PrivateGroupMember;

    id: string;
    shop_user_id: string;
    shop_user_slug: string;
    user_id: string;
    user_fullname: string;
    shop_user_fullname: string;
    
    join_date: Date;
    invitation_date: Date;
}