import { Inject, Injectable } from '@angular/core';
import { CollectionReference, Firestore, collection, query, where, getDocs, limit } from '@angular/fire/firestore';
import { Timestamp } from 'firebase/firestore'
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { ServiceBase } from './service-base';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { ReferralConfig } from 'app/models/referral/config/referral_config';
import { CartItem } from 'app/models/cartitem';
import { UserService } from './user.service';
import { User } from 'app/models/user';
import * as password from 'secure-random-password';
import { converter } from 'app/models/converter';

@Injectable()
export class DiscountCodeService extends ServiceBase {
  private referralConfig: ReferralConfig
  private referralInvitations: CollectionReference<ReferralInvitation>

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
              private db: Firestore,
              private userService: UserService) { 
    super();    
    this.referralConfig = config.referral;
    this.referralInvitations = collection(this.db, 'referral_invitations').withConverter(converter<ReferralInvitation>())
  }

  generateNewDiscountCode(): string {
    return password.randomPassword();
  }

  hasDiscountCode(): boolean {
    var discountCode = localStorage.getItem('discountCode');
    var hasNoDiscountCode = discountCode == null || discountCode === undefined;

    return !hasNoDiscountCode;
  }

  async getInvitationForDiscountCode(discountCode: string): Promise<ReferralInvitation> {
    const q = query(this.referralInvitations,
                    where('discount_code', '==', discountCode),
                    limit(1))
      
    return getDocs(q).then(snap => {
      if (snap.empty) return Promise.reject()
      else return ReferralInvitation.constructWithId(snap.docs[0].id, snap.docs[0].data())
    })
  }

  async getInvitationForPromotorDiscountCode(discountCode: string): Promise<ReferralInvitation> {
    const q = query(this.referralInvitations,
                    where('promotor_discount_code', '==', discountCode),
                    limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty) return Promise.reject('No referral invitation found')
        else {
          const referralInvitation = ReferralInvitation.constructWithId(snap.docs[0].id, snap.docs[0].data())
          return referralInvitation
        }
    })
  }

  isValid(
    invitation: ReferralInvitation,
    totalPrice: number,
    cartItems: CartItem[],
    discountDate: Timestamp,
    isProspect: boolean,
    isPromotor: boolean
  ): boolean {    
    if (invitation.usedByProspect && isProspect) {
      return false;
    }

    if (invitation.usedByPromotor && isPromotor) {
      return false;
    }

    if (totalPrice < invitation.minimum_order_price) {
      return false;
    }

    if (cartItems.length == 0) {
      return false;
    }

    let validProduct: boolean = false;

    cartItems.forEach(item => {
      if (item.product.user_id == invitation.shopowner_user_id) {
        validProduct = true;
      }
    });

    if (!validProduct) {
      return false;
    }

    if (this.calculateDateDiff(discountDate.toDate()) > this.referralConfig.discountValidInDays) {
      return false;
    }

    return true;
  }

  isValidDiscountCode(discountCode: string, shopOwnerUserId: string, totalPrice: number, cartItems: CartItem[], isPromotorDiscount: boolean, isProspectDiscount: boolean): Promise<boolean> { 
    return new Promise<boolean>(async (resolve) => {

    if (discountCode === undefined || discountCode == null) {
      resolve(false);
    }

    if (cartItems.length == 0) {
      resolve(false);
    }

    let validProduct: boolean = false;

    cartItems.forEach(item => {
      if (item.product.user_id == shopOwnerUserId) {
        validProduct = true;
      }
    });

    if (!validProduct) {
      resolve(false);
    }

    let referralInvitation: ReferralInvitation;
    if (isPromotorDiscount) {
      referralInvitation = await this.getInvitationForPromotorDiscountCode(discountCode);
    }

    if (isProspectDiscount) {
      referralInvitation = await this.getInvitationForDiscountCode(discountCode);

      var hasInvitation = !(referralInvitation === null ||
                            referralInvitation === undefined);

      if (hasInvitation) {
        let discountDate = isPromotorDiscount ? referralInvitation.promotor_discount_date : referralInvitation.discount_date;
        let validDiscountCode: boolean = this.isValid(referralInvitation, totalPrice, cartItems, discountDate, isProspectDiscount, isPromotorDiscount);
        resolve(validDiscountCode);
      }
      else {
        resolve(false);
      }
    }
    });
  }

  getDiscountStatusText(invitation: ReferralInvitation, totalPrice: number, cartItems: CartItem[], shopOwnerUserId: string, isProspect: boolean, isPromotor: boolean): Promise<string> {
    return new Promise<string>(async (resolve) => {

    if (invitation.usedByProspect && isProspect) {
      resolve("Je kortingscode is al gebruikt");
    }

    if (invitation.usedByPromotor && isPromotor) {
      resolve("Je kortingscode is al gebruikt");
    }

    if (totalPrice < invitation.minimum_order_price) {
      resolve("Je moet minimaal voor " + invitation.minimum_order_price + " euro aan producten bestellen");
    }

    if (cartItems.length == 0) {
      resolve("Je hebt geen producten in je winkelmandje");
    }

    let validProduct: boolean = false;

    cartItems.forEach(item => {
      if (item.product.user_id == shopOwnerUserId) {
        validProduct = true;
      }
    });

    if (!validProduct) {
      let shopOwnerUser: User = await this.userService.getUserById(invitation.shopowner_user_id);
      resolve("Je kortingscode is alleen geldig bij de producten van " + shopOwnerUser.displayName);
    }
      
    let startDate: Date = new Date(invitation.discount_date.toDate());

    if (this.calculateDateDiff(startDate) > this.referralConfig.discountValidInDays) {
      resolve("Je kortingscode is verlopen (slechts " + this.referralConfig.discountValidInDays + " dagen geldig)");
    }

    resolve("Je kortingscode is succesvol toegepast");
    });
  }

  public calculateDateDiff(date: Date): number {
    let currentDate = new Date();

    let days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }
}
