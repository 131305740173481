import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { User } from 'app/models/user';
import { ReferralMailerService } from 'app/services/referral-mailer.service';
import { ReferralService } from 'app/services/referral.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-referrer',
  templateUrl: './referrer.component.html',
  styleUrls: ['./referrer.component.scss']
})
export class ReferrerComponent implements OnInit {

  public referrerForm: UntypedFormGroup;
  public referralInvitation: ReferralInvitation;
  public hasInvitation: boolean = false;
  public loggedOut: boolean = false;
  public discountCodeSent: boolean = false;

  constructor(private referralService: ReferralService,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private referralMailerService: ReferralMailerService,
              private userService: UserService) {
                this.route.params.subscribe(params => this.loadReferralInvitation(params.inviteId));
  }

  ngOnInit() {
    this.loggedOut = this.userService.sxUser == null || this.userService.sxUser === undefined;
  }

  async sendDiscountCode(form: UntypedFormGroup) {
    if (form.valid) {
      if (this.loggedOut) {
        this.referralInvitation.prospect_email = form.value.email;

        let activated: boolean = this.referralService.activateDiscountCode(this.referralInvitation);
        if (activated) {
          let shopUser: User = await this.userService.getUserById(this.referralInvitation.shopowner_user_id);
          let promotorUser: User = await this.userService.getUserById(this.referralInvitation.promotor_user_id);
          
          this.referralMailerService.sendDiscountCodeEmail(shopUser, promotorUser, this.referralInvitation);
          this.discountCodeSent = true;
        }
      }
    }
  }

  private async loadReferralInvitation(id: string) {
    this.referralInvitation = await this.referralService.getReferralInvitationById(id);    
    this.hasInvitation = !(this.referralInvitation == null || this.referralInvitation === undefined);
    if (this.hasInvitation) {
      this.initializeReferrerForm();
      let nrOfClicks: number = this.referralInvitation.prospectLinkClicks;
      if (nrOfClicks == null || nrOfClicks === undefined) {
        nrOfClicks = 1;
      }
      else {
        nrOfClicks += 1;
      }

      this.referralInvitation.prospectLinkClicks = nrOfClicks;
      this.referralService.updateReferralInvitation(this.referralInvitation);
    }
  }
  
  private initializeReferrerForm() {
    this.referrerForm = this.formBuilder.group({
      email: this.referralInvitation.prospect_email
    });
  }

}
