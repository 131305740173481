import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopProductCategory } from 'app/models/shop-product-category';
import { SlugifyPipe } from 'app/pipes/slugify.pipe';
import { ShopProductCategoryService } from 'app/services/shop-product-category.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'shop-product-category-form',
  templateUrl: './shop-product-category-form.component.html',
  styleUrls: ['./shop-product-category-form.component.scss']
})
export class ShopProductCategoryFormComponent implements OnInit {
  public categoryForm: UntypedFormGroup;
  public model: ShopProductCategory = null;
  public mode: string = '';
  public buttonText: string;
  @Input() userSlug: string;
  @Input() redirectToPage: boolean = true;

  constructor(private formBuilder: UntypedFormBuilder, 
    private userService: UserService,
  private shopProductCategoryService: ShopProductCategoryService,
private route: ActivatedRoute, 
private router: Router) {
  }

  loadData(userSlug: string, categorySlug: string) {
    if (categorySlug === undefined || categorySlug == null) {
      this.mode = 'add';
    }
    else {
      this.mode = 'edit';
    }

    if (userSlug === undefined || userSlug == null) {
      userSlug = this.userSlug;
    }
    
    this.buttonText = this.mode == 'add' ? 'Categorie aanmaken' : 'Categorie bewerken';

    this.userService.findUserBySlug(userSlug).then(user => {
      if (this.mode == 'edit') {
        this.shopProductCategoryService.getCategory(user.id, categorySlug).then(x => {
          this.model = x
          this.mode = 'edit';
          this.loadFormData();
        })  
      }
      else {
        this.loadFormData();
      }
    })        

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => this.loadData(params.user_slug, params.category_slug));
  }

  loadFormData() {
    this.categoryForm = this.formBuilder.group({
      title: this.model != null ? this.model.title : '',
      id: this.model != null && this.model.id != null ? this.model.id : '',
      sequence: this.model != null ? this.model.sequence : 1,
      userId: this.userService.sxUser.id
    });
  }

  addCategory(pForm) {
    if (this.mode == 'add') {
      this.model = new ShopProductCategory();
    }
    this.model.title = pForm.title;
    this.model.sequence = pForm.sequence;
    this.model.userId = this.userService.sxUser.id;
    this.model.slug = new SlugifyPipe().transform(pForm.title);

    if (this.mode == 'add') {
      this.shopProductCategoryService.addCategory(this.model).then((categoryId: string) => {
        this.model.id = categoryId;

        if (this.redirectToPage) {
          this.router.navigate(['/' + this.userService.sxUser.slug + '/manage-product-categories']);
        }
        else {
          this.shopProductCategoryService.trackCategory(this.model);
        }
      })
    }
    else {
      this.shopProductCategoryService.updateCategory(this.model).then(() => {
        if (this.redirectToPage) {
          this.router.navigate(['/' + this.userService.sxUser.slug + '/manage-product-categories']);
        }
        else {
          this.shopProductCategoryService.trackCategory(this.model);
        }
      })
    }
  }

}
