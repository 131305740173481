<h3>Stap 3. Nieuwsbrief controleren en versturen</h3>

<div class="container">
  <div class="row" *ngIf="sentPreview">
    <div class="card border-success mb-9">
      <div class="card-body text-success">
        <h5 class="card-title">De nieuwsbrief is ter controle succesvol verstuurd naar {{email}}</h5>
        <p class="card-text">Heb je na enkele minuten geen mail ontvangen? Controleer dan de SPAM box.</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      Wil je zien hoe de nieuwsbrief eruit ziet voordat je deze verstuurt naar je klanten?<br />
      Controleer de nieuwsbrief door deze naar je eigen e-mail adres te versturen ({{email}}).
    </div>
    <div class="col-md-12 mt-3">
      <button class="btn btn-success" (click)="sendPreview()">Controleer nieuwsbrief</button>
    </div>
  </div>
  <div class="row pb-0 pt-0">
    <div class="col-12">
      Heb je de nieuwsbrief gecontroleerd?<br />
      Verstuur de nieuwsbrief naar al je klanten.
    </div>
  </div>
  <div class="row pt-0 pb-0">
    <div class="col-12 mt-2 pt-0">
      <button class="btn btn-success" (click)="send()">Verstuur nieuwsbrief</button>
    </div>
  </div>
  <div class="row pb-0">
    <div class="container">
      Klanten:
      <div class="row pb-0 pt-0" *ngFor="let customer of customers">
        <div class="col-md-3">
          {{customer.displayName}}
        </div>
        <div class="col-md-9">
          {{customer.email}}
        </div>
      </div>
    </div>
  </div>
</div>