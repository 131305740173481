import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ImageSize } from 'app/models/image_size';
import { NewsletterCreator } from 'app/models/newsletter-creator';
import { Product } from 'app/models/product';
import { UploadedFileMap } from 'app/models/uploaded-file-map.interface';
import { UploadedFile } from 'app/models/uploaded_file';
import { NewsletterService } from 'app/services/newsletter.service';
import { ProductService } from 'app/services/product.service';
import { UploadService } from 'app/services/upload.service';
import { UserService } from 'app/services/user.service';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'newsletter-creator-select-product',
  templateUrl: './newsletter-creator-select-product.component.html',
  styleUrls: ['./newsletter-creator-select-product.component.scss']
})
export class NewsletterCreatorSelectProductComponent implements OnInit {
  public productForm: FormGroup;
  public products: Product[] = [];
  public selectedProducts: Product[] = [];
  public uploadId: string;
  public displayMode: string = "single";
  public resizeImage: boolean = true;
  public resizedImageHeight: number = 582;
  public resizedImageWidth: number = 570;
  public hasSelectedProducts: boolean = false;
  public userSlug: string;
  public productLayoutUnderneathEachOther: boolean = true;
  public productLayoutNextToEachOther: boolean = false;
  public productUnderneath: string = "onderelkaar";
  public productNextToEachOther: string = "naastelkaar";
  public productLayout: string = this.productUnderneath;
  public imageSizes: ImageSize[];

  private fileUploadSubscription: Subscription;
  private newsletterCreatorModel: NewsletterCreator;
  private uploadedFiles: UploadedFileMap; 

  constructor(private formBuilder: FormBuilder, 
              private userService: UserService,
              private productService: ProductService,
              private uploadService: UploadService,
              private newsletterService: NewsletterService,
              private router: Router) { 
                this.userSlug = this.userService.sxUser.slug;

                this.imageSizes = new Array<ImageSize>();
                this.imageSizes.push(new ImageSize(265, 265, 'small'));
                this.imageSizes.push(new ImageSize(570, 570, 'medium'));   
                this.uploadedFiles = new UploadedFileMap();      
              }

  ngOnInit() {
    this.uploadId = uuidv4();
    this.productForm = this.formBuilder.group({
      product: ''
    });

    this.newsletterCreatorModel = this.newsletterService.newsletterCreatorModel;

    if (!this.newsletterCreatorModel.canSelectProducts) {
      this.router.navigate(['/newsletter-creator']);
    }

    if (this.newsletterCreatorModel.products.length > 0) {
      this.selectedProducts = this.newsletterCreatorModel.products;
      this.hasSelectedProducts = this.selectedProducts.length > 0;
    }

    // this.fileUploadSubscription = this.uploadService.getUploadedFile().subscribe(file => {
    //   let model: NewsletterCreator = this.newsletterService.newsletterCreatorModel;
    //   model.promotionImages[file.uid].set(file);
    //   this.newsletterService.setNewsletterCreatorModel(model);
    // });

    let me = this;

    this.fileUploadSubscription = this.uploadService.uploadedFile$.subscribe((file: UploadedFile) => {
        let model: NewsletterCreator = me.newsletterService.newsletterCreatorModel;
        if (me.uploadedFiles[file.uid] == null || me.uploadedFiles[file.uid] === undefined) {
          let arr: Array<UploadedFile> = new Array<UploadedFile>();
          arr.push(file);
          me.uploadedFiles[file.uid] = arr;          
        }
        else {
          let existingArr: Array<UploadedFile> = me.uploadedFiles[file.uid];
          existingArr.push(file);
          me.uploadedFiles[file.uid] = existingArr;
        }
        model.promotionImages = me.uploadedFiles;
        me.newsletterService.setNewsletterCreatorModel(model);
    });

    this.productService.getProducts(this.userService.sxUser, 9999, null).then(products => {
      this.products = products.products;

    });

    this.productLayout = this.newsletterCreatorModel.productLayout;

    if (this.productLayout === undefined) {
      this.productLayout = this.productUnderneath;
    }

    this.setProductLayout(this.productLayout);
  }

  addProduct(productFormData: FormGroup) {
    if (productFormData.valid) {
      let productIndex: number = this.products.findIndex(p => p.id == productFormData.value.product);
      if (productIndex != -1) {
        let product: Product = this.products[productIndex];
        this.selectedProducts.push(product);
        this.hasSelectedProducts = true;
        this.updateProducts();
      }
    }
  }

  removeProduct(productId: string) {
    let productIndex: number = this.products.findIndex(p => p.id == productId);
    if (productIndex != -1) {
      this.selectedProducts = this.selectedProducts.filter(product => product.id != productId);
      this.hasSelectedProducts = this.selectedProducts.length > 0;
      this.updateProducts();
    }
  }

  nextStep() {
    this.updateProducts();
    this.newsletterService.setNewsletterCreatorStep('preview');
  }

  updateProducts() {
    let model: NewsletterCreator = this.newsletterService.newsletterCreatorModel;
    model.products = this.selectedProducts;
    this.newsletterService.setNewsletterCreatorModel(model);
  }

  setProductLayout(layout: string) {
    if (layout == null || layout === undefined) {
      return;
    }

    this.productLayoutUnderneathEachOther = layout === this.productUnderneath;
    this.productLayoutNextToEachOther = layout === this.productNextToEachOther;

    if (this.productLayoutNextToEachOther) {
      this.resizedImageHeight = 265;
      this.resizedImageWidth = 265;
    }
    else {
      this.resizedImageHeight = 570;
      this.resizedImageWidth = 570;
    }

    let model: NewsletterCreator = this.newsletterService.newsletterCreatorModel;
    model.productLayout = layout;

    this.newsletterService.setNewsletterCreatorModel(model);
  }

  get canGoToTheNextStep(): boolean {
    if (!this.hasSelectedProducts) {
      return false;
    }

    let youCanGo: boolean = true;

    if (this.productLayoutUnderneathEachOther && this.selectedProducts.length > 4) {
      youCanGo = false;
    }

    if (this.productLayoutNextToEachOther && this.selectedProducts.length % 2 == 1) {
      youCanGo = false;
    }

    if (this.productLayoutNextToEachOther && this.selectedProducts.length > 6) {
      youCanGo = false;
    }

    if (this.selectedProducts.filter(x => x.stock == 0).length > 0) {
      youCanGo = false;
    }

    return youCanGo;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.fileUploadSubscription.unsubscribe();
  }
}
