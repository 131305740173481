import { Injectable } from '@angular/core';
import { ServiceBase } from './service-base';
import { ShopProductCategory } from 'app/models/shop-product-category';
import { addDoc, collection, CollectionReference, deleteDoc, doc, Firestore, getDocs, limit, orderBy, query, setDoc, where } from '@angular/fire/firestore';
import { converter } from 'app/models/converter';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ShopProductCategoryService extends ServiceBase {

  private categories: CollectionReference<ShopProductCategory>;
  private shopProductCategorySubject = new Subject<ShopProductCategory>();
  public shopProductCategoryState: Observable<ShopProductCategory> = this.shopProductCategorySubject.asObservable();

  constructor(private db: Firestore) { 
    super();

    this.categories = collection(db, 'shop_product_categories').withConverter(converter<ShopProductCategory>())
  }

  async addCategory(category: ShopProductCategory): Promise<string> {
    return addDoc(this.categories, category).then(ref => {
      return ref.id
    })
  }

  async updateCategory(category: ShopProductCategory): Promise<void> {
    const ref = doc(this.categories, category.id)
    return setDoc(ref, category)
  }

  async deleteCategory(categoryId: string): Promise<void> {
    const ref = doc(this.categories, categoryId)

    return deleteDoc(ref)
  }

  async getCategories(userId: string): Promise<ShopProductCategory[]> {
    const q = query(this.categories,
      where('userId', '==', userId),
      orderBy('sequence'))

    return getDocs(q).then(snap => snap.docs.map(doc => {
      const category = doc.data()
      return category
    }))
  }

  async getCategory(userId: string, categorySlug: string): Promise<ShopProductCategory> {
    const q = query(this.categories,
      where('userId', '==', userId),
      where('slug', '==', categorySlug),
      limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty) Promise.reject('No ShopProductCategory found');
      else return ShopProductCategory.constructWithId(snap.docs[0].id, snap.docs[0].data())
    })

  }

  trackCategory(shopProductCategory: ShopProductCategory) {
    this.shopProductCategorySubject.next(shopProductCategory);
  }

}
