import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from 'app/models/user';
import { CustomerService } from 'app/services/customer.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-manage-customers',
  templateUrl: './manage-customers.component.html',
  styleUrls: ['./manage-customers.component.scss']
})
export class ManageCustomersComponent {
  public userSlug: string;
  public user: User;
  public customers: User[] = new Array<User>();
  public privateGroupMembers: User[] = new Array<User>();

  constructor(public route: ActivatedRoute,
              private router: Router,
              private customerService: CustomerService,
              private userService: UserService) {
    this.route.params.subscribe(params => this.getUser(params));    
  }

  async getUser(params: Params) {
    this.userSlug = params.userslug;
    this.userService.findUserBy('slug', this.userSlug).then(user => {
      this.user = user;
      this.getCustomers(user.id);
    }, ohnoes => {
      this.router.navigate(['not-found']);
    });
  }

  async getCustomers(userId: string) {
    const customers = await this.customerService.getCustomersForShopUser(userId);
    const privateGroupMembers = await this.userService.getPrivateGroupMembers(userId);

    customers.forEach(customer => {
      if (this.customers.find(x => x.id === customer.id) == null) {
        this.customers.push(customer);
      }
    });      

    privateGroupMembers.forEach(async privateGroupMember => {
      const user = await this.userService.getUserById(privateGroupMember.user_id);
      if (this.privateGroupMembers.find(x => x.id === user.id) == null) {
        this.privateGroupMembers.push(user);
      }
    });

  }

  deleteCustomer(user: User) {
    
  }

  deletePrivateGroupMember(user: User) {
    
  }

}
