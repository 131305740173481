import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Address } from 'app/models/address';
import { User } from 'app/models/user';
import { AddressService } from 'app/services/address.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'profile-address-settings',
  templateUrl: './profile-address-settings.component.html',
  styleUrls: ['./profile-address-settings.component.scss']
})
export class ProfileAddressSettingsComponent implements OnInit {
  public addressForm: UntypedFormGroup;
  public showForm: boolean;
  private _sxUser: User;  
  private pickupAddress: Address;
  
  constructor(public userService: UserService, 
    private formBuilder: UntypedFormBuilder, 
    private addressService: AddressService) { 
      this._sxUser = this.userService.sxUser;    
      this.fillAddressForm();

      this.addressService.getAddressForUser(this._sxUser.id, 'pickup').then(pickupAddress => {
        this.pickupAddress = pickupAddress;
        this.fillAddressForm();
      })
    }


  ngOnInit() {
  }

  public onSubmitAddressForm(addressForm: UntypedFormGroup) {
    if (addressForm.valid) {
      if (addressForm.value.hasPickupAddress) {
          addressForm.value.address_type = 'pickup';
          this.addressForm.value.firstname = addressForm.value.firstname;
          this.addressForm.value.lastname = addressForm.value.lastname;
          this.addressForm.value.street = addressForm.value.street;
          this.addressForm.value.housenumber = addressForm.value.housenumber;
          this.addressForm.value.housenumber_addition = addressForm.value.housenumber_addition;
          this.addressForm.value.zipcode = addressForm.value.zipcode;
          this.addressForm.value.city = addressForm.value.city;
          this.addressForm.value.country = addressForm.value.country;
          this.addressForm.value.user_id = this._sxUser.id;

          if(this.pickupAddress == null || this.pickupAddress === undefined) {
            this.addressService.addAddressForUser(this.addressForm, this._sxUser.id).then(() => {
              this.addressService.getAddressForUser(this._sxUser.id, 'pickup').then(pickup => {
                this.pickupAddress = pickup;
                this._sxUser.pickup_address_id = pickup.id;
                this.userService.updateUser(this._sxUser);
              })
              // show saved alert
            });      
          }
          else {
            let address: Address = Address.construct(this.addressForm.value);
            address.id = this.pickupAddress.id;
            this._sxUser.pickup_address_id = this.pickupAddress.id;
            this.userService.updateUser(this._sxUser);
            this.addressService.updateAddress(address);
          }
      }
      else {
        if (this.pickupAddress != null) {
          this.addressService.deleteAddress(this.pickupAddress);
          this.pickupAddress = null;

          this._sxUser.pickup_address_id = null;
          this.userService.updateUser(this._sxUser);
        }
      }
    }
  }

  public displayAddressForm(): void {
    this.showForm = true;
  }

  public hideAddressForm(): void {
    this.showForm = false;
  }

  private fillAddressForm(): void {
    if (!(this.pickupAddress == null || this.pickupAddress === undefined)) {
      this.addressForm = this.formBuilder.group({
        firstname: this.pickupAddress.firstname,
        lastname: this.pickupAddress.lastname,
        address_type: this.pickupAddress.address_type,
        street: this.pickupAddress.street,
        housenumber: this.pickupAddress.housenumber,
        housenumber_addition: this.pickupAddress.housenumber_addition,
        zipcode: this.pickupAddress.zipcode,
        city: this.pickupAddress.city,
        country: this.pickupAddress.country,
        hasPickupAddress: true
      });  
      this.showForm = true;
    }
    else {
      this.addressForm = this.formBuilder.group({
        firstname: '',
        lastname: '',
        address_type: 'pickup',
        street: '',
        housenumber: '',
        housenumber_addition: '',
        zipcode: '',
        city: '',
        country: 'NL',
        hasPickupAddress: false
      });

      this.showForm = false;
    }

  }



}
