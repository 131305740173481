import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'app/models/user';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'profile-referral-settings',
  templateUrl: './profile-referral-settings.component.html',
  styleUrls: ['./profile-referral-settings.component.scss']
})
export class ProfileReferralSettingsComponent implements OnInit {
  public referralForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder,                
              private userService: UserService) {
    this.fillReferralForm();
   }

  ngOnInit() {
  }

  public onSubmitReferralForm(referralForm: UntypedFormGroup) {
    if (referralForm.valid) {
      let user:User = this.userService.sxUser;
      user.referralEmailText = referralForm.value.referralemailtext;
      this.userService.updateSxUser(user);
    }
  }

  private fillReferralForm(): void {
    this.referralForm = this.formBuilder.group({
      referralemailtext: this.userService.sxUser.referralEmailText,
    });
  }

}
