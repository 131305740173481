<div class="container">
    <div class="row">
        <progressbar [items]="this.progressBarItems"></progressbar>
    </div>    
    <form *ngIf="productForm" [formGroup]="productForm">
        <div *ngIf="activeStep == 1">
            <h3>{{modeText}}</h3>            
            <div class="form-group">
                <label for="title" class="required">Naam</label>
                <input type="text" class="form-control" id="title" required name="title"
                    placeholder="Naam" formControlName="title">
            </div>
    
            <div class="form-group">
                <label for="description">Omschrijving</label>
                <textarea class="form-control" id="description" name="description"
                    placeholder="Omschrijving" rows="10" formControlName="description"></textarea>
            </div>
            <div class="form-group">
                <label for="price">Prijs van</label>
                <input type="text" class="form-control" id="priceFrom" name="priceFrom"
                    placeholder="Prijs van" formControlName="priceFrom">
            </div>
            <div class="form-group">
                <label for="price" class="required">Prijs voor</label>
                <input type="text" class="form-control" id="price" required name="price"
                    placeholder="Prijs voor" formControlName="price">
            </div>            
            <div class="form-group form-check">
                <input class="form-check-input" type="checkbox" name="subscription" id="subscription"
                 formControlName="subscription" (change)="toggleSubscription($event.target)" />
                 <label for="subscription">Abonneer en bespaar actie</label>
            </div>
            <div class="form-group" *ngIf="model.subscription">
                <label for="subscriptionPrice" class="required">Abonneer en bespaar prijs</label>
                <input type="text" class="form-control" id="subscriptionPrice" name="subscriptionPrice"
                    placeholder="" formControlName="subscriptionPrice">
            </div>
            <div class="form-group">
                <label for="price" class="required">Aantal op voorraad</label>
                <input type="number" min="0" class="form-control" id="stock" required name="stock"
                    placeholder="Voorraad" formControlName="stock">
            </div> 
            <div class="form-group" *ngIf="hasBundles">
                <label for="bundle">Kies een familiegroep</label>
                <select id="bundle_id" name="bundle_id" class="form-control" formControlName="bundle_id">
                    <option value="">Selecteer een familiegroep</option>
                    <option *ngFor="let bundle of bundles" [ngValue]="bundle.id">{{bundle.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="shopProductCategoryId">Kies een hoofdcategorie of maak er een aan</label>
                
                <select name="shopProductCategoryId" formControlName="shopProductCategoryId" class="form-control" (change)="selectShopProductCategory()"> 
                    <option value="">Selecteer een categorie</option>
                    <option *ngFor="let category of shopProductCategories" [ngValue]="category.id">{{category.title}} ({{category.sequence}})</option>
                </select>
            </div>
            <div class="form-group">
                <button id="createMainProductCategory" class="btn btn-success" (click)="createMainProductCategory()">Maak hoofdcategorie aan</button>
                <div class="pt-2" *ngIf="showCreateMainProductCategoryForm">
                    <shop-product-category-form *ngIf="userService.sxUser" [userSlug]="userService.sxUser.slug" [redirectToPage]="false"></shop-product-category-form>
                </div>
            </div>
            <div class="form-group form-check">
                <input class="form-check-input" type="checkbox" name="showOnWebsite" id="showOnWebsite" name="showOnWebsite"
                 formControlName="showOnWebsite" [checked]="showOnWebsite" />
                 <label for="showOnWebsite">Zichtbaar voor iedereen op de website (bij producten)</label>
            </div>
            <button type="submit" class="btn btn-success" (click)="addProduct(productForm.value)">{{modeText}}</button>       
        </div>
        <div *ngIf="activeStep == 2">
            <h4>Productfoto's toevoegen</h4>
            <uploader [uid]="model.id" [displayMode]="this.displayMode" [imageSizes]="this.imageSizes"></uploader>
            <button type="submit" class="btn btn-success" (click)="addProduct(productForm.value)">Afronden</button> 
        </div>           
    </form>
</div>