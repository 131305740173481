import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateGroupInvitation } from 'app/models/private_group_invitation';
import { MailInvitationToUser } from 'app/models/sendinblue/private_group/mail_invitation_to_user';
import { User } from 'app/models/user';
import { OrdermailerService } from 'app/services/ordermailer.service';
import { PrivateGroupService } from 'app/services/private-group.service';
import { UserService } from 'app/services/user.service';
// import { User } from 'firebase';

@Component({
  selector: 'user-group-add-invite',
  templateUrl: './user-group-add-invite.component.html',
  styleUrls: ['./user-group-add-invite.component.scss']
})
export class UserGroupAddInviteComponent implements OnInit {

  public model: PrivateGroupInvitation;
  public sentModel: PrivateGroupInvitation;
  public createdInviteLink: boolean = false;
  public inviteId: string;
  public userSlug: string;
  public noticeMessage: string = '';

  constructor(public userService: UserService,
              private privateGroupService: PrivateGroupService,
              private orderMailerService: OrdermailerService,
              public route: ActivatedRoute) { 
  }

  public get hasNoticeMessage(): boolean {
    return this.noticeMessage != '';
  }

  ngOnInit() {
    this.model = new PrivateGroupInvitation();
    this.model.shop_user_id = this.userService.sxUser.id;
    this.model.shop_user_slug = this.userService.sxUser.slug;
    this.createdInviteLink = false;
    this.userSlug = this.userService.sxUser.slug;
  }

  sendInvite() {
    this.noticeMessage = '';
    this.createdInviteLink = false;

    this.model.send_date = new Date();
    this.model.accepted = false;

    this.userService.findUserBy('email', this.model.email).then(async user => {
      const isMemberAlready: boolean = await this.privateGroupService.userIsMemberOfPrivateGroup(this.model.shop_user_id, user?.id);

      if (isMemberAlready) {
        this.noticeMessage = 'Deze gebruiker is al lid van de privégroep';
      }
      else {        
        if (user?.hasPrivateShop || user?.hasProducts) {
          this.noticeMessage = 'Deze gebruiker heeft al een StyleXplore winkel account';
        }
        else {
          this.sendInvitation();    
        }  
      }
    },
    () => {
      this.sendInvitation();
    });
  }

  private sendInvitation(): void {
      this.privateGroupService.createInvitation(this.model)
      .then(inviteId => {
        this.noticeMessage = '';
        this.model.id = inviteId;
        this.createdInviteLink = true;
        this.inviteId = inviteId;

        this.sentModel = new PrivateGroupInvitation();
        this.sentModel.firstname = this.model.firstname;
        this.sentModel.lastname = this.model.lastname;
        this.sentModel.email = this.model.email;
  
        let tempUser: User = new User();
        tempUser.firstname = this.model.firstname;
        tempUser.lastname = this.model.lastname;
        tempUser.email = this.model.email;
  
        this.orderMailerService.sendPrivateGroupLinkInvitationLinkToUser(this.userService.sxUser, tempUser, inviteId);
        this.model.firstname = '';
        this.model.lastname = '';
        this.model.email = '';
      });  
  }
}
