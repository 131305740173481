<div class="container">
    <div class="row back-button" *ngIf="show">
        <div class="fas fa-arrow-left"></div>
        <a [routerLink]="['/', userSlug, 'bundles']" *ngIf="userSlug !== undefined">Terug</a>
    </div>

    <form #productBundleForm="ngForm" *ngIf="model">
        <div>
            <h3>{{title}}</h3>
            <div class="form-group" *ngIf="edit">
                <label for="title" class="required" for="name">Naam</label>
                <input type="text" class="form-control" id="name" required [(ngModel)]="model.name" name="name"
                    placeholder="Naam">
            </div>
            <div class="form-group" *ngIf="add">
                <label class="required" for="name">Naam</label>
                <input type="hidden" class="form-control" id="name" required [(ngModel)]="model.name" name="name"
                    placeholder="Naam">
            </div>
            <div class="form-group" *ngIf="products">
                <div class="row">
                    <div class="item col-sm" *ngFor="let product of products">
                        <img src="{{product.photo_url}}" class="responsive" alt="{{product.title}}"
                            title="{{product.title}}" style="max-width: 180px; max-height: 240px;" />
                        <div class="caption card-body">
                            <h4 class="group card-title inner list-group-item-heading">{{product.title}}</h4>
                            <p class="group inner list-group-item-text">{{product.shortDescription}}</p>
                            <a class="btn btn-danger" (click)="deleteProductFromBundle(product)"
                                href="javascript:void(0)"
                                *ngIf="userService.userHasProducts && userService.isProductCreator(product)">Verwijder</a>
                        </div>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary" (click)="editProductBundle()"
                *ngIf="add || edit">{{buttonText}}</button>
        </div>
    </form>
</div>