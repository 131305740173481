<div *ngIf="!hasDiscountCode && !showDiscountCodeForm">
  <div class="row">
    <div class="col-md-6"><a href="javascript:void(0)" (click)="toggleDiscountCode()">> Kortingscode invoeren</a></div>
  </div>
</div>
<div *ngIf="showDiscountCodeForm && !hasDiscountCode">
  <div>Voer je kortingscode in:</div>
  <div>
    <form id="discountCodeForm" [formGroup]="discountCodeForm" (ngSubmit)="checkDiscountCode(discountCodeForm)">
      <div class="form-group">
        <input type="text" class="form-control" id="discountCode" placeholder="Kortingscode"
          formControlName="discountCode">
      </div>
      <button type="submit" class="btn btn-primary btn-success">
        Toepassen
      </button>
      <div>&nbsp;</div>
    </form>
  </div>
</div>
<div *ngIf="hasDiscountCode">

</div>
<div *ngIf="checkedDiscountCode">
  <div>&nbsp;</div>
  <div class="card border-success mb-9" *ngIf="discountCodeIsValid">
    <div class="card-body text-success">
      <p class="card-text">{{discountCodeStatusText}}</p>
    </div>
  </div>
  <div class="card border-danger mb-9" *ngIf="!discountCodeIsValid">
    <div class="card-body text-failure">
      <p class="card-text">{{discountCodeStatusText}}</p>
    </div>
  </div>
  <div>&nbsp;</div>
</div>