import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { DashboardComponent } from 'app/components/dashboard/dashboard.component';
import { LoginComponent } from 'app/components/login/login.component';
import { AuthGuardService } from 'app/services/auth-guard.service';

import { ProductsComponent } from 'app/components/products/products.component';
import { ProductDetailComponent } from 'app/components/product-detail/product-detail.component';
import { ProductAddComponent } from 'app/components/product-add/product-add.component';
import { ProductEditComponent } from 'app/components/product-edit/product-edit.component';
import { CartComponent } from 'app/components/cart/cart.component';
import { CheckoutComponent } from 'app/components/checkout/checkout.component';
import { ShippingComponent } from 'app/components/shipping/shipping.component';
import { ConfirmComponent } from 'app/components/confirm/confirm.component';
import { ShoppingAuthGuardService } from 'app/services/shopping-auth-guard.service';
import { ConfirmationComponent } from 'app/components/confirmation/confirmation.component';
import { MyProfileComponent } from 'app/components/my-profile/my-profile.component';
import { OrderDetailComponent } from 'app/components/order-detail/order-detail.component';
import { ProductEditPhotoComponent } from 'app/components/product-edit-photo/product-edit-photo.component';
import { ProductBundlesComponent } from 'app/components/product-bundles/product-bundles.component';
import { ProductBundleAddComponent } from 'app/components/product-bundle-add/product-bundle-add.component';
import { ProductBundleEditComponent } from 'app/components/product-bundle-edit/product-bundle-edit.component';
import { ProductBundleDetailComponent } from 'app/components/product-bundle-detail/product-bundle-detail.component';
import { ProductGuardService } from 'app/services/product-guard.service';
import { UserGroupComponent } from 'app/components/user-group/user-group.component';
import { UserGroupInviteComponent } from 'app/components/user-group-invite/user-group-invite.component';
import { NotFoundComponent } from 'app/components/not-found/not-found.component';
import { PrivateGroupAcceptedComponent } from 'app/components/private-group-accepted/private-group-accepted.component';
import { UserPrivateGroupsComponent } from 'app/components/user-private-groups/user-private-groups.component';
import { RepeatServiceComponent } from 'app/components/repeat-service/repeat-service.component';
import { RepeatServiceConfirmationEmailComponent } from 'app/components/repeat-service-confirmation-email/repeat-service-confirmation-email.component';
import { CustomerOrderCreatorComponent } from 'app/components/customer-order-creator/customer-order-creator.component';
import { ShopOwnerGuardService } from 'app/services/shop-owner-guard.service';
import { NewsletterComponent } from 'app/components/newsletter/newsletter.component';
import { RedirectService } from 'app/services/redirect.service';
import { ProfileShopSettingsComponent } from 'app/components/profile-shop-settings/profile-shop-settings.component';
import { ProfileNewsletterSettingsComponent } from 'app/components/profile-newsletter-settings/profile-newsletter-settings.component';
import { ReferAFriendComponent } from 'app/components/refer-a-friend/refer-a-friend.component';
import { ReferralAuthGuardService } from 'app/services/referral-auth-guard.service';
import { ReferrerComponent } from 'app/components/referrer/referrer.component';
import { ReferrerRedirectService } from 'app/services/referrer-redirect.service';
import { MyProductsComponent } from 'app/components/my-products/my-products.component';
import { NewsletterCreatorComponent } from 'app/components/newsletter-creator/newsletter-creator.component';
import { MyPreferencesComponent } from 'app/components/my-preferences/my-preferences.component';
import { MyInvitationComponent } from 'app/components/my-invitation/my-invitation.component';
import { MyProductLinksComponent } from 'app/components/my-product-links/my-product-links.component';
import { LogoutComponent } from 'app/components/logout/logout.component';
import { MyOrdersComponent } from 'app/components/my-orders/my-orders.component';
import { ManageCustomersComponent } from 'app/components/manage-customers/manage-customers.component';
import { MyShopProductCategoriesComponent } from 'app/components/my-shop-product-categories/my-shop-product-categories.component';
import { ShopProductCategoryFormComponent } from 'app/components/shop-product-category-form/shop-product-category-form.component';
import { CollectionComponent } from 'app/components/collection/collection.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'products',
    component: ProductsComponent
  },
  {
    path: 'users/:slug',
    component: ProductsComponent
  },
  {
    path: ':user_id/products',
    component: ProductsComponent
  },
  {
    path: ':user_id/manage-products',
    component: MyProductsComponent
  },
  {
    path: ':user_id/manage-product-categories',
    component: MyShopProductCategoriesComponent
  },
  {
    path: ':user_slug/product-category/:category_slug/edit',
    component: ShopProductCategoryFormComponent
  },
  {
    path: ':user_slug/product-category/add',
    component: ShopProductCategoryFormComponent
  },
  {
    path: 'products/:slug',
    component: ProductDetailComponent
  },
  {
    path: ':user_id/products/:slug',
    component: ProductDetailComponent
  },
  {
    path: ':user_id/products/:slug/login',
    component: ProductDetailComponent,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ProductGuardService).canActivate(next, state)]
  },
  {
    path: 'products-add',
    component: ProductAddComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: ':user_id/products/:slug/edit',
    component: ProductEditComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'products/:slug/edit',
    component: ProductEditComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: ':user_id/products/:slug/edit-photos',
    component: ProductEditPhotoComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'products/:slug/edit',
    component: ProductEditPhotoComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },
  {
    path: 'shipping',
    component: ShippingComponent,
    canActivate: [() => inject(ShoppingAuthGuardService).canActivate()]
  },
  {
    path: 'confirm',
    component: ConfirmComponent,
    canActivate: [() => inject(ShoppingAuthGuardService).canActivate()]
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    canActivate: [() => inject(ShoppingAuthGuardService).canActivate()]
  },
  {
    path: 'profile',
    component: MyProfileComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },

  {
    path: 'order/:id',
    component: OrderDetailComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: ':userslug/bundles',
    component: ProductBundlesComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: ':userslug/bundles/:slug',
    component: ProductBundleDetailComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: ':userslug/bundles/:slug/edit',
    component: ProductBundleEditComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: ':userslug/bundles-add',
    component: ProductBundleAddComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: ':userslug/group',
    component: UserGroupComponent
  },
  {
    path: ':userslug/join/:id',
    component: UserGroupInviteComponent
  },
  {
    path: ':userslug/new-invite',
    component: UserGroupInviteComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: ':userslug/private-group-accepted/:id',
    component: PrivateGroupAcceptedComponent,
    canActivate: [() => inject(RedirectService).canActivate()]
  },
  {
    path: 'private-groups',
    component: UserPrivateGroupsComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'repeat-service',
    component: RepeatServiceComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'repeat-service-confirmation/:shopuser_id/:user_id',
    component: RepeatServiceConfirmationEmailComponent
  },
  {
    path: 'customer-order-creator',
    component: CustomerOrderCreatorComponent,
    canActivate: [() => inject(ShopOwnerGuardService).canActivate()]
  },
  {
    path: 'customer-order-creator/:step',
    component: CustomerOrderCreatorComponent,
    canActivate: [() => inject(ShopOwnerGuardService).canActivate()]
  },
  {
    path: 'shop-settings',
    component: ProfileShopSettingsComponent,
    canActivate: [() => inject(ShopOwnerGuardService).canActivate()]
  },
  {
    path: 'newsletter-settings',
    component: ProfileNewsletterSettingsComponent,
    canActivate: [() => inject(ShopOwnerGuardService).canActivate()]
  },
  {
    path: 'refer-a-friend',
    component: ReferAFriendComponent,
    canActivate: [() => inject(ReferralAuthGuardService).canActivate()]
  },
  {
    path: 'referrer/:slug/:inviteId',
    component: ReferrerComponent,
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(ReferrerRedirectService).canActivate(next, state)]
  },
  {
    path: 'newsletter-creator',
    component: NewsletterCreatorComponent,
    canActivate: [() => inject(ShopOwnerGuardService).canActivate()]
  },
  {
    path: 'newsletter-creator/:step',
    component: NewsletterCreatorComponent,
    canActivate: [() => inject(ShopOwnerGuardService).canActivate()]
  },
  {
    path: 'preferences',
    component: MyPreferencesComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'invitation',
    component: MyInvitationComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'product-links',
    component: MyProductLinksComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: 'orders',
    component: MyOrdersComponent,
    canActivate: [() => inject(AuthGuardService).canActivate()]
  },
  {
    path: ':userslug/manage-customers',
    component: ManageCustomersComponent,
    canActivate: [() => inject(ShopOwnerGuardService).canActivate()]
  },
  {
    path: ':userslug/collections/:categoryslug',
    component: CollectionComponent
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
