import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ProductService } from 'app/services/product.service';
import { Product } from 'app/models/product';
import { Photo } from 'app/models/photo';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user';

@Component({
  selector: 'app-product-base',
  templateUrl: './product-base.component.html',
  styleUrls: ['./product-base.component.scss']
})
export class ProductBaseComponent implements OnInit {
  public product: Product;
  public productPhotos: Photo[];
  public unfilteredProductPhotos: Photo[];
  public userSlug?: string;

  constructor(public route: ActivatedRoute,
    public productService: ProductService,
    public userService: UserService) {
    this.route.params.subscribe(params => this.getProduct(params));
  }

  ngOnInit() {
  }

  async getProduct(params: Params) {
    this.userSlug = params.user_id;
    let productSlug = params.slug;
    if (this.userSlug == null || this.userSlug === undefined) {
      return this.productService.getProductBySlug(productSlug)
        .then(p => {
          this.product = p;
          this.unfilteredProductPhotos = p.photos;
          this.productPhotos = this.getFilteredPhotos(p.photos);

          this.userService.findUserBy('id', p.user_id).then(user => {
            this.product.user = User.construct(user);
          })
        });

    }
    else {
      return this.userService.findUserBy('slug', this.userSlug).then(user => {
        this.productService.getProductByUserIdAndSlug(user.id, productSlug).then(p => {
          this.product = p;
          this.unfilteredProductPhotos = p.photos;
          this.productPhotos = this.getFilteredPhotos(p.photos);
          this.product.user = User.construct(user);
        });
      });
    }
  }

  async deletePhoto(photo: Photo) {
    return this.productService.deletePhotoFromProduct(this.product.id, photo.filename).then(product => {
      if (product != null) {
        this.product = product;
        this.productPhotos = this.getFilteredPhotos(product.photos);
      }
    });
  }

  getPhotoInSpecificSize(fileSize: string) {
    return this.unfilteredProductPhotos.filter(x => x.fileSize === undefined || x.fileSize == null || x.fileSize === fileSize);
  }

  private getFilteredPhotos(photos: Photo[]) {
    if (photos == null || photos === undefined) {
      return new Array<Photo>();
    }
    return photos.filter(x => x.fileSize === undefined || x.fileSize == null || x.fileSize === 'large');
  }

}
