import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from 'app/services/product.service';
import { ProductBundle } from 'app/models/product_bundle';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-product-bundles',
  templateUrl: './product-bundles.component.html',
  styleUrls: ['./product-bundles.component.scss']
})
export class ProductBundlesComponent implements OnInit {

  public bundles: ProductBundle[];
  public userSlug: string;

  constructor(private productService: ProductService,
    private userService: UserService,
    public route: ActivatedRoute) {
    this.route.params.subscribe(params => this.loadBundles(params.userslug));
   }


  ngOnInit() {
  }

  deleteProductBundle(bundle: ProductBundle) {
    if(confirm("Weet je zeker dat je " + bundle.name + " wilt verwijderen?")) {
      this.productService.deleteProductBundle(bundle.id).then(() => {
        this.deleteFromBundles(bundle.id);
      })
    }
  }

  deleteFromBundles(id: string) {
    const bundleIndex = this.bundles.findIndex(p => p.id == id);

    if (bundleIndex != -1) {
      this.bundles.splice(bundleIndex, 1);
    }
  }

  private loadBundles(userslug: string) {
    this.userSlug = userslug;

    this.userService.findUserBySlug(userslug).then(user => {
      this.productService.getProductBundles(user.id).then(productBundles => {
        this.bundles = productBundles;
      });  
    });
  }

}
