import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgressBarItem } from 'app/models/progressbaritem';
import { User } from 'app/models/user';
import { CustomerService } from 'app/services/customer.service';
import { ProgressbarService } from 'app/services/progressbar.service';

@Component({
  selector: 'customer-order-creator',
  templateUrl: './customer-order-creator.component.html',
  styleUrls: ['./customer-order-creator.component.scss']
})
export class CustomerOrderCreatorComponent implements OnInit {
  public progressBarItems: Array<ProgressBarItem>;
  public step: string;
  public createOrderScreen: boolean;
  public confirmationScreen: boolean;
  public confirmedOrderScreen: boolean;
  public customerScreen: boolean;
  public customer: User;

  constructor(private progressBarService: ProgressbarService,
    private route: ActivatedRoute, 
    private customerService: CustomerService) {
      this.customer = this.customerService.orderCreatorCustomer;
      this.route.params.subscribe(params => this.setStep(params.step));
  }

  ngOnInit() {
    this.customerService.customerState.subscribe((customer: User) => {
      this.customer = customer;
      this.customerService.setOrderCreatorCustomer(customer);
      this.setStep('create-order');
    });

    this.customerService.orderCreatorStepState.subscribe((step: string) => {
      this.setStep(step);
    });
  }

  private setStep(step: string) {
    this.step = step;

    this.createOrderScreen = step == 'create-order';
    this.confirmationScreen = step == 'confirm';
    this.confirmedOrderScreen = step == 'sent';

    this.customerScreen = this.createOrderScreen == false && this.confirmationScreen == false && this.confirmedOrderScreen == false;  

    let activeStep:number = 1;

    if (this.createOrderScreen) {
      activeStep = 2;
    }

    if (this.confirmationScreen) {
      activeStep = 3;
    }

    if (this.confirmedOrderScreen) {
      activeStep = 4;
    }

    this.progressBarItems = this.progressBarService.getCustomerOrderCreatorProgressBar(activeStep);
  }

}
