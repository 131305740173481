<div id="homepage" class="container-fluid marketing">

    <div class="row homepage-row" *ngIf="hasProducts">
        <div class="col">
            <div class="line">
            </div>
        </div>
    </div>

    <div class="row homepage-row" *ngIf="hasProducts">
        <div class="pb-3 col" *ngFor="let product of products">
            <product-row-homepage [product]="product"></product-row-homepage>
        </div>    
    </div>
    <div class="row homepage-row">
        <div class="pb-3 col">
            <pagination 
            [currentPage]="pagination.currentPage"
            [hasMore]="pagination.hasMore"
            (loadMore)="onNextPage()"></pagination>        
        </div>
    </div>
</div>