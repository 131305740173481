import { Address } from './address';
import { CartItem } from './cartitem';

export class Cart {

  public cartItems: CartItem[];
  public sameShippingAndBillingAddress: boolean;
  public pickupAddressId: string;
  public discount: number;
  public discountMinimumOrderPrice: number;
  public discountCode: string;
  public discountDate: Date;
  public discountUserId: string;
  public isPromotorDiscount: boolean;
  public isProspectDiscount: boolean;

  constructor() {
      this.cartItems = [];
  }

  get hasPickupAddress(): boolean {
      let hasNoPickupAddress: boolean = this.pickupAddressId == null || 
                                        this.pickupAddressId === undefined;
      return !hasNoPickupAddress;                                        
  }

  get hasDiscountCode(): boolean {
      let hasNoDiscountCode: boolean = this.discountCode == null || 
                                        this.discountCode === undefined;
      return !hasNoDiscountCode;                                        
  }
  
  get count() : number {
      return this.cartItems.length;
  }

  get totalPrice(): number {
      let totalPrice: number = 0;

    this.cartItems.forEach(cartItem => {
        const productPrice = cartItem.productSubscription ? cartItem.product.subscriptionPrice : cartItem.product.price;
        totalPrice += cartItem.quantity * Number.parseFloat(productPrice);
    }); 

    return totalPrice;
  }

  get totalDiscount(): number{
    let totalDiscount: number = 0;

    if (!(this.discount == null || this.discount === undefined)) {
        totalDiscount = this.discount;
    }

    return totalDiscount;
  }
  
  addItem(cartItem: CartItem) {
      let indexOfProduct: number = this.cartItems.findIndex(ci => ci.product.id == cartItem.product.id);
      if (indexOfProduct != -1) {
          this.cartItems[indexOfProduct] = cartItem;
      }
      else {
        this.cartItems.push(cartItem);
      }
  }

  removeItem(cartItem: CartItem) {
    let indexOfProduct: number = this.cartItems.findIndex(ci => ci.product.id == cartItem.product.id);
    if (indexOfProduct != -1) {
        this.cartItems.splice(indexOfProduct, 1);
    }
  }

  addItems(cartItems: CartItem[]) {
    cartItems.forEach(cartItem => {
        this.addItem(cartItem);
    });
  }

  setItems(cartItems: CartItem[]) {
      this.cartItems = cartItems;
  }

  setCart(cart: Cart) {
      if (cart == null) {
          return;
      }
      
      this.cartItems = cart.cartItems;
      this.sameShippingAndBillingAddress = cart.sameShippingAndBillingAddress;
      this.pickupAddressId = cart.pickupAddressId;
      this.discount = cart.discount;
      this.discountCode = cart.discountCode;
      this.discountDate = cart.discountDate;
      this.discountUserId = cart.discountUserId;
      this.discountMinimumOrderPrice = cart.discountMinimumOrderPrice;
      this.isProspectDiscount = cart.isProspectDiscount;
      this.isPromotorDiscount = cart.isPromotorDiscount;
  }
}
