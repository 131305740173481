import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PrivateGroupInvitation } from 'app/models/private_group_invitation';
import { User } from 'app/models/user';
import { PrivateGroupService } from 'app/services/private-group.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'private-group-accepted',
  templateUrl: './private-group-accepted.component.html',
  styleUrls: ['./private-group-accepted.component.scss']
})
export class PrivateGroupAcceptedComponent implements OnInit {
  public userSlug: string;
  public user: User;
  public invitation: PrivateGroupInvitation;

  constructor(public route: ActivatedRoute, 
              private router: Router,
              private privateGroupService: PrivateGroupService,              
              private userService: UserService) { 
    this.route.params.subscribe(params => this.getUser(params));    
  }

  ngOnInit() {
  }

  async getUser(params: Params) {
    this.userSlug = params.userslug;
    this.userService.findUserBy('slug', this.userSlug).then(user => {
      this.user = user;
      this.getInvitation(params);
    }, ohnoes => {
      this.router.navigate(['not-found']);
    });
  }

  async getInvitation(params: Params) {
    this.privateGroupService.getInvitationById(params.id).then(invitation => {
      this.invitation = invitation;

      let redirectUrl = this.userService.redirectUrl;
      if (redirectUrl !== null && redirectUrl !== undefined && redirectUrl != '') {
        this.router.navigate([this.userService.redirectUrl]);
      }
    });
  }

}
