export default class StringUtils {
    static transformDisplayname(displayName: string): string[] { 
        var names = displayName.split(' ');
        var firstname = names[0];
        var lastname = '';
        var namesLength = names.length;
        
        if (namesLength == 2) {
            lastname = names[1];
        }
        else {
            if(namesLength > 2) {
            for(var i = 1; i <= namesLength - 1; i++) {
                lastname += names[i];
                if (i != namesLength -1) {
                lastname += " ";
                }
            }
            }
        }

        var splitNames = new Array();
        splitNames.push(firstname);
        splitNames.push(lastname);

        return splitNames;
     }
}