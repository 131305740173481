import { Component, OnInit, Input } from '@angular/core';
import { CartItem } from '../../models/cartitem';

@Component({
  selector: 'cart-items',
  templateUrl: './cart-items.component.html',
  styleUrls: ['./cart-items.component.scss']
})
export class CartItemsComponent implements OnInit {
  @Input() items: Array<CartItem>;
  @Input() edit: Boolean;

  constructor() { }

  ngOnInit() {
  }

}
