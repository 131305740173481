import { Component, OnInit, Input } from '@angular/core';
import { CartItem } from '../../models/cartitem';
import { CartService } from '../../services/cart.service';
import { ProductService } from 'app/services/product.service';
import { Product } from 'app/models/product';
import { Subject, Observable } from 'rxjs';
import { ProductSubscriptionService } from 'app/services/product-subscription.service';

@Component({
  selector: 'cartitem',
  templateUrl: './cartitem.component.html',
  styleUrls: ['./cartitem.component.scss']
})
export class CartitemComponent implements OnInit {
  @Input() item: CartItem;
  @Input() edit: Boolean;
  private itemQuantitySubject: Subject<string> = new Subject<string>();

  constructor(private cartService: CartService,
              private productService: ProductService,
              private productSubscriptionService: ProductSubscriptionService) { }

  ngOnInit() {
  }

  updatedQuantity(quantity: string) {
    this.itemQuantitySubject.next(quantity);
  }

  getUpdatedQuantity(): Observable<string> {
    return this.itemQuantitySubject.asObservable();
}

  updateItem(item: CartItem, quantity: string) {
    let originalQuantity: number = this.item.quantity;

    let success: boolean = false;
    this.productService.getProductById(item.product.id).then(product => {
      let productStock: number = product.stock || 0;
      if(this.cartService.canOrderCartItem(productStock, Number(quantity))) {
        success = true;
        this.item.quantity = Number(quantity);
        this.cartService.updateCartItem(item);
      }
      else {
        let newQuantity = productStock - originalQuantity > 0 ? originalQuantity : productStock;
        this.item.quantity = newQuantity;
        this.cartService.updateCartItem(item);
        alert('De voorraad van ' + product.title + ' is op dit moment ' + productStock + ' stuk(s).');
      }
    });
  }

  removeItem(item: CartItem): void {
    if(confirm("Weet je zeker dat je " + item.product.title + " wilt verwijderen?")) {
      this.cartService.removeFromCart(item);
    }
  }

  changeSubscription(): void {
    this.cartService.updateCartItem(this.item);
  }

  loadCartItem(): void {
    this.productSubscriptionService.changeSubscriptionFrequency(this.item);
  }

  numericOnly(event): boolean {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  get thumbnail(): string {
    if (this.item) {
      return this.item.photos.length == 0 ? this.item.product.photo_url : this.item.photos[0].url;
    }
    else { 
      return '';
    }
  } 

}
