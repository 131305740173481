<div class="container">
  <div class="row flex">
    <progressbar [items]="this.progressBarItems"></progressbar>
  </div>
  <div class="container">
    <section id="customerScreen" *ngIf="this.customerScreen">
      <customer-order-creator-customer-data *ngIf="this.customerScreen"></customer-order-creator-customer-data>
    </section>
    <section id="createOrderScreen" *ngIf="this.createOrderScreen">
      <customer-order-creator-order-data [customer]="customer" *ngIf="this.createOrderScreen"></customer-order-creator-order-data>
    </section>
    <section id="confirmationScreen" *ngIf="this.confirmationScreen">
      <customer-order-creator-send [customer]="customer" *ngIf="this.confirmationScreen"></customer-order-creator-send>    
    </section>
    <section id="confirmedOrderScreen" *ngIf="this.confirmedOrderScreen">
      <customer-order-creator-sent  *ngIf="this.confirmedOrderScreen"></customer-order-creator-sent>    
    </section>
    <a href="https://firebasestorage.googleapis.com/v0/b/stylexplore-prod.appspot.com/o/Stylexplore_SocialeMediaOrdersPlaatsen_Faq.pdf?alt=media&token=6d6869db-2c2e-4a3f-bd18-00a38798e5b4" target="_blank">Hulp nodig bij bestellen? Klik hier voor de handleiding</a>
  </div>
</div>