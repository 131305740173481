import { Injectable } from '@angular/core';
import {
  Auth, AuthProvider, FacebookAuthProvider, TwitterAuthProvider, GoogleAuthProvider, UserCredential,
  signInWithPopup, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword,
  deleteUser,
  getAdditionalUserInfo,
  User,
  getAuth
} from '@angular/fire/auth';
import { UserService } from './user.service';
import { collection, Firestore, getDocs, limit, query, where } from '@angular/fire/firestore';
import { ServiceBase } from './service-base';

@Injectable()
export class AuthService extends ServiceBase {
  constructor(
    public afAuth: Auth,
    private userService: UserService,
    private db: Firestore
  ) {
    super();
  }

  async facebookLogin(): Promise<UserCredential> {
    return this.doLogin(new FacebookAuthProvider());
  }

  async twitterLogin(): Promise<any> {
    return this.doLogin(new TwitterAuthProvider());
  }

  async googleLogin(): Promise<any> {
    let provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    return this.doLogin(provider);
  }

  async doLogin(provider: AuthProvider): Promise<UserCredential> {
    return signInWithPopup(this.afAuth, provider)
  }

  async userExists(email: string): Promise<boolean> {
    const q = query(collection(this.db, 'users'),
      where('email', '==', email),
      limit(1))

    return getDocs(q).then(snap => !snap.empty)
  }

  async createAccount(value): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.afAuth, value.email, value.password)
  }

  async deleteAccount(user: User): Promise<void> {
    return deleteUser(user);
  }

  login(user): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.afAuth, user.email, user.password)
  }

  logout() {
    this.userService.logout();
    return new Promise<void>((resolve, reject) => {
      if (this.afAuth.currentUser) {
        signOut(this.afAuth)
        resolve();
      }
      else {
        reject();
      }
    });
  }
}
