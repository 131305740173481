import { Injectable } from '@angular/core';

import { collection, CollectionReference, setDoc, doc, Firestore, query, where, limit, getDocs } from '@angular/fire/firestore';
import { converter } from 'app/models/converter';
import { ServiceBase } from './service-base';
import { User } from 'app/models/user';
import { RepeatService } from 'app/models/repeat_service';

@Injectable()
export class RepeatEmailService extends ServiceBase {
  private services: CollectionReference<RepeatService>

  constructor(private db: Firestore) {
    super();

    this.services = collection(db, 'repeat_service').withConverter(converter<RepeatService>())
  }
  
  create(shopUser: User, user: User): Promise<RepeatService> {
    const ref = doc(this.services)

    var repeatService : RepeatService = new RepeatService(); 

    repeatService.id = ref.id
    repeatService.send_date = new Date();
    repeatService.shop_user_fullname = shopUser.displayName;
    repeatService.shop_user_id = shopUser.id;
    repeatService.user_id = user.id;
    repeatService.shop_user_slug = shopUser.slug;
    repeatService.user_fullname = user.displayName;
  
    return setDoc(ref, repeatService).then(_ => repeatService)
  }

  async update(repeatService: RepeatService): Promise<void> {
    const ref = doc(this.services, repeatService.id)

    return setDoc(ref, repeatService)
  }

  async get(shopUserId: string, user_id: string): Promise<RepeatService> {
    const q = query(this.services,
                    where('shop_user_id', '==', shopUserId),
                    where('user_id', '==', user_id),
                    limit(1))

                    
    return getDocs(q).then(snap => {
      if (snap.empty) return Promise.reject('No referral invitation found')
        else {
          const repeatService = RepeatService.constructWithId(snap.docs[0].id, snap.docs[0].data())
          return repeatService
        }
    })
  }
}
