import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@angular/fire/auth';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user';

@Injectable()
export class ShopOwnerGuardService  {

  constructor(
    public afAuth: Auth,
    public userService: UserService,
    private router: Router
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let user: User = this.userService.sxUser;

      if (user != null) {
        return resolve(user.isShopOwner);
      }
      else {
        this.router.navigate(['/login']);
        return resolve(false);
      }
    });
  }
}
