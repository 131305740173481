import { Component, Input } from '@angular/core';
import { CartItem } from 'app/models/cartitem';
import { CartService } from 'app/services/cart.service';
import { ProductSubscriptionService } from 'app/services/product-subscription.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'product-subscription-modal',
  templateUrl: './product-subscription-modal.component.html',
  styleUrls: ['./product-subscription-modal.component.scss']
})
export class ProductSubscriptionModalComponent {
  @Input() item: CartItem;
  private subscription: Subscription = new Subscription();

  constructor(private cartService: CartService,
    private productSubscriptionService: ProductSubscriptionService) {
      this.subscription.add(this.productSubscriptionService.productSubscription$.subscribe((cartItem: CartItem) => {
        this.item = cartItem;
      }));
  }

  selectFrequency(frequency: any) {
    this.item.productSubscription.frequency = frequency;
    this.item.productSubscription.frequencySelected = frequency != '';
    
  }

  changeSubscription(): void {
    this.cartService.updateCartItem(this.item);
  }

}
