import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Auth } from '@angular/fire/auth';
import { ProductService } from './product.service';
import { UserService } from './user.service';

@Injectable()
export class ProductGuardService {

  constructor(
    public afAuth: Auth,
    public userService: UserService,
    public productService: ProductService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var productSlug = route.params.slug;
      var userSlug = route.params.user_id;
      var sxUser = this.userService.sxUser;
      this.userService.redirectUrl = state.url.replace('/login', '');

      this.userService.findUserBy('slug', userSlug).then(user => {
        const userGroupUrl: string = '/' + user.slug + '/group';
        const hasPrivateGroupMembers = user.privateShop && user.hasPrivateGroupMembership

        // shop owner
        if (sxUser != null && sxUser.id == user.id) {
          return resolve(true);
        }

        if (sxUser != null && user.privateShop && user.isPrivateGroupMember(sxUser.id)) {
          return resolve(true);
        }

        if (productSlug == null && user.privateShop || productSlug === undefined && user.privateShop) {
          this.router.navigate([userGroupUrl]);
          return resolve(false);
        }

        if (productSlug == null || productSlug === undefined) {
          return resolve(true);
        }

        this.productService.getProductByUserIdAndSlug(user.id, productSlug).then(product => {
          let privateProduct = product.private !== undefined ? product.private : false;

          if (!privateProduct) {
            return resolve(true);
          }

          // no logged in user
          if (sxUser === null) {
            this.router.navigate([userGroupUrl]);
            return resolve(false);
          }

          if (sxUser.privateGroupMembers === undefined) {
            this.router.navigate([userGroupUrl]);
            return resolve(false);
          }

          // no membership user
          if (sxUser.privateGroupMembers.indexOf(user.id) == -1) {
            this.router.navigate([userGroupUrl]);
            return resolve(false);
          }

          return resolve(true);
        });
      });
    });
  }
}
