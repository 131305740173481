<div *ngIf="percentage | async as pct">
    <progress [value]="pct" max="100"></progress> {{ pct | number }}%
</div>

<div>
    <div *ngIf="task.snapshot?.state == 'success'">
        <img [src]="url"><br>
    </div>

    <!-- <button (click)="task.pause()" [disabled]="!isActive(task)">Pause</button>
    <button (click)="task.cancel()" [disabled]="!isActive(task)">Cancel</button>
    <button (click)="task.resume()" [disabled]="!(task.snapshot?.state === 'paused')">Resume</button>-->
</div>