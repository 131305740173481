import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { GetProductsResponse, ProductService } from './product.service'; // Adjust the import as necessary
import { Product } from 'app/models/product';

export interface Pagination {
  currentPage: number;
  itemsPerPage: number;
  lastVisible: QueryDocumentSnapshot<Product> | null;
  hasMore: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  public paginationSubject = new BehaviorSubject<Pagination>({
    currentPage: 1,
    itemsPerPage: 12,
    lastVisible: null,
    hasMore: true,
  });

  pagination$ = this.paginationSubject.asObservable();
  products: Product[] = [];
  productResponse: GetProductsResponse;

  constructor(private productService: ProductService) {}

  update(lastVisible: QueryDocumentSnapshot<Product> | null, hasMore: boolean) {
    const current = this.paginationSubject.value;
    current.lastVisible = lastVisible;
    current.hasMore = hasMore;
    this.paginationSubject.next({ ...current, lastVisible });
  }

  async loadMoreProducts(fetchedProducts: GetProductsResponse) {
    const pagination = this.paginationSubject.value;

    this.productResponse = fetchedProducts;

    // Update the product list
    this.setProducts(fetchedProducts);

    // Update the last visible document
    this.update(fetchedProducts.lastVisible, fetchedProducts.products.length === pagination.itemsPerPage);
  }

  // New method to set products from the component
  setProducts(newProducts: GetProductsResponse) {
    this.products = newProducts.products; // Set the products in the service
  }

  resetPagination() {
    this.paginationSubject.next({
      currentPage: 1,
      itemsPerPage: 10,
      lastVisible: null,
      hasMore: true,
    });
    this.products = []; // Reset products
  }
}
