import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'app/models/user';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'profile-newsletter-settings',
  templateUrl: './profile-newsletter-settings.component.html',
  styleUrls: ['./profile-newsletter-settings.component.scss']
})
export class ProfileNewsletterSettingsComponent implements OnInit {
  public userForm: UntypedFormGroup;
  private _sxUser: User;  
  
  constructor(public userService: UserService, 
    private formBuilder: UntypedFormBuilder) { 
      this._sxUser = this.userService.sxUser;    
      this.fillUserDataForm();
  }
  
  ngOnInit() {
  }

  public onSubmitUserForm(userForm: UntypedFormGroup) {
    if (userForm.valid) {   
      this._sxUser.advantage1 = userForm.value.advantage1;
      this._sxUser.advantage2 = userForm.value.advantage2;
      this._sxUser.advantage3 = userForm.value.advantage3;
      this._sxUser.advantage4 = userForm.value.advantage4;

      this.userService.updateSxUser(this._sxUser);
    }
  }

  private fillUserDataForm(): void {
    this.userForm = this.formBuilder.group({
      advantage1: this._sxUser.advantage1,
      advantage2: this._sxUser.advantage2,
      advantage3: this._sxUser.advantage3,
      advantage4: this._sxUser.advantage4
    });
  }

}
