export class Pagination {
    currentPage: number;
    itemsPerPage: number;
    lastVisible: any = null;
    hasMore: boolean = true;  // Track if there are more items to load
  
    constructor(itemsPerPage: number = 10) {
      this.currentPage = 1;
      this.itemsPerPage = itemsPerPage;
    }
  
    changePage(newPage: number): number {
      this.currentPage = newPage;
      return this.currentPage;
    }
  
    updateLastVisible(lastDoc: any) {
      this.lastVisible = lastDoc;
    }
  
    setHasMore(hasMore: boolean) {
      this.hasMore = hasMore;
    }
  }
  