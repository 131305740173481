<div class="container">
  <div class="row">
    <div class="col-lg-12 my-3">
      <div class="pull-right">
        <div class="btn-group">
          <button class="btn btn-info" id="list">
            List View
          </button>
          <button class="btn btn-danger" id="grid">
            Grid View
          </button>
        </div>
      </div>
    </div>
  </div>
  <div id="products" class="row view-group" *ngIf="itemList">
    <div class="item col-xs-4 col-lg-4" *ngFor="let item of itemList">
      <div class="thumbnail card">
        <!-- <div class="img-event" *ngFor="let photo in item.photos">
          <img class="group list-group-image img-fluid" src="{{photo.url}}" alt="{{item.title}}" title=="{{item.title}}" />
        </div> -->
        <div class="caption card-body">
          <h4 class="group card-title inner list-group-item-heading">{{item.title}}</h4>
          <p class="group inner list-group-item-text">{{item.shortDescription}}</p>
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <p class="lead">{{item.price}}</p>
              </div>
            <div class="col-xs-12 col-md-6">
              <a class="btn btn-success" (click)="editProduct(product)">Edit</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>