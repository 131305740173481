import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'app/models/product';
import { User } from 'app/models/user';
import { ProductService } from 'app/services/product.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'product-row',
  templateUrl: './product-row.component.html',
  styleUrls: ['./product-row.component.scss']
})
export class ProductRowComponent implements OnInit {
  @Input() product: Product;
  public userSlug: string;

  constructor(public productService: ProductService,
              public userService: UserService) { 

  }

  ngOnInit() {
    if (this.hasProduct) {
      let user: User = this.userService.sxUser;
      this.userSlug = user.slug;
    }
  }

  public get hasProduct(): boolean {
    return this.product != null;
  }

}
