<div class="container">
  <div class="row"></div>
  <div class="pb-2 user-profile" *ngIf="user">
    <p class="card-text">Ontmoet je verkoper</p>
    <div class="container-fluid">
      <div class="col-md-9 w-auto">
        <div class="row w-auto">
          <img src="{{user.photo}}" class="responsive profile-photo" />
          <div class="col">
            <a class="nav-link d-inline-block shop-owner-information-link" data-html="true" data-placement="right" data-popover-content="#shopOwnerInformation"
              data-toggle="popover" data-trigger="focus" tabindex="0">
              <h5 class="card-title w-auto" title="Klik hier voor meer informatie">{{user.firstname}}</h5>
            </a>
            <div class="hidden d-none popover-container" id="shopOwnerInformation">
              <div class="popover-body">
                <ul class="nav navbar-nav">
                  <li>{{user.shopTitle}}</li>
                  <li>{{user.shopDescription}}</li>
                  <li>{{user.phoneNumber}}</li>
                  <li><a href="mailto:{{user.email}}">{{user.email}}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
    <div class="col pl-0">
      <p class="card-text">Eigenaar van {{user.shopTitle}}</p>
    </div>
    <div class="col pl-0 pt-2"
      *ngIf="user && user.isShopOwner && userService.sxUser && userService.sxUser.id == user.id">
      <button class="btn btn-info" id="add" [routerLink]="['/', user.slug, 'manage-products']">
        Beheer producten
      </button>
    </div>
    <div class="col pl-0 pt-2"
      *ngIf="user && user.isShopOwner && userService.sxUser && userService.sxUser.id == user.id">
      <button class="btn btn-info" id="add" [routerLink]="['/', user.slug, 'manage-product-categories']">
        Beheer productcategorieën
      </button>
    </div>
  </div>
    <div class="category" *ngFor="let category of categoriesWithProducts">
      <div class="row pb-0" *ngIf="shopProductCategories">
        <div class="col-md-3" *ngFor="let product of categoryProducts[category.id]">
          <a [routerLink]="['/', userSlug, 'collections', category.slug]">
            <div class="card">
                <img src="{{product.photo_url}}" alt="{{product.title}}" title="{{product.title}}" class="card-img-top">
            </div>
          </a>
        </div>
      </div>
      <div class="row p-0 m-0 gradient-background">        
      </div>
      <div class="row pt-2 pl-2">
        <div class="collection-preview-content">
          <div class="collection-preview-header">
            <h3>{{category.title}}</h3>
          </div>
          <div class="collection-preview-subheader">{{categoriesWithProductsCount[category.id]}} producten</div>
        </div>
        <div class="collection-preview-description-shade"></div>
      </div>  
  </div>
  <div class="row" *ngIf="!shopProductCategories || shopProductCategories.length == 0">
    <div class="col-md-3" *ngFor="let product of products">
      <a [routerLink]="productRouterLink(product)">
        <div class="card">
          <img class="card-img-top" src="{{product.photo_url}}" alt="{{product.title}}" title="{{product.title}}">
          <div class="card-body">
            <h5 class="card-title">{{product.title}}</h5>
            <div class="row" *ngIf="!product.private">
              <div class="col-6">
                <p class="lead"
                  *ngIf="canOrderProduct(product) && !product.private || product.private && !product.user?.privateShop && userService.sxUser != null || product.private && product.user?.privateShop && userService.sxUser != null && product.user?.isPrivateGroupMember(userService.sxUser.id)">
                  <strong>€ {{product.price}}</strong></p>
                <p class="lead"
                  *ngIf="canOrderProduct(product) && userService.sxUser != null && product.priceFrom != null && product.priceFrom != '' && product.priceFrom != product.price && (!product.private || product.private && !product.user?.privateShop && userService.sxUser != null || product.private && product.user?.privateShop && userService.sxUser != null && product.user?.isPrivateGroupMember(userService.sxUser.id))">
                  <del>€ {{product.priceFrom}}</del></p>
              </div>
              <div class="product-buy"
                *ngIf="!userService.userHasProducts && canOrderProduct(product) && hasStock(product)">
                <button type="button" class="btn btn-success" (click)="addToCart(product)"><span
                    class="fas fa-cart-plus white-text"></span></button>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <pagination 
    [currentPage]="pagination.currentPage"
    [hasMore]="pagination.hasMore"
    (loadMore)="onNextPage()"></pagination>  
  </div>
</div>