<div class="row">
  <form id="userForm" [formGroup]="userForm" (ngSubmit)="onSubmitUserForm(userForm)">
    <div class="container" *ngIf="userService.userHasProducts">
      <div class="header">
        Deze instellingen verschijnen in de orderbevestiging email.
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for="shopTitle">Winkel naam</label><br />
          <input type="text" class="form-control" id="shopTitle"
            placeholder="Bijv. beauty door {{userService.sxUser.displayName}}" formControlName="shopTitle">
        </div>
        <div class="col-md-6">
          <label for="shopDescription">Winkel omschrijving</label><br />
          <textarea class="form-control" id="shopDescription" rows="5" formControlName="shopDescription"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label for="shippingMessage">Bericht na plaatsen bestelling</label><br />
          <textarea class="form-control" rows="5" id="shippingMessage" formControlName="shippingMessage"
             placeholder="Bijv. Voor nu nog even geduld. Je kunt lekker ontspannen en alvast genieten van de mooie beauty momenten die nog gaan komen."></textarea>
        </div>
        <div class="col-md-6">
          <label for="paymentDetailsMessage">Betaalgegevens tekst bestelling versturen</label><br />
          <textarea class="form-control" rows="5" id="paymentDetailsMessage" rows="5" formControlName="paymentDetailsMessage"
             placeholder="Bijv. Op het moment dat je bestelling gereed is voor betaling ontvang je een email met een link waarmee je kunt betalen op een beveiligde site. Zodra wij jouw betaling hebben ontvangen wordt je bestelling verstuurd."></textarea>
        </div>
      </div>  
      <div class="row">
        <div class="col-md-6">
          <label for="shippingDetailsMessage">Wanneer komt de order aan?</label><br />
          <textarea class="form-control" rows="5" id="shippingDetailsMessage" formControlName="shippingDetailsMessage"
             placeholder="Bijv. Wij doen er natuurlijk alles aan om je bestelling zo snel en zorgvuldig mogelijk af te handelen. Wanneer je bestelling betaald en klaar is voor verzending, ontvang je een verzendbevestiging."></textarea>
        </div>
        <div class="col-md-6">
          <label for="orderContactDetails">Hoe ben je bereikbaar?</label><br />
          <textarea class="form-control" rows="5" id="orderContactDetails" rows="5" formControlName="orderContactDetails"
             placeholder="Bijv. Je kunt mij telefonisch bereiken via 06-... of stuur een Whatsapp-bericht."></textarea>
        </div>
      </div>           
      <div class="row">
        <div class="col-md-6">
          <label for="pickupOrderPaymentDetailsMessage">Betaalgegevens bestelling ophalen</label><br />
          <textarea class="form-control" rows="5" id="pickupOrderPaymentDetailsMessage" formControlName="pickupOrderPaymentDetailsMessage"
             placeholder="Bijv. Als je de bestelling ophaalt dan kun je betalen met een Tikkie of contant"></textarea>
        </div>
        <div class="col-md-6">
          <label for="pickupOrderShippingDetailsMessage">Wanneer de bestelling ophalen</label><br />
          <textarea class="form-control" rows="5" id="pickupOrderShippingDetailsMessage" rows="5" formControlName="pickupOrderShippingDetailsMessage"
             placeholder="Bijv. Je krijgt van mij een berichtje wanneer je de bestelling kunt komen ophalen"></textarea>
        </div>
      </div>           
    </div>
    <button type="submit" class="btn btn-success">
      Opslaan
    </button>
  </form>
</div>