import { User } from 'app/models/user';
import { Address } from 'app/models/address';
import { OrderLine } from '../orderline';
import { SendinblueMailBase } from "./sendinblue_mail_base";
import { MailOrderParamsSubscription } from "./mail_order_params_subscription";
import { ProductSubscription } from "../product_subscription";
import { DatePipe } from "@angular/common";

export class MailOrderSubscription extends SendinblueMailBase {
    static instanceType = MailOrderSubscription;

    constructor(orderLines: OrderLine[], shopUser: User, shippingAddress: Address, billingAddress: Address, pickupAddress: Address, sxUser: User, discount: number, productSubscriptions: ProductSubscription[], datePipe: DatePipe) {
        super();
        this.shopUser = shopUser;
        this.params = new MailOrderParamsSubscription(orderLines, shippingAddress, billingAddress, shopUser, pickupAddress, sxUser, discount, productSubscriptions, datePipe);
    }

}
