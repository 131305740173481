import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { User } from 'app/models/user';
import { ReferralService } from './referral.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ReferrerRedirectService  {

  constructor(private router: Router,
    private userService: UserService,
    private referralService: ReferralService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      var userSlug = route.params.slug;
      var inviteId = route.params.inviteId;
      var sxUser: User = this.userService.sxUser;
      var hasSxUser = !(sxUser === undefined || sxUser == null);

      const promotorUser: User = await this.userService.findUserBy('slug', userSlug);
      const referralInvitation: ReferralInvitation = await this.referralService.getReferralInvitationById(inviteId);
      let validReferral: boolean = true;

      if (referralInvitation == null || referralInvitation === undefined || referralInvitation.activated) {
        this.router.navigate(['/login']);
        return resolve(false);
      }

      if (promotorUser.hasShopUser || promotorUser.isShopOwner || promotorUser.hasPrivateShop || promotorUser.hasPrivateGroupMembership) {
        return resolve(true);
      }
      else {
        this.router.navigate(['/login']);
        return resolve(false);
      }
    });
  }   
}
