<div class="container">
  <div class="row flex">
    <div class="title">
      <h5>Totaal</h5>
    </div>
    <div class="content">
      <span>Totaal inc. 21% btw</span>
      <span class="total">&euro; {{totalPrice | number }}</span>
    </div>
    <div class="content" *ngIf="hasDiscountCode">
      <span>Korting <a href="javascript:void(0)" (click)="removeDiscountCode()">
          <span class="delete-link">
            x
          </span>
        </a>
      </span>
      <span class="total">&euro; -{{totalDiscount | number }}</span>
    </div>
    <div class="content" *ngIf="hasDiscountCode">
      <span>Totaal min korting
      </span>
      <span class="total">&euro; {{totalWithDiscountApplied | number }}</span>
    </div>
  </div>
</div>