import { Serializeable } from './serializeable';

export class FileResized extends Serializeable {
    static instanceType = FileResized;
    height: number;
    width: number;
    file: File;

    constructor(file: File, height: number, width: number) {
        super();

        this.height = height;
        this.width = width;
        this.file = file;
    }
}