import { Component, OnInit } from '@angular/core';
import { Order } from 'app/models/order';
import { CustomerService } from 'app/services/customer.service';
import { OrderService } from 'app/services/order.service';

@Component({
  selector: 'customer-order-creator-sent',
  templateUrl: './customer-order-creator-sent.component.html',
  styleUrls: ['./customer-order-creator-sent.component.scss']
})
export class CustomerOrderCreatorSentComponent implements OnInit {
  public order: Order;
  
  constructor(private orderService: OrderService,
              private customerService: CustomerService) { 
    this.order = this.orderService.getCurrentOrder();
    this.customerService.clearOrderCreatorData();
  }

  ngOnInit() {
  }

}
