import { Component, OnInit } from '@angular/core';
import { Product } from 'app/models/product';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from 'app/services/product.service';
import { ProductBaseComponent } from 'app/components/product-base/product-base.component';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent extends ProductBaseComponent {
  public mode: string = "edit";

  ngOnInit() {
  }

}
