import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UploadedFile } from 'app/models/uploaded_file';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private subject = new Subject<UploadedFile>();
  public uploadedFile$: Observable<UploadedFile> = this.subject.asObservable();
  
  fileUploaded(file: UploadedFile) {
      this.subject.next(file);
  }  
}
