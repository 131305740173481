import { Component, Input, OnInit } from '@angular/core';
import { Cart } from 'app/models/cart';
import { CustomerService } from 'app/services/customer.service';
import { DiscountCodeService } from 'app/services/discount-code.service';
import { UserService } from 'app/services/user.service';
import { Subscription } from 'rxjs';
import { CartState } from '../../models/cart-state';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'cart-total',
  templateUrl: './cart-total.component.html',
  styleUrls: ['./cart-total.component.scss']
})
export class CartTotalComponent implements OnInit {
  private subscription : Subscription;
  public totalPrice: number;
  public totalDiscount: number;
  public discountCode: string;
  public discountUserId: string;
  public hasDiscountCode: boolean;
  public totalWithDiscountApplied: number;
  
  @Input() cart: Cart;

  constructor(private cartService: CartService, 
              private customerService: CustomerService,
              private userService: UserService,
              private discountCodeService: DiscountCodeService) { }

  ngOnInit() {
    this.subscription = this
    .cartService
    .cartState
    .subscribe((state : CartState) => {
      if (state.items.length > 0 && state.totalPrice > 0) {
        this.totalPrice = state.totalPrice;
      }

      this.totalDiscount = state.discount;
      this.discountCode = state.discountCode;

      if (!(state.discountCode === undefined || state.discountCode == null)) {
        this.discountCodeService.isValidDiscountCode(this.discountCode, state.discountUserId, this.totalPrice, state.items, state.isPromotorDiscount, state.isProspectDiscount).then(validDiscountCode => {
          this.hasDiscountCode = validDiscountCode;

          if (validDiscountCode) {
            let hasMinimumOrderPrice: boolean = !(state.discountMinimumOrderPrice == null || state.discountMinimumOrderPrice === undefined);
            this.hasDiscountCode = validDiscountCode && hasMinimumOrderPrice && this.totalPrice >= state.discountMinimumOrderPrice;
            if (this.hasDiscountCode) {
              this.totalWithDiscountApplied = this.totalPrice - this.totalDiscount;
            }  
          }
        })
      }
      else {
        this.hasDiscountCode = false;
      }  
    });

    if (this.cart == null) {
      this.cartService.loadCart();
    }

    if (this.cart != null) {
      this.totalPrice = this.cart.totalPrice;
      this.totalDiscount = this.cart.discount;
      this.discountCode = this.cart.discountCode;
      this.discountUserId = this.cart.discountUserId;
      
      this.customerService.cartState.subscribe((state: CartState) => {
        if (state.items.length > 0 && state.totalPrice > 0) {
          this.totalPrice = state.totalPrice;
        }
      });
    }
  }

  removeDiscountCode() {
    this.cartService.removeDiscount();
    this.cartService.fireNewCartState();
    // this.cartService.loadCart();
  }

}
