import { map } from 'rxjs/operators';

export class Serializeable {
    static instanceType: any;

    static serializeResponseMap(): any {
      const createInstance = (r: any) => {
        return Object.assign(new this.instanceType(), r);
      };
  
      return map((respValue: any) => {
        if (Array.isArray(respValue)) {
          return respValue.map(r => createInstance(r));
        }
        return createInstance(respValue);
      });
    }

    static constructWithId(id: any, item: any): any {
      item.id = id;
      return this.construct(item);
    }

    static construct(item: any): any {
      return Object.assign(new this.instanceType(), item)
    }
  }
