<div class="content">
  <div class="product-data">
    <div class="image-wrapper">
      <div class="image-container">
        <img class="img-responsive" src="{{thumbnail}}" alt="{{item.product.title}}"
          title="{{item.product.title}}" style="max-width: 90px; max-height: 120px;" />
      </div>
    </div>
    <div class="info-wrapper">
      <div>
        <div class="brand-name">
          {{item.product.title}}
        </div>
        <div class="" *ngIf="item.productSubscription">
          Abonneer &amp; Bespaar: Elke {{item.productSubscription.frequency}} dagen. 
          <a href="javascript:void(0)" data-toggle="modal" data-target="#subscriptionChangeModal" (click)="loadCartItem()">
            Wijzig
          </a>
        </div>
        
        <!-- <div class="description">
          {{item.product.description}}
        </div> -->
      </div>
      <ul class="link-wrapper" *ngIf="edit">
          <a href="javascript:void(0)" (click)="removeItem(item)">
            <li class="delete-link">
              Verwijder
            </li>      
          </a>
        </ul>
    </div>
  </div>
  <div class="price-data">
    <div class="quantity">
      <span *ngIf="edit">
          <input type="text" name="quantity" value="{{item.quantity}}" (keypress)="numericOnly($event)" (blur)="updateItem(item, $event.target.value)" />
      </span>
      <span *ngIf="!edit">
          {{item.quantity}}
      </span>
    </div>

    <div class="price" *ngIf="!item.productSubscription">&euro; {{item.product.price}}</div>
    <div class="price" *ngIf="item.productSubscription">&euro; {{item.product.subscriptionPrice}}</div>

  </div>
</div>
<p class="separator"></p>