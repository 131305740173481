import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ProductService } from 'app/services/product.service';
import { Product } from 'app/models/product';
import { UserService } from 'app/services/user.service';
import { Photo } from 'app/models/photo';
import { CartService } from 'app/services/cart.service';
import { PrivateGroupService } from 'app/services/private-group.service';
import { User } from 'app/models/user';
import { ShopProductCategory } from 'app/models/shop-product-category';
import { ShopProductCategoryService } from 'app/services/shop-product-category.service';
import { Category } from 'app/models/category';
import { Pagination } from 'app/models/pagination.model';
import { PaginationService } from 'app/services/pagination.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent {
  public products: Product[] = [];
  public productPhotos: Photo[];
  public userSlug: string;
  public showList: boolean = false;
  public privateShopOwners: User[] = new Array<User>();
  public users: User[] = new Array<User>();
  public user: User = null;
  public category: ShopProductCategory = null;
  pagination: Pagination;

  constructor(private productService: ProductService,
    private cartService: CartService,
    public router: Router,
    public route: ActivatedRoute,
    public userService: UserService,
    private shopProductCategoryService: ShopProductCategoryService,
    public paginationService: PaginationService,
  ){
    this.pagination = new Pagination(12);
    this.route.params.subscribe(params => this.loadData(params.userslug, params.categoryslug));
  }

  ngOnInit() {
    this.paginationService.pagination$.subscribe(() => {
      this.products.push(...this.paginationService.products); // Get products from pagination service
    });
 
  }

  async loadUser(slug = '') {
    if (slug !== '') {
        this.user = await this.userService.findUserBySlug(slug);
        this.users.push(this.user);
    }   
  }

  async loadData(slug = '', categorySlug: string) {
    await this.loadUser(slug);
    await this.loadProducts(slug, categorySlug);
    
    this.products?.forEach(product => {
      if (product.user === undefined || product.user == null || !product.user?.hasOwnProperty('id')) {

        const user = this.users.find(u => u.id == product.user_id);
        product.user = User.construct(user);
      }
      else {
        product.user = User.construct(product.user);
      }
    })
    // setTimeout(this.loadPopoverData, 500);
  }

  async loadProducts(slug = '', categorySlug: string) {
    this.userSlug = slug;

    if (slug !== '') {

      if(this.user == null) {
        this.user = await this.userService.findUserBySlug(slug);
      }

      this.category = await this.shopProductCategoryService.getCategory(this.user.id, categorySlug);
      await this.fetchProducts();
    }
  }

  async fetchProducts() {
    const products = await this.productService.getProductsByShopProductCategoryId(this.user, this.category.id, this.pagination.itemsPerPage, this.pagination.lastVisible);      
    await this.paginationService.loadMoreProducts(products);
  }

  gotoProduct(product: Product) {
    this.router.navigate(['/products', product.slug]);
  }

  addToCart(product: Product) {
    this.cartService.addToCart(product);
  }

  productRouterLink(product: Product) {
    if (this.userSlug === undefined || this.userSlug === '') {
      return `/products/${product.slug}`;
    }
    else {
      return ['/', this.userSlug, 'products', product.slug];
    }
  }
  
  canOrderProduct(product: Product): boolean {
    return this.userService.canOrderProduct(product, this.cartService);
  }

  hasStock(product: Product): boolean {
    return this.userService.productHasStock(product, this.cartService);
  }
}
