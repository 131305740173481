import { NgModule, InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';
import { ReferralConfig } from './models/referral/config/referral_config';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
    sendinblueApiKey: string;
    sendinblueSendMailUrl: string;
    sendinblueOrderConfirmationTemplateId: number;
    sendinblueOrderPickupConfirmationTemplateId: number;
    sendinblueShopOwnerOrderConfirmationTemplateId: number;
    sendinblueShopOwnerOrderPickupConfirmationTemplateId: number;
    sendinbluePrivateGroupLinkInvitationLinkTemplateId: number;
    sendinbluePrivateGroupLinkInvitationAcceptedTemplateId: number;
    sendinblueRepeatServiceInvitationCustomerTemplateId: number;
    sendinblueRepeatServiceInvitationCustomerConfirmationTemplateId: number;
    sendinblueNewUserPasswordTemplateId: number;
    sendinblueNewsLetterTemplateId: number;
    sendinblueReferralInvitationToProspectTemplateId: number;
    sendinblueReferralDiscountCodeToProspectTemplateId: number;
    sendinblueReferralDiscountCodeToPromotorTemplateId: number;
    sendinblueNewsLetterCreatorTemplateId: number;
    sendinblueNewsLetterCreatorNaastElkaarTemplateId: number;
    brevoSubscribeAndSaveOrderTemplateId: number;
    brevoSubscribeAndSaveOrderShopOwnerTemplateId: number;
    emailFrom: string;
    emailFromName: string;
    referral: ReferralConfig;
}

export const APP_DI_CONFIG: AppConfig = {
    sendinblueApiKey: environment.sendinblueApiKey,
    sendinblueSendMailUrl: environment.sendinblueSendMailUrl,
    sendinblueOrderConfirmationTemplateId: environment.sendinblueOrderConfirmationTemplateId,
    sendinblueOrderPickupConfirmationTemplateId: environment.sendinblueOrderPickupConfirmationTemplateId,
    sendinblueShopOwnerOrderConfirmationTemplateId: environment.sendinblueShopOwnerOrderConfirmationTemplateId,
    sendinblueShopOwnerOrderPickupConfirmationTemplateId: environment.sendinblueShopOwnerOrderPickupConfirmationTemplateId,
    sendinbluePrivateGroupLinkInvitationLinkTemplateId: environment.sendinbluePrivateGroupLinkInvitationLinkTemplateId,
    sendinbluePrivateGroupLinkInvitationAcceptedTemplateId: environment.sendinbluePrivateGroupLinkInvitationAcceptedTemplateId,
    sendinblueRepeatServiceInvitationCustomerTemplateId: environment.sendinblueRepeatServiceInvitationCustomerTemplateId,
    sendinblueRepeatServiceInvitationCustomerConfirmationTemplateId: environment.sendinblueRepeatServiceInvitationCustomerConfirmationTemplateId,
    sendinblueNewUserPasswordTemplateId: environment.sendinblueNewUserPasswordTemplateId,
    sendinblueNewsLetterTemplateId: environment.sendinblueNewsLetterTemplateId,
    sendinblueReferralInvitationToProspectTemplateId: environment.sendinblueReferralInvitationToProspectTemplateId,
    sendinblueReferralDiscountCodeToProspectTemplateId: environment.sendinblueReferralDiscountCodeToProspectTemplateId,
    sendinblueReferralDiscountCodeToPromotorTemplateId: environment.sendinblueReferralDiscountCodeToPromotorTemplateId,
    sendinblueNewsLetterCreatorTemplateId: environment.sendinblueNewsLetterCreatorTemplateId,
    sendinblueNewsLetterCreatorNaastElkaarTemplateId: environment.sendinblueNewsLetterCreatorNaastElkaarTemplateId,
    brevoSubscribeAndSaveOrderTemplateId: environment.brevoSubscribeAndSaveOrderTemplateId,
    brevoSubscribeAndSaveOrderShopOwnerTemplateId: environment.brevoSubscribeAndSaveOrderShopOwnerTemplateId,
    emailFrom: environment.emailFrom,
    emailFromName: environment.emailFromName,
    referral: environment.referral
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }