import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Address } from 'app/models/address';
import { AddressState } from 'app/models/address_state';
import { Cart } from 'app/models/cart';
import { CartItem } from 'app/models/cartitem';
import { User } from 'app/models/user';
import { AddressService } from 'app/services/address.service';
import { CustomerService } from 'app/services/customer.service';
import { OrderService } from 'app/services/order.service';
import { UserService } from 'app/services/user.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'customer-order-creator-send',
  templateUrl: './customer-order-creator-send.component.html',
  styleUrls: ['./customer-order-creator-send.component.scss']
})
export class CustomerOrderCreatorSendComponent implements OnInit {
  @Input() customer: User;
  public cart: Cart;
  private billingAddress: Address;
  private shippingAddress: Address;
  public showBillingAddress: boolean;
  public sameShippingAndBillingAddress;
  public hasPickupAddress: boolean;
  public shipOrder: boolean = false;
  public pickupOrder: boolean = false;
  public pickupAddressId: string = '';

  constructor(private customerService: CustomerService,
              private orderService: OrderService, 
              private addressService: AddressService,
              private userService: UserService,
              private router: Router) { 
    this.cart = this.customerService.orderCreatorCart;
  }

  async ngOnInit() {
    let pickupAddress = await this.addressService.getAddressForUser(this.userService.sxUser.id, 'pickup');
    this.pickupAddressId = pickupAddress?.id;  

    let index = this.cart.cartItems.findIndex(a => a.product.user.pickup_address_id !== null && a.product.user.pickup_address_id !== undefined);
    this.hasPickupAddress = index != -1;

    if (this.hasPickupAddress) {
      let cartItem: CartItem = this.cart.cartItems[index];
    }
    else {
      this.pickupAddressId = null;
      this.pickupOrder = false;
      this.shipOrder = true;
    }

    if (this.pickupOrder == false && this.shipOrder == false) {
      this.pickupOrder = false;
      this.shipOrder = true;
    }

    this.customerService.saveOrderCreatorCart(this.cart);
    this.cart = this.customerService.orderCreatorCart;

    this.orderService.orderState.subscribe(() => {
      this.router.navigate(['/customer-order-creator/sent']);
    });

    this.addressService.sameBilling.subscribe((state: AddressState) => {
      this.showBillingAddress = !state.sameShippingAndBillingAddress;

      if (state.sameShippingAndBillingAddress) {
        this.billingAddress = state.address;
        this.shippingAddress = state.address;
      }
      else {
        this.shippingAddress = null;
      }

      this.sameShippingAndBillingAddress = state.sameShippingAndBillingAddress;
    });

  }

  changeOrderMethod(shipOrder: boolean) {
    this.shipOrder = shipOrder;
    this.pickupOrder = !shipOrder;

    if (this.pickupOrder) {
      this.cart.pickupAddressId = this.pickupAddressId;
    }
    else {
      this.cart.pickupAddressId = null;
    }

    this.customerService.saveOrderCreatorCart(this.cart);
  }

  placeOrder() {
    this.cart = this.customerService.orderCreatorCart;
    var user: User = this.customerService.orderCreatorCustomer;

    forkJoin([
      this.addressService.getAddressForUser(user.id, 'shipping'),
      this.addressService.getAddressForUser(user.id, 'billing')]
    ).subscribe(async addresses => {
      this.shippingAddress = addresses[0];
      this.billingAddress = addresses[1];

      if (this.sameShippingAndBillingAddress && (this.billingAddress == null || this.billingAddress === undefined)) {
        this.billingAddress = this.shippingAddress;
      }

      await this.orderService.createOrder(this.cart, user, this.shippingAddress, this.billingAddress, this.userService.sxUser.id);     
    });
  }

}
