import { Serializeable } from 'app/models/serializeable';
import { User } from '../user';
import { EmailInfo } from './email_info';

export class SendinblueMailBase extends Serializeable {
    public sender: EmailInfo;
    public to: EmailInfo[];
    public replyTo: EmailInfo;
    public templateId: number;
    public params: any;
    public shopUser: User;
    public promotorUser: User;
   
    constructor() {
        super();

        this.to = new Array<EmailInfo>(); 
    }

    public setFromEmail(shopEmailInfo: EmailInfo) {
        if (this.hasShopUser) {
            shopEmailInfo.name = this.shopUser.shopTitle + ' via Stylexplore.com';
        }

        if (this.hasPromotorUser) {
            shopEmailInfo.name = this.promotorUser.displayName + ' via Stylexplore.com';
        }

        this.sender = shopEmailInfo;
        this.replyTo = shopEmailInfo;
    }

    private get hasShopUser(): boolean {
        let hasNoShopUser: boolean = this.shopUser === undefined || this.shopUser == null;
        return !hasNoShopUser;
    }

    private get hasPromotorUser(): boolean {
        let hasNoPromotorUser: boolean = this.promotorUser === undefined || this.promotorUser == null;
        return !hasNoPromotorUser;
    }
}
