import { Component, OnInit, Input } from '@angular/core';
import { OrderLine } from 'app/models/orderline';

@Component({
  selector: 'orderline',
  templateUrl: './orderline.component.html',
  styleUrls: ['./orderline.component.scss']
})
export class OrderlineComponent implements OnInit {
  @Input() item: OrderLine;

  constructor() { }

  ngOnInit() {
  }

}
