<div class="container">
  <div class="row flex">
    <progressbar [items]="this.progressBarItems"></progressbar>
  </div>
  <div class="container">
    <section id="create" *ngIf="this.createNewsletter">
      <newsletter-creator-create-newsletter *ngIf="this.createNewsletter"></newsletter-creator-create-newsletter>
    </section>
    <section id="selectProduct" *ngIf="this.selectProduct">
      <newsletter-creator-select-product *ngIf="this.selectProduct"></newsletter-creator-select-product>
    </section>
    <section id="previewNewsletter" *ngIf="this.previewNewsletter">
      <newsletter-creator-newsletter-preview *ngIf="this.previewNewsletter"></newsletter-creator-newsletter-preview>
    </section>
    <section id="confirmedStep" *ngIf="this.confirmedStep">
      <newsletter-creator-newsletter-sent *ngIf="this.confirmedStep"></newsletter-creator-newsletter-sent>
    </section>
  </div>
</div>