<h3>Stap 3. Vul adresgegevens in</h3>

<div class="container">
  <div class="row">
    <div class="col-12">
      <address addressType="shipping" editButton="true" title="Bezorgadres" formButtonText="Opslaan"
      sameShipping="true" [user]="customer"></address>
    <address addressType="billing" editButton="true" title="Factuuradres" formButtonText="Opslaan"
      [user]="customer"></address>
    </div>
    <div class="col-12">
      <button type="submit" class="btn btn-success" (click)="gotoSendOrderScreen()">Controleer en verstuur bestelling</button>
    </div>
  </div>
</div>