import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'app-my-preferences',
  templateUrl: './my-preferences.component.html',
  styleUrls: ['./my-preferences.component.scss']
})
export class MyPreferencesComponent {
  private urlFragment: string = '';

  constructor(private route: ActivatedRoute, 
    public userService: UserService
  ) {

    this.route.fragment.subscribe(f => {
      this.urlFragment = f;
      this.navigateToUrlFragment();
    });

  }

  navigateToUrlFragment() {
    if (this.urlFragment != '') {
      const element = document.getElementById(this.urlFragment + '-tab');
      if (element) {
        element.click();
      }
    }
  }

}
