<div class="container">
  <div class="row">
    <progressbar [items]="this.progressBarItems"></progressbar>
  </div>
  <div class="row flex">
    <div class="sidebar">
      <h3>Je Bestelling</h3>
      <cart-items [items]="this.items" [edit]="false"></cart-items>
      <cart-total></cart-total>
    </div>
    <section id="registration">
      <discount-code></discount-code>
      <div *ngIf="hasPickupAddress">
        <h5>Wil je de producten laten bezorgen of afhalen?</h5>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <input type="radio" name="ship" id="ship-order" [checked]="shipOrder" (click)="changeOrderMethod(true)" /><label for="ship-order">Laten bezorgen</label>
            </div>
            <div class="col-md-6">
              <input type="radio" name="ship" id="pickup-order" [checked]="pickupOrder" (click)="changeOrderMethod(false)" /><label for="pickup-order">Afhalen</label>
            </div>    
          </div>
        </div>
      </div>
      <div *ngIf="this.shipOrder">
        <address addressType="shipping" editButton="true" title="Je bezorgadres" formButtonText="Opslaan" sameShipping="true"></address>
        <address addressType="billing" editButton="true" title="Je factuuradres" formButtonText="Opslaan" *ngIf="showBillingAddress"></address>  
      </div>
      <div *ngIf="this.pickupOrder">
        <address [edit]="false" addressType="pickup" [addressId]="pickupAddressId" [editButton]="false" title="Het afhaaladres" formButtonText="Opslaan" [sameShipping]="false"></address>
      </div>
      <button class="btn btn-success fullwidth" (click)="gotoNextScreen()">Verder</button>
    </section>
  </div>
</div>