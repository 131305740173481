import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PrivateGroupInvitation } from 'app/models/private_group_invitation';
import { PrivateGroupMember } from 'app/models/private_group_member';
import { User } from 'app/models/user';
import { AuthService } from 'app/services/auth.service';
import { PrivateGroupService } from 'app/services/private-group.service';
import { UserService } from 'app/services/user.service';
import StringUtils from 'app/utils/stringutils';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public checkoutForm: UntypedFormGroup;
  public loginForm: UntypedFormGroup;
  public displayNewCustomer: boolean = false;
  public displayLogin: boolean = true;
  @Input() registerRoute: string;
  @Input() loginRoute: string;
  @Input() showLoginForm: boolean = true;
  @Input() showRegistrationForm: boolean = true;
  @Input() privateShopUserSlug: string;

  constructor(private authService: AuthService,
    private userService: UserService,
    private privateGroupService: PrivateGroupService,
    private router: Router,
    private formBuilder: UntypedFormBuilder) {
    this.checkoutForm = this.formBuilder.group({
      firstname: '',
      lastname: '',
      email: '',
      password: '',
    });

    this.loginForm = this.formBuilder.group({
      email: '',
      password: ''
    });

  }

  ngOnInit() {
    let invitation: PrivateGroupInvitation = this.privateGroupService.currentInvitation;
    let hasInvitation = invitation != null;
    let hasAccepted = hasInvitation && invitation.accepted;
    if (hasInvitation && !hasAccepted) {
      this.checkoutForm = this.formBuilder.group({
        firstname: invitation.firstname,
        lastname: invitation.lastname,
        email: invitation.email,
        password: ''
      });

    }
    else {
      if (hasInvitation) { // user has already accepted invitation
        this.router.navigate([invitation.shop_user_slug, 'group']);
      }

    }

    if (this.showLoginForm) {
      this.showLogin();
    }
    else {
      this.hideLogin();
    }

    if (this.showRegistrationForm) {
      this.showRegistration();
    }
    else {
      this.hideRegistration();
    }


  }

  onSubmit(customerData: UntypedFormGroup) {
    // Process checkout data here
    if (customerData.valid) {
      let invitation: PrivateGroupInvitation = this.privateGroupService.currentInvitation;
      let hasInvitation = invitation != null;
      let hasPrivateShopUserSlug = this.privateShopUserSlug !== undefined || this.privateShopUserSlug != null;

      let privateGroupMember: PrivateGroupMember = new PrivateGroupMember();
      let shopOwnerUser: User = null;

      if (hasInvitation) {
        invitation.accepted = true;
        invitation.join_date = new Date();

        privateGroupMember.invitation_date = invitation.send_date;
        privateGroupMember.join_date = invitation.join_date;
        privateGroupMember.shop_user_id = invitation.shop_user_id;
        privateGroupMember.shop_user_slug = invitation.shop_user_slug;
        this.registerRoute = this.registerRoute.replace(':id', invitation.id);
      }

      this.authService.userExists(customerData.value.email).then(userExists => {
        if (!userExists) {
          this.authService.createAccount(customerData.value).then(val => {
            this.userService.createUniqueUserSlug(customerData.value.firstname, customerData.value.lastname).then(userSlug => {
              this.userService.createUser(customerData.value, userSlug, val).then(newUser => {
                if (hasInvitation) {
                  invitation.sx_user_id = newUser.id;
                  this.privateGroupService.updateInvitation(invitation);

                  this.userService.getUserById(invitation.shop_user_id).then(shopOwnerUser => {
                    if (shopOwnerUser.privateGroupMembers == null || shopOwnerUser.privateGroupMembers === undefined) {
                      shopOwnerUser.privateGroupMembers = [];
                    }

                    if (shopOwnerUser.privateGroupMembers.indexOf(newUser.id) == -1) {
                      shopOwnerUser.privateGroupMembers.push(newUser.id);
                      this.userService.updateUser(shopOwnerUser);
                    }

                    if (newUser.privateGroupMemberships === undefined || newUser.privateGroupMemberships == null) {
                      newUser.privateGroupMemberships = [];
                    }

                    if (newUser.privateGroupMemberships.indexOf(shopOwnerUser.id) == -1) {
                      newUser.privateGroupMemberships.push(shopOwnerUser.id);
                      this.userService.updateUser(newUser);
                    }

                    this.privateGroupService.createPrivateGroupMember(privateGroupMember).then(pgmId => {
                      privateGroupMember.id = pgmId;
                      privateGroupMember.shop_user_fullname = shopOwnerUser.displayName;
                      privateGroupMember.user_fullname = customerData.value.firstname + ' ' + customerData.value.lastname;
                      privateGroupMember.user_id = newUser.id;
                      this.privateGroupService.updatePrivateGroupMember(privateGroupMember);

                      this.router.navigate([this.registerRoute]);

                    })

                  });
                }

                if (hasPrivateShopUserSlug && !hasInvitation) {
                  this.userService.findUserBySlug(this.privateShopUserSlug).then(shopUser => {
                    shopOwnerUser = shopUser;
                    this.privateGroupService.createInvitationForUser(shopOwnerUser, newUser).then(createdInvitation => {
                      this.registerRoute = this.registerRoute.replace(':id', createdInvitation.id);
                      this.privateGroupService.sendPrivateGroupInvitationToUser(createdInvitation, shopOwnerUser, newUser);
                      this.router.navigate([this.registerRoute]);
                    });
                  });
                }
              });
            });
          });
        }
        else {
          this.userService.findUserBy('email', customerData.value.email).then(user => {
            if (hasInvitation) {
              invitation.sx_user_id = user.id;
              this.privateGroupService.updateInvitation(invitation);

              this.userService.getUserById(invitation.shop_user_id).then(shopOwnerUser => {
                if (shopOwnerUser.privateGroupMembers == null || shopOwnerUser.privateGroupMembers === undefined) {
                  shopOwnerUser.privateGroupMembers = [];
                }

                if (shopOwnerUser.privateGroupMembers.indexOf(user.id) == -1) {
                  shopOwnerUser.privateGroupMembers.push(user.id);
                  this.userService.updateUser(shopOwnerUser);
                }

                if (user.privateGroupMemberships === undefined || user.privateGroupMemberships == null) {
                  user.privateGroupMemberships = [];
                }

                if (user.privateGroupMemberships.indexOf(shopOwnerUser.id) == -1) {
                  user.privateGroupMemberships.push(shopOwnerUser.id);
                  this.userService.updateUser(user);
                }

                this.privateGroupService.createPrivateGroupMember(privateGroupMember).then(pgmId => {
                  privateGroupMember.id = pgmId;
                  privateGroupMember.shop_user_fullname = shopOwnerUser.displayName;
                  privateGroupMember.user_fullname = customerData.value.firstname + ' ' + customerData.value.lastname;
                  privateGroupMember.user_id = user.id;
                  this.privateGroupService.updatePrivateGroupMember(privateGroupMember);
                });

              });

              invitation = this.privateGroupService.currentInvitation;

              if (invitation != null && invitation !== undefined && hasPrivateShopUserSlug) {
                this.registerRoute = this.registerRoute.replace(':id', invitation.id);
              }

              this.router.navigate([this.registerRoute]);
            }

            if (hasPrivateShopUserSlug && !hasInvitation) {
              this.userService.findUserBy('slug', this.privateShopUserSlug).then(shopUser => {
                shopOwnerUser = shopUser;
                this.privateGroupService.createInvitationForUser(shopOwnerUser, user).then(newInvitation => {
                  this.privateGroupService.sendPrivateGroupInvitationToUser(newInvitation, user, shopOwnerUser);
                  this.registerRoute = this.registerRoute.replace(':id', newInvitation.id);
                  this.router.navigate([this.registerRoute]);
                });
              });
            }
          })
        }
      });
    }
  }

  onSubmitLogin(customerData: UntypedFormGroup) {
    this.authService.login(customerData).then(_user => {
      setTimeout(() => {
        this.userService.redirectUrl = null;
        this.router.navigate([this.loginRoute]);
      }, 200);
    })
  }

  showRegistration() {
    this.displayNewCustomer = true;
  }

  hideRegistration() {
    this.displayNewCustomer = false;
  }

  showLogin() {
    this.displayNewCustomer = false;
    this.displayLogin = true;
  }

  hideLogin() {
    this.displayLogin = false;
  }

  fbLogin() {
    this.authService.facebookLogin()
      .then(user => {
        this.authService.userExists(user.user.email).then(res => {
          if (!res) {
            var newUser: User = new User();
            var names = StringUtils.transformDisplayname(user.user.displayName);
            newUser.firstname = names[0];
            newUser.lastname = names[1];
            newUser.email = user.user.email;

            this.userService.createUniqueUserSlug(newUser.firstname, newUser.lastname).then(userSlug => {
              this.userService.createUser(newUser, userSlug, user).then(() => {
                this.router.navigateByUrl(window.history.state);
              });
            });
          }
        });

        let redirect = window.history.state.redirect;
        let productUserId = window.history.state.productUserId;
        this.userService.findUserBy('email', user.user.email).then(sxUser => {
          this.userService.getUserById(productUserId).then(productUser => {
            if (productUser.privateGroupMembers !== undefined && productUser.privateGroupMembers.indexOf(sxUser.id) != -1) {
              this.router.navigateByUrl(window.history.state); // go to product page
            }
            else {
              this.router.navigateByUrl(redirect); // todo: show error because the user is no member of the group
            }
          });
        });

        this.router.navigate(['/']);
      })
  }

}
