<div id="product-detail" class="container-fluid">
    <div class="item">
        <div class="row">
            <div class="card mb-12">
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="card-body">
                            <h5 class="card-title">Producten</h5>
                        <div class="col" *ngIf="sxUser.isShopOwner">
                            <div class="col">
                                Mijn producten
                            </div>
                            <div class="col">
                                <a [routerLink]="['/', sxUser.slug, 'products']">https://www.stylexplore.com/{{sxUser.slug}}/products</a>
                            </div>                                    
                        </div>                            
                        <div class="col pt-2" *ngIf="sxUser.isShopOwner">
                            <div class="col">
                                Beheer producten
                            </div>
                            <div class="col">
                                <a [routerLink]="['/', sxUser.slug, 'manage-products']">https://www.stylexplore.com/{{sxUser.slug}}/manage-products</a>
                            </div>                                    
                        </div>                            
                        <div class="col pt-2" *ngIf="sxUser.isShopOwner">
                            <div class="col">
                                Productfamilies toevoegen
                            </div>
                            <div class="col">
                                <a [routerLink]="['/', sxUser.slug, 'bundles']">https://www.stylexplore.com/{{sxUser.slug}}/bundles</a>
                            </div>                                    
                        </div>                            
                        <div class="col pt-2">
                            <div class="col">Alle producten</div>                                
                            <div class="col">
                                <a [routerLink]="['/', 'products']">https://www.stylexplore.com/products</a>
                            </div>                                    
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>  