<div class="container">
  <div class="row">
    <progressbar [items]="this.progressBarItems"></progressbar>
  </div>
  <div class="row flex">
    <div class="sidebar">
      <h3>Je Bestelling</h3>
      <cart-items [items]="this.items" [edit]="false"></cart-items>
      <cart-total></cart-total>
    </div>
    <section id="registration">
      <ul class="registration-login-toggle">
        <p><b>Heb je al een account?</b></p>
        <div class="form-check">
          <input type="radio" id="mode-registration" name="mode" value="registration" checked
            (click)="showRegistration()">
          <label for="mode-registration"> Nee, ik ben een nieuwe klant.</label>
        </div>

        <div class="form-check">
          <input type="radio" id="mode-login" name="mode" value="login" (click)="showLogin()">
          <label class="form-check-label" for="mode-login"> Ja, ik ben al geregistreerd.</label>
        </div>
      </ul>
      <form *ngIf="displayNewCustomer" id="newCustomerForm" [formGroup]="checkoutForm"
        (ngSubmit)="onSubmit(checkoutForm)">
        <div class="authentication unknown-customer">
          <p class="separator">
            <small>of met jouw e-mailadres inloggen</small>
          </p>
          <div class="form-group">
            <label for="email" class="required">E-mailadres</label>
            <input type="email" class="form-control" id="email" placeholder="E-mailadres" formControlName="email">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="firstname" class="required">Voornaam</label>
            <input type="text" class="form-control" id="firstname" placeholder="Voornaam" formControlName="firstname"
              required>
              <form-validator [control]="checkoutForm.get('firstname')"></form-validator>
          </div>
          <div class="form-group col-md-6">
            <label for="lastname" class="required">Achternaam</label>
            <input type="text" class="form-control" id="lastname" placeholder="Achternaam" formControlName="lastname"
              required>
              <form-validator [control]="checkoutForm.get('lastname')"></form-validator>
          </div>
        </div>
        <div class="form-group">
          <label for="password" class="required">Wachtwoord (minimaal 8 karakters)</label>
          <input type="password" class="form-control" id="password" placeholder="Wachtwoord (minimaal 8 karakters)" formControlName="password"
            required minlength="8">
            <form-validator [control]="checkoutForm.get('password')"></form-validator>
        </div>
        <button type="submit" class="btn btn-success btn-block">
          Verder
        </button>
      </form>
      <form *ngIf="!displayNewCustomer" id="existingCustomerForm" [formGroup]="loginForm"
        (ngSubmit)="onSubmitLogin(loginForm.value)">
        <p class="separator">
          <small>of met jouw e-mailadres inloggen</small>
        </p>
        <h3>Inloggen</h3>
        <div class="form-group">
          <label for="email" class="required">Email</label>
          <input type="email" class="form-control" id="email" placeholder="Email" formControlName="email" required>
          <form-validator [control]="loginForm.get('email')"></form-validator>
        </div>
        <div class="form-group">
          <label for="password" class="required">Wachtwoord (minimaal 8 karakters)</label>
          <input type="password" class="form-control" id="password" placeholder="Wachtwoord (minimaal 8 karakters)" formControlName="password"
            required minlength="8">
            <form-validator [control]="loginForm.get('password')"></form-validator>
        </div>
        <button type="submit" class="btn btn-success btn-block">
          Inloggen
        </button>
      </form>
    </section>
  </div>
</div>