import { Inject, Injectable } from '@angular/core';
import {
  CollectionReference, Firestore,
  collection, doc, query, where, limit,
  getDocs, getDoc, setDoc, Timestamp
} from '@angular/fire/firestore';
import { converter } from 'app/models/converter';
import { APP_CONFIG, AppConfig } from '../app-config.module';
import { ReferralConfig } from 'app/models/referral/config/referral_config';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { User } from 'app/models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { UserService } from './user.service';
import * as password from 'secure-random-password';
import { DiscountCodeService } from './discount-code.service';


@Injectable({
  providedIn: 'root'
})

export class ReferralService extends ServiceBase {
  private referralConfig: ReferralConfig;
  private appConfig: AppConfig;

  private referralUserSubject: BehaviorSubject<User>;
  public referralUser: Observable<User>;

  private invitations: CollectionReference<ReferralInvitation>

  constructor(
    @Inject(APP_CONFIG)
    private config: AppConfig,
    private db: Firestore,
    private userService: UserService,
    private discountCodeService: DiscountCodeService
  ) {
    super();
    this.appConfig = config;
    this.referralConfig = config.referral;

    var currentSxUserLs = localStorage.getItem('referralUser');

    if (currentSxUserLs != null && currentSxUserLs !== "undefined") {
      let theSxUser: User = new User();
      Object.assign(theSxUser, JSON.parse(currentSxUserLs));
      this.referralUserSubject = new BehaviorSubject<User>(theSxUser);
      this.referralUser = this.referralUserSubject.asObservable();
    }
    else {
      this.referralUserSubject = new BehaviorSubject<User>(null);
      this.referralUser = this.referralUserSubject.asObservable();
    }

    this.invitations = collection(db, 'referral_invitations').withConverter(converter<ReferralInvitation>())
  }

  async getReferralInvitation(
    shopownerUserId: string,
    promotorUserId: string,
    prospectEmail: string
  ): Promise<ReferralInvitation> {
    const q = query(this.invitations,
      where('shopowner_user_id', '==', shopownerUserId),
      where('promotor_user_id', '==', promotorUserId),
      where('prospect_email', '==', prospectEmail),
      limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty) return Promise.reject()
      else return ReferralInvitation.constructWithId(snap.docs[0].id, snap.docs[0].data())
    })
  }

  async getReferralInvitationByDiscountCode(discountCode: string): Promise<ReferralInvitation> {
    const q = query(this.invitations,
      where('discount_code', '==', discountCode),
      limit(1))

    return getDocs(q).then(snap => {
      if (snap.empty) return Promise.reject()
      else return ReferralInvitation.constructWithId(snap.docs[0].id, snap.docs[0].data())
    })
  }

  async getReferralInvitationById(id: string): Promise<ReferralInvitation> {
    const ref = doc(this.invitations, id)

    return getDoc(ref).then(ref => ReferralInvitation.constructWithId(ref.id, ref.data()))
  }

  async getActivePromotorDiscounts(userId: string): Promise<ReferralInvitation[]> {
    const q = query(this.invitations,
      where('promotor_user_id', '==', userId),
      where('activated', '==', true),
      limit(20))

    return getDocs(q).then(snap => {
      return snap.docs.map(i => ReferralInvitation.constructWithId(i.id, i.data()))
        .filter(i => !(i.activation_date == null || i.activation_date === undefined) &&
          this.discountCodeService.calculateDateDiff(i.activation_date.toDate()) <= this.referralConfig.discountValidInDays)
    })
  }

  async updateReferralInvitation(invitation: ReferralInvitation): Promise<void> {
    const ref = doc(this.invitations, invitation.id)

    return setDoc(ref, invitation)
  }

  async createNewReferralInvitation(
    prospectEmail: string,
    prospectFirstName: string,
    prospectLastName: string,
    shopownerUser: User,
    promotorUser: User
  ): Promise<ReferralInvitation> {
    const ref = doc(this.invitations)

    let invitation = new ReferralInvitation(ref.id, this.userService.user.uid);
    invitation.shopowner_user_id = shopownerUser.id;
    invitation.promotor_name = promotorUser.displayName;
    invitation.promotor_email = promotorUser.email;
    invitation.prospect_email = prospectEmail;
    invitation.prospect_first_name = prospectFirstName;
    invitation.prospect_last_name = prospectLastName;
    invitation.minimum_order_price = this.referralConfig.minimumOrderPrice;
    invitation.promotor_discount = this.referralConfig.promotorDiscount;
    invitation.prospect_discount = this.referralConfig.prospectDiscount;
    invitation.usedByProspect = false;
    invitation.usedByPromotor = false;
    invitation.activated = false;

    return setDoc(ref, invitation).then(_ref => invitation)
  }

  async linkReferralInvitationToProspect(
    shopOwnerUserId: string,
    promotorUserId: string,
    referralUserId: string
  ): Promise<boolean> {
    return new Promise<boolean>(async (resolve, _reject) => {
      const referralInvitation: ReferralInvitation = await this.getReferralInvitation(shopOwnerUserId, promotorUserId, this.userService.sxUser.email);

      if (!(referralInvitation === undefined || referralInvitation == null)) {
        if (!referralInvitation.activated) {
          referralInvitation.prospect_user_id = referralUserId;
          referralInvitation.discount_date = timestamp();
          referralInvitation.discount_code = password.randomPassword();
          referralInvitation.activated = true;
          referralInvitation.usedByProspect = false;

          this.updateReferralInvitation(referralInvitation);
          resolve(true);
        }
      } else resolve(false)
    });
  }

  async linkReferralInvitationToProspectByDiscountCode(discountCode: string): Promise<boolean> {
    const referralInvitation: ReferralInvitation = await this.getReferralInvitationByDiscountCode(discountCode);

    if (!(referralInvitation === undefined || referralInvitation == null)) {
      if (!referralInvitation.activated) {
        referralInvitation.prospect_user_id = this.userService.sxUser.id;
        referralInvitation.discount_date = timestamp();
        referralInvitation.discount_code = password.randomPassword();
        referralInvitation.activated = true;

        return this.updateReferralInvitation(referralInvitation).then(() => true)
      }
    } else Promise.resolve(false);
  }

  activateDiscountCode(invitation: ReferralInvitation): boolean {
    if (invitation.activated) {
      return false;
    }

    invitation.discount_code = password.randomPassword();
    invitation.activated = true;
    invitation.usedByProspect = false;
    invitation.discount_date = timestamp();

    this.updateReferralInvitation(invitation);

    return true;
  }

  async getTotalSentReferralInvitations(promotorUserId: string): Promise<ReferralInvitation[]> {
    const q = query(this.invitations,
      where('promotor_user_id', '==', promotorUserId))

    return getDocs(q).then(snap => snap.docs.map(ref => ReferralInvitation.constructWithId(ref.id, ref.data())))
  }
}

function timestamp(date = new Date()) {
  return Timestamp.fromDate(date)
}
