import { Injectable } from '@angular/core';
import { ProgressBarItem } from 'app/models/progressbaritem';

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {
  constructor() { }

  public getCheckoutProgressBar(activeStep: number = 2): Array<ProgressBarItem> {
    var items: Array<ProgressBarItem> = new Array<ProgressBarItem>();

    var step1 = new ProgressBarItem("/products", "Shoppen", activeStep == 1, true);
    var step2 = new ProgressBarItem("/checkout", "Jouw gegevens", activeStep == 2, activeStep == 3 || activeStep == 4);
    var step3 = new ProgressBarItem("/shipping", "Adresgegevens", activeStep == 3 || activeStep == 4, activeStep == 4);    
    var step4 = new ProgressBarItem("/confirm", "Bestellen", activeStep == 4, activeStep == 5);

    items.push(step1);
    items.push(step2);
    items.push(step3);
    items.push(step4);

    return items;
  }

  public getAddProductProgressBar(productUrl: string, productPhotosUrl: string, activeStep: number = 1): Array<ProgressBarItem> {
    var items: Array<ProgressBarItem> = new Array<ProgressBarItem>();

    var step1 = new ProgressBarItem(productUrl, "Productgegevens invullen", activeStep == 1, true);
    var step2 = new ProgressBarItem(productPhotosUrl, "Productfoto's toevoegen", activeStep == 2);

    items.push(step1);
    items.push(step2);
    return items;
  }

  public getCustomerOrderCreatorProgressBar(activeStep: number = 1): Array<ProgressBarItem> {
    var items: Array<ProgressBarItem> = new Array<ProgressBarItem>();

    var step1 = new ProgressBarItem("/customer-order-creator", "Klant gegevens", activeStep == 1, true);
    var step2 = new ProgressBarItem("/customer-order-creator/create-order", "Order gegevens", activeStep == 2, activeStep == 3 || activeStep == 4);
    var step3 = new ProgressBarItem("/customer-order-creator/confirm", "Plaats bestelling", activeStep == 3 || activeStep == 4, activeStep == 4);    

    items.push(step1);
    items.push(step2);
    items.push(step3);

    return items;
  }

  public getNewsletterCreatorProgressBar(activeStep: number = 1): Array<ProgressBarItem> {
    var items: Array<ProgressBarItem> = new Array<ProgressBarItem>();

    var step1 = new ProgressBarItem("/newsletter-creator", "Nieuwsbrief opstellen", activeStep == 1, true);
    var step2 = new ProgressBarItem("/newsletter-creator/select-product", "Product selecteren", activeStep == 2, activeStep == 3 || activeStep == 4);
    var step3 = new ProgressBarItem("/newsletter-creator/preview", "Nieuwsbrief controleren en versturen", activeStep == 3 || activeStep == 4, activeStep == 4);

    items.push(step1);
    items.push(step2);
    items.push(step3);

    return items;
  }

}
