import { Component, OnInit, Input } from '@angular/core';
import { ProductBundle } from 'app/models/product_bundle';

@Component({
  selector: 'product-bundle',
  templateUrl: './product-bundle.component.html',
  styleUrls: ['./product-bundle.component.scss']
})
export class ProductBundleComponent implements OnInit {

  @Input() model: ProductBundle;
  constructor() { 
  }

  ngOnInit() {
  }

}
