<div class="container">
    <div class="row back-button pb-0">
        <div class="fas fa-arrow-left arrow-back"></div>
        <a [routerLink]="['/', userSlug, 'manage-products']" *ngIf="userSlug !== undefined">Terug naar productenbeheer</a>
      </div>    
    <div id="products" class="row view-group" *ngIf="categories">
        <h2>Beheer productcategorieën</h2>
        <div class="col-lg-12 pl-0">
            <div class="pull-right">
              <div class="btn-group">
                <button class="btn btn-info" id="add" [routerLink]="['/', userSlug, 'product-category', 'add']">
                  Productcategorie toevoegen
                </button>
              </div>
            </div>
          </div>
        <div class="list">
            <div class="row">
                <div class="item col">
                    Hoofdcategorie
                </div>
                <div class="item col">
                    Volgorde
                </div>
                <div class="item col">

                </div>
            </div>
            <div class="item col" *ngFor="let category of categories">
                <div class="row">
                    <div class="item col pl-0 pr-0">
                        {{category.title}}
                    </div>
                    <div class="item col pl-0 pr-0">
                        {{category.sequence}}
                    </div>
                    <div class="item col pl-0 pr-0">
                        <div class="col-xs-12 col-md-6">
                            <a [routerLink]="['/', userSlug, 'product-category', category.slug,  'edit']">Bewerken</a><br />
                            <a (click)="deleteCategory(category)" href="javascript:void(0)">Verwijder</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>