import { Serializeable } from './serializeable';

export class User extends Serializeable {
    static instanceType = User;

    id: string;
    displayName: string;
    email: string;
    firstname: string;
    lastname: string;
    user_type: number;
    slug: string;
    shipping_address_id: string;
    billing_address_id: string;
    pickup_address_id: string;
    shopTitle: string;
    shopDescription: string;
    shippingMessage: string;
    paymentDetailsMessage: string;
    shippingDetailsMessage: string;
    pickupOrderPaymentDetailsMessage: string;
    pickupOrderShippingDetailsMessage: string;
    orderContactDetails: string;
    phoneNumber: string;
    photo: string;
    privateShop: boolean;
    privateGroupMembers: string[];
    privateGroupMemberships: string[];
    admin: boolean;
    customer: boolean;
    shop_user_id: string;
    advantage1: string;
    advantage2: string;
    advantage3: string;
    advantage4: string;
    referralEmailText: string;

    constructor(id = null, user_id = null) {
        super();
    }

    public get hasProfilePhoto(): boolean {
        let noPhoto:boolean = this.photo === undefined || this.photo == "";
        return !noPhoto;
    }

    public get hasShopTitle(): boolean {
        let noShopTitle:boolean = this.shopTitle === undefined || this.shopTitle == "";
        return !noShopTitle;
    }

    public get hasPrivateGroupMembership(): boolean {
        let noMemberships: boolean = this.privateGroupMemberships === undefined || this.privateGroupMemberships == null;
        let isMember: boolean = false;

        if (!noMemberships) {
            isMember = this.privateGroupMemberships.length > 0;
        }

        return isMember;
    }

    public isPrivateGroupMember(userId: string) {
        if (userId == null || userId === undefined || userId === '')
            return false;

        if (userId === this.id) { // shop owner
            return true;
        }

        let noPrivateMembers: boolean = this.privateGroupMembers === undefined || this.privateGroupMembers == null;
        let isMember: boolean = false;
        if (!noPrivateMembers) {
            isMember = this.privateGroupMembers.indexOf(userId) != -1;
        }       
        
        return isMember;
    }

    public get hasPrivateShop(): boolean {
        return this.privateShop !== undefined ? this.privateShop : false;
    }

    public get hasProducts(): boolean {
        return this.user_type == 2;
    }

    public get hasPickupAddress(): boolean {
        let hasNoPickupAddress = this.pickup_address_id == null || this.pickup_address_id == undefined;
        return !hasNoPickupAddress;
    }

    public get isShopOwner(): boolean {
        return this.hasPrivateShop || this.hasProducts;
    }

    public get hasShopUser(): boolean {
        let hasNoShopUser: boolean = this.shop_user_id == null || this.shop_user_id === undefined;
        return !hasNoShopUser;
    }
}