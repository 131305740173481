import { Component, Input, OnInit } from '@angular/core';
import { User } from 'app/models/user';
import { CustomerService } from 'app/services/customer.service';

@Component({
  selector: 'customer-order-creator-address-data',
  templateUrl: './customer-order-creator-address-data.component.html',
  styleUrls: ['./customer-order-creator-address-data.component.scss']
})
export class CustomerOrderCreatorAddressDataComponent implements OnInit {
  @Input() customer: User;

  constructor(private customerService: CustomerService) { }

  ngOnInit() {
  }

  gotoSendOrderScreen() {
    this.customerService.setOrderCreatorStep('send');
  }
}
