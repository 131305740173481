import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'app/services/order.service';
import { Order } from 'app/models/order';
import { Address } from 'app/models/address';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  public order: Order;
  public shippingAddress: Address;
  public billingAddress: Address;
  public pickupAddress: Address;
  public sameAddress: boolean;
  
  constructor(public route: ActivatedRoute, 
              private orderService: OrderService) { 
    this.route.params.subscribe(params => this.getOrder(params.id));
  }

  ngOnInit() {
  }

  async getOrder(slug: string) {
    this.orderService.getOrderById(slug).then(order => {
      this.order = order;

      order.addresses.forEach(address => {
        let orderAddress: Address = new Address();
        Object.assign(orderAddress, address);
    
        if (orderAddress.address_type == 'shipping') {
          this.shippingAddress = orderAddress;
        }  

        if (orderAddress.address_type == 'billing') {
          this.billingAddress = orderAddress;
        }  

        if (orderAddress.address_type == 'pickup') {
          this.pickupAddress = orderAddress;
        }
      }); 

      this.sameAddress = order.billing_address_id == order.shipping_address_id;
    });
  }

}
