import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ReferralService } from './referral.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ReferralAuthGuardService {
  constructor
    (public afAuth: Auth,
      public userService: UserService,
      public referralService: ReferralService,
      private router: Router) {
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      var sxUser = this.userService.sxUser;
      var hasSxUser: boolean = sxUser != null;
      var validUser: boolean = false;

      if (!hasSxUser) {
        return resolve(false);
      }

      // shop owner or customer
      if (sxUser.isShopOwner || sxUser.hasPrivateGroupMembership ||
        sxUser.hasShopUser || sxUser.privateShop || sxUser.customer) {
        validUser = true;
        return resolve(true);
      }

      if (!validUser) {
        this.router.navigate(['/login']);
      }
      else {
        return resolve(true);
      }

      return resolve(false);

    });
  }

}
