import { Component, OnInit } from '@angular/core';
import { ProductBundle } from 'app/models/product_bundle';
import { ProductService } from 'app/services/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { User } from 'app/models/user';

@Component({
  selector: 'app-product-bundle-add',
  templateUrl: './product-bundle-add.component.html',
  styleUrls: ['./product-bundle-add.component.scss']
})
export class ProductBundleAddComponent implements OnInit {

  public model: ProductBundle;
  private user: User;

  constructor(private productService: ProductService,
              private userService: UserService, 
              private router: Router,
              public route: ActivatedRoute) { 
                this.route.params.subscribe(params => this.loadUser(params.userslug));
  }

  ngOnInit() {
    this.model = new ProductBundle();
  }

  private loadUser(userslug: string) {
    this.userService.findUserBySlug(userslug).then(user => {
      this.user = user;
    })
  }

  addProductBundle() {
    this.model.user_id = this.user.id;
    this.model.slug = this.model.generateSlug;

    this.productService.addProductBundle(this.model)
    .then(productId => {
      this.model.id = productId;
      this.router.navigate([this.userService.sxUser.slug, 'bundles']);
    });  
  }

}
