import { Component, Inject, OnInit } from '@angular/core';
import { AppConfig, APP_CONFIG } from 'app/app-config.module';
import { ReferralConfig } from 'app/models/referral/config/referral_config';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from 'app/models/user';
import { OrdermailerService } from 'app/services/ordermailer.service';
import { ReferralMailerService } from 'app/services/referral-mailer.service';
import { ReferralService } from 'app/services/referral.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss']
})
export class ReferAFriendComponent implements OnInit {
  public referAFriendForm: UntypedFormGroup;
  public discountCodeSent: boolean = false;
  public shopOwner: User;
  public referralConfig: ReferralConfig;
  public sentInvitations: ReferralInvitation[];
  public usedInvitations: Array<ReferralInvitation>;
  public invitesUsed: number = 0;
  public invitesUnused: number = 0;
  public totalInvitesSent: number = 0;
  public successfulInvitesText: string;
  public totalClicks: number = 0;
  private _sxUser: User;

  constructor(@Inject(APP_CONFIG) private config: AppConfig,
    public userService: UserService,
    private referralService: ReferralService,
    private referralMailerService: ReferralMailerService,
    private formBuilder: UntypedFormBuilder) {
    this.referralConfig = config.referral;
    this._sxUser = this.userService.sxUser;
    this.fillUserDataForm();
    this.usedInvitations = new Array<ReferralInvitation>();
  }

  async ngOnInit() {
    this.shopOwner = await this.userService.getSponsorUser();
    this.sentInvitations = await this.referralService.getTotalSentReferralInvitations(this.userService.sxUser.id);
    this.totalInvitesSent = this.sentInvitations.length;
    this.sentInvitations.forEach(invite => {
      if (invite.usedByProspect) {
        this.invitesUsed += 1;
        this.usedInvitations.push(invite);
      }
      else {
        this.invitesUnused += 1;
      }

      if (!(invite.prospectLinkClicks === undefined || invite.prospectLinkClicks == null)) {
        this.totalClicks += invite.prospectLinkClicks;
      }
    });

    if (this.invitesUsed == 0) {
      this.successfulInvitesText = "Nog geen succesvolle verwijzingen";
    }
    else {
      this.successfulInvitesText =  this.invitesUsed.toString() + " succesvolle verwijzingen"
    }
  }

  private fillUserDataForm(): void {
    this.referAFriendForm = this.formBuilder.group({
      firstname: '',
      lastname: '',
      email: '',
      invitationText: this._sxUser.referralEmailText
    });
  }

  onSubmitReferAFriendForm(referForm: UntypedFormGroup) {
    if (referForm.valid) {
      this.referralService.createNewReferralInvitation(referForm.value.email, referForm.value.firstname, referForm.value.lastname, this.shopOwner, this.userService.sxUser).then(invitation => {
        this.referralMailerService.sendReferralInvitationEmailToProspect(this.shopOwner, this.userService.sxUser, invitation);
        this.discountCodeSent = true;
      });
    }
  }

}
