<link href="https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.6.1/css/bootstrap4-toggle.min.css"
  rel="stylesheet">
<script src="https://cdn.jsdelivr.net/gh/gitbrent/bootstrap4-toggle@3.6.1/js/bootstrap4-toggle.min.js"></script>

<div class="container">
  <div class="row">
    <div class="col-md-3">
      <h5 class="profile-header"><b>Gegevens &amp; voorkeuren</b></h5>
      <ul class="nav flex-column">
        <li *ngIf="userService.userHasProducts"><a id="shop-tab" href="#shop" data-toggle="tab">Winkel</a></li>
        <li *ngIf="userService.userHasProducts"><a id="address-tab" href="#address" data-toggle="tab">Afhaaladres</a></li>
        <li><a id="referral-tab" href="#messages" data-toggle="tab">Berichten van Stylexplore.com</a></li>
      </ul>
    </div>
    <div class="col-md-9">
      <section>
        <!-- Tab panes -->
        <div class="tab-content">
          <div class="tab-pane container fade in show active" id="shop" *ngIf="userService.userHasProducts">
            <h5 class="profile-header tab-header">Winkel</h5>
            <profile-shop-settings></profile-shop-settings>
          </div>
          <div class="tab-pane container fade" id="address" *ngIf="userService.userHasProducts">
            <h5 class="profile-header tab-header">Afhaaladres</h5>
            <profile-address-settings></profile-address-settings>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>