<div class="container">
  <div class="row flex">
    <section id="cart" class="w-100">
      <h3>Controleer en bestel</h3>
      <div class="container">
        <div class="row">
          Klant: {{customer.displayName}} ({{customer.email}})
        </div>  
        <div class="row border">
          <div *ngIf="hasPickupAddress">
            <h5>Wil de klant de producten laten bezorgen of komen afhalen?</h5>
            <div class="container">
              <div class="row">
                <div class="col-md-6">
                  <input type="radio" name="ship" [checked]="shipOrder" id="ship-order" (click)="changeOrderMethod(true)" /><label for="ship-order">Laten bezorgen</label>
                </div>
                <div class="col-md-6">
                  <input type="radio" name="ship" [checked]="pickupOrder" id="pickup-order" (click)="changeOrderMethod(false)" /><label for="pickup-order">Afhalen</label>
                </div>    
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" *ngIf="this.pickupOrder">
            <address [displayFullWidth]="true" [edit]="false" addressType="pickup" [addressId]="pickupAddressId" addressType="pickup" edit="false" title="Het afhaaladres" formButtonText="Opslaan"></address>
          </div>
          <div class="col-sm" *ngIf="this.shipOrder">
            <address addressType="shipping" [editButton]="true" title="Bezorgadres" formButtonText="Opslaan" [user]="customer" sameShipping="true"></address>
          </div>
          <div class="col-sm" *ngIf="this.shipOrder">
            <address addressType="billing" [editButton]="true" title="Factuuradres" formButtonText="Opslaan" [user]="customer" *ngIf="showBillingAddress"></address>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="fullwidth">
            <product-items [cart]="cart" [edit]="false"></product-items>
            <cart-total [cart]="cart"></cart-total>      
            <button class="btn btn-success" (click)="placeOrder()">Plaats bestelling</button>
          </div>
        </div>
      </div>      
    </section>
  </div>
</div>