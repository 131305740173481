<div id="login" class="mx-auto">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin(loginForm.value)">
    <h3 align="center">Inloggen</h3>
    <div class="error">
      <div *ngIf="passwordInvalid">Je wachtwoord is onjuist. Vul het wachtwoord opnieuw in.</div>
      <div *ngIf="emailBadlyFormatted">Je e-mail is in een incorrect formaat. Controleer je email adres.</div>
      <div *ngIf="noUserFound">Dit is een onbekend account. Controleer je email en je wachtwoord.</div>
      <br />
    </div>
    <div class="form-group">
      <label for="email">E-mailadres</label>
      <input type="email" class="form-control" id="email" placeholder="E-mailadres" formControlName="email">
    </div>
    <div class="form-group">
      <label for="password">Wachtwoord</label>
      <input type="password" class="form-control" id="password" placeholder="Wachtwoord" formControlName="password">
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-primary btn-block" style="margin-bottom: 20px">
        Inloggen
      </button>
    </div>
  </form>

  <div class="form-group">
    <button type="buton" class="btn btn-block" (click)="fbLogin()">
      <i class="fa fa-facebook" aria-hidden="true"></i>
      Inloggen met Facebook
    </button>

    <!-- <button type="button" class="btn btn-block">
      <i class="fa fa-twitter" aria-hidden="true"></i>
      Login with Twitter
    </button>

    <button type="button" class="btn btn-block">
      <i class="fa fa-github" aria-hidden="true"></i>
      Login with Github
    </button>

    <button type="button" class="btn btn-block">
      <i class="fa fa-google" aria-hidden="true"></i>
      Login with Google
    </button> -->
  </div>
</div>