import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RepeatService } from 'app/models/repeat_service';
import { User } from 'app/models/user';
import { OrdermailerService } from 'app/services/ordermailer.service';
import { RepeatEmailService } from 'app/services/repeat.email.service';
import { UserService } from 'app/services/user.service';
import { repeat } from 'rxjs/operators';

@Component({
  selector: 'app-repeat-service-confirmation-email',
  templateUrl: './repeat-service-confirmation-email.component.html',
  styleUrls: ['./repeat-service-confirmation-email.component.scss']
})
export class RepeatServiceConfirmationEmailComponent implements OnInit {

  constructor(private orderMailerService: OrdermailerService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private repeatEmailService: RepeatEmailService) { }
    public sxUser: User;

  ngOnInit() {
    this.route.params.subscribe(params => this.sendEmail(params));  
  }

  sendEmail(params: Params) {
    let shopuser_id: string = params.shopuser_id;
    let user_id: string = params.user_id;
    let shopUser: User;

    this.userService.getUserById(shopuser_id).then(user => {
      shopUser = user;
      this.userService.getUserById(user_id).then(user2 => {
        this.sxUser = user2;
        this.repeatEmailService.get(shopUser.id, user2.id).then(repeatService => {
          repeatService.join_date = new Date();
          repeatService.active = true;
          this.repeatEmailService.update(repeatService);
          this.orderMailerService.sendRepeatServiceInvitationConfirmationToCustomer(shopUser, user2);
        })
      });
    });

  }

}
