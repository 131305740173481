<div class="container">
  <div class="jumbotron">
    <h1 class="display-4">Hoe het werkt</h1>
      <ul>
        <li>Laat je vrienden kennismaken met {{shopOwner?.shopTitle}}</li>
        <li>Ontvang € {{referralConfig.promotorDiscount.toFixed(2)}} korting bij bestellingen vanaf € {{referralConfig.minimumOrderPrice.toFixed(2)}} voor elke vriend(in) die door jou kennis maakt met {{shopOwner?.shopTitle}}</li>
        <li>Je vriend(in) ontvangt € {{referralConfig.prospectDiscount.toFixed(2)}} korting bij het plaatsen van de eerste bestelling</li>
      </ul>
  </div>
  <div class="row">
    <div class="col-md-3">
      <h3>{{successfulInvitesText}}</h3>
      <ul class="invites" *ngIf="invitesUsed > 0">
        <li *ngFor="let invite of usedInvitations">
          {{invite.prospect_first_name}} {{invite.prospect_last_name}}
        </li>
      </ul>
      <hr />
      <div class="row invite-row">
        <div class="col-md-9">Uitnodigingen verzonden</div>
        <div class="col-md-3 text-end">{{totalInvitesSent}}</div>
      </div>
      <hr />
      <div class="row invite-row">
        <div class="col-md-9">Aantal keer geklikt op de uitnodigingen</div>
        <div class="col-md-3 text-end">{{totalClicks}}</div>
      </div>
      <hr />
    </div>
    <div class="col-md-6">
      {{userService.sxUser.displayName}}, nodig nu iemand uit via:
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#profile">Email</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#shop">Jouw naam</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#newsletter">Jouw link</a>
        </li>
      </ul>
    
      <div class="tab-content">
        <div class="tab-pane container fade in show active" id="profile">
          <div class="card border-success mb-9" *ngIf="discountCodeSent">
            <div class="card-body text-success">
              <h5 class="card-title">De uitnodiging is verstuurd per email</h5>
              <p class="card-text">Heeft de persoon na enkele minuten geen mail ontvangen? Check dan de SPAM box.</p>
            </div>
          </div>
          <form id="userForm" [formGroup]="referAFriendForm" (ngSubmit)="onSubmitReferAFriendForm(referAFriendForm)">
            <div class="row">
              <div class="col-sm">
                <label for="firstname" class="required">Voornaam</label>
                <input type="text" class="form-control" id="firstname" placeholder="Voornaam"
                  formControlName="firstname" required>
              </div>
              <div class="col-sm">
                <label for="lastname" class="required">Achternaam</label>
                <input type="text" class="form-control" id="lastname" placeholder="Achternaam"
                  formControlName="lastname" required>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label for="email" class="required">Email</label><br />
                <input type="text" class="form-control" id="email" placeholder="Email" formControlName="email"
                  required>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label for="email" class="required">Tekst</label><br />
                <textarea  class="form-control" id="invitationText" placeholder="Tekst" formControlName="invitationText"
                  required rows="5"></textarea>
              </div>
            </div>
            <button type="submit" class="btn btn-success btn-block">
              Verstuur
            </button>
    
            </form>
          
        </div>
        <div class="tab-pane container fade" id="shop">
          <profile-shop-settings></profile-shop-settings>
        </div>
        <div class="tab-pane container fade" id="newsletter">
          <profile-newsletter-settings></profile-newsletter-settings>
        </div>
        <div class="tab-pane container fade" id="address">
          <profile-address-settings></profile-address-settings>
        </div>
      </div>    
    </div>
  </div>
</div>

