// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
   apiKey: 'AIzaSyChgqdxp508pP3v3O16zBW9dSlN6zoLNxA',
   authDomain: 'stylexplore-prod.firebaseapp.com',
   databaseURL: 'https://stylexplore-prod.firebaseio.com',
   projectId: 'stylexplore-prod',
   storageBucket: 'stylexplore-prod.appspot.com',
   messagingSenderId: ''
  },
  sendinblueApiKey: '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',
  sendinblueSendMailUrl: 'https://api.sendinblue.com/v3/smtp/email',
  sendinblueOrderConfirmationTemplateId: 85,
  sendinblueOrderPickupConfirmationTemplateId: 100,
  sendinblueShopOwnerOrderPickupConfirmationTemplateId: 101,
  sendinblueShopOwnerOrderConfirmationTemplateId: 97,
  sendinbluePrivateGroupLinkInvitationLinkTemplateId: 89,
  sendinbluePrivateGroupLinkInvitationAcceptedTemplateId: 90,
  sendinblueRepeatServiceInvitationCustomerTemplateId: 95,
  sendinblueRepeatServiceInvitationCustomerConfirmationTemplateId: 93,
  sendinblueNewUserPasswordTemplateId: 98,
  sendinblueNewsLetterTemplateId: 99,
  sendinblueReferralInvitationToProspectTemplateId: 71,
  sendinblueReferralDiscountCodeToProspectTemplateId: 72,
  sendinblueReferralDiscountCodeToPromotorTemplateId: 78,
  sendinblueNewsLetterCreatorTemplateId: 80,
  sendinblueNewsLetterCreatorNaastElkaarTemplateId: 82,
  brevoSubscribeAndSaveOrderTemplateId: 93,
  brevoSubscribeAndSaveOrderShopOwnerTemplateId: 103,
  emailFrom: 'noreply@stylexplore.com',
  emailFromName: 'StyleXplore',
  referral: {
    minimumOrderPrice: 25,
    promotorDiscount: 5,
    prospectDiscount: 7.5,
    discountValidInDays: 14
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
