<div class="uploader">
    <div class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)" [class.hovering]="isHovering" *ngIf="multiple">
        <p>Drag and Drop one or more files</p>
    </div>
    <div class="file">
        <label class="file-label">
        <input class="file-input" type="file" multiple="multiple" (change)="onDrop($event.target.files)" *ngIf="multiple">
        <input class="file-input" type="file" (change)="onDrop($event.target.files)" *ngIf="single">
          <span class="file-cta">
            <span class="file-icon">
              <i class="fa fa-upload"></i>
            </span>
            <span class="file-label" *ngIf="multiple">
              kies bestand(en)
            </span>
            <span class="file-label" *ngIf="single">
              kies een bestand
            </span>
          </span>
        </label>
      </div>
    
    <div *ngFor="let file of files">
        <upload-task [file]="file" [uid]="this.uid" [resizeImage]="this.resizeImage" [resizedImageHeight]="this.resizedImageHeight" [resizedImageWidth]="this.resizedImageWidth" [imageSizes]="this.imageSizes"></upload-task>
    </div>
</div>