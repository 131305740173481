<div class="wrapper">
  <ol class="progressbar">
    <li class="progressbar-step {{item.cssClass}}" *ngFor="let item of items">
      <a href="{{item.url}}">
        <svg class="progressbar-icon">
            <use xlink:href="#checkmark-bold" /></svg>
      </a>
      <span class="progressbar-step-label"><a href="{{item.url}}" class="progressbar-link">{{item.label}}</a></span>
    </li>
  </ol>
  <svg xmlns="http://www.w3.org/2000/svg" class="svg">
    <symbol id="checkmark-bold" viewBox="0 0 24 24">
      <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
    </symbol>
  </svg>
</div>