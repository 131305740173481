<div class="container">
  <div class="row back-button pb-0">
    <div class="fas fa-arrow-left arrow-back"></div>
    <a [routerLink]="['/', userSlug, 'products']" *ngIf="userSlug !== undefined">Terug naar productoverzicht</a>
  </div>

  <div class="row" *ngIf="userService.userHasProducts">
    <div class="col pl-0">
      <p class="h2">Beheer producten</p>
    </div>
    <div class="col-lg-12 pl-0">
      <div class="pull-right">
        <div class="btn-group">
          <button class="btn btn-info" id="add" [routerLink]="['/products-add']">
            Product toevoegen
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-12 pt-2 pl-0">
      <div class="pull-right">
        <div class="btn-group">
          <button class="btn btn-info" id="add" [routerLink]="['/', userSlug, 'manage-product-categories']">
            Beheer productcategorieën
          </button>
        </div>
      </div>
    </div>
  </div>
  <div id="products" class="row view-group" *ngIf="products">
    <div class="list" *ngIf="showList">
      <div class="row">
        <div class="item col-sm-3">
          Product
        </div>
        <div class="item col-sm-3">
          Prijs
        </div>
        <div class="item col-sm-3">
          Voorraad
        </div>
        <div class="item col-sm-3">

        </div>
      </div>
      <div class="item col-sm-12" *ngFor="let product of products">
        <div class="row">
          <div class="item col-sm-3">
            <a [routerLink]="['/', userSlug, 'products', product.slug]">
              <img class="group list-group-image img-fluid" style="max-width: 50px; max-height: 50px;"
                src="{{product.photo_url}}" alt="{{product.title}}" title="{{product.title}}" />
            </a><br />
            <a [routerLink]="['/', userSlug, 'products', product.slug]">{{product.title}}</a>
          </div>
          <div class="item col-sm-3">
            € {{product.price}}
          </div>
          <div class="item col-sm-3">
            <input type="text" class="quantity" value="{{product.stock || 0}}" (keypress)="numericOnly($event)"
              (change)="updateStock(product, $event.target.value)"
              *ngIf="userService.userHasProducts && userService.isProductCreator(product)" />
          </div>
          <div class="item col-sm">
            <div class="col-xs-12 col-md-6"
              *ngIf="userService.userHasProducts && userService.isProductCreator(product)">
              <a [routerLink]="['/', userSlug, 'products', product.slug,  'edit']">Bewerken</a><br />
              <a [routerLink]="['/', userSlug, 'products', product.slug]">Bekijk product</a><br />
              <a (click)="deleteProduct(product)" href="javascript:void(0)">Verwijder</a>
            </div>
          </div>
        </div>
      </div>
      <pagination 
      [currentPage]="pagination.currentPage"
      [hasMore]="pagination.hasMore"
      (loadMore)="onNextPage()"></pagination> 
    </div>
  </div>
</div>