import { Injectable } from '@angular/core';
import { Firestore, CollectionReference, collection, addDoc } from '@angular/fire/firestore';

import { ServiceBase } from './service-base';
import { User } from 'app/models/user';
import { Newsletter } from 'app/models/newsletter';
import { Observable, Subject } from 'rxjs';
import { NewsletterCreator } from 'app/models/newsletter-creator';
import { Product } from 'app/models/product';
import { converter } from 'app/models/converter';
import { UploadedFileMap } from 'app/models/uploaded-file-map.interface';

@Injectable()
export class NewsletterService extends ServiceBase {
  private newsletterCreatorStepSubject = new Subject<string>();
  public newsletterCreatorStepState: Observable<string> = this.newsletterCreatorStepSubject.asObservable();

  private newsletterCreatorModelSubject = new Subject<NewsletterCreator>();
  public newsletterCreatorModelState: Observable<NewsletterCreator> = this.newsletterCreatorModelSubject.asObservable();
  private newsletters: CollectionReference<Newsletter>

  constructor(private db: Firestore) {
    super();

    this.newsletters = collection(db, 'newsletters').withConverter(converter<Newsletter>())
  }

  setNewsletterCreatorStep(step: string) {
    this.newsletterCreatorStepSubject.next(step);
  }

  setNewsletterCreatorModel(model: NewsletterCreator) {
    this.newsletterCreatorModelSubject.next(model);
    localStorage.setItem('newsletterCreatorModel', JSON.stringify(model));
  }

  removeNewsletterCreatorModelFromLocalStorage() {
    localStorage.removeItem('newsletterCreatorModel');
  }

  get newsletterCreatorModel(): NewsletterCreator {
    const creator = localStorage.getItem('newsletterCreatorModel')
    if (!creator) {
      return new NewsletterCreator();
    }

    let newsletterObject = JSON.parse(creator) as NewsletterCreator;
    let model: NewsletterCreator = new NewsletterCreator();
    model.subject = newsletterObject.subject;
    model.preview = newsletterObject.preview;
    model.title = newsletterObject.title;
    model.text = newsletterObject.text;
    model.products = newsletterObject.products;
    model.productLayout = newsletterObject.productLayout;

    let productArr: Product[] = [];
    let imagesArr: UploadedFileMap = new UploadedFileMap();
    model.products = Object.assign(productArr, newsletterObject.products);
    model.promotionImages = Object.assign(imagesArr, newsletterObject.promotionImages);

    return model;
  }

  async create(shopUser: User, user: User): Promise<Newsletter> {
    const newsletter: Newsletter = new Newsletter();

    newsletter.send_date = new Date();
    newsletter.shop_user_fullname = shopUser.displayName;
    newsletter.shop_user_id = shopUser.id;
    newsletter.user_id = user.id;
    newsletter.shop_user_slug = shopUser.slug;
    newsletter.user_fullname = user.displayName;

    return addDoc(this.newsletters, newsletter).then(ref => {
      newsletter.id = ref.id
      return newsletter
    })
  }
}
