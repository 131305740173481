<div id="userGroup" class="mx-auto" *ngIf="user">
    <section class="card p-2 mb-2 private-group">
        <img src="{{user.photo}}" class="responsive profile-photo" />
        <h3 align="center">Privégroep van {{user.displayName}}</h3>
        <div class="text-center" *ngIf="user.hasShopTitle">
            Eigenaar van {{user.shopTitle}}
        </div>    
    </section>
    <register *ngIf="!hasSxUser" [privateShopUserSlug]="userSlug" [loginRoute]="loginUrl" [registerRoute]="registrationUrl" [showLoginForm]="showLoginForm" [showRegistrationForm]="showRegistrationForm"></register>    
    <button *ngIf="hasSxUser && showRequestMembership && !createdInviteLink && !hasPendingMembership" class="btn btn-primary" (click)="requestMembership()">Lidmaatschap aanvragen</button>
    <div *ngIf="hasSxUser && createdInviteLink || hasSxUser && hasPendingMembership">
        Lidmaatschap is aangevraagd! Er is een email verstuurd naar {{sxUser.email}}.
    </div>

    <section class="warning-member mx-auto" *ngIf="hasSxUser && !showRequestMembership">
        <div class="thumbnail">
            <div class="caption card-body bg-danger text-white card">
                Je bent al lid van deze groep
            </div>
        </div>        
    </section>

    <button *ngIf="hasSxUser && !showRequestMembership" class="btn btn-primary mt-2" (click)="visitGroup()">Bezoek privégroep</button>

</div>