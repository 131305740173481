<div id="product-detail" class="container-fluid">
    <div class="item">
        <div class="row">
            <div class="card mb-12">
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="card-body">
                            <h5 class="card-title">Uitnodiging</h5>
                            <div class="col" *ngIf="sxUser">
                                <div class="col">
                                    Laat je vrienden kennismaken met {{sxUser.shopTitle}} <span class="text-danger">WERKT NOG NIET</span>
                                </div>
                                <div class="col">
                                    <a [routerLink]="['/refer-a-friend']">https://www.stylexplore.com/refer-a-friend</a>
                                </div>                                    
                            </div>                            
                            <div class="col pt-2" *ngIf="sxUser.isShopOwner">
                                <div class="col">Laat vrienden je privéwinkel zien</div>                                
                                <div class="col">
                                    <a [routerLink]="['/', sxUser.slug, 'products']">https://www.stylexplore.com/{{sxUser.slug}}/products</a>
                                </div>                                    
                            </div>
                            <div class="col pt-2" *ngIf="userService.userHasProducts && sxUser.privateShop">
                                <div class="col">Verstuur uitnodiging privégroep</div>                                
                                <div class="col">
                                    <a [routerLink]="['/', sxUser.slug, 'new-invite']">https://www.stylexplore.com/{{sxUser.slug}}/new-invite</a>
                                </div>                                    
                            </div>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>  