import { Component, OnInit } from '@angular/core';
import { OrderService } from 'app/services/order.service';
import { Order } from 'app/models/order';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  public order: Order;
  constructor(private orderService: OrderService) { 
    this.order = this.orderService.getCurrentOrder()
  }

  ngOnInit() {
    
  }
}
