import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ServiceBase {
    protected ngUnsubscribe: Subject<void> = new Subject();

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}