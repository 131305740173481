import { Serializeable } from 'app/models/serializeable';
import { Product } from './product';
import { UploadedFile } from './uploaded_file';
import { UploadedFileMap } from './uploaded-file-map.interface';

export class NewsletterCreator extends Serializeable {
    static instanceType = NewsletterCreator;

    id: string;
    subject: string;
    preview: string;
    title: string;
    text: string;
    products: Product[] = [];
    promotionImages: UploadedFileMap = new UploadedFileMap();
    productLayout: string;

    constructor(id = null) {
        super();

        if (this.id !== null) {
            this.id = id;
        }
    }

    get canSelectProducts(): boolean {
        if (this.subject === undefined || this.subject == null || this.subject === '') {
            return false;
        }

        if (this.title === undefined || this.title == null || this.title === '') {
            return false;
        }

        if (this.text === undefined || this.text == null || this.text === '') {
            return false;
        }

        return true;
    }
}