import { Serializeable } from '../../serializeable';
import { OrderLine } from 'app/models/orderline';
import { User } from 'app/models/user';
import { ReferralInvitation } from 'app/models/referral/referral_invitation';

export class MailReferralInvitationToProspect extends Serializeable {
    static instanceType = MailReferralInvitationToProspect;
   
    public prospect_firstname: string;
    public prospect_lastname: string;

    public shopowner_fullname: string;
    public shopowner_email: string;
    public shopowner_photo: string;
    public shopowner_linkshop: string;
    public shopowner_phoneNumber: string;
    public shop_title: string;

    public promotor_firstname: string;
    public promotor_lastname: string;
    public promotor_email: string;
    public promotor_phoneNumber: string;
    public promotor_referral_link: string;
    public promotor_invitation_text: string;

    constructor(shopUser: User, promotorUser: User, invitation: ReferralInvitation) {
        super();
        
        this.prospect_firstname = invitation.prospect_first_name;
        this.prospect_lastname = invitation.prospect_last_name;

        this.shopowner_fullname = shopUser.displayName;
        this.shopowner_email = shopUser.email;
        this.shopowner_phoneNumber = shopUser.phoneNumber;
        this.shopowner_photo = shopUser.photo;
        this.shopowner_linkshop = 'https://www.stylexplore.com/' + shopUser.slug + '/products';
        this.shop_title = shopUser.shopTitle;

        this.promotor_firstname = promotorUser.firstname;
        this.promotor_lastname = promotorUser.lastname;
        this.promotor_email = promotorUser.email;
        this.promotor_phoneNumber = promotorUser.phoneNumber;
        this.promotor_referral_link = 'https://www.stylexplore.com/referrer/' + promotorUser.slug + "/" + invitation.id;
        this.promotor_invitation_text = promotorUser.referralEmailText;
    }
}