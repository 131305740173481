import { Serializeable } from 'app/models/serializeable';
import { User } from 'app/models/user';
import { Product } from 'app/models/product';

export class RepeatServiceManual extends Serializeable {
    static instanceType = RepeatServiceManual;

    id: string;
    customer: string;
    product: Product;
    shopOwner: string;
    shopOwners: User[];
    customers: User[];
    products: Product[] = [];

    constructor(id = null) {
        super();

        if (this.id !== null) {
            this.id = id;
        }
    }

    get hasCustomer(): boolean {
        return this.customer != null || this.customer != undefined;
    }

    get hasProduct(): boolean {
        return this.product != null || this.product != undefined;
    }

    get hasShopOwner(): boolean {
        return this.shopOwner != null || this.shopOwner != undefined;
    }

    get hasCustomers(): boolean {
        return this.customers != null || this.customers != undefined && this.customers.length > 0;
    }

    get hasShopOwners(): boolean {
        return this.shopOwners != null || this.shopOwners != undefined && this.shopOwners.length > 0;
    }

}
