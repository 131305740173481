<h3>Stap 1. Klantgegevens</h3>

<div class="container">
  <div class="row">
    <div class="col-12">
      Maak je een order aan voor een nieuwe klant of een bestaande klant?
      <div>
        <input type="radio" value="newCustomer" name="customer" id="new-customer" checked (click)="switchCustomerType('newCustomer')"><label for="new-customer">Nieuwe klant</label>
      </div>
      <div>
        <input type="radio" value="existingCustomer" name="customer" id="existing-customer" (click)="switchCustomerType('existingCustomer')"><label for="existing-customer">Bestaande klant</label>
      </div>
      <div id="newCustomer" *ngIf="newCustomer">
        <h5 class="card-title">Voer klantgegevens in</h5>
        <form id="customerForm" [formGroup]="customerForm" (ngSubmit)="createNewCustomer(customerForm)">
          <div class="form-group">
            <label for="title">Voornaam</label>
            <input type="text" class="form-control" id="name" required formControlName="firstname" name="firstname"
              placeholder="Voornaam">
          </div>
          <div class="form-group">
            <label for="title">Achternaam</label>
            <input type="text" class="form-control" id="name" required formControlName="lastname" name="lastname"
              placeholder="Achternaam">
          </div>
          <div class="form-group">
            <label for="title">Email</label>
            <input type="text" class="form-control" id="email" required formControlName="email" name="email"
              placeholder="Email">
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                <div *ngIf="email.errors?.['emailExists']">Dit email adres is al gekoppeld aan een bestaande klant. Vul een uniek email adres in.</div>
                <div *ngIf="email.errors?.['userExists']">Dit email adres is gekoppeld aan een bestaande StyleExplore gebruiker die geen klant is. Vul een uniek email adres in.</div>
            </div>            
          </div>
          <button type="submit" class="btn btn-success">Volgende stap</button>
        </form>
      </div>
      <div id="existingCustomer" *ngIf="existingCustomer">
        <h5 class="card-title">Selecteer klant</h5>
        <form #inviteForm="ngForm" [formGroup]="existingCustomerForm" (ngSubmit)="createOrder(existingCustomerForm)">
          <div class="form-group">
            <select id="customer" required name="customer" formControlName="customer">
              <option value="">Selecteer een klant</option>
              <option value="{{customer.id}}" *ngFor="let customer of customers">{{customer.displayName}}</option>
            </select>
          </div>
          <div>
            <button type="submit" class="btn btn-success" (click)="createNewCustomer()">Volgende stap</button>
          </div>  
        </form>
      </div>
    </div>

  </div>
</div>