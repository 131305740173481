import { Component, OnInit } from '@angular/core';
import { Pagination } from 'app/models/pagination.model';
import { Product } from 'app/models/product';
import { AuthService } from 'app/services/auth.service';
import { PaginationService } from 'app/services/pagination.service';
import { ProductService } from 'app/services/product.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public products: Product[] = [];
  public pagination: Pagination;

  constructor(public authService: AuthService,
              public productService: ProductService, 
            public paginationService: PaginationService) { }

  ngOnInit() {
    this.pagination = new Pagination(12);
    this.fetchProducts();
  }

  fetchProducts() {
    this.productService.getAllProducts(this.pagination.itemsPerPage, this.pagination.lastVisible).then(async products => {
      await this.paginationService.loadMoreProducts(products);
      if (products.products.length > 0) {
        this.products.push(...products.products);
        this.pagination.updateLastVisible(products.lastVisible);
        this.pagination.setHasMore(products.products.length === this.pagination.itemsPerPage);        
      }
      else {
        this.pagination.setHasMore(false);
      }
    })

  }

  public get hasProducts() {
    return !!this.products.length
  }

  onNextPage() {
    this.pagination.changePage(this.pagination.currentPage + 1);
    this.fetchProducts();
  }

}
