<nav class="navbar navbar-expand-md navbar-light bg-light" role="navigation">
    <div class="brand-bar">
        <div class="navbar-brand">
            <a href="#">
                <img src="..\..\assets\images\sx_logo.svg" class="header_img" alt="StyleXplore">
            </a>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#menu-items"
            aria-controls="menu-items" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>


    <div class="collapse navbar-collapse bg-light" id="menu-items">
        <ul class="navbar-nav">
            <li class="nav-item pr-2" *ngIf="!userService.sxUser">
                <a [routerLink]="['login']" class="nav-link">Inloggen</a>
            </li>
            <li class="nav-item pr-2" *ngIf="userService.sxUser">
                <div class="d-flex flex-column text-right pt-1">
                    <div>Welkom</div>
                    <div class="font-weight-bold">{{userService.sxUser.firstname}}</div>
                </div>
            </li>
            <li class="nav-item pr-2">
                <a class="nav-link" data-html="true" data-placement="bottom" data-popover-content="#a1" data-toggle="popover" data-trigger="focus" tabindex="0">
                    <div class="fas fa-user"></div>
                </a>
                <div class="hidden d-none popover-container" id="a1">
                    <div class="popover-body">
                        <ul class="nav navbar-nav">
                            <li *ngIf="!currentFirebaseUser"><a class="dropdown-item" [routerLink]="['products']" class="nav-link">Producten</a></li>
                            <li *ngIf="!currentFirebaseUser"><hr class="dropdown-divider m-0" /></li>                            
                            <li *ngIf="currentFirebaseUser && userService.userHasProducts"><a class="dropdown-item" [routerLink]="['customer-order-creator']" class="nav-link">Klantorder</a></li>
                            <li *ngIf="currentFirebaseUser && userService.userHasProducts"><hr class="dropdown-divider m-0" /></li>
                            <li *ngIf="currentFirebaseUser"><a [routerLink]="['orders']" class="nav-link">Bestellingen</a></li>
                            <li *ngIf="currentFirebaseUser"><hr class="dropdown-divider m-0" /></li>
                            <li *ngIf="currentFirebaseUser"><a [routerLink]="['profile']" class="nav-link">Accountoverzicht</a></li>
                            <li *ngIf="currentFirebaseUser"><hr class="dropdown-divider m-0" /></li>
                            <li *ngIf="currentFirebaseUser && userService.userHasProducts"><a class="dropdown-item" [routerLink]="['newsletter-creator']" class="nav-link">Berichten</a></li>
                            <li *ngIf="currentFirebaseUser && userService.userHasProducts"><hr class="dropdown-divider m-0" /></li>
                            <li *ngIf="currentFirebaseUser && userService.userHasProducts"><a [routerLink]="['preferences']" class="nav-link">Gegevens &amp; voorkeuren</a></li>
                            <li *ngIf="currentFirebaseUser && userService.userHasProducts"><hr class="dropdown-divider m-0" /></li>
                            <li *ngIf="currentFirebaseUser && userService.userHasProducts"><a [routerLink]="['invitation']" class="nav-link">Uitnodiging</a></li>
                            <li *ngIf="currentFirebaseUser && userService.userHasProducts"><hr class="dropdown-divider m-0" /></li>
                            <li *ngIf="currentFirebaseUser && userService.sxUser && userService.sxUser.isShopOwner"><a [routerLink]="['product-links']" class="nav-link">Producten</a></li>
                            <li *ngIf="currentFirebaseUser && userService.sxUser && !userService.sxUser.isShopOwner"><a [routerLink]="['products']" class="nav-link">Producten</a></li>

                            <li *ngIf="currentFirebaseUser"><hr class="dropdown-divider m-0" /></li>
                            <li *ngIf="currentFirebaseUser"><a [routerLink]="['private-groups']" class="nav-link">Groepen</a></li>
                            <li *ngIf="currentFirebaseUser"><hr class="dropdown-divider m-0" /></li>                                                        
                            <li *ngIf="currentFirebaseUser"><a [routerLink]="['logout']" class="nav-link">Uitloggen</a></li>
                
                        </ul>   
                    </div>
                  </div>
            </li>
            <li class="nav-item">
                <a [routerLink]="['cart']" class="nav-link">
                    <span class="basket-icon"></span>
                    <span class="basket-badge badge badge-success">{{totalCartItems}}</span>
                </a>
            </li>
        </ul>
    </div>
</nav>

<router-outlet></router-outlet>