import { Serializeable } from 'app/models/serializeable';

export class EmailInfo extends Serializeable {
    static instanceType = EmailInfo;

    public name: string;
    public email: string;

    constructor(name: string, email: string) {
        super();

        this.name = name;
        this.email = email;
    }
}
