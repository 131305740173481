import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PrivateGroupInvitation } from 'app/models/private_group_invitation';
import { User } from 'app/models/user';
import { OrdermailerService } from 'app/services/ordermailer.service';
import { PrivateGroupService } from 'app/services/private-group.service';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.scss']
})
export class UserGroupComponent implements OnInit {

  public userSlug: string;
  public user: User;
  @Input() showLoginForm: boolean = false;
  @Input() showRegistrationForm: boolean = true;
  public showRequestMembership: boolean = false;
  public hasPendingMembership: boolean = false;
  public registrationUrl: string;
  public loginUrl: string;
  public hasSxUser: boolean;
  public createdInviteLink: boolean = false;
  public inviteId: string;
  public sxUser: User;

  constructor(public route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private privateGroupService: PrivateGroupService,
              private orderMailerService: OrdermailerService) { 
    this.route.params.subscribe(params => this.getUser(params));
    this.sxUser = this.userService.sxUser;
    this.hasSxUser = this.sxUser != null;
  }

  ngOnInit() {
  }

  requestMembership() {
    if (this.user.privateGroupMembers == null || this.user.privateGroupMembers === undefined) {
      this.user.privateGroupMembers = [];
    }

    this.privateGroupService.createInvitationForUser(this.user, this.userService.sxUser).then(invitation => {
      if (invitation != null) {
        this.createdInviteLink = true;
        this.inviteId = invitation.id;

        this.privateGroupService.sendPrivateGroupInvitationToUser(invitation, this.user, this.userService.sxUser);
      }
    });    
  }

  async getUser(params: Params) {
    this.userSlug = params.userslug;
    this.userService.findUserBy('slug', this.userSlug).then(async user => {
      this.user = user;
      this.registrationUrl = '/' + user.slug + '/private-group-accepted/:id';
      var redirectUrl: string = this.userService.redirectUrl;
      
      if (redirectUrl !== undefined && redirectUrl !== '') {
        this.loginUrl = redirectUrl;
      }
      else {
        this.loginUrl = '/' + user.slug + '/products';
      }

      if (this.userService.sxUser != null) {
        this.hasPendingMembership = await this.privateGroupService.userHasRequestedMembership(this.user.id, this.userService.sxUser.id);

        this.showRequestMembership = !this.user.isPrivateGroupMember(this.userService.sxUser.id);
      }

    }, ohnoes => {
      this.router.navigate(['not-found']);
    });
  }

  visitGroup() {
    this.router.navigate(['/', this.user.slug, 'products']);
  }

}
