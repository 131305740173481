import { Component, OnInit, Input } from '@angular/core';
import { ProductBaseComponent } from '../product-base/product-base.component';

@Component({
  selector: 'app-product-edit-photo',
  templateUrl: './product-edit-photo.component.html',
  styleUrls: ['./product-edit-photo.component.scss']
})
export class ProductEditPhotoComponent extends ProductBaseComponent {
  public mode: string = "edit";
  public displayMode: string = "multi";


  ngOnInit() {
  }

}
