import { Serializeable } from '../../serializeable';
import { OrderLine } from 'app/models/orderline';
import { User } from 'app/models/user';

export class MailInvitationToUser extends Serializeable {
    static instanceType = MailInvitationToUser;
   
    public shop_title: string;
    public shop_description: string;
    public order_contact: string;
    public shopowner_fullname: string;
    public shopowner_photo: string;
    public shopowner_mobilephone: string;
    public shopowner_email: string;
    public link: string;
    public private_group_link: string;
    public firstname: string;
    public fullname: string;

    constructor(shopUser: User, user: User, invitationLink: string, privateGroupLink: string) {
        super();
        
        this.shop_title = shopUser.shopTitle;
        this.shop_description = shopUser.shopDescription;
        this.order_contact = shopUser.orderContactDetails;
        this.shopowner_fullname = shopUser.displayName;
        this.shopowner_photo = shopUser.photo;
        this.shopowner_mobilephone = shopUser.phoneNumber;
        this.shopowner_email = shopUser.email;
        this.firstname = user.firstname;
        this.fullname = user.firstname + ' ' + user.lastname;
        this.link = "https://www.stylexplore.com" + invitationLink;
        this.private_group_link = "https://www.stylexplore.com" + privateGroupLink;
    }
}